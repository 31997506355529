import React, { useState } from 'react';
import { mdiCloseCircle } from '@mdi/js';
import Icon from '@mdi/react';
import {
  inputOnClickHandler,
  resetAndFocusInput,
  canResetValue,
  datePartConfig,
  sendManualValue,
  backTrackFocus,
} from '../dateInputHelper';
import { setValue } from '../dateInputManualStateHelper';
import classes from './DateInputBox.module.scss';
import classNames from 'classnames';
import {
  DateFormatProps,
  DateInputSetValueProps,
  DateInputActivityConfig,
  ManualInputState,
} from '../types';
import { translate, Translations } from '../../../../../locale';
import { KeyCodes } from '../../../../../constants';
import { Card } from '../../../../../middleware/types';
import BaseTranscript from '../../../../BaseTranscript/BaseTranscript';

interface OnBlurHandlerProps {
  event: React.ChangeEvent<HTMLInputElement>;
  datePart: string;
  inputRefs: HTMLInputElement[];
  index: number;
  config: DateInputActivityConfig;
  dateProps: DateFormatProps;
  dateValue: { [key: string]: string };
  setManualState: (manualState: ManualInputState) => void;
}

const onBlurHandler = (props: OnBlurHandlerProps) => {
  let value = props.event.target.value;

  if (
    value.length === 1 &&
    ['dd', 'mm'].indexOf(props.datePart.toLowerCase()) > -1
  ) {
    value = value.padStart(2, '0');

    const setValueProps: DateInputSetValueProps = {
      event: props.event,
      inputRefs: props.inputRefs,
      inputIndex: props.index,
      value,
      config: props.config,
      dateProps: props.dateProps,
      dateValue: props.dateValue,
      placeHolder: props.datePart,
      setManualState: props.setManualState,
    };
    setValue(setValueProps);
  }
};

interface DateInputBoxProps {
  dateProps: DateFormatProps;
  isError: boolean;
  clickedOutside: boolean;
  dateValue: { [key: string]: string };
  isValidDate: boolean;
  config: DateInputActivityConfig;
  card: Card;
  setManualState: (manualState: ManualInputState) => void;
  hideElement: () => void;
}

export function DateInputBox(props: DateInputBoxProps): JSX.Element | null {
  const [transcriptMessage, setTranscriptMessage] = useState<string>('');
  const { dateProps, dateValue, isError, clickedOutside } = props;

  if (!dateProps) {
    return null;
  }

  const inputRefs: HTMLInputElement[] = [];

  return (
    <div
      className={classNames(
        classes.DateInputBox,
        !clickedOutside ? classes.Focused : null,
        isError ? classes.Error : null
      )}
      aria-label={translate(Translations.manualDateInput)}
      data-testid={classes.DateInputBox}
    >
      {dateProps.dateFormat
        .split(dateProps.delimiter)
        .map((datePart: string, index: number, array: string[]) => {
          const id = datePart.toLowerCase();
          const placeHolder = dateProps.mask[index];
          const label = translate(
            //@ts-ignore
            Translations.manualDateInputLabels[
              //@ts-ignore
              datePartConfig[id].translationKey
            ]
          );

          const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const setValueProps: DateInputSetValueProps = {
              event,
              inputRefs,
              inputIndex: index,
              value: event.target.value,
              config: props.config,
              dateProps: props.dateProps,
              dateValue,
              placeHolder: datePart,
              setManualState: props.setManualState,
            };

            setValue(setValueProps);
          };

          const onBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
            const blurProps: OnBlurHandlerProps = {
              config: props.config,
              datePart,
              dateProps: props.dateProps,
              dateValue,
              event,
              index,
              inputRefs,
              setManualState: props.setManualState,
            };
            onBlurHandler(blurProps);
          };
          return (
            <React.Fragment key={index}>
              <input
                id={id}
                className={classNames(
                  classes.InputItem,
                  //@ts-ignore
                  classes[datePartConfig[id].className]
                )}
                value={dateValue[id]}
                onChange={onChange}
                onBlur={onBlur}
                onKeyUp={(event: React.KeyboardEvent) => {
                  if (event.key === KeyCodes.Enter && props.isValidDate) {
                    sendManualValue(
                      props.card,
                      dateProps,
                      dateValue,
                      props.config.id,
                      props.hideElement
                    );
                  }
                }}
                onKeyDown={(event: React.KeyboardEvent) => {
                  if (event.key === KeyCodes.Backspace) {
                    backTrackFocus(props.dateValue, id, inputRefs, index);
                  }
                }}
                onClick={inputOnClickHandler}
                placeholder={placeHolder}
                autoComplete="off"
                autoFocus={index === 0}
                ref={(inputRef) => (inputRef ? inputRefs.push(inputRef) : null)}
                aria-label={label}
                title={label}
                type="text"
                pattern="[0-9]*"
                inputMode="numeric"
              />

              {array.length - 1 === index ? null : (
                <span className={classes.InputSeparator}>
                  {dateProps.delimiter}
                </span>
              )}
            </React.Fragment>
          );
        })}

      {canResetValue(props.dateValue) ? (
        <span
          className={classes.InputValueResetContainer}
          onClick={() =>
            resetAndFocusInput(
              props.setManualState,
              dateProps,
              inputRefs[0],
              setTranscriptMessage
            )
          }
          onKeyUp={(e: React.KeyboardEvent) => {
            switch (e.key) {
              case KeyCodes.Enter:
              case KeyCodes.Spacebar:
                resetAndFocusInput(
                  props.setManualState,
                  dateProps,
                  inputRefs[0],
                  setTranscriptMessage
                );
                break;
            }
          }}
          tabIndex={0}
          aria-label={translate(Translations.resetDate)}
        >
          <Icon path={mdiCloseCircle} />
        </span>
      ) : null}

      <BaseTranscript transcriptMessage={transcriptMessage} />
    </div>
  );
}
