import { action } from 'typesafe-actions';
import { RecoveryActionTypes } from './types';
import { AnyAction } from 'redux';
import { CustomActivity } from '../../middleware/types';

export const setRetryRejectedActivitiesLimit = (retryCount: number) =>
  action(RecoveryActionTypes.SET_RETRY_REJECTED_ACTIVITIES_LIMIT, retryCount);

export const setLastUserRespondedActivity = (activityId: string) =>
  action(RecoveryActionTypes.SET_LAST_USER_RESPONDED_ACTIVITY, activityId);

export const setCurrentFailedActivity = (
  activity: CustomActivity | undefined
): AnyAction =>
  action(RecoveryActionTypes.SET_CURRENT_FAILED_ACTIVITY, activity);

export const setReceivedClientActivityIds = (clientActivityIds: string[]) =>
  action(
    RecoveryActionTypes.SET_RECEIVED_CLIENT_ACTIVITY_IDS,
    clientActivityIds
  );

export const resetRecoveryState = () => action(RecoveryActionTypes.RESET_STATE);

export const setPreviousActivity = (activity: CustomActivity) =>
  action(RecoveryActionTypes.SET_PREVIOUS_ACTIVITY, activity);
