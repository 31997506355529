import classNames from 'classnames';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  KeyboardEvent,
} from 'react';
import { AppInsightsUserInteraction, KeyCodes } from '../../../constants';
import { DataCustomActivity } from '../../../constants/testEnums';
import { BotPassThroughProps } from '../../../containers/BotMessageBase/BotMessageBase';
import CustomInputPortal from '../../../containers/CustomInputPortal/CustomInputPortal';
import { translate, Translations } from '../../../locale';
import { findCustomActivity } from '../../../middleware/activityMiddleware';
import {
  Card,
  CustomActivityConfig,
  KeyValue,
} from '../../../middleware/types';
import { trackComponentInteracted } from '../../../utils/appInsightHelper';
import {
  useInitialFocusCallback,
  useLifecycleTracking,
} from '../../../utils/customHooks';
import { sendMessageBack } from '../../../utils/directLineUtils';
import { canBeEditedCustomUserResponse } from '../../../utils';
import CheckBox from '../../UI/Checkbox/Checkbox';
import classes from './MultipleChoice.module.scss';

import ButtonPrimary from '@/components/Buttons/ButtonPrimary/ButtonPrimary';

export interface MultipleChoiceActivityConfig extends CustomActivityConfig {
  confirmButton: string;
  itemList: KeyValue[];
  separator: string;
}

interface Toggle {
  index: number;
  title: string;
  checked: boolean;
}

const getInitialToggles = (
  itemList: KeyValue[],
  separator: string,
  prefillText: string | undefined
): Toggle[] => {
  if (!itemList || itemList.length === 0) {
    return [];
  }

  const preSelectedItems = prefillText?.split(separator);
  return itemList.map(
    (item, index) =>
      ({
        index,
        title: item.value,
        checked: preSelectedItems?.some(
          (preSelectedItem) => preSelectedItem.trim() === item.value.trim()
        ),
      } as Toggle)
  );
};

const updateToggleState = (
  toggleCopy: Toggle[],
  index: number,
  setToggles: Dispatch<SetStateAction<Toggle[]>>
) => {
  const previousValue = toggleCopy[index].checked;
  toggleCopy[index].checked = !previousValue;
  setToggles(toggleCopy);
};

const updateSendButtonAvailability = (
  toggleCopy: Toggle[],
  setSendDisabled: Dispatch<SetStateAction<boolean>>
) => {
  const checkedItems = toggleCopy.filter((item) => item.checked);
  setSendDisabled(checkedItems.length === 0);
};

const checkboxHandler = (
  index: number,
  toggles: Toggle[],
  setToggles: Dispatch<SetStateAction<Toggle[]>>,
  setIsSendDisabled: Dispatch<SetStateAction<boolean>>
) => {
  const toggleCopy = [...toggles];
  updateToggleState(toggleCopy, index, setToggles);
  updateSendButtonAvailability(toggleCopy, setIsSendDisabled);
};

const onKeyUpHandler = (
  event: KeyboardEvent<HTMLElement>,
  index: number,
  toggles: Toggle[],
  setToggles: Dispatch<SetStateAction<Toggle[]>>,
  setIsSendDisabled: Dispatch<SetStateAction<boolean>>
) => {
  if (event.key === KeyCodes.Spacebar || event.key === KeyCodes.Enter) {
    checkboxHandler(index, toggles, setToggles, setIsSendDisabled);
  }
};

const submitMultipleChoice = (
  hideChildrenOfElement: () => void,
  toggles: Toggle[],
  separator: string,
  card: Card,
  setIsSendDisabled: Dispatch<SetStateAction<boolean>>
) => {
  const itemsToSubmit = toggles
    .filter((t: Toggle) => t.checked)
    .map((t: Toggle) => t.title)
    .join(`${separator} `);

  setIsSendDisabled(true);
  hideChildrenOfElement();

  trackComponentInteracted(
    card.activity.id!,
    AppInsightsUserInteraction.ValueSubmitted,
    itemsToSubmit
  );
  sendMessageBack(
    itemsToSubmit,
    {
      canBeEdited: canBeEditedCustomUserResponse(card),
    },
    card.activity
  );
};

export default function MultipleChoice(
  props: BotPassThroughProps
): JSX.Element {
  const config = findCustomActivity(
    props.card.activity
  ) as MultipleChoiceActivityConfig;

  const initialToggles = getInitialToggles(
    config.itemList,
    config.separator,
    config.prefillText
  );

  if (initialToggles.length === 0) {
    trackComponentInteracted(
      props.card.activity.id!,
      AppInsightsUserInteraction.MultipleChoiceNoTogglesAvailableForDisplay
    );
  }

  const isAnyToggleChecked =
    initialToggles.filter((t: Toggle) => t.checked).length > 0;

  const [toggles, setToggles] = useState(initialToggles);
  const [isSendDisabled, setIsSendDisabled] = useState(!isAnyToggleChecked);

  const [, setRef] = useInitialFocusCallback();

  // log mount/unmount events to appInsights
  useLifecycleTracking(props.card.activity.id);

  useEffect(() => {
    trackComponentInteracted(
      config.id,
      AppInsightsUserInteraction.MultipleChoiceValueChosen,
      JSON.stringify({ toggles, isSendDisabled })
    );
  }, [config.id, toggles, isSendDisabled]);

  const multipleChoiceContainerLabel = translate(
    Translations.multipleChoiceChoicesAvailable,
    toggles?.length,
    [String(toggles?.length)]
  );

  return (
    <>
      <div
        className={classes.ListGroup}
        ref={setRef}
        aria-label={multipleChoiceContainerLabel}
      >
        {toggles.map((toggle: Toggle, index: number) => {
          return (
            <CheckBox
              key={index}
              styleClassName={classNames(classes.ListGroupItem)}
              onClickHandler={() =>
                checkboxHandler(
                  toggle.index,
                  toggles,
                  setToggles,
                  setIsSendDisabled
                )
              }
              onKeyUpHandler={(event: KeyboardEvent<HTMLElement>) =>
                onKeyUpHandler(
                  event,
                  toggle.index,
                  toggles,
                  setToggles,
                  setIsSendDisabled
                )
              }
              dataCustomActivity={
                DataCustomActivity.SELECT_MULTIPLE_CHOICE_ITEM_AT_INDEX +
                `_${index}`
              }
              checked={toggle.checked}
              label={toggle.title}
            />
          );
        })}
      </div>

      <CustomInputPortal>
        <div className={classes.SendButtonContainer}>
          <ButtonPrimary
            disabled={isSendDisabled}
            onClick={() =>
              submitMultipleChoice(
                props.hideChildrenOfElement,
                toggles,
                config.separator,
                props.card,
                setIsSendDisabled
              )
            }
          >
            {config.confirmButton}
          </ButtonPrimary>
        </div>
      </CustomInputPortal>
    </>
  );
}
