import { FunctionComponent } from 'react';

import classes from './TextResponse.module.scss';

import { ActivityRoles } from '@/middleware/types';

import { translate, Translations } from '@/locale';

import ReactMarkdown from 'react-markdown';

interface TextResponseProps {
  text: string;
}

const TestResponse: FunctionComponent<TextResponseProps> = ({ text }) => {
  return (
    <div
      data-user-role={ActivityRoles.User}
      aria-label={translate(Translations.youSaid)}
      className={classes.TextResponse}
    >
      {text && <ReactMarkdown linkTarget={'_blank'}>{text}</ReactMarkdown>}
    </div>
  );
};

export default TestResponse;
