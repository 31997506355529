import { useState, useEffect, memo } from 'react';

import { Store } from 'redux';

import ReactWebChat from 'botframework-webchat-component';

import { StyleOptions } from 'botframework-webchat-api';
import { DirectLine } from 'botframework-directlinejs';

import { activityMiddleware } from '../../middleware/activityMiddleware';
import { activityStatusMiddleware } from '../../middleware/activityStatusMiddleware';
import {
  Selectors,
  AppInsightsEventNames,
  ErrorMessage,
} from '../../constants';
import { initGoogleTagManager } from '../../middleware/telemetry/googleTagManagerHelper';
import {
  connectToDirectline,
  reconnectToDirectline,
} from './initialization/directLine';
import { createChatStore } from './initialization/chatStore';
import { setupRecovery } from './initialization/recovery';
import { appInsights } from '@/appInsights/appInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
  createTheme,
  getWebchatStyleOptions,
} from './initialization/chatTheme';
import { ConfigurableTheme } from '@/styles/types';
import { getConfig, handleWebchatTelemetry } from '@/utils';
import { useThrowError, ThrowErrorFunction } from '@/hooks';
import { WebchatConfigurationKeys } from '@/configuration/types';

export interface WebchatProps {
  locale?: string;
  chatTheme: ConfigurableTheme | null;
}

const initWebChat = async (
  setDirectLine: (directline: DirectLine) => void,
  setChatStore: (chatStore: Store | null) => void,
  triggerReconnect: () => void,
  throwError: ThrowErrorFunction
) => {
  try {
    setDirectLine(await connectToDirectline());
    setupRecovery();

    const cs = createChatStore({ triggerReconnect, throwError });
    appInsights.trackEvent(
      {
        name: AppInsightsEventNames.ChatStoreInitialization,
      },
      {
        chatStore: cs,
      }
    );
    setChatStore(cs);
  } catch (e) {
    const error = e as Error;
    appInsights.trackException({
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties: { data: ErrorMessage.ChatInitFailed },
    });

    throwError(error);
  }

  //This adds css styles to root including partner site designs, its needed for now
  createTheme();
};

const reconnect = async (
  watermark: number,
  setDirectLine: (directline: DirectLine) => void,
  throwError: ThrowErrorFunction
) => {
  setDirectLine(await reconnectToDirectline(watermark, throwError));
};

export const WebChat = ({
  chatTheme,
  locale,
}: WebchatProps): JSX.Element | null => {
  const [directLine, setDirectLine] = useState<DirectLine | null>(null);
  const [needsReconnect, setNeedsReconnect] = useState<boolean>(false);

  const [chatStore, setChatStore] = useState<Store | null>(null);
  const [styleOptions, setStyleOptions] = useState<StyleOptions | null>(null);
  const throwError = useThrowError();

  useEffect(() => {
    const googleTagManagerKey = getConfig(
      WebchatConfigurationKeys.GOOGLE_TAG_MANAGER_KEY
    );
    initGoogleTagManager(googleTagManagerKey);

    const triggerReconnectCallback = () => setNeedsReconnect(true);
    void initWebChat(
      setDirectLine,
      setChatStore,
      triggerReconnectCallback,
      throwError
    );
  }, []);

  useEffect(() => {
    if (needsReconnect && directLine) {
      void reconnect(
        directLine['watermark'] as number,
        setDirectLine,
        throwError
      );
      setNeedsReconnect(false);
    }
  }, [needsReconnect, directLine]);

  useEffect(() => {
    chatTheme && setStyleOptions(getWebchatStyleOptions());
  }, [JSON.stringify(chatTheme)]);

  return directLine && chatStore && styleOptions ? (
    <ReactWebChat
      styleOptions={styleOptions}
      directLine={directLine}
      store={chatStore}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      activityMiddleware={activityMiddleware}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      activityStatusMiddleware={activityStatusMiddleware}
      locale={locale}
      className={Selectors.ChatBotRoot}
      onTelemetry={(event) => handleWebchatTelemetry(event, appInsights)}
    />
  ) : null;
};

export default memo(WebChat);
