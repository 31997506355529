// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"borderRadius": "1.5rem",
	"bubbleBorderRadius": ".5rem",
	"sendBoxBorderWidth": ".125rem",
	"sendBoxInputHeight": "2.75rem",
	"sendBoxLineHeight": "24",
	"sendBoxMaxLines": "4",
	"sendBoxMargins": ".5rem 1rem .5rem .625rem",
	"maxChatHeight": "750px",
	"headerZIndex": "2"
};
export default ___CSS_LOADER_EXPORT___;
