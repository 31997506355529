export interface PointOfNoReturnInfo {
  userActivityId: string;
  messageSequence: number;
  canBeEdited: boolean;
  postActivityRejected: boolean;
}

export interface BotConfigState {
  error?: string;
  lastBotMessageId?: string;
  lastMessageId?: string;
  editActivityIds: string[];
  pointOfNoReturnInfo: PointOfNoReturnInfo[];
  isPostActivityFulfilled: boolean;
  lastPostReachedBotService: boolean;
  setDoNotAddNextPointOfNoReturn: boolean;
  firstVisibleMessageReceived: boolean;
  isConsoleVisible: boolean;
  isTypingVisible: boolean;
  conversationId: string | null;
}

export enum BotConfigActionTypes {
  CLEAR_CONFIG = 'BOT_CONFIG/CLEAR_CONFIG',
  SET_LAST_BOT_ACTIVITY_ID = 'BOT_CONFIG/SET_LAST_BOT_ACTIVITY_ID',
  SET_LAST_ACTIVITY_ID = 'BOT_CONFIG/SET_LAST_ACTIVITY_ID',
  SET_EDIT_ACTIVITY_IDS = 'BOT_CONFIG/SET_EDIT_ACTIVITY_IDS',
  SET_POINT_OF_NO_RETURN_INFO = 'BOT_CONFIG/SET_POINT_OF_NO_RETURN_INFO',
  SET_IS_POST_ACTIVITY_FULFILLED = 'BOT_CONFIG/SET_IS_POST_ACTIVITY_FULFILLED',
  SET_LAST_POST_REACHED_BOT_SERVICE = 'BOT_CONFIG/SET_LAST_POST_REACHED_BOT_SERVICE',
  SET_LAST_USER_MESSAGE_POST_FULFILL_REJECTED = 'BOT_CONFIG/SET_LAST_USER_MESSAGE_POST_FULFILL_REJECTED',
  SET_LAST_USER_MESSAGE_CAN_BE_EDITED = 'BOT_CONFIG/SET_LAST_USER_MESSAGE_CAN_BE_EDITED',
  SET_DO_NOT_ADD_NEXT_POINT_OF_NO_RETURN = 'BOT_CONFIG/SET_DO_NOT_ADD_NEXT_POINT_OF_NO_RETURN',
  SET_FIRST_VISIBLE_MESSAGE_RECEIVED = 'BOT_CONFIG/SET_FIRST_VISIBLE_MESSAGE_RECEIVED',
  SET_CONVERSATION_ID = 'BOT_CONFIG/SET_CONVERSATION_ID',
  SET_CONSOLE_VISIBILITY = 'BOT_CONFIG/SET_CONSOLE_VISIBILITY',
  SET_IS_KEYBOARD_USER = 'BOT_CONFIG/SET_IS_KEYBOARD_USER',
  SET_TYPING_VISIBILITY = 'BOT_CONFIG/SET_TYPING_VISIBILITY',
  DISABLE_ALL_PREVIOUS_NODE_EDITING = 'DISABLE_ALL_PREVIOUS_NODE_EDITING',
}

export enum BusinessChannel {
  Phone = 'Phone',
  OpenPages = 'Web',
  MyPages = 'MyPages',
}
