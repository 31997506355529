import { appInsights } from '@/appInsights/appInsights';
import { AppStatus, NodeType, StatisticsNodeEventTypes } from '@/constants';
import {
  isDevEnvironment,
  isTestEnvironment,
} from '@/utils';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Message } from 'botframework-directlinejs';
import { findCustomActivity } from '../activityMiddleware';
import { CustomActivityConfig, MessageTypes } from '../types';
import { configuredStore } from '@/store';
import { addChatStatus } from '@/store/chat-activities-config/actions';

export function sendStatusFromStatistics(statisticsNodeStatus: string) {
  switch (statisticsNodeStatus) {
    case StatisticsNodeEventTypes.Started: {
      sendStatus(NodeType.Statistics, AppStatus.ConversationStarted)
      break;
    }
    case StatisticsNodeEventTypes.ClaimStarted: {
      sendStatus(NodeType.Statistics, AppStatus.ClaimStarted);
      break;
    }
    case StatisticsNodeEventTypes.ClaimSent: {
      sendStatus(NodeType.Statistics, AppStatus.ClaimSubmitted);
      break;
    }
  }
}

export function sendStatus(messageType: string, status: AppStatus) {

    if (isDevEnvironment() || isTestEnvironment()) {
      console.info(`${messageType}: ${status}`);
    }

    configuredStore.store.dispatch(addChatStatus(status))

    if (isIfiOSApp()) {
      try {
        window.webkit.messageHandlers.updateStatus.postMessage(status);
      } catch (e) {
        const error = e as Error;
        appInsights.trackException({
          error,
          severityLevel: SeverityLevel.Error,
        });
      }
    } else if (isIfAndroidApp()) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        updateStatus.Update(status);
      } catch (e) {
        const error = e as Error;
        appInsights.trackException({
          error,
          severityLevel: SeverityLevel.Error,
        });
      }
    }
}

function isIfiOSApp() {
  return /IfiOS/.test(navigator.userAgent);
}

function isIfAndroidApp() {
  return /IfAndroid/.test(navigator.userAgent);
}

export function SendEndOfConversationStatus(activity: Message) {
  const customActivity = findCustomActivity(activity) as CustomActivityConfig;
  if (
    customActivity &&
    customActivity.messageType === MessageTypes.endOfConversation
  ) {
    sendStatus(NodeType.End, AppStatus.ConversationEnded);
  }
}
