import classNames from 'classnames';
import React from 'react';
import classes from './Alert.module.scss';

export interface AlertProps {
  message: string;
  stickToRightSideOnDesktop?: boolean;
  stickToRightSideOnMobile?: boolean;
}

export const Alert = (props: AlertProps) => {
  return (
    <div
      className={classNames(
        classes.Alert,
        props.stickToRightSideOnDesktop ? classes.RightSideDesktop : null,
        props.stickToRightSideOnMobile ? classes.RightSideMobile : null
      )}
      role="alert"
    >
      <div className={classes.AlertIcon} />
      <span className={classes.AlertMessage}>{props.message}</span>
    </div>
  );
};

export default Alert;
