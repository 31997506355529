import { FunctionComponent } from 'react';
import { translate, Translations } from '@/locale';
import ButtonPrimary from '@/components/Buttons/ButtonPrimary/ButtonPrimary';
import ButtonSecondary from '@/components/Buttons/ButtonSecondary/ButtonSecondary';

export interface ModalFooterProps {
  disablePrimaryButton: boolean;
  submit: () => void;
  cancel: () => void;
  children?: React.ReactNode;
}

const ModalFooter: FunctionComponent<ModalFooterProps> = ({
  disablePrimaryButton,
  cancel,
  submit,
}: ModalFooterProps) => {
  return (
    <div>
      <ButtonSecondary onClick={cancel}>
        {translate(Translations.cancel)}
      </ButtonSecondary>

      <ButtonPrimary disabled={disablePrimaryButton} onClick={submit}>
        {translate(Translations.select)}
      </ButtonPrimary>
    </div>
  );
};

export default ModalFooter;
