/* eslint-disable @typescript-eslint/unbound-method */
import { AnyAction, Dispatch, Middleware } from 'redux';
import { WebChatActivity } from 'botframework-webchat-core';
import initChat from './events/initChat';
import {
  Card,
  ActivityRoles,
  DirectLineActionTypes,
  DirectLineConnectionStatus,
  ActivityTypes,
  DEBUG_PREFIX,
  StatisticsEvent,
  WebChatActionTypes,
} from './types';
import { configuredStore } from '@/store';
import {
  setLastBotActivityId,
  setPointOfNoReturnInfo,
  setIsPostActivityFulfilled,
  setLastActivityId,
  setFirstVisibleMessageReceived,
  setLastUserMessageCanBeEdited,
  disableAllPreviousNodeEditing,
  setLastPostReachedBotService,
} from '../store/bot-config/actions';
import { Message } from 'botframework-directlinejs';
import { isEditedActivity, getEntity } from '../utils/cardHelper';
import { ThrowErrorFunction } from '@/hooks';

import { isDevEnvironment, resetConversation } from '@/utils';

import { gtmSendPushEvent } from './telemetry/googleTagManagerHelper';
import { appInsights } from '@/appInsights/appInsights';
import { AppInsightsEventNames, AuthenticationRoutes } from '../constants';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import {
  finalizeFailedActivity,
  cleanUpRetryActivities,
  includeBotReceivedMessages,
} from './helpers/retryActivityHelper';
import {
  getToggle,
  FeatureToggles,
  getIntToggle,
} from '../utils/featureToggles';
import {
  setLastUserRespondedActivity,
  setPreviousActivity,
} from '../store/recovery/actions';
import handleTypingForActivity from '@/utils/activity/handleTypingForActivity';
import {
  SendEndOfConversationStatus,
  sendStatusFromStatistics,
} from './helpers/mobileAppHelper';
import { callApi } from '../api/callApi';
import {Endpoints, RequestMethod, ResponseStatus} from '@/api/types';
import { AuthenticationResult } from '../utils/authUtils';
import {AxiosError} from "axios";
let lastPostActivityTimestamp: number;
let timeoutWaitingClientActivityId: string;
let timeoutWaitingTimer: NodeJS.Timeout | null = null;

//not 100% in-line with Botframework webchat due to typings for webchat store not available yet
interface WebchatState {
  activities: WebChatActivity[];
  connectivityStatus: string;
  dictateState: number;
  language: string;
  readyState: number;
  referenceGrammarID?: string;
  sendBoxValue: string;
  sendTimeout: number;
  sendTypingIndicator: boolean;
  shouldSpeakIncomingActivity: boolean;

  //properties with undeterminable types as of now
  /* eslint-disable @typescript-eslint/no-explicit-any */
  dictateInterims: any[];
  suggestedActions: any[];
  typing: any;
  lastTypingAt: any;
  notifications: any;
  /* eslint-enable @typescript-eslint/no-explicit-any */
}

type ChatStoreMiddlewareWrapper = (
  triggerReconnect: () => void,
  throwError: ThrowErrorFunction
) => Middleware<Record<string, unknown>, WebchatState>;

export const chatStoreMiddlewareWrapper: ChatStoreMiddlewareWrapper =
  (triggerReconnect, throwError) => (middlewareApi) => (next) => (action) => {
    if (getToggle(FeatureToggles.webchatLogAllBotResponses)) {
      appInsights.trackEvent(
        { name: AppInsightsEventNames.DirectlineIncomingTraffic },
        { action }
      );
    }

    if (
      action.payload &&
      action.payload.connectionStatus ===
        DirectLineConnectionStatus.FailedToConnect
    ) {
      appInsights.trackTrace({
        message: 'Warn: Direct line failed to connect',
        severityLevel: SeverityLevel.Warning,
      });
    }

    if (action.type === WebChatActionTypes.SEND_MESSAGE_BACK) {
      const authSessionHash = configuredStore.store.getState().bffAuthenticationState.authSessionHash ?? '';
      callApi<boolean, any>({ url: `${Endpoints.CHECK_AUTH_SESSION}?authSessionHash=${authSessionHash}`, method: RequestMethod.POST, baseURL: '' }).then(response => {
        if (response.status !== 200 || response.data === false) {
          destroySession();
        }
      }).catch(e => {
        const error = e as AxiosError
        if (error.response?.status === ResponseStatus.UNAUTHORIZED){
          destroySession();
        }
      });
    }

    switch (action.type) {
      case DirectLineActionTypes.UPDATE_CONNECTION_STATUS: {
        if (
          action.payload &&
          action.payload.connectionStatus ===
            DirectLineConnectionStatus.ExpiredToken
        ) {
          handleExpiredToken();
        }
        break;
      }

      case DirectLineActionTypes.CONNECT_FULFILLED: {
        handleConnectFulfilled(action);
        break;
      }

      case DirectLineActionTypes.CONNECT_REJECTED: {
        const isDev = isDevEnvironment();

        if (!isDev) {
          handleConnectRejected(action, throwError);
        }
        break;
      }

      case DirectLineActionTypes.INCOMING_ACTIVITY: {
        handleIncomingActivity(
          middlewareApi.getState,
          action,
          middlewareApi.dispatch
        );
        break;
      }

      case DirectLineActionTypes.POST_ACTIVITY: {
        handlePostActivity(action.payload.activity.id);
        break;
      }

      case DirectLineActionTypes.POST_ACTIVITY_FULFILLED: {
        handlePostActivityFulfilled();
        break;
      }

      case DirectLineActionTypes.POST_ACTIVITY_REJECTED: {
        appInsights.trackEvent(
          { name: 'POST_ACTIVITY_REJECTED received' },
          {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            clientActivityID: action?.meta?.clientActivityID as string,
            rejectionTimeout: Date.now() - lastPostActivityTimestamp,
          }
        );
        if (getToggle(FeatureToggles.enableReconectOnPActivityRejected)) {
          triggerReconnect();
        }
        break;
      }

      case DirectLineActionTypes.POST_ACTIVITY_PENDING: {
        handlePostActivityPending(action, triggerReconnect);
        break;
      }

      case DirectLineActionTypes.QUEUE_INCOMING_ACTIVITY: {
        handleQueueIncomingActivity(action);
        break;
      }
    }

    return next(action);
  };

function handlePostActivityPending(
  action: AnyAction,
  triggerReconnect: () => void
) {
  if (
    !getIntToggle(
      FeatureToggles.webchatReconnectWebsocketTimeoutMilliseconds
    ) ||
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    action.payload?.activity?.name === 'Init' // do not set timeout for init event - let it happen.
  ) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const clientActivityID = action?.meta?.clientActivityID as string | undefined;
  if (!clientActivityID) {
    appInsights.trackEvent({
      name: 'POST_ACTIVITY_PENDING received without clientActivityID',
    });
    return;
  }

  lastPostActivityTimestamp = Date.now();

  if (timeoutWaitingTimer) {
    clearTimeout(timeoutWaitingTimer);
  }

  timeoutWaitingClientActivityId = clientActivityID;

  timeoutWaitingTimer = setTimeout(() => {
    appInsights.trackEvent(
      {
        name: AppInsightsEventNames.DirectlineReconnectWebSocket,
      },
      {
        rejectionTimeout: Date.now() - lastPostActivityTimestamp,
      }
    );
    triggerReconnect();
  }, getIntToggle(FeatureToggles.webchatReconnectWebsocketTimeoutMilliseconds));
}

function handleQueueIncomingActivity(action: AnyAction) {
  if (
    !timeoutWaitingTimer ||
    // ignore customTyping as it doesn't contain the clientActivityID
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    action?.payload?.activity?.type === 'customTyping'
  ) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const clientActivityID = action?.payload?.activity?.channelData
    ?.clientActivityID as string | undefined;
  if (!clientActivityID) {
    appInsights.trackEvent(
      {
        name: 'QUEUE_INCOMING_ACTIVITY received but clientActivityID was not found',
      },
      {
        payload: JSON.stringify(action),
      }
    );

    return;
  }

  if (timeoutWaitingClientActivityId !== clientActivityID) {
    appInsights.trackEvent(
      {
        name: 'QUEUE_INCOMING_ACTIVITY received but clientActivityID is not what was expected',
      },
      {
        timeoutWaitingClientActivityId,
        clientActivityID,
      }
    );

    return;
  }

  clearTimeout(timeoutWaitingTimer);
  timeoutWaitingTimer = null;
}

function handleExpiredToken() {
  window.location.reload();
}

function handleConnectFulfilled(action: AnyAction) {
  initChat();

  const { directLine } = action.payload;

  appInsights.trackEvent(
    {
      name: AppInsightsEventNames.DirectlineConnected,
    },
    {
      streamUrl: directLine.streamUrl,
      webSocket: directLine.webSocket,
      token: directLine.token,
      domain: directLine.domain,
      directlineConversationId: directLine.conversationId,
    }
  );
}

function handleConnectRejected(
  action: AnyAction,
  throwError: (error: Error) => void
) {
  const error = new Error(AppInsightsEventNames.DirectlineFailedToConnect);
  appInsights.trackException({
    severityLevel: SeverityLevel.Error,
    error,
    properties: {
      ...action.meta,
      ...action.payload,
    },
  });
  throwError(error);
}

function handleIncomingActivity(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getState: () => WebchatState,
  action: AnyAction,
  dispatch: Dispatch
): void | null {
  const card: Card = action.payload;
  const { activity } = card;

  if (getToggle(FeatureToggles.webchatLogIncomingActivities)) {
    appInsights.trackEvent(
      { name: AppInsightsEventNames.DirectlineIncomingActivity },
      { card }
    );
  }

  handleTypingForActivity({
    activity: activity as WebChatActivity,
    dispatch: configuredStore.store.dispatch,
  });

  configuredStore.store.dispatch(setLastPostReachedBotService(true));
  cleanUpRetryActivities(card, dispatch);
  includeBotReceivedMessages(card);
  finalizeFailedActivity();

  if (isEditedActivity(card)) {
    return null;
  }

  if (
    activity.type !== ActivityTypes.Typing &&
    activity.type !== ActivityTypes.Retry
  ) {
    storeLastActivity(activity.id);
  }

  if (!card) {
    return;
  }

  if (activity.from.role === ActivityRoles.Bot) {
    switch (card.activity.type) {
      case ActivityTypes.Message:
        handleIncomingMessageFromBot(card);
        break;

      case ActivityTypes.StatisticsEvent:
        handleIncomingStatisticsActivity(card);
        break;

      default:
        break;
    }
  }

  if (
    activity.from.role === ActivityRoles.User &&
    isCardOfType(card, ActivityTypes.Message)
  ) {
    handleIncomingActivityFromUser(card);
  }

  configuredStore.store.dispatch(setPreviousActivity(card.activity));
}

function handleIncomingMessageFromBot(card: Card) {
  handleIncomingActivityFromBot(card);
  handleFirstVisibleMessage(card);
}

function handlePostActivityFulfilled() {
  configuredStore.store.dispatch(setIsPostActivityFulfilled(true));
}

function handlePostActivity(activityId: string) {
  configuredStore.store.dispatch(setIsPostActivityFulfilled(false));

  configuredStore.store.dispatch(setLastPostReachedBotService(false));
  configuredStore.store.dispatch(setLastUserRespondedActivity(activityId));
}

function handleFirstVisibleMessage(card: Card) {
  if (
    card.activity.id &&
    !configuredStore.store.getState().botConfigState.firstVisibleMessageReceived
  ) {
    configuredStore.store.dispatch(setFirstVisibleMessageReceived(true));
  }
}

function backendValidationFailed(card: Card) {
  const validationFailedEntity = (card.activity as Message).entities?.find(
    (entity) => entity.validationFailed
  );

  return !!validationFailedEntity;
}

function isCardOfType(card: Card, activityType: ActivityTypes) {
  return card.activity.type === activityType;
}

function handleIncomingActivityFromBot(card: Card) {
  const activity = card.activity as Message;
  if (activity.text?.substr(0, DEBUG_PREFIX.length) !== DEBUG_PREFIX) {
    storeActivityIdAsLast(card);
  }

  disableAllPreviousNodeEditingIfBlockingNode(card);

  if (backendValidationFailed(card)) {
    configuredStore.store.dispatch(setLastUserMessageCanBeEdited(false));
  }

  SendEndOfConversationStatus(activity);
}

function destroySession(){
  appInsights.trackEvent(
    { name: AppInsightsEventNames.SessionExpired }
  );

  const redirectUri = window.location.pathname + window.location.search;

  const signOutUri = `${AuthenticationRoutes.SignOutCallback}?redirectUri=${encodeURIComponent(
    redirectUri
  )}`;
  resetConversation(configuredStore.store.dispatch);
  window.location.replace(signOutUri);
}
function handleIncomingStatisticsActivity(card: Card) {
  const statisticsEvent = card.activity as StatisticsEvent;
  if (!statisticsEvent?.conversation) {
    return;
  }

  gtmSendPushEvent(
    statisticsEvent.value.StatisticsEventType,
    statisticsEvent.conversation.id,
    statisticsEvent.value.StatisticsData
  );

  sendStatusFromStatistics(statisticsEvent.value.StatisticsEventType);
}

function storeActivityIdAsLast(card: Card) {
  const id = card.activity.id;
  const { store } = configuredStore;
  if (!id || !store) {
    return;
  }
  store.dispatch(setLastBotActivityId(id));
}

export function storeLastActivity(id = ''): void {
  const { store } = configuredStore;
  if (!store || !store.getState()) {
    return;
  }
  const configState = store.getState().botConfigState;
  if (!configState) {
    return;
  }

  if (id !== configState.lastMessageId) {
    store.dispatch(setLastActivityId(id));
  }
}

function handleIncomingActivityFromUser(card: Card) {
  const id = card.activity.id;
  const { store } = configuredStore;
  if (!id || !store) {
    return;
  }

  store.dispatch(setPointOfNoReturnInfo(id));
}

function disableAllPreviousNodeEditingIfBlockingNode(card: Card) {
  const entities: any[] = getEntity(card);
  if (!entities || entities.length === 0) {
    return;
  }

  const { store } = configuredStore;
  if (!store) {
    return;
  }

  const entity = entities[0] as MessageHelperEntity;

  const disableEditing = entity.PreviousNodeCanBeProcessedOnce;
  if (disableEditing && card.activity.id) {
    store.dispatch(disableAllPreviousNodeEditing(card.activity.id));
  }
}

interface MessageHelperEntity {
  PreviousNodeCanBeProcessedOnce: boolean | undefined;
}
