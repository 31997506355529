import classes from './FileUploadUserResponse.module.scss';
import {
  MessageTypes,
  Card,
  CustomUserResponse,
} from '../../../../../middleware/types';
import Icon from '@mdi/react';
import { bytesToSize } from '../../../../../utils';
import { getFileTypeIcon } from '../fileUploadHelper';
import { AriaDocumentStructureRoles } from '../../../../../constants/aria';
import { Message } from 'botframework-directlinejs';
import { UploadedFile } from '../types';

interface FileUploadUserResponseProps {
  card: Card;
}

const FileUploadUserResponse = ({
  card,
}: FileUploadUserResponseProps): JSX.Element => {
  const activity = card.activity as Message;
  const response = activity.value as CustomUserResponse;
  const { files } = response;

  return (
    <div
      className={classes.FileUploadResponse}
      data-custom-response="true"
      data-message-type={MessageTypes.fileUpload}
      role={AriaDocumentStructureRoles.LIST}
    >
      {files.map((file: UploadedFile, index: number) => {
        return (
          <div
            key={index}
            className={classes.File}
            role={AriaDocumentStructureRoles.LIST_ITEM}
          >
            <div className={classes.LeftSide}>
              <span className={classes.Icon}>
                <Icon path={getFileTypeIcon(file.fileType)} size={1} />
              </span>
            </div>
            <div className={classes.RightSide}>
              <div className={classes.FileName}>
                <span>{file.fileName}</span>
              </div>

              <div className={classes.FileSize}>
                {bytesToSize(file.fileSize)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FileUploadUserResponse;
