import React from 'react';
import classes from './Checkbox.module.scss';
import classNames from 'classnames';
import Icon from '@mdi/react';
import { mdiCheckboxBlankOutline, mdiCheckboxMarked } from '@mdi/js';

export interface CheckBoxProps {
  styleClassName: string;

  onClickHandler: () => void;
  onKeyUpHandler: (event: React.KeyboardEvent<HTMLElement>) => void;

  checked: boolean;
  label: string;

  dataCustomActivity: string;
  disabled?: boolean;
}

export default function CheckBox(props: CheckBoxProps) {
  return (
    <div
      tabIndex={0}
      role="checkbox"
      aria-checked={props.checked}
      className={classNames(classes.CheckBoxContainer, props.styleClassName)}
      onClick={props.onClickHandler}
      onKeyUp={props.onKeyUpHandler}
      data-custom-activity={props.dataCustomActivity}
    >
      <span className={classes.IconBackground}>
        <Icon
          className={classNames(
            classes.CheckBoxIcon,
            props.disabled ? classes.CheckBoxIconDisabled : null
          )}
          path={props.checked ? mdiCheckboxMarked : mdiCheckboxBlankOutline}
          size={1}
        />
      </span>
      <label className={classes.CheckBoxLabel}>{props.label}</label>
    </div>
  );
}
