import { FunctionComponent } from 'react';
import classes from './RatingStarsResponse.module.scss';
import {
  CustomUserResponse,
  MessageTypes,
  Card,
} from '../../../../../middleware/types';
import { RatingStarsActivityConfig } from '../types';
import { Message } from 'botframework-directlinejs';
import { mdiStar, mdiStarOutline } from '@mdi/js';
import Icon from '@mdi/react';

interface RatingStarsResponseProps {
  card: Card;
}

const RatingStarsResponse: FunctionComponent<RatingStarsResponseProps> = ({
  card,
}: RatingStarsResponseProps) => {
  const { activity } = card;
  const { value } = activity as Message;
  const { userRating, customEntity } = value as CustomUserResponse;
  const { starCount, ratingHint } = customEntity as RatingStarsActivityConfig;

  return (
    <div
      className={classes.RatingStarsResponse}
      data-custom-response="true"
      data-message-type={MessageTypes.ratingStars}
      data-user-rating={userRating}
    >
      {Array.from(
        Array(starCount).keys(),
        (ratingValue: number, index: number) => {
          ++ratingValue;
          const isFilledStar = ratingValue <= userRating;
          return (
            <span
              className={classes.Star}
              key={`${ratingValue} ${index}`}
              aria-label={`${ratingHint}: ${ratingValue}`}
              data-rating={ratingValue}
              data-filled={isFilledStar}
            >
              <Icon path={isFilledStar ? mdiStar : mdiStarOutline} />
            </span>
          );
        }
      )}
    </div>
  );
};

export default RatingStarsResponse;
