/* https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques */
export enum AriaWidgetRoles {
  BUTTON = 'button',
}

export enum AriaDocumentStructureRoles {
  LIST = 'list',
  LIST_ITEM = 'listitem',
  PRESENTATION = 'presentation',
  REGION = 'region',
}

export enum AriaLive {
  OFF = 'off',
  POLITE = 'polite',
  ASSERTIVE = 'assertive',
}

export enum AriaAtomic {
  FALSE = 'false',
  TRUE = 'true',
}

export enum AriaRelevant {
  ADDITIONS = 'additions',
  REMOVALS = 'removals',
  TEXT = 'text',
  ALL = 'all',
}

export enum AriaRoleDescription {
  MESSAGE = 'message',
}
