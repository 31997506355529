import { FunctionComponent } from 'react';
import classes from './TypingIndicator.module.scss';

const TypingIndicator: FunctionComponent = () => (
  <div className={classes.Spinner}>
    <div className={classes.BounceOne} />
    <div className={classes.BounceTwo} />
    <div />
  </div>
);

export default TypingIndicator;
