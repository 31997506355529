import { all, fork, put, takeEvery, delay } from 'redux-saga/effects';
import { showNotification, hideNotification } from './actions';
import { NotificationActionTypes } from './types';
import { AnyAction } from 'redux';

function* handleShowNotification(action: AnyAction): Generator {
  yield put(showNotification(action.payload));

  yield delay(action.payload.duration);

  yield put(hideNotification(action.payload.id));
}

function* watchShowNotificationRequest(): Generator {
  yield takeEvery(
    NotificationActionTypes.SHOW_NOTIFICATION_WITH_TIMEOUT,
    handleShowNotification
  );
}

function* notificationSaga(): IterableIterator<any> {
  yield all([fork(watchShowNotificationRequest)]);
}

export default notificationSaga;
