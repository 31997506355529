import { action } from 'typesafe-actions';
import { BotConfigActionTypes } from './types';
import { AnyAction } from 'redux';
import { DirectLineActionTypes } from '../../middleware/types';

export const clearBotConfig = (): AnyAction =>
  action(BotConfigActionTypes.CLEAR_CONFIG);

export const setLastBotActivityId = (id: string): AnyAction =>
  action(BotConfigActionTypes.SET_LAST_BOT_ACTIVITY_ID, id);

export const setLastActivityId = (id = ''): AnyAction =>
  action(BotConfigActionTypes.SET_LAST_ACTIVITY_ID, id);

export const setEditActivityIds = (ids?: string[]): AnyAction =>
  action(BotConfigActionTypes.SET_EDIT_ACTIVITY_IDS, ids);

export const setPointOfNoReturnInfo = (userActivityId: string): AnyAction =>
  action(BotConfigActionTypes.SET_POINT_OF_NO_RETURN_INFO, userActivityId);

export const deleteActivity = (activityID: string) =>
  action(DirectLineActionTypes.DELETE_ACTIVITY, { activityID });

export const disableAllPreviousNodeEditing = (activityID: string) =>
  action(BotConfigActionTypes.DISABLE_ALL_PREVIOUS_NODE_EDITING, activityID);

export const setIsPostActivityFulfilled = (isPostActivityFulfilled: boolean) =>
  action(
    BotConfigActionTypes.SET_IS_POST_ACTIVITY_FULFILLED,
    isPostActivityFulfilled
  );

export const setLastPostReachedBotService = (
  lastPostReachedBotService: boolean
) =>
  action(
    BotConfigActionTypes.SET_LAST_POST_REACHED_BOT_SERVICE,
    lastPostReachedBotService
  );

export const setLastUserMessagePostFulfillRejected = () =>
  action(BotConfigActionTypes.SET_LAST_USER_MESSAGE_POST_FULFILL_REJECTED);

export const setDoNotAddNextPointOfNoReturn = (
  doNotAddNextPointOfNoReturn: boolean
) =>
  action(
    BotConfigActionTypes.SET_DO_NOT_ADD_NEXT_POINT_OF_NO_RETURN,
    doNotAddNextPointOfNoReturn
  );

export const setFirstVisibleMessageReceived = (value: boolean) =>
  action(BotConfigActionTypes.SET_FIRST_VISIBLE_MESSAGE_RECEIVED, value);

export const setConversationId = (conversationId: string) =>
  action(BotConfigActionTypes.SET_CONVERSATION_ID, conversationId);

export const setConsoleVisibility = (state: boolean) =>
  action(BotConfigActionTypes.SET_CONSOLE_VISIBILITY, state);

export const setTypingVisibility = (state: boolean) =>
  action(BotConfigActionTypes.SET_TYPING_VISIBILITY, state);

export const setLastUserMessageCanBeEdited = (canBeEdited: boolean) =>
  action(BotConfigActionTypes.SET_LAST_USER_MESSAGE_CAN_BE_EDITED, canBeEdited);
