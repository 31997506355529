import qs from 'query-string';

import { configuredStore } from '@/store';
import { ChatCommands } from '../types';
import { postEvent } from '../../utils/directLineUtils';
import { AppInsightsEventNames } from '../../constants';
import { KnownSearchParams } from '../../store/page-config/types';
import { appInsights } from '@/appInsights/appInsights';
import { getPrefillData } from '@/api/prefill/prefillMethods';
import { getStatisticsValues } from '@/utils';

const RETRY_TIMEOUT_IN_MILLISECONDS = 20000;

const initChat = (): void => {
  void sendInitEvent();

  setTimeout(() => {
    reinitializeChatIfMessageNotReceived();
  }, RETRY_TIMEOUT_IN_MILLISECONDS);
};

const reinitializeChatIfMessageNotReceived = () => {
  const { store } = configuredStore;
  const appState = store.getState();
  const firstVisibleMessageReceived =
    appState.botConfigState.firstVisibleMessageReceived;

  if (!firstVisibleMessageReceived) {
    appInsights.trackEvent({
      name: AppInsightsEventNames.FirstBotMessageNotReceived,
    });
    void sendInitEvent();
  }
};

const sendInitEvent = async () => {
  const { store } = configuredStore;
  const appState = store.getState();
  const pageConfig = appState.pageConfigState.config;

  const query = qs.parse(location.search);
  const { token } = query;

  const limitedConfig = Object.values(KnownSearchParams).reduce(
    (result, key) => {
      //@ts-ignore
      result[key] = pageConfig?.[key];
      return result;
    },
    {}
  );

  const prefillData = (
    await getPrefillData({ body: { token: token as string } })
  ).data;

  const eventConfig = {
    command: ChatCommands.Init,
    tenantId: pageConfig?.tenantId,
    chatParam: JSON.stringify({
      ...limitedConfig,
      ...getStatisticsValues(),
      ...prefillData,
    }),
  };

  postEvent(eventConfig);
  appInsights.trackEvent(
    {
      name: AppInsightsEventNames.ChatInitEventTriggered,
    },
    {
      config: eventConfig,
    }
  );
};

export default initChat;
