import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';

import { BotPassThroughProps } from '../../../containers/BotMessageBase/BotMessageBase';
import { RootState } from '../../../store/types';

import { getCardActions } from '../../../utils/cardHelper';

import ActionLink from '@/components/UI/ActionLink/ActionLink';

export const API_PREFIX = '/api/claim/downloadPdf';

const SummaryActivity: FunctionComponent<BotPassThroughProps> = (
  props: BotPassThroughProps
) => {
  const conversationId = useSelector<RootState, string>(
    (state) => state.botConfigState.conversationId || ''
  );

  const actionColor = useSelector<RootState, string | undefined>(
    (state) => state.themeState.theme?.colors.action
  );

  const actions = getCardActions(props.card);

  const firstAction = actions?.[0];

  return (
    <>
      {firstAction && firstAction.fileName && (
        <ActionLink
          text={firstAction.title}
          url={`${API_PREFIX}/${conversationId}/${firstAction.fileName}`}
          actionColor={actionColor}
        />
      )}
    </>
  );
};

export default SummaryActivity;
