import { ConfigurableTheme } from '../../styles/types';

export default {
  colors: {
    error: '#DC3545',
    errorBackground: '#F9DFE1',
    errorDark: '#DC3545',

    background: '#F8F8F8',
    backgroundSecondary: '#ffffff',
    headerBackground: '#ffffff',
    headerText: '#1A1A1A',
    menuArrow: '#1A1A1A',
    modalBackdrop: 'rgba(36, 34, 33, 0.25)',

    secondary: '#1A1A1A',
    secondaryLight: '#EAEAEA',
    secondarySupportLight: '#ffffff',
    secondarySupport: '#8a8a8a',

    action: '#1A1A1A',
    actionDark: '#616161',
    actionLight: '#88868b',
    actionLightest: '#a3a3a3',

    actionSecondary: '#ffffff',
    actionSecondaryDark: '#fafafa',
    actionSecondaryDarker: '#f5f5f5',

    actionText: '#F8F8F8',
    actionTextDark: '#1A1A1A',
    actionTextLight: 'rgba(26, 26, 26, 0.5)',

    focusOutline: '#1A1A1A',

    inputBackground: '#ffffff',
    inputPlaceholderFocus: 'rgba(26, 26, 26, 0.2)',
    inputSupport: 'rgba(26, 26, 26, 0.2)',

    botMessageBackground: '#EAEAEA',
    userMessageBackground: '#FFFFFF',
    messageText: '#242221',

    botActionBackground: '#ffffff',
    botActionText: '#1A1A1A',
    botActionBackgroundHover: 'rgba(26, 26, 26, 0.02)',
    botActionBackgroundActive: 'rgba(26, 26, 26, 0.02)',

    calendarItemHover: '#EAEAEA',
    calendarItemBackgroundHover: '#ffffff',
    calendarItemCurrent: '#1A1A1A',
    calendarItemSelected: '#1A1A1A',
    calendarItemSelectedBackground: '#F8F8F8',
    calendarItemOutside: 'rgba(26, 26, 26, 0.32)',
    calendarSecondaryItem: '#1A1A1A',

    borderDark: 'rgba(26, 26, 26, .6)',
    borderMedium: 'rgba(26, 26, 26, .4)',
    borderLight: 'rgba(26, 26, 26, .18)',
  },
  fonts: {
    fontStack: '"se-motor-cupra"',
  },
} as ConfigurableTheme;
