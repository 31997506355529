import { Reducer, AnyAction } from 'redux';
import { RecoveryState, RecoveryActionTypes } from './types';

export const defaultEventRetryCount = 2;

export const recoveryInitialState: RecoveryState = {
  retryRejectedActivitiesLimit: defaultEventRetryCount,
  lastUserRespondedActivity: '',
  currentFailedActivity: undefined,
  receivedClientActivityIds: [],
  previousActivity: undefined,
};

const recoveryReducer: Reducer<RecoveryState> = (
  state: RecoveryState = recoveryInitialState,
  action: AnyAction
): RecoveryState => {
  switch (action.type) {
    case RecoveryActionTypes.SET_RETRY_REJECTED_ACTIVITIES_LIMIT: {
      return {
        ...state,
        retryRejectedActivitiesLimit: action.payload,
      };
    }

    case RecoveryActionTypes.SET_LAST_USER_RESPONDED_ACTIVITY: {
      return {
        ...state,
        lastUserRespondedActivity: action.payload,
      };
    }

    case RecoveryActionTypes.SET_CURRENT_FAILED_ACTIVITY: {
      return {
        ...state,
        currentFailedActivity: action.payload,
      };
    }

    case RecoveryActionTypes.SET_RECEIVED_CLIENT_ACTIVITY_IDS: {
      return {
        ...state,
        receivedClientActivityIds: action.payload,
      };
    }

    case RecoveryActionTypes.SET_PREVIOUS_ACTIVITY: {
      return {
        ...state,
        previousActivity: action.payload,
      };
    }

    case RecoveryActionTypes.RESET_STATE: {
      return recoveryInitialState;
    }

    default: {
      return state;
    }
  }
};
export { recoveryReducer };
