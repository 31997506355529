import { useEffect, useState } from 'react';
import ActionButtons from '../../../containers/BotMessageBase/ActionButtons/ActionButtons';
import { getCardActions } from '../../../utils/cardHelper';
import { findCustomActivity } from '../../../middleware/activityMiddleware';
import {
  CustomActivityConfig,
  KeyValue,
  CustomActivityAction,
} from '../../../middleware/types';
import classes from './ListFromCarouselInput.module.scss';
import { sendMessageBack } from '../../../utils/directLineUtils';
import ListNodeItem from './ListNodeItem';
import { BotPassThroughProps } from '../../../containers/BotMessageBase/BotMessageBase';
import { AriaLive, AriaRelevant } from '../../../constants/aria';
import canBeEditedCustomUserResponse from '@/utils/responseEditHelper';
import { isNumber } from '@microsoft/applicationinsights-core-js';
import ListFromCarouselTotalSum from './ListFromCarouselTotalSum';

export interface CarouselChoiceItem {
  displayValue: string;
  valueList: KeyValue[];
  displayColumns: string[];
  iteration: number;
  deleteKey: string;
  summableValue: number;
}

export interface ListFromCarouselActivityConfig extends CustomActivityConfig {
  carouselChoiceItemList: CarouselChoiceItem[];
  noItemsInListMessage: string;
  removeButtonText: string;
  undoRemoveButtonText: string;
  maxItemCount?: number;
  maxCountErrorMessage: string;
  minItemCount?: number;
  minCountErrorMessage: string;
  addItemButtonIndices: number[];
  continueButtonIndices: number[];
  showTotalSum: boolean;
  totalSumLabel: string;
  totalSumUnits: string;
}

/**
 * @param props {BotPassThroughProps}
 */
export default function ListFromCarouselInput(
  props: BotPassThroughProps
): JSX.Element {
  const actions = getCardActions(props.card);

  const {
    carouselChoiceItemList,
    removeButtonText,
    undoRemoveButtonText,
    noItemsInListMessage,
    maxItemCount,
    maxCountErrorMessage,
    minItemCount,
    minCountErrorMessage,
    addItemButtonIndices,
    continueButtonIndices,
    showTotalSum,
    totalSumLabel,
    totalSumUnits,
  } = findCustomActivity(props.card.activity) as ListFromCarouselActivityConfig;

  const [hideActions, setHideActions] = useState(false);
  const [itemIdsToDelete, setItemIdsToDelete] = useState<string[]>([]);
  const [tooManyItems, setTooManyItems] = useState(false);
  const [notEnoughItems, setNotEnoughItems] = useState(false);
  const [disabledButtonsIndices, setDisabledButtonsIndices] = useState<
    number[]
  >([]);
  const [summableValues, setSummableValues] = useState<number[]>([]);

  const actionsAreVisible = (): boolean => {
    if (hideActions) {
      return false;
    }

    return props.card.activity.id === props.lastBotActivityId;
  };

  const onCustomActivityClicked = (action: CustomActivityAction) => {
    setHideActions(true);
    sendMessageBack(
      action.title,
      {
        itemIdsToDelete: itemIdsToDelete,
        canBeEdited: canBeEditedCustomUserResponse(props.card),
      },
      props.card.activity
    );
  };

  const toggleMarkedForDeletion = (itemDeleteKey: string): void => {
    const isCurrentlyMarkedForDeletion =
      itemIdsToDelete.indexOf(itemDeleteKey) >= 0;
    if (!isCurrentlyMarkedForDeletion) {
      setItemIdsToDelete([...itemIdsToDelete, itemDeleteKey]);
    } else {
      const newList = [...itemIdsToDelete];
      newList.splice(newList.indexOf(itemDeleteKey), 1);
      setItemIdsToDelete(newList);
    }
  };

  const columnCount =
    carouselChoiceItemList.length > 0 &&
    carouselChoiceItemList[0].displayColumns
      ? 1 + carouselChoiceItemList[0].displayColumns.length
      : 1;

  const showEmptyListMessage = () => {
    return (
      <div className={classes.EmptyListMessage}>{noItemsInListMessage}</div>
    );
  };

  useEffect(() => {
      setTooManyItems(
        maxItemCount !== undefined &&
          carouselChoiceItemList.length - itemIdsToDelete.length >= maxItemCount
      );
      setNotEnoughItems(
        minItemCount !== undefined &&
          carouselChoiceItemList.length - itemIdsToDelete.length < minItemCount
      );
      recalculateDisabledActions();

    setSummableValues(
      carouselChoiceItemList
        .filter((item) => itemIdsToDelete.indexOf(item.deleteKey) < 0)
        .filter((item) => isNumber(item.summableValue))
        .map((item) => item.summableValue)
    );
  }, [itemIdsToDelete]);

  const recalculateDisabledActions = () => {
    const canAddItems =
      maxItemCount === undefined ||
      carouselChoiceItemList.length - itemIdsToDelete.length < maxItemCount;
    const canContinue =
      (maxItemCount === undefined ||
        carouselChoiceItemList.length - itemIdsToDelete.length <=
          maxItemCount) &&
      (minItemCount === undefined ||
        carouselChoiceItemList.length - itemIdsToDelete.length >= minItemCount);

    setDisabledButtonsIndices([
      ...(canAddItems ? [] : addItemButtonIndices),
      ...(canContinue ? [] : continueButtonIndices),
    ]);
  };

  return (
    <div className={classes.ListFromCarousel}>
      <div className={classes.GridContainer}>
        {!carouselChoiceItemList.length ? (
          showEmptyListMessage()
        ) : (
          <>
            <div
              aria-live={AriaLive.POLITE}
              aria-relevant={AriaRelevant.TEXT}
              role="grid"
              aria-colcount={
                actionsAreVisible() ? columnCount + 1 : columnCount
              }
            >
              {carouselChoiceItemList.map((item, index) => (
                <ListNodeItem
                  key={index}
                  item={item}
                  index={index}
                  columnCount={columnCount}
                  itemMarkedForDeletion={
                    itemIdsToDelete.indexOf(item.deleteKey) > -1
                  }
                  toggleMarkedForDeletionHandler={toggleMarkedForDeletion}
                  removeButtonsVisible={actionsAreVisible()}
                  removeButtonText={removeButtonText}
                  undoRemoveButtonText={undoRemoveButtonText}
                />
              ))}
            </div>
            <ListFromCarouselTotalSum
              summableItems={summableValues}
              showTotalSum={showTotalSum}
              totalSumLabel={totalSumLabel}
              totalSumUnits={totalSumUnits}
            ></ListFromCarouselTotalSum>
          </>
        )}
      </div>
      {actionsAreVisible() ? (
          <>
            {tooManyItems ? (
              <div className={classes.InvalidCountNotification}>
                <div aria-hidden="true" className={classes.AlertIcon}></div>
                <div className={classes.AlertText}>
                  {maxCountErrorMessage}
                </div>
              </div>
            ) : null}
            {notEnoughItems ? (
              <div className={classes.InvalidCountNotification}>
                <div aria-hidden="true" className={classes.AlertIcon}></div>
                <div className={classes.AlertText}>
                  {minCountErrorMessage}
                </div>
              </div>
            ) : null}
            <ActionButtons
              {...props}
              focusFirstAction={true}
              disableOnClick={true}
              actions={actions}
              onClickHandler={onCustomActivityClicked}
              disabledButtonsIndices={disabledButtonsIndices}
            />
          </>
      ) : null}
    </div>
  );
}
