import { createRoot } from 'react-dom/client';

import { Selectors } from './constants';

import App from './App';
import { StrictMode } from 'react';

const chatbotEl = document.getElementById(Selectors.ChatBotRoot);

try {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(chatbotEl!);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
} catch (_error) {
  sessionStorage.clear();
  location.reload();
}
