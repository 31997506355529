import { chatStore } from '../containers/Chat/initialization/chatStore';
import {
  WebChatActionTypes,
  MessagePayload,
  CustomUserResponsePayload,
  EventPayload,
  CustomUserResponse,
  CustomActivity,
} from '../middleware/types';
import { AppInsightsEventNames, ErrorMessage } from '../constants';
import { updateLastUserRespondedActivity } from '../middleware/helpers/retryActivityHelper';
import focusOnFocusSink from './focusOnFocusSink';
import { appInsights } from '@/appInsights/appInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { findCustomActivity } from '../middleware/activityMiddleware';

const chatStoreUndefinedError = () => {
  const error = new Error(ErrorMessage.ChatStoreUndefined);
  appInsights.trackException({
    exception: error,
    severityLevel: SeverityLevel.Error,
  });
  throw error;
};

export const sendMessage = (text: string): void => {
  if (!chatStore) {
    chatStoreUndefinedError();
  }

  const payload: MessagePayload = {
    text,
  };

  chatStore?.dispatch({
    type: WebChatActionTypes.SEND_MESSAGE,
    payload,
  });
};

let counter = 0;

const getReplyToNodeId = (activity?: CustomActivity): string | undefined => {
  if (!activity) {
    return undefined;
  }

  const customActivityConfig = findCustomActivity(activity);
  return customActivityConfig?.id;
};

/* Post a response object which can hold data to render custom user response */
export const sendMessageBack = (
  text?: string,
  value?: CustomUserResponse,
  activity?: CustomActivity
): void => {
  focusOnFocusSink();
  updateLastUserRespondedActivity(activity?.id);

  setTimeout(() => {
    if (!chatStore) {
      chatStoreUndefinedError();
    }

    let payload: CustomUserResponsePayload;

    const nodeId = getReplyToNodeId(activity);
    counter++;
    payload = {
      text,
      value: { ...value, counter: counter, nodeId: nodeId },
      displayText: text,
    };

    chatStore?.dispatch({
      type: WebChatActionTypes.SEND_MESSAGE_BACK,
      payload,
    });
  }, 200);
};

/* Post data to Bot without showing in UI */
export const postEvent = (data: any): void => {
  if (!chatStore) {
    chatStoreUndefinedError();
  }

  const payload: EventPayload = {
    value: data,
  };

  if (data.name || data.command) {
    payload.name = data.name || data.command;
  }

  appInsights.trackEvent(
    {
      name: AppInsightsEventNames.PostEvent,
    },
    { payload }
  );

  chatStore?.dispatch({
    type: WebChatActionTypes.SEND_EVENT,
    payload,
  });
};
