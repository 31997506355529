import { LocalizedUrls, Translation } from './types';
import { Translations } from './translations';
import { getLocale } from '../store/bot-config/selectors';

import 'moment/locale/nb';
import 'moment/locale/fi';
import 'moment/locale/sv';
import 'moment/locale/da';

const PLURALITY_KEY = 'plural';

export const DEFAULT_LOCALE = 'en';
export const DEFAULT_URL_KEY = 'default';
export const localeMapping = {
  en: DEFAULT_LOCALE,
  'en-EN': DEFAULT_LOCALE,
  'en-US': DEFAULT_LOCALE,
  'en-GB': DEFAULT_LOCALE,
  'nb-NO': 'no',
  'fi-FI': 'fi',
  'sv-SE': 'sv',
  'da-DK': 'dk',
  'sv-FI': 'sv',
};

const translate = (
  translationKey: Translation,
  translationPlurality?: number | null,
  replaceArgs?: string[]
): string => {
  const currentLocale = getLocaleShort();

  const translation = getTranslationValue(
    translationKey,
    currentLocale,
    translationPlurality
  );
  if (!replaceArgs) {
    return translation;
  }

  return replaceValues(translation, replaceArgs);
};

const getTranslationValue = (
  translationKey: Translation,
  currentLocale: string,
  translationPlurality?: number | null
) => {
  if (
    translationPlurality !== 1 &&
    PLURALITY_KEY in translationKey &&
    //@ts-ignore
    currentLocale in translationKey[PLURALITY_KEY]
  ) {
    //@ts-ignore
    return translationKey[PLURALITY_KEY][currentLocale];
  }

  if (currentLocale in translationKey) {
    //@ts-ignore
    return translationKey[currentLocale];
  }

  return translationKey[DEFAULT_LOCALE];
};

const replaceValues = (input: string, replaceArgs: string[]): string => {
  let result = input;

  for (let i = 0; i < replaceArgs.length; i++) {
    const replacement = replaceArgs[i];
    const matchInInput = `{${i}}`;

    result = result.replace(matchInInput, replacement);
  }

  return result;
};

const getLocaleShort = () => {
  const locale = getLocale() || DEFAULT_LOCALE;
  //@ts-ignore
  return localeMapping[locale];
};

const localizeUrl = (
  translationKey: LocalizedUrls,
  translationPlurality?: number | null,
  replaceArgs?: string[]
): string => {
  const currentLocale = getLocaleShort();

  const translation = getLocalizedValue(
    translationKey,
    currentLocale,
    translationPlurality
  );
  if (!replaceArgs) {
    return translation;
  }

  return replaceValues(translation, replaceArgs);
};

const getLocalizedValue = (
  translationKey: LocalizedUrls,
  currentLocale: string,
  translationPlurality?: number | null
) => {
  if (
    translationPlurality !== 1 &&
    PLURALITY_KEY in translationKey &&
    //@ts-ignore
    currentLocale in translationKey[PLURALITY_KEY]
  ) {
    //@ts-ignore
    return translationKey[PLURALITY_KEY][currentLocale];
  }

  if (currentLocale in translationKey) {
    //@ts-ignore
    return translationKey[currentLocale];
  }

  return translationKey[DEFAULT_URL_KEY];
};

export { Translations, translate, getLocaleShort, localizeUrl };
