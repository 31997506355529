// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes Sending-module_slideInFromBottom--RwO46{0%{transform:translateY(100%)}100%{transform:translateY(0)}}@keyframes Sending-module_slideInFromTop--u4Vmj{0%{transform:translateY(-100%)}100%{transform:translateY(0)}}.Sending-module_Sending--I3vxW{animation:Sending-module_slideInFromBottom--RwO46 500ms ease-out 0s 1}.Sending-module_Sending--I3vxW>div{margin:.125rem}", "",{"version":3,"sources":["webpack://./src/styles/_animations.scss","webpack://./src/containers/ActivityStatus/Sending.module.scss"],"names":[],"mappings":"AAAA,mDACE,GACE,0BAAA,CAEF,KACE,uBAAA,CAAA,CAIJ,gDACE,GACE,2BAAA,CAEF,KACE,uBAAA,CAAA,CCZJ,+BACE,qEAAA,CAEA,mCACE,cAAA","sourcesContent":["@keyframes slideInFromBottom {\r\n  0% {\r\n    transform: translateY(100%);\r\n  }\r\n  100% {\r\n    transform: translateY(0);\r\n  }\r\n}\r\n\r\n@keyframes slideInFromTop {\r\n  0% {\r\n    transform: translateY(-100%);\r\n  }\r\n  100% {\r\n    transform: translateY(0);\r\n  }\r\n}\r\n\r\n","@import '~styles/animations';\r\n\r\n.Sending {\r\n  animation: slideInFromBottom 500ms ease-out 0s 1;\r\n\r\n  & > div {\r\n    margin: 0.125rem;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Sending": "Sending-module_Sending--I3vxW",
	"slideInFromBottom": "Sending-module_slideInFromBottom--RwO46",
	"slideInFromTop": "Sending-module_slideInFromTop--u4Vmj"
};
export default ___CSS_LOADER_EXPORT___;
