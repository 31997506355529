import { mdiCloseCircle, mdiLoading, mdiCheck, mdiAlertCircle } from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';

import { bytesToSize } from '../../../../../../utils';
import { getFileTypeIcon } from '../../fileUploadHelper';
import { translate, Translations } from '../../../../../../locale';
import {
  FileListItemMapping,
  FileListItemProps,
  FileUploadState,
} from '../../types';
import {
  AriaWidgetRoles,
  AriaLive,
  AriaDocumentStructureRoles,
} from '../../../../../../constants/aria';
import { KeyCodes } from '../../../../../../constants';
import classes from './FileListItem.module.scss';
import { DataCustomActivity } from '../../../../../../constants/testEnums';

const FileListItem = (props: FileListItemProps): JSX.Element => {
  const { file, removeFile } = props;

  const getFileListItemConfig = (
    state: string,
    fileName: string
  ): FileListItemMapping => {
    switch (state) {
      case FileUploadState.UPLOADING:
        return {
          className: classNames(classes.Icon, classes.FileUploading),
          spin: true,
          path: mdiLoading,
          role: AriaDocumentStructureRoles.PRESENTATION,
          title: translate(Translations.fileUpload.fileIsUploading),
          tabIndex: -1,
        };
      case FileUploadState.DONE:
        return {
          className: classNames(classes.Icon, classes.FileDone),
          spin: false,
          path: mdiCheck,
          role: AriaDocumentStructureRoles.PRESENTATION,
          title: translate(Translations.fileUpload.fileUploaded),
          tabIndex: -1,
        };
      case FileUploadState.FAILED:
        return {
          className: classNames(classes.Icon, classes.FileFailed),
          spin: false,
          path: mdiAlertCircle,
          role: AriaDocumentStructureRoles.PRESENTATION,
          title: translate(Translations.fileUpload.fileFailedToUpload),
          tabIndex: -1,
        };
      case FileUploadState.ADDED:
      default:
        return {
          className: classNames(classes.Icon, classes.FileRemove),
          spin: false,
          path: mdiCloseCircle,
          role: AriaWidgetRoles.BUTTON,
          title: `${translate(Translations.fileUpload.removeFile)} ${fileName}`,
          tabIndex: 0,
        };
    }
  };

  const { className, spin, path, role, title, tabIndex } =
    getFileListItemConfig(file.state, file.name);

  const removeFileClickHandler = () => {
    if (file.state !== FileUploadState.ADDED) {
      return;
    }

    removeFile();
  };

  const actionIcon = (
    <span
      className={classNames(classes.ActionButton, className)}
      tabIndex={tabIndex}
      role={role}
      onClick={removeFileClickHandler}
      onKeyUp={(e) => {
        switch (e.key) {
          case KeyCodes.Enter:
          case KeyCodes.Spacebar:
            removeFileClickHandler();
        }
      }}
      aria-live={AriaLive.POLITE}
      title={title}
      aria-label={title}
      data-custom-activity={
        DataCustomActivity.REMOVE_FILE_AT_INDEX + `_${props.index}`
      }
    >
      <Icon
        path={path}
        className={classNames(className, spin ? classes.Spin : null)}
      />
    </span>
  );

  return (
    <div
      className={classes.FileListItem}
      role={AriaDocumentStructureRoles.LIST_ITEM}
    >
      <div className={classes.LeftSide}>
        <div className={classes.FileTypePreview}>
          {file.thumbnail ? (
            <img
              className={classes.ThumbnailImage}
              src={file.thumbnail}
              alt={file.name}
            />
          ) : (
            <Icon
              path={getFileTypeIcon(file.type)}
              size={1}
              className={classes.FileTypeIcon}
            />
          )}
        </div>

        <span className={classes.FileName}>{file.name}</span>
      </div>

      <div className={classes.RightSide}>
        <span className={classes.FileSize}>{bytesToSize(file.size)}</span>
      </div>

      {actionIcon}
    </div>
  );
};

export default FileListItem;
