import { ThemeState, ThemeActionTypes } from './types';
import theme from '../../styles/index';
import { Reducer, AnyAction } from 'redux';

export const themeInitialState: ThemeState = {
  theme,
};

const themeReducer: Reducer<ThemeState> = (
  state = themeInitialState,
  action: AnyAction
) => {
  switch (action.type) {
    case ThemeActionTypes.SET_THEME: {
      return { ...state, theme: action.payload };
    }

    case ThemeActionTypes.OVERRIDE_THEME: {
      return {
        ...state,
        theme: {
          colors: {
            ...theme.colors,
            ...action.payload.colors,
          },
          dimensions: theme.dimensions,
          fonts: theme.fonts,
          breakpoints: theme.breakpoints,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default themeReducer;
