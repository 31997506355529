import { Hostname } from '../constants';

import defaultFavIcon from '../assets/images/favicons/if.png';

import { RefObject, MutableRefObject, useRef, useEffect } from 'react';

import { appInsights } from '@/appInsights/appInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

export function getCurrentUriRoute(): string {
  return window.location.pathname + window.location.search;
}

export function generateId(): string {
  return Math.random().toString().substr(10);
}

/**
 * src: https://github.com/pocesar/react-use-event-listener
 * The reason this is not a npm package install is because of react-scripts
 * not able to import *.mjs (module) files correctly. Workaround would need react-scripts eject.
 */
export type Handler<E, Fallback> = E extends keyof HTMLElementEventMap
  ? (e: HTMLElementEventMap[E]) => any
  : Fallback;

export function useEventListener<
  EventName extends keyof HTMLElementEventMap,
  EventHandler extends (e: Event) => any
>(
  eventName: EventName,
  handler: Handler<EventName, EventHandler>,
  element?:
    | EventTarget
    | HTMLElement
    | Window
    | Document
    | typeof globalThis
    | RefObject<any>
    | MutableRefObject<any>,
  options?: EventListenerOptions
): void {
  let isRef = false;

  if (typeof element === 'undefined' || !element) {
    /*global globalThis*/
    if (typeof globalThis !== 'undefined') {
      element = globalThis;
    } else if (typeof window !== 'undefined') {
      element = window;
    } else {
      const elementAssignmentError = new Error(
        'no valid element for useEventListener'
      );
      appInsights.trackException({
        exception: elementAssignmentError,
        severityLevel: SeverityLevel.Critical,
        properties: {
          stackTrace: elementAssignmentError.stack,
        },
      });
    }
  } else if ('current' in element) {
    isRef = true;
  }

  const savedHandler = useRef<Handler<EventName, EventHandler>>();
  savedHandler.current = handler;

  const currentOptions = useRef<EventListenerOptions>();
  currentOptions.current = options;

  useEffect(() => {
    const capturedEventName = eventName;
    const capturedOptions = currentOptions.current;
    let capturedElement: EventTarget | null = null;

    if (isRef) {
      capturedElement = (element as RefObject<any>).current;
    } else if (element && 'addEventListener' in element) {
      capturedElement = element as EventTarget;
    }

    if (!capturedElement || !capturedEventName) {
      return;
    }

    const eventListener: EventListener = (event) => {
      if (savedHandler.current) {
        savedHandler.current(event as any);
      }
    };

    capturedElement.addEventListener(
      capturedEventName,
      eventListener,
      capturedOptions
    );

    return () => {
      capturedElement!.removeEventListener(
        capturedEventName,
        eventListener,
        capturedOptions
      );
    };
  }, [eventName, element, savedHandler, currentOptions, isRef]);
}

export const camelToKebabCase = (text: string) =>
  text.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();

export const setOrReplaceFavoritesIcon = (icon: string = defaultFavIcon) => {
  const link: HTMLLinkElement =
    document.querySelector('link[rel*="icon"]') ||
    document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = icon;
  document.getElementsByTagName('head')[0].appendChild(link);
};

export const chunkArray = <T>(array: T[], size: number) => {
  const results = [];

  while (array.length) {
    results.push(array.splice(0, size));
  }

  return results;
};

export const isDevEnvironment = (): boolean => {
  return (
    process.env.NODE_ENV === 'development' ||
    window.location.hostname.includes(Hostname.LocalHost)
  );
};

export const isTestEnvironment = (): boolean => {
  const environments = [
    Hostname.Itest,
    Hostname.Stest,
    Hostname.Atest,
    Hostname.ItestExternal,
    Hostname.StestExternal,
    Hostname.AtestExternal,
  ];
  return environments.some((el) => window.location.hostname.includes(el));
};
