import { action } from 'typesafe-actions';
import { ClientSideConfiguration } from '@/api/types';
import { GlobalConfigActionTypes } from '../global-config/types';

export const setIsMobile = (state: boolean) => {
  return action(GlobalConfigActionTypes.SET_IS_MOBILE, state);
};

export const setIsDesktop = (state: boolean) => {
  return action(GlobalConfigActionTypes.SET_IS_DESKTOP, state);
};

export const setHasAlert = (state: boolean) => {
  return action(GlobalConfigActionTypes.SET_HAS_ALERT, state);
};

export const setClientSideConfig = (
  clientSideConfig: ClientSideConfiguration
) => {
  return action(
    GlobalConfigActionTypes.SET_CLIENT_SIDE_CONFIG,
    clientSideConfig
  );
};
