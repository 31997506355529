import { configuredStore } from '@/store';
import { Hostname, Locale } from '../../constants';
import { getLocaleFromPageConfig } from '../page-config/selectors';

export const getLocale = (): string | undefined => {
  if (!configuredStore) {
    return;
  }

  const state = configuredStore.store.getState();

  return getLocaleFromPageConfig(state) || getLocaleFormHostName();
};

function getLocaleFormHostName(): string {
  switch (window.location.hostname) {
    case Hostname.DenmarkHostname:
      return Locale.DK;
    case Hostname.FinlandHostname:
      return Locale.FI;
    case Hostname.NorwayHostname:
      return Locale.NO;
    case Hostname.SwedenHostname:
    case Hostname.SwedenHostKontaktname:
      return Locale.SV;
    default:
      return Locale.EN;
  }
}
