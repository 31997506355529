import { Reducer, AnyAction } from 'redux';
import { PageConfigState, PageConfigActions } from './types';

const pageConfigInitialState: PageConfigState = {
  config: undefined,
};

const pageConfigReducer: Reducer<PageConfigState> = (
  state: PageConfigState = pageConfigInitialState,
  action: AnyAction
): PageConfigState => {
  switch (action.type) {
    case PageConfigActions.CLEAR_CONFIG: {
      return { ...state, config: undefined };
    }

    case PageConfigActions.SET_PAGE_CONFIG: {
      return { ...state, config: action.payload };
    }

    default: {
      return state;
    }
  }
};

export { pageConfigReducer, pageConfigInitialState };
