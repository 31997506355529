import { AnyAction } from 'redux';
import { action } from 'typesafe-actions';
import { ConversationActionTypes } from '../conversation/types';

export const appendActivityIdsToHide = (activityIds: string[]): AnyAction => {
  return action(
    ConversationActionTypes.APPEND_ACTIVITY_IDS_TO_HIDE,
    activityIds
  );
};

export const resetConversationState = (): AnyAction => {
  return action(ConversationActionTypes.RESET_STATE);
};
