import { mdiBandage, mdiMedicalBag } from '@mdi/js';
import { ChatRoutes, Country, Locale } from '../constants';
import { TenantMapping } from './types';

const norwayPersonalInjuryMapping: TenantMapping[] = [
  {
    path: ChatRoutes.NorwayCommercialPersonalInjury,
    pageConfig: {
      tenantId: 'ab9bc53c-f96f-4abc-a6be-da7fe174c73a',
      brandName: 'Norway Commercial Personal Injury',
      homePageSvgIcon: mdiMedicalBag,
      country: Country.NO,
      locale: Locale.NO,
    },
  },
  {
    path: ChatRoutes.NorwayPrivatePersonalInjury,
    pageConfig: {
      tenantId: 'b87d6e9a-c247-480e-98eb-f514f61f94cf',
      homePageSvgIcon: mdiBandage,
      brandName: 'Norway Private Personal Injury',
      country: Country.NO,
      locale: Locale.NO,
    },
  },
];

export default norwayPersonalInjuryMapping;
