import { MessageType } from '@riga-claims/atlas-models';
import { WebChatActivity } from 'botframework-webchat-core';

export interface CustomActivityEntity {
  canBeEdited: boolean;
  messageType: MessageType;
  propertyValidationRules: [];
  type: 'CustomActivity';
}

const getCustomActivityEntity = (
  activity?: WebChatActivity
): CustomActivityEntity | undefined => {
  return activity?.entities?.filter(
    (entity) => entity.type === 'CustomActivity'
  )[0] as unknown as CustomActivityEntity | undefined;
};

export default getCustomActivityEntity;
