import { configuredStore } from '@/store';

const DECIMAL = 10;

enum LaunchDarklyPermanentFlags {
  retryRejectedActivitiesLimit = 'retry-rejected-activities-limit',
  webchatLogMountUnmountEvents = 'webchat-log-mount-unmount-events',
  webchatLogDateEvents = 'webchat-log-date-events',
  webchatLogClassifierEvents = 'webchat-log-classifier-events',
  webchatReconnectWebsocketTimeoutMilliseconds = 'webchat-reconnect-websocket-timeout-milliseconds',
}

enum LaunchDarklyTemporaryFlags {
  webchatLogIncomingActivities = 'webchat-log-incoming-activities',
  webchatLogAllBotResponses = 'webchat-log-all-bot-responses',
  webchatTypingIndicatorDelayMilliseconds = 'webchat-typing-indicator-delay-milliseconds',
  signOutCookieOnOidcFailure = 'sign-out-cookie-on-oidc-failure',
  removeUsageOfCanBeEditedFlag = 'remove-usage-of-can-be-edited-flag',
  enableStateFix = 'webchat-enable-state-fix',
  enableReconectOnPActivityRejected = 'enable-reconnect-on-post-activity-rejected',
  webchatTypingForStatistics = 'webchat-typing-for-statistics',
  logLongListOverlayToggled = "webchat-log-long-list-overlay-toggled",
}

export const FeatureToggles = {
  ...LaunchDarklyPermanentFlags,
  ...LaunchDarklyTemporaryFlags,
};

export const getStringToggle = (
  toggleName: string,
  defaultValue = ''
): string => {
  const state = configuredStore.store.getState();
  return (
    state.globalConfigState.clientSideConfiguration?.featureFlags?.[
      toggleName
    ] || defaultValue
  );
};

export const getToggle = (toggleName: string): boolean => {
  const stringToggleValue = getStringToggle(toggleName);
  if (!stringToggleValue) {
    return false;
  }
  return getStringToggle(toggleName).toLowerCase() === 'true';
};

export const getIntToggle = (toggleName: string): number => {
  const stringToggleValue = getStringToggle(toggleName);
  if (!stringToggleValue) {
    return 0;
  }
  return parseInt(stringToggleValue, DECIMAL);
};
