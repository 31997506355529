import { FunctionComponent } from 'react';

import classNames from 'classnames';

import classes from './CategoryMultipleChoiceRecord.module.scss';

import { Record } from '../types';

import { Checkbox, CheckboxGroup } from '@ids-react/checkbox';

export interface CategoryRecordProps {
  record: Record;
  selected: boolean;
  onRecordClick: (record: Record) => void;
}

const CategoryMultipleChoiceRecord: FunctionComponent<CategoryRecordProps> = ({
  record,
  selected,
  onRecordClick,
}: CategoryRecordProps) => {
  return (
    <li
      className={classNames(classes.CategoryItem, {
        [classes.CategoryItemSelected]: selected,
      })}
      role="presentation"
      key={record.code}
    >
      <CheckboxGroup className={classes.Record} key={record.text}>
        <Checkbox
          key={record.text}
          checkboxLabelClassName={classes.Label}
          id={record.code}
          name={record.text}
          checked={selected}
          onClick={() => onRecordClick(record)}
        >
          {record.text}
        </Checkbox>
      </CheckboxGroup>
    </li>
  );
};

export default CategoryMultipleChoiceRecord;
