import Icon from '@mdi/react';
import { mdiSend } from '@mdi/js';

import { sendMessageBack } from '../../../../utils/directLineUtils';
import { canBeEditedCustomUserResponse } from '../../../../utils';
import { NO_RATING, RatingValue, RatingStarsActivityConfig } from './types';
import { Card } from '../../../../middleware/types';

import RoundButton from '@/components/RoundButton/RoundButton';

interface SubmitButtonProps {
  rating: RatingValue;
  config: RatingStarsActivityConfig;
  card: Card;
  hideChildrenOfElement: () => void;
}

const SubmitButton = (props: SubmitButtonProps): JSX.Element => {
  const isDisabled = props.rating === NO_RATING;

  const { rating, config, card, hideChildrenOfElement } = props;

  return (
    <RoundButton
      disabled={isDisabled}
      ariaLabel={config.submitHint}
      onClick={() => {
        hideChildrenOfElement();
        sendMessageBack(
          String(rating),
          {
            userRating: rating,
            customEntity: config,
            canBeEdited: canBeEditedCustomUserResponse(card),
          },
          card.activity
        );
      }}
    >
      <Icon path={mdiSend} size={1} />
    </RoundButton>
  );
};

export default SubmitButton;
