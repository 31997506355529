
const getAuthLoginUri = (
  commandReference: string,
  languageCode: string,
  brandName?: string
): string => {
  const redirectUrl = window.location.pathname + window.location.search;
  const brand = brandName === undefined ? '' : brandName;
  return `/auth/curitylogin?redirectUri=${encodeURIComponent(
    redirectUrl
  )}&loginType=${commandReference}&language=${languageCode}&brand=${brand}`;
};

export default getAuthLoginUri;
