import { Reducer } from 'redux';
import { BffAuthenticationActionTypes } from './actions';
import { BffAuthenticationState, BffAuthenticationActions } from './types';

export const bffAuthenticationInitialState: BffAuthenticationState = {
  handledSilentAuthenticationActivityIds: [],
  displayedAuthenticationActivityIds: [],
  handledAuthenticationActivityIds: [],
  allowInternalAccount: false,
  username: null,
  authSessionHash: null,
};

export const bffAuthenticationReducer: Reducer<BffAuthenticationState> = (
  state: BffAuthenticationState = bffAuthenticationInitialState,
  action: BffAuthenticationActionTypes
): BffAuthenticationState => {
  switch (action.type) {
    case BffAuthenticationActions.ADD_HANDLED_SILENT_AUTH_ACTIVITY_ID: {
      return {
        ...state,
        handledSilentAuthenticationActivityIds: [
          ...state.handledSilentAuthenticationActivityIds,
          action.payload,
        ],
      };
    }

    case BffAuthenticationActions.ADD_DISPLAYED_AUTHENTICATION_ACTIVITY_ID: {
      return {
        ...state,
        displayedAuthenticationActivityIds: [
          ...state.displayedAuthenticationActivityIds,
          action.payload,
        ],
      };
    }

    case BffAuthenticationActions.ADD_HANDLED_AUTHENTICATION_ACTIVITY_ID: {
      return {
        ...state,
        handledAuthenticationActivityIds: [
          ...state.handledAuthenticationActivityIds,
          action.payload,
        ],
      };
    }

    case BffAuthenticationActions.SET_USERNAME: {
      return {
        ...state,
        username: action.payload,
      };
    }

    case BffAuthenticationActions.SET_AUTH_SESSION_HASH: {
      return {
        ...state,
        authSessionHash: action.payload,
      };
    }

    case BffAuthenticationActions.CLEAR_AUTHENTICATION_STATE: {
      return bffAuthenticationInitialState;
    }

    default: {
      return state;
    }
  }
};
