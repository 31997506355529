//#region  DataSource
export interface LongListClassifier {
  categories: Category[];
}

export interface Category {
  name: string;
  order: number;
  records: Record[];
}

export interface Record {
  code: string;
  text: string;
  order: number | undefined;
}
//#endregion

export enum ListSelectionStyle {
  MultipleChoices,
  SingleChoice,
}

export class SelectionChangeArgs {
  constructor(public category: Category, public selectedItems: Record[]) {}
}
