import { Property } from 'csstype';

export interface ConfigurableTheme {
  colors: Colors;
  fonts: Fonts;
}

export interface Theme {
  colors: Colors;
  dimensions: Dimensions;
  fonts: Fonts;
  breakpoints: Breakpoints;
}

export interface Colors {
  error?: string;
  errorBackground?: string;
  errorDark?: string;

  background?: string;
  backgroundSecondary?: string;
  headerBackground?: string;
  headerText?: string;
  headerTextInverted?: string;
  menuArrow?: string;
  menuArrowInverted?: string;
  modalBackdrop?: string;

  secondary?: string;
  secondaryLight?: string;
  secondarySupport?: string;
  secondarySupportLight?: string;

  action?: string;
  actionDark?: string;
  actionLight?: string;
  actionLightest?: string;

  actionSecondary?: string;
  actionSecondaryDark?: string;
  actionSecondaryDarker?: string;

  actionText?: string;
  actionTextDark?: string;
  actionTextLight?: string;

  focusOutline?: string;

  inputBackground?: string;
  inputPlaceholder?: string;
  inputPlaceholderFocus?: string;
  inputSupport?: string;

  botMessageBackground?: string;
  userMessageBackground?: string;
  messageText?: string;

  botActionBackground?: string;
  botActionText?: string;
  botActionBackgroundHover?: string;
  botActionBackgroundActive?: string;

  calendarItemHover?: string;
  calendarItemBackgroundHover?: string;
  calendarItemCurrent?: string;
  calendarItemSelected?: string;
  calendarItemSelectedBackground?: string;
  calendarItemOutside?: string;
  calendarSecondaryItem?: string;

  borderDark?: string;
  borderMedium?: string;
  borderLight?: string;
}

export interface Dimensions {
  bubbleBorderRadius: Property.BorderRadius<string>;
  borderRadius: Property.BorderRadius<string>;
  sendBoxInputHeight: Property.Height<string>;
  sendBoxLineHeight: number;
  sendBoxMaxLines: number;
  sendBoxMargins: Property.Margin<string>;
  headerZIndex: Property.ZIndex;
}

export interface Fonts {
  fontStack: Property.FontFamily;
}

export interface Breakpoints {
  smallPhoneMaxWidth: Property.Width<string>;
  phoneMaxWidth: Property.Width<string>;
  tabletPortraitMinWidth: Property.Width<string>;
  tabletPortraitMaxWidth: Property.Width<string>;
  tabletLandscapeMinWidth: Property.Width<string>;
  tabletLandscapeMaxWidth: Property.Width<string>;
  desktopMinWidth: Property.Width<string>;
  desktopMaxWidth: Property.Width<string>;
  bigDesktopMinWidth: Property.Width<string>;
}

export enum CssGlobalVariables {
  COLOR_ACTION = '--color-action',
  COLOR_BACKGROUND = '--color-background',
  COLOR_BOT_MESSAGE_BACKGROUND = '--color-bot-message-background',
  COLOR_BOT_ACTION_BACKGROUND = '--color-bot-action-background',
  COLOR_BORDER_LIGHT = '--color-border-light',
  COLOR_BOT_ACTION_BACKGROUND_HOVER = '--color-bot-action-background-hover',
  COLOR_FOCUS_OUTLINE = '--color-focus-outline',
  FONT_STACK = '--font-stack',
}
