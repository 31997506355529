import {
  DateFormatProps,
  DateInputActivityConfig,
  ManualInputState,
} from './types';
import { appInsights } from '@/appInsights/appInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import Moment from 'moment';
import {
  ValidationType,
  PropertyValidationRule,
  Card,
} from '../../../../middleware/types';
import { sendMessageBack } from '../../../../utils/directLineUtils';
import { canBeEditedCustomUserResponse } from '../../../../utils';
import { trackDateComponentInteracted } from '../../../../utils/appInsightHelper';
import { AppInsightsUserInteraction } from '../../../../constants';
import { translate, Translations } from '../../../../locale';

export const LOWER_YEAR_LIMIT = 1900;
export const UPPER_YEAR_LIMIT = 2999;
export const MONTH_JANUARY = 0;
export const MONTH_DECEMBER = 11;

export const datePartConfig = {
  dd: {
    className: 'Day',
    translationKey: 'day',
    regex: /(^\d{1}$|0[1-9]|1\d|2\d|3[01])/,
  },

  mm: {
    className: 'Month',
    translationKey: 'month',
    regex: /(^\d{1}$|0[1-9]|1[0-2])/,
  },

  yyyy: {
    className: 'Year',
    translationKey: 'year',
    regex: /^(1|2)$|^(19|2[0-9])(\d|){2}$/,
  },
};

export const getDateValidation = (
  config: DateInputActivityConfig
): PropertyValidationRule | undefined => {
  if (!config || !config.propertyValidationRules) {
    return;
  }

  const validation = config.propertyValidationRules.find(
    (validationRule) => validationRule.validationType === ValidationType.General
  );

  return validation;
};

export const getDateProps = (
  config: DateInputActivityConfig
): DateFormatProps => {
  try {
    if (!config.separator || !config.mask || !config.dateFormat) {
      throw new Error(
        `Some properties are missing from date input component: ${JSON.stringify(
          config
        )}. Using fallback values.`
      );
    }

    return {
      mask: config.mask.split(config.separator),
      delimiter: config.separator,
      dateFormat: config.dateFormat.toUpperCase(),
      validation: getDateValidation(config),
    } as DateFormatProps;
  } catch (e) {
    const error = e as Error;
    appInsights.trackException({
      error,
      severityLevel: SeverityLevel.Error,
    });
    // fallback
    return {
      mask: ['DD', 'MM', 'YYYY'],
      delimiter: '.',
      dateFormat: 'DD.MM.YYYY',
      validation: {
        validationType: ValidationType.General,
        message: '',
        expression: `(^\\d{1}$|0[1-9]|1\\d|2\\d|3[01]).(^\\d{1}$|0[1-9]|1[0-2]).((19|2[0-9])(\\d|){2}$)`,
      },
    } as DateFormatProps;
  }
};

export const inputOnClickHandler = (
  event: React.MouseEvent<HTMLInputElement>
) => {
  const target = event.target as HTMLInputElement;
  target.select();
};

export const getReplacedValue = (
  newValue: string,
  placeHolder: string,
  inputRefs: HTMLInputElement[],
  inputIndex: number
): string => {
  const placeHolderLength = placeHolder.length;
  if (newValue.length > placeHolderLength) {
    return newValue.slice(0, placeHolderLength);
  } else if (
    newValue.length === placeHolderLength &&
    typeof inputRefs[inputIndex + 1] !== 'undefined'
  ) {
    inputRefs[inputIndex + 1].focus();
  }
  return newValue;
};

export const transformDate = (
  dateProps: DateFormatProps,
  currentDateValue: { [key: string]: string }
): string => {
  const { dateFormat, delimiter } = dateProps;
  const dateFormatParts = dateFormat.split(delimiter);

  return dateFormatParts.reduce(
    (resultDate: string, datePart: string, index: number) => {
      resultDate +=
        currentDateValue[datePart.toLowerCase()] +
        (index !== dateFormatParts.length - 1 ? delimiter : '');
      return resultDate;
    },
    ''
  );
};

export const isValidDatePart = (
  datePartValue: string,
  datePartMask: string,
  currentDateValue?: { [key: string]: string }
): boolean => {
  if (!currentDateValue) {
    return false;
  }

  const isFullLength = datePartValue.length === datePartMask.length;
  const isFebruary = currentDateValue['mm'] === '02';

  switch (datePartMask) {
    case 'yyyy': {
      const yearNumber = parseInt(datePartValue);
      if (isFullLength && (yearNumber < 1000 || yearNumber > 3000)) {
        return false;
      }
      break;
    }

    case 'dd': {
      const yearInInput = Number(currentDateValue['yyyy']);
      const yearToCompareTo =
        yearInInput >= LOWER_YEAR_LIMIT && yearInInput <= UPPER_YEAR_LIMIT
          ? yearInInput
          : Moment().year();
      const februaryOfInputYear = Moment(`02.${yearToCompareTo}`, 'MM.yyyy');
      const dayValue = parseInt(datePartValue, 0);
      if (
        isFullLength &&
        isFebruary &&
        dayValue > februaryOfInputYear.daysInMonth()
      ) {
        return false;
      }

      if (
        !isFebruary &&
        parseInt(datePartValue, 0) >
          Moment(currentDateValue['mm'], 'MM').daysInMonth()
      ) {
        return false;
      }
      break;
    }

    case 'mm': {
      if (
        isFullLength &&
        isFebruary &&
        parseInt(currentDateValue['dd'], 0) > 29
      ) {
        return false;
      }
      break;
    }
  }

  return !(
    //@ts-ignore
    (!!datePartValue && !datePartConfig[datePartMask].regex.test(datePartValue))
  );
};

export const areAllPartsValid = (currentDateValue: {
  [key: string]: string;
}): boolean => {
  return Object.keys(currentDateValue)
    .filter((key) => !!currentDateValue[key])
    .every((key) => {
      return isValidDatePart(currentDateValue[key], key, currentDateValue);
    });
};

export const areAllPartsFilled = (currentDateValue: {
  [key: string]: string;
}) => {
  return Object.keys(currentDateValue).every((key) => {
    return (
      !!currentDateValue[key] && key.length === currentDateValue[key].length
    );
  });
};

export const resetAndFocusInput = (
  setManualState: (manualState: ManualInputState) => void,
  dateProps: DateFormatProps,
  inputRef: HTMLInputElement | null,
  setTranscriptMessage: (transcriptMessage: string) => void
) => {
  setManualState({
    dateValue: setupDateObj(dateProps),
    isError: false,
    isValidDate: false,
  });

  if (inputRef) {
    inputRef.focus();
  }

  setTranscriptMessage(translate(Translations.dateWasCleared));
  setTimeout(() => {
    setTranscriptMessage('');
  }, 1000);
};

export const setupDateObj = (dateProps: DateFormatProps) => {
  const { dateFormat, delimiter } = dateProps;
  return dateFormat
    .split(delimiter)
    .reduce((dateObj: { [key: string]: string }, maskPart: string) => {
      dateObj = dateObj || {};
      dateObj[maskPart.toLowerCase()] = '';
      return dateObj;
    }, {});
};

export const canResetValue = (dateValue: { [key: string]: string }): boolean =>
  Object.values(dateValue).some((datePart) => datePart !== '');

export const sendManualValue = (
  card: Card,
  dateProps: DateFormatProps,
  dateValue: { [key: string]: string },
  id: string,
  hideElement: () => void
) => {
  const selectedDate = transformDate(dateProps, dateValue);

  sendMessageBack(
    selectedDate,
    {
      canBeEdited: canBeEditedCustomUserResponse(card),
    },
    card.activity
  );

  trackDateComponentInteracted(
    id,
    AppInsightsUserInteraction.DateConfirmed,
    selectedDate
  );

  hideElement();
};

export const backTrackFocus = (
  dateValue: { [key: string]: string },
  placeHolder: string,
  inputRefs: HTMLInputElement[],
  inputIndex: number
) => {
  const value = dateValue[placeHolder];
  if (value === '' && typeof inputRefs[inputIndex - 1] !== 'undefined') {
    inputRefs[inputIndex - 1].focus();
  }
};

export function isDisabledDay(
  inputDate: Date,
  pastMoment: Moment.Moment | null,
  futureMoment: Moment.Moment | null
) {
  const inputMoment = Moment(inputDate);

  if (pastMoment && futureMoment) {
    return (
      isDayBeforeMinimum(inputMoment, pastMoment) ||
      isDayAfterMaximum(inputMoment, futureMoment)
    );
  }

  if (pastMoment) {
    return isDayBeforeMinimum(inputMoment, pastMoment);
  }

  if (futureMoment) {
    return isDayAfterMaximum(inputMoment, futureMoment);
  }

  return false;
}

export const isDayBeforeMinimum = (
  input: Moment.Moment,
  minimumDate: Moment.Moment
) => {
  return input.startOf('day').isBefore(minimumDate.startOf('day'));
};

export const isDayAfterMaximum = (
  input: Moment.Moment,
  maximumDate: Moment.Moment
) => {
  return input.startOf('day').isAfter(maximumDate.startOf('day'));
};
