import React, { Component } from 'react';
import Moment from 'moment';
import classes from './YearPicker.module.scss';
import { YearPickerNavigation } from './YearPickerNavigation';
import { YearPickerBody } from './YearPickerBody';
import { initializeState, YearPickerAnimationClass } from './yearPickerHelper';

export interface YearPickerProps {
  isOpen: boolean;
  showOutsideYears: boolean;
  initialDate: Moment.Moment;
  selectedDay?: Date;
  currentYear: number;
  allowedPastDate: Moment.Moment | null;
  allowedFutureDate: Moment.Moment | null;
  setIsOpen: (isOpen: boolean) => void;
  selectYear: (year: number) => void;
}

export interface YearPickerState {
  today: Moment.Moment;
  initialDate: Moment.Moment;
  currentDate: Moment.Moment;
  yearGrid: number[][];
  currentPageIndex: number;
  allowedYearRange: number[];
  lowerYearLimit: number;
  upperYearLimit: number;
  animationClass: YearPickerAnimationClass;
}

export class YearPicker extends Component<YearPickerProps, YearPickerState> {
  constructor(props: YearPickerProps) {
    super(props);
    this.state = initializeState(props);
  }

  setCurrentPageIndex = (currentPageIndex: number) => {
    this.setState({ currentPageIndex });
  };

  setAnimationClass = (animationClass: YearPickerAnimationClass) => {
    this.setState({ animationClass });
  };

  isNextButtonDisabled = (): boolean => {
    const nextPageIndex = this.state.currentPageIndex + 1;

    return typeof this.state.yearGrid[nextPageIndex] === 'undefined'
      ? true
      : this.state.yearGrid[nextPageIndex].filter(year =>
          this.state.allowedYearRange.includes(year)
        ).length === 0;
  };

  isPreviousButtonDisabled = (): boolean => {
    const previousPageIndex = this.state.currentPageIndex - 1;

    return previousPageIndex < 0
      ? true
      : this.state.yearGrid[previousPageIndex].filter(year =>
          this.state.allowedYearRange.includes(year)
        ).length === 0;
  };

  render() {
    return (
      <div className={classes.YearPicker}>
        <YearPickerNavigation
          isOpen={this.props.isOpen}
          isPreviousButtonDisabled={this.isPreviousButtonDisabled()}
          isNextButtonDisabled={this.isNextButtonDisabled()}
          currentYear={this.props.currentYear}
          currentPageIndex={this.state.currentPageIndex}
          animationClass={this.state.animationClass}
          setCurrentPageIndex={this.setCurrentPageIndex.bind(this)}
          setAnimationClass={this.setAnimationClass.bind(this)}
          setIsOpen={this.props.setIsOpen}
        />

        <YearPickerBody
          today={this.state.today}
          currentYear={this.props.currentYear}
          allowedYearRange={this.state.allowedYearRange}
          currentPageIndex={this.state.currentPageIndex}
          yearGrid={this.state.yearGrid}
          animationClass={this.state.animationClass}
          setAnimationClass={this.setAnimationClass.bind(this)}
          selectYear={this.props.selectYear}
        />
      </div>
    );
  }
}
