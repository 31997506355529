import { useCallback, useEffect, useState } from 'react';
import { AutoCompleteItem } from '../types';
import { AriaLive } from '../../../../../constants/aria';
import { getAutoCompleteResultsTranscript } from '../inputClassifierHelper';
import BaseTranscript from '../../../../BaseTranscript/BaseTranscript';
import debounce from 'debounce';

const TRANSCRIPT_APPEAR_DELAY_MS = 1500;

export interface AutoCompleteTranscriptProps {
  searchValue: string;
  dataSet: AutoCompleteItem[];
  noResultsFoundMessage: string;
}

const AutoCompleteTranscript = (
  props: AutoCompleteTranscriptProps
): JSX.Element => {
  const [transcriptMessage, setTranscriptMessage] = useState<string>('');

  const { dataSet, searchValue, noResultsFoundMessage } = props;

  const debouncedSetTranscript = useCallback(
    debounce(setTranscriptMessage, TRANSCRIPT_APPEAR_DELAY_MS),
    []
  );

  useEffect(() => {
    setTranscriptMessage('');

    const resultsMessage = getAutoCompleteResultsTranscript(
      searchValue,
      dataSet,
      noResultsFoundMessage
    );

    debouncedSetTranscript(resultsMessage);
  }, [dataSet, searchValue, noResultsFoundMessage, debouncedSetTranscript]);

  return (
    <BaseTranscript
      announceImmediately
      transcriptMessage={transcriptMessage}
      politenessSetting={AriaLive.POLITE}
    />
  );
};

export default AutoCompleteTranscript;
