export interface BffAuthenticationState {
  handledSilentAuthenticationActivityIds: string[];
  displayedAuthenticationActivityIds: string[];
  handledAuthenticationActivityIds: string[];
  username: string | null;
  authSessionHash: string | null;
  allowInternalAccount: boolean;
}

export enum BffAuthenticationActions {
  CLEAR_AUTHENTICATION_STATE = 'BFF_AUTH/CLEAR_AUTHENTICATION_STATE',
  ADD_HANDLED_SILENT_AUTH_ACTIVITY_ID = 'BFF_AUTH/ADD_DISPLAYED_SILENT_AUTH_ACTIVITY_ID',
  ADD_DISPLAYED_AUTHENTICATION_ACTIVITY_ID = 'BFF_AUTH/ADD_DISPLAYED_AUTHENTICATION_ACTIVITY_ID',
  ADD_HANDLED_AUTHENTICATION_ACTIVITY_ID = 'BFF_AUTH/ADD_HANDLED_AUTHENTICATION_ACTIVITY_ID',
  SET_USERNAME = 'BFF_AUTH/SET_USERNAME',
  SET_AUTH_SESSION_HASH = 'BFF_AUTH/SET_AUTH_SESSION_HASH',
}
