import { chunkArray } from '../../../../../utils/utils';
import { YearPickerProps, YearPickerState } from './YearPicker';
import Moment from 'moment';
import { LOWER_YEAR_LIMIT, UPPER_YEAR_LIMIT } from '../dateInputHelper';

export enum YearPickerAnimationClass {
  ENTER = 'enter',
  EXIT = 'exit',
}

export enum YearPickerAnimationDelay {
  ON_EXIT = 200,
  ON_SELECT = 100,
}

export enum YearPickerChunkType {
  ROW = 5,
  PAGE = 50,
}

export const createYearGrid = (
  lowerYearLimit: number,
  upperYearLimit: number,
  chunkType: YearPickerChunkType
): number[][] => {
  const yearGrid = [];
  for (let year = lowerYearLimit; year <= upperYearLimit; year++) {
    yearGrid.push(year);
  }

  return chunkArray(yearGrid, chunkType);
};

export const findSelectedYearPage = (
  yearGrid: number[][],
  currentYear: number
): number => {
  return yearGrid.findIndex(group => group.includes(currentYear));
};

export const setAllowedYearRange = (
  lowerYearLimit: number,
  upperYearLimit: number
): number[] => {
  const allowedYearRange = [];
  for (let year = lowerYearLimit; year <= upperYearLimit; year++) {
    allowedYearRange.push(year);
  }

  return allowedYearRange;
};

export const initializeState = (props: YearPickerProps): YearPickerState => {
  const { allowedFutureDate, allowedPastDate, initialDate } = props;

  const today = Moment();

  const yearGrid = createYearGrid(
    LOWER_YEAR_LIMIT,
    UPPER_YEAR_LIMIT,
    YearPickerChunkType.PAGE
  );

  const lowerYearLimit = allowedPastDate
    ? allowedPastDate.year()
    : LOWER_YEAR_LIMIT;

  const upperYearLimit = allowedFutureDate
    ? allowedFutureDate.year()
    : UPPER_YEAR_LIMIT;

  const currentDate =
    (props.selectedDay && Moment(props.selectedDay)) || initialDate;

  const currentPageIndex = findSelectedYearPage(yearGrid, props.currentYear);

  const allowedYearRange = setAllowedYearRange(lowerYearLimit, upperYearLimit);

  return {
    today,
    initialDate,
    currentDate,
    yearGrid,
    currentPageIndex,
    allowedYearRange,
    lowerYearLimit,
    upperYearLimit,
    animationClass: YearPickerAnimationClass.ENTER,
  };
};
