import { ConfigurableTheme } from '../../styles/types';

export default {
  colors: {
    error: '#C82E2D',
    errorBackground: '#FCE8E8',
    errorDark: '#9f2322',

    background: '#ffffff',
    backgroundSecondary: '#ffffff',
    headerBackground: '#ffffff',
    headerText: '#000000',
    menuArrow: '#000000',
    modalBackdrop: 'rgba(0, 0, 0, 0.4)',

    secondary: '#000000',
    secondaryLight: 'rgba(0, 0, 0, 0.7)',
    secondarySupportLight: '#ffffff',
    secondarySupport: '#8a8a8a',

    action: '#025893',
    actionDark: '#02416f',
    actionLight: '#0276c2',
    actionLightest: '#029dff',

    actionSecondary: '#ffffff',
    actionSecondaryDark: '#fafafa',
    actionSecondaryDarker: '#f5f5f5',

    actionText: '#ffffff',
    actionTextDark: 'rgba(0, 0, 0, 0.8)',
    actionTextLight: 'rgba(0, 0, 0, 0.5)',

    focusOutline: 'rgba(0, 0, 0, 0.2)',

    inputBackground: '#ffffff',
    inputPlaceholderFocus: 'rgba(0, 0, 0, 0.2)',
    inputSupport: 'rgba(0, 0, 0, 0.2)',

    botMessageBackground: 'rgba(0, 0, 0, 0.07)',
    userMessageBackground: '#D6D6D6',
    messageText: '#000000',

    botActionBackground: '#ffffff',
    botActionText: '#025893',
    botActionBackgroundHover: '#fafafa',
    botActionBackgroundActive: '#fafafa',

    calendarItemHover: '#ffffff',
    calendarItemBackgroundHover: '#025893',
    calendarItemCurrent: '#02487a',
    calendarItemSelected: '#ffffff',
    calendarItemSelectedBackground: '#02487a',
    calendarItemOutside: 'rgba(0, 0, 0, 0.2)',
    calendarSecondaryItem: '#02487a',

    borderDark: 'rgba(0, 0, 0, .6)',
    borderMedium: 'rgba(0, 0, 0, .4)',
    borderLight: 'rgba(0, 0, 0, .18)',
  },
  fonts: {
    fontStack: '"se-motor-subaru"',
  },
} as ConfigurableTheme;
