import { KnownSearchParams, PageConfig } from '@/store/page-config/types';

const hasKnownSearchParamsChanged = (
  currentPageConfig: PageConfig,
  previousPageConfig: PageConfig
): boolean => {
  return Object.values(KnownSearchParams).some(
    (pageConfigProperty) =>
      currentPageConfig[pageConfigProperty] !==
      previousPageConfig[pageConfigProperty]
  );
};

export default hasKnownSearchParamsChanged;
