import { useEffect } from 'react';
import classes from './SomethingWentWrong.module.scss';
import Icon from '@mdi/react';
import { mdiRobot } from '@mdi/js';
import { translate, Translations } from '../../../locale';
import { appInsights } from '@/appInsights/appInsights';
import { configuredStore } from '@/store';
import { resetConversation } from '../../../utils';

import ButtonPrimary from '@/components/Buttons/ButtonPrimary/ButtonPrimary';

export default function SomethingWentWrong(): JSX.Element {
  useEffect(() => {
    appInsights.trackPageView({ name: 'SomethingWentWrong' });
  }, []);

  function restartChat() {
    const { dispatch } = configuredStore.store;

    resetConversation(dispatch);
    window.location.reload();
  }

  return (
    <div className={classes.SomethingWentWrongContainer}>
      <p className={classes.Message}>
        <span className={classes.BigSize}>
          {translate(Translations.errorPage.somethingWentWrong)}!
        </span>
        <span className={classes.MiddleSize}>
          {translate(Translations.errorPage.weAreWorkingOnIt)}!
        </span>
        <span>{translate(Translations.errorPage.tryAgainLater)}.</span>
      </p>
      <div className={classes.RestartButton}>
        <ButtonPrimary onClick={restartChat}>
          {translate(Translations.restartChat)}
        </ButtonPrimary>
      </div>
      <Icon className={classes.Icon} path={mdiRobot} size={15} />
    </div>
  );
}
