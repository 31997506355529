import { StatisticsData } from '../../middleware/types';
import TagManager from 'react-gtm-module';
import { getLocaleShort } from '../../locale';
import { KnownSearchParams } from '../../store/page-config/types';
import {
  GoogleAnalyticsCountry,
  GoogleAnalyticsBusinessArea,
  GoogleAnalyticsLanguage,
  StatisticsNodeEventTypes,
} from '../../constants';

const initializeGtm = (gaKey: string) => {
  const tagManagerArgs = {
    gtmId: gaKey,
  };
  TagManager.initialize(tagManagerArgs);
};

function getCountry(statisticsData?: StatisticsData[]) {
  const country = getStatisticsValue(KnownSearchParams.COUNTRY, statisticsData);
  //@ts-ignore
  const analyticsCountry = GoogleAnalyticsCountry[country];
  if (analyticsCountry) {
    return analyticsCountry;
  }
  return country;
}

function getBusinessArea(statisticsData?: StatisticsData[]) {
  const businessArea = getStatisticsValue(
    KnownSearchParams.BUSINESS_AREA,
    statisticsData
  );
  const analyticsBusinessArea =
    //@ts-ignore
    GoogleAnalyticsBusinessArea[businessArea];
  if (analyticsBusinessArea) {
    return analyticsBusinessArea;
  }
  return businessArea;
}

function getLanguage() {
  const locale = getLocaleShort();
  //@ts-ignore
  const analyticsLanguage = GoogleAnalyticsLanguage[locale];
  if (analyticsLanguage) {
    return analyticsLanguage;
  }
  return locale.toUpperCase();
}

export function gtmSendPushEvent(
  gaEventType: string,
  conversationId: string,
  statisticsData?: StatisticsData[]
) {
  const environmentInfo = {
    country: getCountry(statisticsData),
    businessArea: getBusinessArea(statisticsData),
    lineOfBusiness: getStatisticsValue(
      KnownSearchParams.LINE_OF_BUSINESS,
      statisticsData
    ),
    portfolio: getStatisticsValue(KnownSearchParams.PORTFOLIO, statisticsData),
    partner: getStatisticsValue(KnownSearchParams.PARTNER, statisticsData),
  } as EnvironmentInfo;

  const pageInfo = {
    pageType: 'Claims Page',
    pageLanguage: getLanguage(),
    pageUrl: window.location.href,
    canonicalPageUrl: '',
    httpStatusCode: '',
  } as PageInfo;

  switch (gaEventType) {
    case StatisticsNodeEventTypes.Started: {
      sendEvent(
        'Claim Started',
        'started',
        conversationId,
        environmentInfo,
        pageInfo
      );

      break;
    }

    case StatisticsNodeEventTypes.ClaimSent: {
      sendEvent(
        'Claim Closed',
        'confirmed',
        conversationId,
        environmentInfo,
        pageInfo
      );
      break;
    }
  }
}

function getStatisticsValue(
  key: string,
  statisticsData?: StatisticsData[]
): string {
  if (!statisticsData) return '';

  for (let index = 0; index < statisticsData.length; index++) {
    const element = statisticsData[index];
    if (element.Property === key) {
      return element.Value;
    }
  }
  return '';
}

function sendEvent(
  eventName: string,
  mainStep: string,
  conversationId: string,
  environmentInfo?: EnvironmentInfo,
  pageInfo?: PageInfo
) {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      processInfo: {
        name: 'claims',
        processStep: {
          mainStep: mainStep,
        },
      },
      chatBotInfo: {
        chatBotID: conversationId,
      },
      environmentInfo: environmentInfo,
      pageInfo: pageInfo,
    },
  });
}

export const initGoogleTagManager = (key?: string): void => {
  if (!key) {
    return;
  }

  initializeGtm(key);
};

interface EnvironmentInfo {
  country: string;
  businessArea: string;
  lineOfBusiness: string;
  portfolio: string;
  partner: string;
}

interface PageInfo {
  pageType: string;
  pageLanguage: string;
  pageUrl: string;
  canonicalPageUrl: string;
  httpStatusCode: string;
}
