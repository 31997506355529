import { Dispatch, SetStateAction } from 'react';

import { appInsights } from '@/appInsights/appInsights';
import { uploadFile } from '@/api/claim/claimMethods';
import { AcceptedFile, FileUploadState, UploadedFile } from './types';

import { AppInsightsEventNames } from '../../../../constants';

export const uploadFiles = async ({
  setFailedUpload,
  setUploadInProgress,
  filesToUpload,
  abortController,
  conversationId,
  setFileUploadState,
  setUploadedFiles,
}: {
  setFailedUpload: Dispatch<SetStateAction<boolean>>;
  setUploadInProgress: Dispatch<SetStateAction<boolean>>;
  filesToUpload: AcceptedFile[];
  setFileUploadState: (file: AcceptedFile, state: FileUploadState) => void;
  abortController: AbortController;
  conversationId: string;
  setUploadedFiles: Dispatch<SetStateAction<UploadedFile[]>>;
}): Promise<void> => {
  setFailedUpload(false);
  setUploadInProgress(true);

  await Promise.all(
    filesToUpload.map((acceptedFile) =>
      processFileUpload(
        acceptedFile,
        conversationId,
        abortController,
        setFileUploadState,
        setUploadedFiles
      )
    )
  );

  setUploadInProgress(false);
};

export const processFileUpload = async (
  file: AcceptedFile,
  conversationId: string,
  abortController: AbortController,
  setFileUploadState: (file: AcceptedFile, state: FileUploadState) => void,
  setUploadedFiles: Dispatch<SetStateAction<UploadedFile[]>>
): Promise<void> => {
  appInsights.trackEvent(
    {
      name: AppInsightsEventNames.ProcessFileUpload,
    },
    {
      name: file.data.name,
      sizeInBytes: file.data.size,
      type: file.data.type,
    }
  );

  setFileUploadState(file, FileUploadState.UPLOADING);
  try {
    const uploadResult = await uploadFile({
      file: file.data,
      conversationId,
      abortController,
    });

    if (uploadResult === null) {
      setFileUploadState(file, FileUploadState.FAILED);
    } else {
      setFileUploadState(file, FileUploadState.DONE);
      setUploadedFiles((uploadedFiles) => [
        ...uploadedFiles,
        {
          fileName: file.name,
          filePath: uploadResult.data?.filePath || '',
          fileType: file.type,
          fileSize: file.size,
        },
      ]);

      appInsights.trackEvent(
        {
          name: AppInsightsEventNames.FileSuccessfullyUploaded,
        },
        {
          name: file.data.name,
          filePath: uploadResult.data?.filePath,
        }
      );
    }
  } catch {
    setFileUploadState(file, FileUploadState.FAILED);
  }
};
