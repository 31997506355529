import { FunctionComponent } from 'react';

import classNames from 'classnames';

import classes from './CategorySingleChoiceRecord.module.scss';

import { Record } from '../types';
import { isArrowKey } from '@/utils';

interface CategorySingleChoiceRecordProps {
  record: Record;
  selected: boolean;
  onRecordClick: (record: Record) => void;
}

const CategorySingleChoiceRecord: FunctionComponent<
  CategorySingleChoiceRecordProps
> = ({ record, selected, onRecordClick }: CategorySingleChoiceRecordProps) => {
  const inputId = `LongListChoice_${record.code}`;

  return (
    <li className={classes.CategoryItem} role="presentation" key={record.code}>
      <input
        tabIndex={0}
        className={classes.RadioButton}
        id={inputId}
        type={'radio'}
        checked={selected}
        onKeyDown={(e) => {
          if (isArrowKey(e.key)) {
            const radioButton = e.target as HTMLInputElement;
            const label = radioButton.labels?.[0];
            label?.scrollIntoView({
              behavior: 'auto',
              block: 'center',
              inline: 'center',
            });
          }
        }}
        onClick={() => onRecordClick(record)}
      />
      <label
        className={classNames(classes.RadioLabel, {
          [classes.Selected]: selected,
        })}
        htmlFor={inputId}
      >
        {record.text}
      </label>
    </li>
  );
};

export default CategorySingleChoiceRecord;
