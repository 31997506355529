import { callApi } from '../callApi';

import {
  ApiResponse,
  Endpoints,
  RequestMethod,
  DirectLineConfiguration,
  PostAuthEventRequest,
  UserInformation,
} from '../types';

export const generateDirectlineToken = (params?: {
  abortController?: AbortController;
}): Promise<ApiResponse<DirectLineConfiguration>> => {
  return callApi({
    url: `${Endpoints.GENERATE_DIRECTLINE_TOKEN}`,
    abortController: params?.abortController,
  });
};

export const getReconnectToken = ({
  conversationId,
  watermark,
  abortController,
}: {
  conversationId: string;
  watermark?: number;
  abortController?: AbortController;
}): Promise<ApiResponse<DirectLineConfiguration>> => {
  return callApi({
    url: `${Endpoints.GET_RECONNECT_TOKEN}/${conversationId}?watermark=${
      watermark ?? ''
    }`,
    abortController,
  });
};

export const postAuthEvent = ({
  body,
  abortController,
}: {
  body: PostAuthEventRequest;
  abortController?: AbortController;
}): Promise<ApiResponse<UserInformation>> => {
  return callApi({
    url: Endpoints.POST_AUTH_EVENT,
    method: RequestMethod.POST,
    body,
    abortController,
  });
};

export const postSilentAuthEvent = ({
  body,
  abortController,
}: {
  body: PostAuthEventRequest;
  abortController?: AbortController;
}): Promise<ApiResponse<UserInformation>> => {
  return callApi({
    url: Endpoints.POST_SILENT_AUTH_EVENT,
    method: RequestMethod.POST,
    body,
    abortController,
    retry: true,
  });
};
