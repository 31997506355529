import { AppStatus } from '@/constants';
import { Reducer, AnyAction } from 'redux';
import { ChatActivitiesActionTypes, ChatActivitiesState } from './types';

export const chatActivitiesInitialState: ChatActivitiesState = {
  chatStatuses: []
};

export const chatActivitiesReducer: Reducer<ChatActivitiesState> = (
  state: ChatActivitiesState = chatActivitiesInitialState,
  action: AnyAction
): ChatActivitiesState => {
  switch (action.type) {
    case ChatActivitiesActionTypes.ADD_CHAT_STATUS: {
      return {
        ...state,
        chatStatuses: [...state.chatStatuses, action.payload as AppStatus]
      };
    }
    case ChatActivitiesActionTypes.REMOVE_LAST_CHAT_STATUS: {
      return {
        ...state,
        chatStatuses: state.chatStatuses.slice(0, state.chatStatuses.length - 1),
      };
    }
    default: {
      return state;
    }
  }
};
