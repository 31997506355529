import loaderClasses from '@/components/UI/Loader/Loader.module.scss';
import classNames from 'classnames';
import { Loader } from '@ids-react/loader';

import classes from './Sending.module.scss';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/types';
export const Sending = () => {
  const [showLoader, setShowLoader] = useState(false);
  const lastPostReachedBotService = useSelector<RootState, boolean>((state) => {
    return state.botConfigState.lastPostReachedBotService;
  });
  React.useEffect(() => {
    window.setTimeout(() => setShowLoader(true), 300);
  }, []);

  if (lastPostReachedBotService || !showLoader) return <></>;

  return (
    <div className={classes.Sending}>
      <Loader
        className={classNames(
          loaderClasses.Loader,
          loaderClasses.ActionLoader,
          loaderClasses.Tiny
        )}
      />
    </div>
  );
};

export default Sending;
