// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ClickOutside-module_ClickOutsideWrapper--alMQe{display:inherit;width:inherit;height:inherit;justify-content:inherit}", "",{"version":3,"sources":["webpack://./src/containers/ClickOutside/ClickOutside.module.scss"],"names":[],"mappings":"AAAA,gDACE,eAAA,CACA,aAAA,CACA,cAAA,CACA,uBAAA","sourcesContent":[".ClickOutsideWrapper {\r\n  display: inherit;\r\n  width: inherit;\r\n  height: inherit;\r\n  justify-content: inherit;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ClickOutsideWrapper": "ClickOutside-module_ClickOutsideWrapper--alMQe"
};
export default ___CSS_LOADER_EXPORT___;
