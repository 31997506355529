import { VFC } from 'react';

import classes from './FullPageLoader.module.scss';

import { Loader, LoaderSize }  from '@ids-react/loader'

const FullPageLoader: VFC = () => {
  return (
    <div className={classes.FullPageLoader}>
      <Loader size={LoaderSize.LARGE}/>
    </div>
  );
};

export default FullPageLoader;
