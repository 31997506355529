import React from 'react';
import defaultLogo from '../../../assets/images/logos/if-logo.svg';
import classes from './Logo.module.scss';
import { connect } from 'react-redux';
import { RootState } from '../../../store/types';
import { PageConfig } from '../../../store/page-config/types';
import { Theme } from '../../../styles/types';

interface LogoProps {
  theme?: Theme;
  pageConfig?: PageConfig;
}

const Logo: React.FC<LogoProps> = (props: LogoProps): JSX.Element | null => {
  const { theme, pageConfig } = props;

  if (!pageConfig || !theme) {
    return null;
  }

  const logoDesktop = pageConfig.logoDesktop || defaultLogo;
  const logoMobile =
    pageConfig.logoMobile || pageConfig.logoDesktop || defaultLogo;

  return (
    <div className={classes.Logo}>
      <picture>
        <source
          srcSet={logoDesktop}
          media={`(min-width: ${theme.breakpoints.tabletPortraitMinWidth})`}
        />
        <source
          srcSet={logoMobile}
          media={`(max-width: ${theme.breakpoints.phoneMaxWidth})`}
        />
        <img src={logoDesktop} alt="" />
      </picture>
    </div>
  );
};

const mapStateToProps = ({
  themeState,
  pageConfigState,
}: RootState): LogoProps => ({
  theme: themeState.theme,
  pageConfig: pageConfigState.config,
});

export default connect(mapStateToProps)(Logo);
