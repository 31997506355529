import { focusSinkRef } from '../containers/Chat/Chat';
import { HTMLElementAttribute } from '../constants';

const focusOnFocusSink = (): void => {
  focusSinkRef.current?.setAttribute(HTMLElementAttribute.TAB_INDEX, '0');
  focusSinkRef.current?.focus();
  focusSinkRef.current?.setAttribute(HTMLElementAttribute.TAB_INDEX, '-1');
};

export default focusOnFocusSink;
