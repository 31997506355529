import { Translation } from './types';
import { Currency, MonthMapping } from '../constants';

/**
 * Each static string (label/message/aria-label/hint)
 * must consist of `Translation` object where there is
 * default locale `en` defined which is fallback for
 * missing locales.
 * There is a reason `Translations` object has no type set, it allows
 * Typescript intellisense autocomplete to work properly
 */
const Translations = {
  ifClaimsIntakeSolutions: {
    en: 'If Claims Intake Solutions',
  } as Translation,

  botSaid: {
    en: 'The bot said',
    dk: 'Chatrobot',
    no: 'Vår chatbot sa',
    sv: 'Vår chatbot sa',
    fi: 'If',
  },

  youSaid: {
    en: 'You said',
    dk: 'Dig',
    no: 'Du sa',
    sv: 'Du sa',
    fi: 'Sinä',
  },

  navigationGuard: {
    title: {
      en: 'Close claims report',
      dk: 'Luk skadeanmeldelsen',
      no: 'Steng skademeldingen',
      sv: 'Stänga anmälan',
      fi: 'Sulje vahinkoilmoitus',
    },

    text: {
      en: 'Whoa, hold on a sec! You are about to leave this page and lose all of your claims progress. Are you sure you want to do that?',
      dk: 'Hov, vent lidt! Du er ved at forlade denne side og vil miste al information du har indtastet. Er du sikker på det er det du ønsker?',
      no: 'Vent litt! Du er i ferd med å avbryte skademeldingen, og vil da miste det du har skrevet. Er du sikker på at du vil avbryte?',
      sv: 'Du är påväg att lämna den här sidan och vi kommer då radera alla uppgifter du fyllt i. Stämmer det med vad du vill göra?',
      fi: 'Olet poistumassa vahinkoilmoitukselta ja täyttämäsi tiedot tyhjennetään. Oletko varma, että haluat tehdä näin?',
    },

    confirm: {
      en: 'Yes, close claims report',
      dk: 'Ja, luk anmeldelsen',
      no: 'Ja, avbryt skademeldingen',
      sv: 'Ja, stäng anmälan',
      fi: 'Kyllä, sulje vahinkoilmoitus',
    },

    cancel: {
      en: 'No, continue',
      dk: 'Nej, fortsæt med anmeldelsen',
      no: 'Nei, fortsett skademeldingen',
      sv: 'Nej, fortsätt med anmälan ',
      fi: 'Ei, jatkan vahinkoilmoitusta',
    },
  },

  manualDateInput: {
    en: 'Manual date input',
    dk: 'Skriv dato',
    no: 'Skriv inn dato',
    sv: 'Skriv in datum',
    fi: 'Kirjoita päivämäärä',
  },

  textInput: {
    limitedToCharacters: {
      en: 'Limited to {0} characters',
      dk: 'Begrænset til {0} tegn',
      no: 'Begrenset til {0} tegn',
      sv: 'Begränsad till {0} tecken',
      fi: 'Merkkimäärä rajoitettu {0} merkkiin',
    },

    closeToCharacterLimit: {
      en: 'Close to reaching character limit',
      dk: 'Tæt på maksimalt antal tegn',
      no: 'Du nærmer deg maks antall tegn',
      sv: 'Nära att nå maximalt med tecken',
      fi: 'Merkkien enimmäismäärä lähestyy',
    },

    characterLimitReached: {
      en: 'Character limit of {0} has been reached',
      dk: 'Antal tegn {0} er opbrugt',
      no: 'Grensen på {0} tegn er nådd',
      sv: 'Begränsat med antal tecken {0} uppnått',
      fi: 'Merkkien enimmäismäärä {0} on täynnä',
    },
  },

  dateInputAlerts: {
    enterValidDate: {
      en: 'Enter valid date',
      dk: 'Indsæt gyldig dato',
      no: 'Oppgi en gyldig dato',
      sv: 'Ange giltigt datum',
      fi: 'Anna kelvollinen päivämäärä',
    },

    enterRealDate: {
      en: 'Enter real date',
      dk: 'Angiv korrekt dato',
      no: 'Oppgi riktig dato',
      sv: 'Ange korrekt datum',
      fi: 'Anna todellinen päivämäärä',
    },

    dateMustBeBetweenTodayAndFuture: {
      en: 'Date must be between today and {0}',
      dk: 'Datoen skal være mellem dagens dato {0} ',
      no: 'Datoen må være mellom dagens dato og {0}',
      sv: 'Datum måste vara mellan dagens datum och {0}',
      fi: 'Päivän tulee olla tämän päivän ja {0} välillä',
    },

    dateMustBeBetween: {
      en: 'Date must be between {0} and {1}',
      dk: 'Datoen skal være mellem {0} og {1}',
      no: 'Datoen må være mellom {0} og {1}',
      sv: 'Datum måste vara mellan {0} och {1}',
      fi: 'Päivän tulee olla välillä {0} ja {1}',
    },

    dateMustBeBetweenPastAndToday: {
      en: 'Date must be between {0} and today',
      dk: 'Datoen skal være mellem {0} og dagens dato',
      no: 'Datoen må være mellom {0} og dagens dato',
      sv: 'Datum måste vara mellan {0} och dagens datum',
      fi: 'Päivän tulee olla {0} ja tämän päivän välillä',
    },

    dateMustBeTodayOrInPast: {
      en: 'Date must be today or in the past',
      dk: 'Datoen skal være dagens dato eller en tidligere dato',
      no: 'Datoen må være dagens dato eller tidligere',
      sv: 'Datum måste vara dagens datum eller ett tidigare datum',
      fi: 'Päivän tulee olla joko tämä päivä tai jokin aiempi',
    },

    dateMustBeTodayOrInTheFuture: {
      en: 'Date must be today or in the future',
      dk: 'Datoen skal være dagens dato eller en i fremtiden',
      no: 'Datoen må være dagens dato eller en framtidig dato',
      sv: 'Datum måste vara dagens datum eller ett framtida datum',
      fi: 'Päivän tulee olla joko tänään tai tulevaisuudessa',
    },

    dateMustBeBefore: {
      en: 'Date must be before {0}',
      dk: 'Datoen skal være inden {0}',
      no: 'Datoen må være før {0}',
      sv: 'Datum måste vara före {0}',
      fi: 'Päivän tulee olla ennen {0}',
    },

    dateMustBeAfter: {
      en: 'Date must be after {0}',
      dk: 'Datoen skal være efter {0}',
      no: 'Datoen må være etter {0}',
      sv: 'Datum måste vara efter {0}',
      fi: 'Päivän tulee olla {0} jälkeen',
    },
  },

  manualDateInputLabels: {
    day: {
      en: 'Day',
      dk: 'Dag',
      no: 'Dag',
      sv: 'Dag',
      fi: 'Päivä',
    },
    month: {
      en: 'Month',
      dk: 'Måned',
      no: 'Måned',
      sv: 'Månad',
      fi: 'Kuukausi',
    },
    year: {
      en: 'Year',
      dk: 'År',
      no: 'År',
      sv: 'År',
      fi: 'Vuosi',
    },
  },

  dateWasCleared: {
    en: 'Date was cleared',
    dk: 'Datoen blev fjernet',
    no: 'Datofeltet ble tømt',
    sv: 'Datum är nu tomt',
    fi: 'Päivämäärä tyhjennettiin',
  },

  openDatePicker: {
    en: 'Open calendar to select date',
    dk: 'Vis kalender og vælg dato',
    no: 'Åpne kalenderen og velg dato',
    sv: 'Öppna kalendern',
    fi: 'Avaa kalenteri valitaksesi päivämäärän',
  },

  closeDatePicker: {
    en: 'Close calendar and return to chat',
    dk: 'Luk kalender og fortsæt anmeldelsen',
    no: 'Lukk kalenderen',
    sv: 'Stäng kalendern',
    fi: 'Sulje kalenteri',
  },

  previousMonth: {
    en: 'Previous month',
    dk: 'Forrige måned',
    no: 'Forrige måned',
    sv: 'Föregående månad',
    fi: 'Aiemmat kuukaudet',
  },

  nextMonth: {
    en: 'Next month',
    dk: 'Næste måned',
    no: 'Neste måned',
    sv: 'Nästa månad',
    fi: 'Seuraavat kuukaudet',
  },

  monthMapping: {
    [MonthMapping.JANUARY]: {
      en: 'January',
      dk: 'Januar',
      no: 'Januar',
      sv: 'Januari',
      fi: 'Tammikuu',
    },
    [MonthMapping.FEBRUARY]: {
      en: 'February',
      dk: 'Februar',
      no: 'Februar',
      sv: 'Februari',
      fi: 'Helmikuu',
    },
    [MonthMapping.MARCH]: {
      en: 'March',
      dk: 'Marts',
      no: 'Mars',
      sv: 'Mars',
      fi: 'Maaliskuu',
    },
    [MonthMapping.APRIL]: {
      en: 'April',
      dk: 'April',
      no: 'April',
      sv: 'April',
      fi: 'Huhtikuu',
    },
    [MonthMapping.MAY]: {
      en: 'May',
      dk: 'Maj',
      no: 'Mai',
      sv: 'Maj',
      fi: 'Toukokuu',
    },
    [MonthMapping.JUNE]: {
      en: 'June',
      dk: 'Juni',
      no: 'Juni',
      sv: 'Juni',
      fi: 'Kesäkuu',
    },
    [MonthMapping.JULY]: {
      en: 'July',
      dk: 'Juli',
      no: 'Juli',
      sv: 'Juli',
      fi: 'Heinäkuu',
    },
    [MonthMapping.AUGUST]: {
      en: 'August',
      dk: 'August',
      no: 'August',
      sv: 'Augusti',
      fi: 'Elokuu',
    },
    [MonthMapping.SEPTEMBER]: {
      en: 'September',
      dk: 'September',
      no: 'September',
      sv: 'September',
      fi: 'Syyskuu',
    },
    [MonthMapping.OCTOBER]: {
      en: 'October',
      dk: 'Oktober',
      no: 'Oktober',
      sv: 'Oktober',
      fi: 'Lokakuu',
    },
    [MonthMapping.NOVEMBER]: {
      en: 'November',
      dk: 'November',
      no: 'November',
      sv: 'November',
      fi: 'Marraskuu',
    },
    [MonthMapping.DECEMBER]: {
      en: 'December',
      dk: 'December',
      no: 'Desember',
      sv: 'December',
      fi: 'Joulukuu',
    },
  },

  previousYears: {
    en: 'Previous years',
    dk: 'Forrige år',
    no: 'Tidligere år',
    sv: 'Previous years',
    fi: 'Previous years',
  },

  nextYears: {
    en: 'Next years',
    dk: 'Næste år',
    no: 'Kommende år',
    sv: 'Next years',
    fi: 'Next years',
  },

  resetDate: {
    en: 'Reset date',
    dk: 'Nulstil dato',
    no: 'Tilbakestill dato',
    sv: 'Återställ datum',
    fi: 'Tyhjennä päivämäärä',
  },

  manualTimeInput: {
    en: 'Time input',
    dk: 'Skriv tid',
    no: 'Skriv inn tid',
    sv: 'Skriv in tid',
    fi: 'Kirjoita kellonaika',
  },

  enterRealTime: {
    en: 'Enter real time',
    dk: 'Angiv korrekt tid',
    no: 'Oppgi riktig tid',
    sv: 'Ange korrekt tid',
    fi: 'Anna todellinen aika',
  },

  manualTimeInputLabels: {
    hours: {
      en: 'Hours',
      dk: 'Time',
      no: 'Timer',
      sv: 'Timmar',
      fi: 'Tunnit',
    },
    minutes: {
      en: 'Minutes',
      dk: 'Minutter',
      no: 'Minutter',
      sv: 'Minuter',
      fi: 'Minuutit',
    },
  },

  inputClassifier: {
    clearSearch: {
      en: 'Clear search',
      dk: 'Nustil søgning',
      fi: 'Tyhjennä haku',
    },

    availableOptions: {
      en: 'Available options: {resultsCombined}. Go to list to pick one or continue typing.',
      dk: 'Available options: {resultsCombined}. Go to list to pick one or continue typing.',
      no: 'Available options: {resultsCombined}. Go to list to pick one or continue typing.',
      sv: 'Available options: {resultsCombined}. Go to list to pick one or continue typing.',
      fi: 'Available options: {resultsCombined}. Go to list to pick one or continue typing.',
    },
  },

  actionButtonOptionsAvailable: {
    en: 'There is 1 option available',
    dk: 'Der findes kun en mulighed',
    no: 'Det er bare ett mulig valg',
    sv: 'Det finns 1 möjligt val',
    fi: 'Valittavissa on 1 vaihtoehto',
    plural: {
      en: 'There are {0} options available',
      dk: 'Der er {0} muligheder at vælge mellem',
      no: 'Det er {0} alternativer å velge mellom',
      sv: 'Det finns {0} möjliga val',
      fi: 'Valittavissa on {0} vaihtoehtoa',
    },
  },

  multipleChoiceChoicesAvailable: {
    en: 'There is 1 choice available',
    dk: 'Der findes kun et valgmulighed',
    no: 'Det er ett mulig alternativ',
    sv: 'Det finns 1 val möjligte',
    fi: 'Valittavissa on 1 vaihtoehto',
    plural: {
      en: 'There are {0} choices available',
      dk: 'Der er {0} valgmuligheder',
      no: 'Det er {0}  mulige valg',
      sv: 'Det finns {0} val möjliga',
      fi: 'Valittavissa on {0} vaihtoehtoa',
    },
  },

  inputBoxPlaceholder: {
    en: 'Type your message',
    no: 'Skriv inn melding',
    dk: 'Skriv din besked',
    fi: 'Kirjoita viesti',
    sv: 'Skriv ditt meddelande',
  },

  currencyLabelMap: {
    [Currency.SEK]: {
      en: 'SEK Swedish kronor',
      dk: 'SEK Svenska kronor',
      no: 'SEK Svenska kronor',
      sv: 'SEK Svenska kronor',
      fi: 'SEK Svenska kronor',
    },
    [Currency.NOK]: {
      en: 'NOK Norwegian kroner',
      dk: 'NOK Norsk kronor',
      no: 'NOK Norsk kronor',
      sv: 'NOK Norsk kronor',
      fi: 'NOK Norsk kronor',
    },
    [Currency.DKK]: {
      en: 'DKK Danish kroner',
      dk: 'DKK Danske kronor',
      no: 'DKK Danske kronor',
      sv: 'DKK Danske kronor',
      fi: 'DKK Danske kronor',
    },
    [Currency.EUR]: {
      en: 'Euro',
      dk: 'Euro',
      no: 'Euro',
      sv: 'Euro',
      fi: 'Euro',
    },
    [Currency.USD]: {
      en: 'Dollars',
      dk: 'Dollars',
      no: 'Dollars',
      sv: 'Dollars',
      fi: 'Dollars',
    },
  },

  send: {
    en: 'Send',
    dk: 'Sende',
    no: 'Sende',
    sv: 'Skicka',
    fi: 'Lähetä',
  },

  edit: {
    en: 'Edit',
    dk: 'Ændre',
    no: 'Endre',
    sv: 'Ändra',
    fi: 'Muuta',
  },

  failedToSendMessage: {
    en: 'Failed to send Message!',
    dk: 'Beskeden kunne ikke sendes!',
    no: 'Sending mislyktes!',
    sv: 'Något gick fel!',
    fi: 'Viestin lähetys epäonnistui!',
  },

  tryAgain: {
    en: 'Try again',
    dk: 'Send igen',
    no: 'Prøv på nytt',
    sv: 'Skicka igen',
    fi: 'Kokeile uudelleen',
  },

  sending: {
    en: 'Sending',
    dk: 'Sender',
    no: 'Sender',
    sv: 'Skickar',
    fi: 'Lähetetään',
  },

  editLastQuestion: {
    en: 'Edit last answered question',
    dk: 'Ændre svar',
    no: 'Endre svar',
    sv: 'Korrigera svar',
    fi: 'Muokkaa edellistä vastausta',
  },

  cancel: {
    en: 'Cancel',
    dk: 'Fortryd',
    no: 'Avbryt',
    sv: 'Avbryt',
    fi: 'Peruuta',
  },

  select: {
    en: 'Select',
    dk: 'Vælg',
    no: 'Velg',
    sv: 'Välj',
    fi: 'Valitse',
  },

  pageTitle: {
    en: 'Report a claim',
    dk: 'Anmeld skade',
    no: 'Meld skade',
    sv: 'Anmäl skada',
    fi: 'Vahinkoilmoitus',
  },

  seMotorPageTitleKontakt: {
    sv: 'Kontakt',
    en: 'Contact',
  },

  fiTravelPageTitle: {
    en: 'Damage report',
    fi: 'Vahinkoilmoitus',
    sv: 'Anmäl skada',
  },

  fiForestPageTitle: {
    en: 'Damage report',
    fi: 'Vahinkoilmoitus',
    sv: 'Anmäl skada',
  },

  fiLegalAndLiabilityPageTitle: {
    en: 'Damage report',
    fi: 'Vahinkoilmoitus',
    sv: 'Anmäl skada',
  },

  fiAnimalPageTitle: {
    fi: 'Vahinkoilmoitus',
    sv: 'Anmäl skada',
  },

  fiMotorPageTitle: {
    fi: 'Ajoneuvon vahinkoilmoitus',
  },

  fiProfAthletesPageTitle: {
    en: 'Professional athlete claim report',
    fi: 'Ammattiurheilijan vahinkoilmoitus',
  },

  fiProfAthletesEngPageTitle: {
    en: 'Professional athlete claim report',
    fi: 'Professional athlete claim report',
  },

  fiCommercialPersonalInjuryEbHealthPageTitle: {
    fi: 'Vahinkoilmoitus',
    sv: 'Anmäl skada',
  },

  fiHealthNavigatorPageTitle: {
    fi: 'Suorakorvaus',
  },

  fiClaimsCenterPageTitle: {
    fi: 'Korvauskeskus',
  },

  fiCommercialPropertyPageTitle: {
    fi: 'Vahinkoilmoitus',
    sv: 'Anmäl skada',
  },

  seMotorPageTitle: {
    en: 'Report a claim',
    sv: 'Anmäl skada',
  },

  seFollowUpPageTitle: {
    en: 'Report a claim',
    sv: 'Komplettera skada',
  },

  nordicCommonPageTitle: {
    en: 'Contact',
    dk: 'Kontakt',
    no: 'Kontakt',
    sv: 'Kontakt',
    fi: 'Ota yhteyttä',
  },

  errorPage: {
    somethingWentWrong: {
      en: 'Sorry, something went wrong',
      dk: 'Beklager, der er opstået en fejl',
      no: 'En feil har oppstått',
      sv: 'Något har gått fel',
      fi: 'Tapahtui jokin virhe',
    },
    weAreWorkingOnIt: {
      en: 'We are working on it',
      dk: 'Vi arbejder på at løse problemet.',
      no: 'Vi jobber med saken',
      sv: 'Vi arbetar på det',
      fi: 'Selvitämme ongelmaa',
    },
    tryAgainLater: {
      en: 'Please try again later',
      dk: 'Prøv gerne igen senere',
      no: 'Forsøk igjen senere',
      sv: 'Försök igen senare',
      fi: 'Yritä myöhemmin uudelleen',
    },
  },

  pageNotFound: {
    title: {
      en: 'Page not found',
      dk: 'Siden findes ikke',
      no: 'Vi finner ikke siden',
      sv: 'Sidan kan inte hittas',
      fi: 'Sivua ei löydy',
    },
    message: {
      en: 'The link you clicked may be broken or the page may be removed',
      dk: 'Linket du klikkede på, virker desværre ikke',
      no: 'Linken du brukte virker ikke, eller så har siden blitt fjernet',
      sv: 'Länken du använt verkar inte fungera eller så har sidan blivit borttagen',
      fi: 'Linkki voi olla viallinen tai sivu on poistettu',
    },
  },

  menu: {
    title: {
      en: 'Menu',
      dk: 'Menu',
      no: 'Meny',
      sv: 'Meny',
      fi: 'Valikko',
    },
    signOut: {
      en: 'Sign out',
      dk: 'Log ud',
      no: 'Logg ut',
      sv: 'Logga ut',
      fi: 'Kirjaudu ulos',
    },
    contactSupport: {
      en: 'Other contact methods',
      dk: 'Kontakt kundeservice',
      no: 'Kontakt kundeservice',
      sv: 'Andra kontaktvägar',
      fi: 'Muut yhteydenottotavat',
    },
    accessibilityStatement: {
      en: 'Accessibility statement',
      sv: 'Tillgänglighetsutlåtande',
      fi: 'Saavutettavuusseloste',
    },
  },

  bugReport: {
    title: {
      en: 'Report a technical issue',
      dk: 'Rapporter teknisk fejl',
      no: 'Rapporter en teknisk feil',
      sv: 'Rapportera ett tekniskt fel',
      fi: 'Ilmoita teknisestä virheestä',
    },
    description: {
      en: 'Tell us what went wrong',
      dk: 'Skriv til os, hvad der gik galt',
      no: 'Fortell oss hva som har gått galt',
      sv: 'Berätta vad som gått fel',
      fi: 'Kerro tarkemmin virhetilanteesta',
    },
    inputPlaceholder: {
      en: 'Describe what went wrong...',
      dk: 'Skriv her',
      no: 'Beskriv feilen',
      sv: 'Beskriv felet',
      fi: 'Kirjoita tähän...',
    },
    submitSuccess: {
      en: 'Bug report sent. Thank you for your feedback!',
      dk: 'Din fejlrapport er sendt. Mange tak for hjælpen.',
      no: 'En feilrapport er sendt. Takk for din tilbakemelding.',
      sv: 'Felmeddelandet är skickat. Tack för din feedback!',
      fi: 'Virheilmoitus lähetetty. Kiitos palautteestasi.',
    },
    submitError: {
      en: 'Something went wrong while submitting your bug report. Please try again.',
      dk: 'Noget gik galt ved afsendelsen, forsøg gerne igen!',
      no: 'En feil oppstod ved sending av feilrapporten. Forsøk igjen.',
      sv: 'Något gick fel när vi skickade din felrapport. Försök igen.',
      fi: 'Virheilmoituksen lähettämisessä tapahtui virhe. Yritäthän uudestaan.',
    },
  },

  signOut: {
    heading: {
      en: "You've signed out of your account",
      dk: 'Du er nu logget ud',
      no: 'Du har nå logget ut av din konto',
      sv: 'Du har nu loggat ut ditt konto',
      fi: 'Olet kirjautunut ulos',
    },
    subHeading1: {
      en: 'You can close the tab now',
      dk: 'Du kan nu lukke vinduet',
      no: 'Du kan lukke vinduet nå',
      sv: 'Du har nu loggat ut ditt konto',
      fi: 'Voit sulkea välilehden',
    },
    subHeading2: {
      en: 'You can start a new claim',
      dk: 'Eller melde en ny skade',
      no: 'Du kan starte å melde ny skade',
      sv: 'Starta nytt skadeärende',
      fi: 'Voit ilmoittaa uudesta vahingosta',
    },
    buttonText: {
      en: 'Start new claim',
      dk: 'Anmeld ny skade',
      no: 'Start ny skadehenvendelse',
      sv: 'Starta nytt skadeärende',
      fi: 'Ilmoita uudesta vahingosta',
    },
  },

  fileUpload: {
    removeFile: {
      en: 'Remove file',
      dk: 'Fjern filen',
      fi: 'Poista tiedosto',
      no: 'Fjern',
      sv: 'Ta bort',
    },

    fileIsUploading: {
      en: 'File is uploading...',
      dk: 'Filen vedhæftes...',
      fi: 'Tallennetaan...',
      no: 'Laster opp...',
      sv: 'Fil laddas upp...',
    },

    fileUploaded: {
      en: 'File uploaded',
      dk: 'Filen er vedhæftet',
      fi: 'Tiedosto tallennettu',
      no: 'Opplastet',
      sv: 'Fil uppladdad',
    },

    fileFailedToUpload: {
      en: 'File failed to upload',
      dk: 'Vedhæftelsen mislykkedes',
      fi: 'Tallennus epäonnistui',
      no: 'Opplastningen mislyktes',
      sv: 'Uppladdning misslyckades',
    },

    acceptedFileListToUpload: {
      en: 'The following files will be uploaded',
      dk: 'Følgende filer vedhæftes',
      fi: 'Seuraavat tiedostot tallennetaan',
      no: 'Følgende filer vil bli lastet opp',
      sv: 'Följande filer kommer laddas upp',
    },

    rejectedFileListToSkip: {
      en: 'The following files will be skipped',
      dk: 'Følgende filer kan ikke vedhæftes',
      fi: 'Seuraavat tiedostot ohitetaan',
      no: 'Følgende filer kan ikke lastes opp',
      sv: 'Följande filer kommer inte laddas upp',
    },

    fileCanNotBeUploaded: {
      en: '{0} file can not be uploaded',
      dk: '{0} fil kan ikke vedhæftes',
      fi: '{0} tiedostoa ei voi tallentaa',
      no: '{0} fil kan ikke lastes opp',
      sv: '{0} fil kan inte laddas upp',
      plural: {
        en: '{0} files can not be uploaded',
        dk: '{0} filer kan ikke vedhæftes',
        fi: '{0} tiedostoa ei voi tallentaa',
        no: '{0} filer kan ikke lastes opp',
        sv: '{0} filer kan inte laddas upp',
      },
    },

    fileWillBeUploaded: {
      en: '{0} file will be uploaded',
      dk: '{0} fil vedhæftes',
      fi: '{0} tiedosto tallennetaan',
      no: 'Du har valgt {0} fil',
      sv: '{0} fil kommer laddas upp',
      plural: {
        en: '{0} files will be uploaded',
        dk: '{0} filer vedhæftes',
        fi: '{0} tiedostoa tallennetaan',
        no: 'Du har valgt {0} filer',
        sv: '{0} filer kommer laddas upp',
      },
    },

    dismiss: {
      en: 'Dismiss',
      dk: 'Fjern',
      fi: 'Sulje',
      no: 'Lukk',
      sv: 'Dölj',
    },

    fileIsTooBigScreenReader: {
      en: 'File {0} is too big. The selected file must be smaller than {1}.',
      dk: 'Filen {0} er for stor. Den valgte fil skal være mindre end {1}.',
      no: 'Filen {0} er for stor. Den valgte filen må være mindre enn {1}.',
      sv: 'Filen {0} är för stor. Vald fil måste vara mindre än {1}.',
      fi: 'Tiedosto {0} on liian suuri. Valittu tiedosto tulee olla pienempi kuin {1}.',
    },

    fileIsEmptyScreenReader: {
      en: 'The selected file {0} is empty.',
      dk: 'Den valgte fil {0} er tom.',
      no: 'Den valgte filen {0} er tom',
      sv: 'Vald fil {0} är tom.',
      fi: 'Valittu tiedosto {0} on tyhjä.',
    },

    fileTypeIsNotSupported: {
      en: 'File type is not supported for file {0}. Supported formats: {1}.',
      dk: 'Filformatet understøttes ikke i filen {0}. Formater som understøttes er: {1}.',
      no: 'Filtypen i filen {0} støttes ikke. Filtyper som støttes: {1}.',
      sv: 'Filformatet stöds inte i filen {0}. Format som stöds: {1}.',
      fi: 'Tiedoston {0} tyyppi ei ole sallittu. Sallitut tiedostotyypit: {1}.',
    },

    fileIsAddedToListScreenReader: {
      en: 'File {0} is added to the list',
      dk: 'Filen {0} til tilføjet til listen',
      no: 'Filen {0} har blitt lagt til i listen.',
      sv: 'Filen {0} är inlagd i listan',
      fi: 'Tiedosto {0} on lisätty listaan',
    },
  },

  itemList: {
    deleteCurrentRow: {
      en: 'Delete current row',
      dk: 'Fjern række',
      no: 'Fjern denne rekken',
      sv: 'Ta bort aktuell rad',
      fi: 'Poista nykyinen rivi',
    },
    recoverDeletedRow: {
      en: 'Recover deleted row',
      dk: 'Genskab slettet række',
      no: 'Gjenopprett den fjernede rekken',
      sv: 'Återskapa aktuell rad',
      fi: 'Kumoa rivin poisto',
    },
  },

  restartChat: {
    en: 'Restart chat',
    dk: 'Genstart chatten',
    no: 'Prøv på nytt',
    sv: 'Starta om chat',
    fi: 'Avaa lomake uudelleen',
  },

  bytes: {
    en: 'Byte',
    dk: 'Byte',
    no: 'Byte',
    sv: 'Byte',
    fi: 'Tavu',
    plural: {
      en: 'Bytes',
      dk: 'Bytes',
      no: 'Bytes',
      sv: 'Bytes',
      fi: 'Tavua',
    },
  },

  invalidSize: {
    en: 'Invalid size',
    dk: 'Invalid size',
    no: 'Invalid size',
    sv: 'Invalid size',
    fi: 'Invalid size',
  },

  console: {
    en: 'Console',
  },
};

export { Translations };
