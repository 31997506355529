import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classes from './BugReport.module.scss';

import { translate, Translations } from '../../../locale';

import { bugReport } from '@/api/bugReport/bugReportMethods';
import { showNotificationWithTimeout } from '../../../store/notification/actions';
import { appInsights } from '@/appInsights/appInsights';
import { generateId } from '../../../utils/utils';

import { RootState } from '../../../store/types';
import { AppInsightsEventNames } from '../../../constants';
import { BotConfigState } from '../../../store/bot-config/types';
import { BugReportRequest } from '@/api/types';
import { PageConfigState } from '@/store/page-config/types';

import ButtonPrimary from '@/components/Buttons/ButtonPrimary/ButtonPrimary';
import ButtonSecondary from '@/components/Buttons/ButtonSecondary/ButtonSecondary';
import Modal from '../Modal/Modal';
import useEmitOverlayOpened from '@/hooks/useEmitOverlayOpened';

interface BugReportProps {
  showModal: boolean;
  close: () => void;
}

const BugReport: FunctionComponent<BugReportProps> = ({
  showModal,
  close,
}: BugReportProps) => {
  const [text, setText] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dispatch = useDispatch();
  const emitOverlayOpened = useEmitOverlayOpened();

  useEffect(() => {
    if (showModal) {
      emitOverlayOpened(close)
    }
  }, [showModal]);

  const botConfigState = useSelector<RootState, BotConfigState>(
    (state) => state.botConfigState
  );
  const pageConfig = useSelector<RootState, PageConfigState>(
    (state) => state.pageConfigState
  );
  const isAuthenticated = useSelector<RootState, boolean>(
    (state) => !!state.bffAuthenticationState.username
  );

  const calculateNotificationDuration = (message: string) => {
    // 50 ms per character, no shorter than 2 seconds, no longer than 7 seconds
    const messageDuration = Math.min(Math.max(message.length * 50, 2000), 7000);

    const animationDuration = 1500;

    return messageDuration + animationDuration;
  };

  const submitBugReport = async () => {
    setIsSubmitting(true);
    appInsights.trackEvent({
      name: AppInsightsEventNames.BugReport,
    });

    const bugReportRequest: BugReportRequest = {
      conversationId: botConfigState.conversationId
        ? botConfigState.conversationId
        : 'null',
      tenantId: pageConfig?.config?.tenantId || null,
      isStarted: botConfigState.firstVisibleMessageReceived ? 'Yes' : 'No',
      local: pageConfig.config?.locale,
      brand: pageConfig?.config?.brandName,
      isAuthenticated: isAuthenticated ? 'Yes' : 'No',
      url: window.location.href,
      text,
    };

    const { data: result } = await bugReport({ body: bugReportRequest });

    setIsSubmitting(false);

    const message = result?.isSuccess
      ? translate(Translations.bugReport.submitSuccess)
      : translate(Translations.bugReport.submitError);

    dispatch(
      showNotificationWithTimeout({
        id: generateId(),
        text: message,
        duration: calculateNotificationDuration(message),
      })
    );

    close();
  };

  const modalBody = (
    <div className={classes.BugReport}>
      <p className={classes.Description}>
        {translate(Translations.bugReport.description)}
      </p>

      <textarea
        className={classes.InputArea}
        placeholder={translate(Translations.bugReport.inputPlaceholder)}
        value={text}
        onChange={(event) => setText(event.target.value)}
      />
    </div>
  );
  const modalFooter = (
    <div className={classes.ButtonBox}>
      <ButtonSecondary onClick={close}>
        {translate(Translations.cancel)}
      </ButtonSecondary>

      <ButtonPrimary
        onClick={submitBugReport}
        disabled={!text || text === '' || isSubmitting}
      >
        {translate(Translations.send)}
      </ButtonPrimary>
    </div>
  );

  return (
    <Modal
      title={translate(Translations.bugReport.title)}
      modalVisible={showModal}
      shouldCloseOnEscape={true}
      closeHandler={close}
      modalBody={modalBody}
      modalFooter={modalFooter}
    />
  );
};

export default BugReport;
