import { DropEvent, FileRejection } from 'react-dropzone';
import { CustomActivityConfig } from '../../../../middleware/types';
import { UploadFileResponse } from '@/api/types';

export enum FileUploadState {
  ADDED = 'added',
  UPLOADING = 'uploading',
  DONE = 'done',
  FAILED = 'failed',
  REJECTED = 'rejected',
}

export interface FileMetadata {
  id: string;
  state: FileUploadState;
  name: string;
  size: number;
  lastModified: number;
  type: string;
}

export interface AcceptedFile extends FileMetadata {
  thumbnail: string | undefined;
  data: File;
}

export interface UploadedFile {
  filePath: string;
  fileName: string;
  fileType: string;
  fileSize: number;
}

export interface RejectedFile extends FileMetadata {
  reason: string;
  reasonForScreenReader: string;
}

export interface RejectedFileItemProps {
  file: RejectedFile;
}

export interface FileUploadDialogProps {
  config: FileUploadConfig;
  showModal: boolean;
  uploadInProgress: boolean;
  setUploadInProgress: React.Dispatch<React.SetStateAction<boolean>>;
  acceptedFiles: AcceptedFile[];
  handleOnAccepted: <T extends File>(
    acceptedFiles: T[],
    event: DropEvent
  ) => Promise<void>;
  rejectedFiles: RejectedFile[];
  handleOnRejected: (rejectedFiles: FileRejection[], event: DropEvent) => void;
  attemptFileUpload: () => Promise<void>;
  removeFileFromList: (fileIndex: number) => void;
  setFileUploadState: (file: AcceptedFile, state: FileUploadState) => void;
  clearRejectedFiles: () => void;
  failedUpload: boolean;
  cancelUploadCleanup: () => void;
}
export interface FileUploadData extends UploadFileResponse {
  id: string;
  fileName: string;
  fileSize: number;
  fileType: string;
}

export interface FileUploadConfig extends CustomActivityConfig {
  fileUploadButton: string;
  uploadFilesButtonText: string;
  uploadInProgressButtonText: string;
  browseFilesButtonText: string;
  dropZoneContainerText: string;
  uploadFailedErrorMessage: string;
  allowedFileExtensions: string;
  fileTypeErrorMessage: string;
  maxFileSizeInBytes: number;
  fileSizeErrorMessage: string;
  maxFileCount: number;
  fileCountErrorMessage: string;
  fileEmptyErrorMessage: string;
  modalHeaderText: string;
}

export interface FileUploadDropZoneProps {
  dropZoneContainerText: string;
  browseFilesButtonText: string;
  handleOnAccepted: (acceptedFiles: File[], event: DropEvent) => Promise<void>;
  handleOnRejected: (
    fileRejections: FileRejection[],
    event: DropEvent,
    customRejectedMessage?: string
  ) => void;
  dropZoneClassName?: string;
  buttonClassName?: string;
  allowedFileExtensions: string;
  maxFileSizeInBytes: number;
  uploadInProgress: boolean;
}

export interface FileListItemProps {
  file: AcceptedFile;
  removeFile: () => void;
  index: number;
}

export interface FileListItemMapping {
  className: string;
  spin: boolean;
  path: string;
  role: string;
  title: string;
  tabIndex: number;
}

export interface FileListItemConfiguration {
  [key: string]: FileListItemMapping;
}
