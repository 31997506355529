import classes from './RejectedFileItem.module.scss';
import { bytesToSize } from '../../../../../../utils';
import Icon from '@mdi/react';
import { mdiAlertCircleOutline } from '@mdi/js';
import { RejectedFileItemProps } from '../../types';
import { AriaDocumentStructureRoles } from '../../../../../../constants/aria';

const RejectedFileItem = (props: RejectedFileItemProps): JSX.Element => {
  const { file } = props;
  const { name, size, reason, reasonForScreenReader } = file;

  return (
    <div
      className={classes.RejectedFileItem}
      role={AriaDocumentStructureRoles.LIST_ITEM}
    >
      <div className={classes.Item}>
        <div className={classes.LeftSide}>
          <div className={classes.Icon}>
            <Icon path={mdiAlertCircleOutline} size={1} />
          </div>

          <span className={classes.FileName}>{name}</span>
        </div>

        <div className={classes.RightSide}>
          <span className={classes.FileSize}>{bytesToSize(size)}</span>
        </div>
      </div>
      <div
        role="alert"
        className={classes.RejectionReason}
        aria-label={reasonForScreenReader}
      >
        {reason}
      </div>
    </div>
  );
};

export default RejectedFileItem;
