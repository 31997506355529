// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonPrimary-module_ButtonPrimary--lMVGy{font-family:var(--font-stack) !important}.ButtonPrimary-module_ButtonPrimary--lMVGy:not(:disabled){background-color:var(--color-action) !important;color:var(--color-action-secondary) !important;border-color:var(--color-action) !important}.ButtonPrimary-module_ButtonPrimary--lMVGy:not(:disabled):hover{background:var(--color-action-dark) !important;border-color:var(--color-action-dark) !important;box-shadow:inset 0 0 0 .0625rem var(--color-action-dark) !important}", "",{"version":3,"sources":["webpack://./src/components/Buttons/ButtonPrimary/ButtonPrimary.module.scss"],"names":[],"mappings":"AAIA,2CACE,wCAAA,CAEA,0DACE,+CAAA,CACA,8CAAA,CACA,2CAAA,CAGF,gEACE,8CAAA,CACA,gDAAA,CACA,mEAAA","sourcesContent":["@import '~styles/colors';\r\n\r\n$box-shadow-width: 0.0625rem;\r\n\r\n.ButtonPrimary {\r\n  font-family: v(font-stack) !important;\r\n\r\n  &:not(:disabled) {\r\n    background-color: v(color-action) !important;\r\n    color: v(color-action-secondary) !important;\r\n    border-color: v(color-action) !important;\r\n  }\r\n\r\n  &:not(:disabled):hover {\r\n    background: v(color-action-dark) !important;\r\n    border-color: v(color-action-dark) !important;\r\n    box-shadow: inset 0 0 0 $box-shadow-width v(color-action-dark) !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ButtonPrimary": "ButtonPrimary-module_ButtonPrimary--lMVGy"
};
export default ___CSS_LOADER_EXPORT___;
