export interface Notification {
  id: string;
  text: string;
  duration: number;
  dismissable?: boolean;
}

export interface NotificationState {
  readonly notifications: Notification[];
}

export enum NotificationActionTypes {
  SHOW_NOTIFICATION = 'NOTIFICATION/SHOW_NOTIFICATION',
  SHOW_NOTIFICATION_WITH_TIMEOUT = 'NOTIFICATION/SHOW_NOTIFICATION_WITH_TIMEOUT',
  HIDE_NOTIFICATION = 'NOTIFICATION/HIDE_NOTIFICATION',
}
