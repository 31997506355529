import {
  DateFormatProps,
  DateInputActivityConfig,
  DateInputSetValueProps,
  ManualInputState,
} from './types';
import Moment from 'moment';
import { translate, Translations } from '../../../../locale';
import {
  isDayAfterMaximum,
  isDayBeforeMinimum,
  transformDate,
  areAllPartsFilled,
  areAllPartsValid,
  getReplacedValue,
} from './dateInputHelper';

const C_SHARP_DAY_FORMAT = 'dd';
const TYPESCRIPT_DAY_FORMAT = 'DD';

const onlyDigitsRegex = /^\d+$/;

export const setValue = (props: DateInputSetValueProps) => {
  const { event, dateProps, config } = props;
  const inputValue = event.target.value;
  if (inputValue !== '' && !onlyDigitsRegex.test(inputValue)) {
    event.preventDefault();
    event.stopPropagation();
    return;
  }

  const newDateValue = { ...props.dateValue };
  newDateValue[event.target.id] = getReplacedValue(
    props.value || inputValue,
    props.placeHolder,
    props.inputRefs,
    props.inputIndex
  );
  const manualState = getManualState(dateProps, config, newDateValue);

  props.setManualState(manualState);
};

export const getManualState = (
  dateProps: DateFormatProps,
  config: DateInputActivityConfig,
  dateValue: { [key: string]: string }
): ManualInputState => {
  const allPartsFilled = areAllPartsFilled(dateValue);
  const allPartsValid = areAllPartsValid(dateValue);

  if (!allPartsValid) {
    return {
      dateValue,
      isError: true,
      isValidDate: false,
      errorMessage: translate(Translations.dateInputAlerts.enterRealDate),
    };
  }

  if (allPartsValid && !allPartsFilled) {
    return {
      dateValue,
      isError: false,
      isValidDate: false,
    };
  }

  const dateString = transformDate(dateProps, dateValue);
  const momentDate = Moment(dateString, dateProps.dateFormat);

  if (!momentDate.isValid) {
    return {
      dateValue,
      isError: true,
      isValidDate: false,
      errorMessage: translate(Translations.dateInputAlerts.enterRealDate),
    };
  }

  const { allowedPastDate, allowedFutureDate, dateFormat } = config;

  const dateFormatAdjusted = dateFormat.replace(
    C_SHARP_DAY_FORMAT,
    TYPESCRIPT_DAY_FORMAT
  );
  const allowedFutureDateAsMoment = Moment(allowedFutureDate);
  const allowedPastDateAsMoment = Moment(allowedPastDate);

  if (allowedPastDate && allowedFutureDate) {
    const isDayBeforeMinimumDate = isDayBeforeMinimum(
      momentDate,
      allowedPastDateAsMoment
    );
    if (isDayBeforeMinimumDate) {
      return getManualStateForDateBeforeMinBetween(
        dateValue,
        allowedPastDateAsMoment,
        allowedFutureDateAsMoment,
        dateFormatAdjusted
      );
    }

    const isDayAfterMaximumDate = isDayAfterMaximum(
      momentDate,
      allowedFutureDateAsMoment
    );

    if (isDayAfterMaximumDate) {
      return getManualStateForDateAfterMaxBetween(
        dateValue,
        allowedPastDateAsMoment,
        allowedFutureDateAsMoment,
        dateFormatAdjusted
      );
    }
  }

  if (
    allowedPastDate &&
    isDayBeforeMinimum(momentDate, allowedPastDateAsMoment)
  ) {
    return getManualStateForDateBeforeMin(
      dateValue,
      allowedPastDate,
      dateFormatAdjusted
    );
  }

  if (
    allowedFutureDate &&
    isDayAfterMaximum(momentDate, allowedFutureDateAsMoment)
  ) {
    return getManualStateForDateAfterMax(
      dateValue,
      allowedFutureDate,
      dateFormatAdjusted
    );
  }

  const validationExpr = dateProps.validation.expression;
  if (validationExpr && !RegExp(validationExpr).test(dateString)) {
    return {
      dateValue,
      isError: true,
      isValidDate: false,
      errorMessage:
        dateProps.validation.message.length > 0
          ? dateProps.validation.message
          : translate(Translations.dateInputAlerts.enterValidDate),
    };
  }

  return {
    dateValue,
    isError: false,
    isValidDate: true,
  };
};

const getManualStateForDateBeforeMinBetween = (
  dateValue: { [key: string]: string },
  allowedPastDate: Moment.Moment,
  allowedFutureDate: Moment.Moment,
  dateFormat: string
) => {
  return {
    dateValue,
    isError: true,
    isValidDate: false,
    errorMessage: getMessageForBetweenTwoDates(
      allowedPastDate,
      allowedFutureDate,
      dateFormat
    ),
  };
};

const getManualStateForDateAfterMaxBetween = (
  dateValue: { [key: string]: string },
  allowedPastDate: Moment.Moment,
  allowedFutureDate: Moment.Moment,
  dateFormat: string
) => {
  return {
    dateValue,
    isError: true,
    isValidDate: false,
    errorMessage: getMessageForBetweenTwoDates(
      allowedPastDate,
      allowedFutureDate,
      dateFormat
    ),
  };
};

const getMessageForBetweenTwoDates = (
  allowedPastDate: Moment.Moment,
  allowedFutureDate: Moment.Moment,
  dateFormat: string
) => {
  const today = Moment();
  const futureDateFormatted = allowedFutureDate.format(dateFormat);
  const pastDateFormatted = allowedPastDate.format(dateFormat);

  if (today.isSame(allowedFutureDate, 'day')) {
    return translate(
      Translations.dateInputAlerts.dateMustBeBetweenPastAndToday,
      null,
      [pastDateFormatted]
    );
  } else if (today.isSame(allowedPastDate, 'day')) {
    return translate(
      Translations.dateInputAlerts.dateMustBeBetweenTodayAndFuture,
      null,
      [futureDateFormatted]
    );
  } else {
    return translate(Translations.dateInputAlerts.dateMustBeBetween, null, [
      allowedPastDate.format(dateFormat),
      futureDateFormatted,
    ]);
  }
};

const getManualStateForDateAfterMax = (
  dateValue: { [key: string]: string },
  allowedFutureDate: string,
  dateFormat: string
) => {
  const today = Moment();
  const allowedFutureDateAsMoment = Moment(allowedFutureDate);

  let errorMessage = '';
  if (today.isSame(allowedFutureDate, 'day')) {
    errorMessage = translate(
      Translations.dateInputAlerts.dateMustBeTodayOrInPast
    );
  } else {
    errorMessage = translate(
      Translations.dateInputAlerts.dateMustBeBefore,
      null,
      [allowedFutureDateAsMoment.format(dateFormat)]
    );
  }

  return {
    dateValue,
    isError: true,
    isValidDate: false,
    errorMessage: errorMessage,
  };
};

const getManualStateForDateBeforeMin = (
  dateValue: { [key: string]: string },
  allowedPastDate: string,
  dateFormat: string
) => {
  const today = Moment();
  const allowedPastDateAsMoment = Moment(allowedPastDate);

  let errorMessage = '';
  if (today.isSame(allowedPastDate, 'day')) {
    errorMessage = translate(
      Translations.dateInputAlerts.dateMustBeTodayOrInTheFuture
    );
  } else {
    errorMessage = translate(
      Translations.dateInputAlerts.dateMustBeAfter,
      null,
      [allowedPastDateAsMoment.format(dateFormat)]
    );
  }

  return {
    dateValue,
    isError: true,
    isValidDate: false,
    errorMessage: errorMessage,
  };
};
