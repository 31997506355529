import { useState, useCallback } from 'react';

export type ThrowErrorFunction = (error: Error) => void;

const useThrowError = (): ThrowErrorFunction => {
  const [_, setError] = useState();
  return useCallback(
    (error) => {
      setError(() => {
        throw error;
      });
    },
    [setError]
  );
};

export default useThrowError;
