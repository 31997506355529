import { FunctionComponent, useEffect } from 'react';
import classes from './PageNotFound.module.scss';

import { translate, Translations } from '../../../locale';

import Icon from '@mdi/react';
import { mdiRobot } from '@mdi/js';

import { appInsights } from '@/appInsights/appInsights';

const PageNotFound: FunctionComponent = () => {
  useEffect(() => {
    appInsights.trackPageView({ name: 'PageNotFound' });
  }, []);

  return (
    <div className={classes.PageNotFoundContainer}>
      <div className={classes.Message}>
        <span className={classes.BigSize}>
          {translate(Translations.pageNotFound.title)}!
        </span>
        <span className={classes.MiddleSize}>
          {translate(Translations.pageNotFound.message)}.
        </span>
      </div>
      <Icon className={classes.Icon} path={mdiRobot} size={15} />
    </div>
  );
};

export default PageNotFound;
