import { lazy } from 'react';
import SignOutCallback from './components/Authentication/SignOut/SignOutCallback';
import PageNotFound from './components/UI/PageNotFound/PageNotFound';
import Chat from './containers/Chat/Chat';
import {
  AppRoutes,
  AppRoutesSwedenMotorPartners,
  AuthenticationRoutes,
  ChatRoutes,
} from './constants';
import { isTestEnvironment, isDevEnvironment } from './utils/utils';
import linkToTenantMapping from './mapping/linkToTenantMapping';
import { TenantMapping } from './mapping/types';
import { RouteProps } from 'react-router-dom';
import SilentAuthentication from './containers/SilentAuthentication/SilentAuthentication';

const Home = lazy(() => import('./containers/Home/Home'));

/* Chat routes based on location origin */
const chatRoutesByOrigin = () => {
  let chatOriginUrls: string[] = [];
  const routes: RouteProps[] = [];
  const location = window.location;

  // FIXME: investigate why Jest imports "linkToTenantMapping" as undefined
  chatOriginUrls = linkToTenantMapping
    ? linkToTenantMapping.reduce((result: string[], mapping: TenantMapping) => {
        if (mapping.pageConfig.productionUrl) {
          result.push(mapping.pageConfig.productionUrl);
        }
        // https://confluence.ifint.biz/display/EC/IMF+domains+for+Atlas -> Test domain (ATEST)
        if (mapping.pageConfig.testUrl) {
          result.push(mapping.pageConfig.testUrl);
        }
        return result;
      }, [])
    : [];

  if (chatOriginUrls.includes(location.origin)) {
    routes.push({
      path: AppRoutes.Root,
      element: (
        <SilentAuthentication>
          <Chat />
        </SilentAuthentication>
      ),
    });
  }

  return routes;
};

/* If classic chat routes based on location path */
const chatRoutesByPath = Object.values(ChatRoutes).reduce(
  (result: RouteProps[], key) => {
    result.push({
      path: key,
      element: (
        <SilentAuthentication>
          <Chat />
        </SilentAuthentication>
      ),
    });
    return result;
  },
  []
);
/* Sweden Motor specific partner routes based on location path */
const swedenMotorPartnerRoutes = Object.values(
  AppRoutesSwedenMotorPartners
).reduce((result: RouteProps[], key) => {
  result.push({
    path: key,
    element: (
      <SilentAuthentication>
        <Chat />
      </SilentAuthentication>
    ),
  });
  return result;
}, []);
/* Authentication specific routes through IDM */
const authRoutes: RouteProps[] = [
  {
    path: AuthenticationRoutes.SignOutCallback,
    element: <SignOutCallback />,
  },
];

function homePageRoutes() {
  const result: RouteProps[] = [];
  const isDevelopmentEnvironment = isDevEnvironment();

  if (isDevelopmentEnvironment || isTestEnvironment()) {
    result.push({
      path: AppRoutes.HomePage,
      element: <Home />,
    });
  }

  if (isDevelopmentEnvironment) {
    result.push({
      path: AppRoutes.Root,
      element: <Home />,
    });
  }

  return result;
}

const routes: RouteProps[] = [
  ...authRoutes,

  ...chatRoutesByOrigin(),

  ...chatRoutesByPath,

  ...swedenMotorPartnerRoutes,

  ...homePageRoutes(),

  {
    path: AppRoutes.NotFound,
    element: <PageNotFound />,
  },
];

export default routes;
