import { ReactElement } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { customPortalRef } from '../Chat/Chat';

import classes from './CustomInputPortal.module.scss';

export default function CustomInputPortal({
  children,
  horizontalPosition = 'start',
}: {
  children: ReactElement;
  horizontalPosition?: 'start' | 'end';
}): React.ReactPortal | null {
  return (
    customPortalRef.current &&
    ReactDOM.createPortal(
      <div
        className={classNames(
          classes.CustomInputPortalContainer,
          classes[`CustomInputPortalContainer-${horizontalPosition}`]
        )}
      >
        {children}
      </div>,
      customPortalRef.current
    )
  );
}
