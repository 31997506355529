import { FunctionComponent } from 'react';
import classes from './LinearProgressBar.module.scss';

const LinearProgressBar: FunctionComponent = () => (
  <div className={classes.LinearProgress}>
    <div className={classes.Indeterminate}></div>
  </div>
);

export default LinearProgressBar;
