import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { KeyCodes } from '../../constants';
import classes from './ClickOutside.module.scss';

export interface ClickOutsidePassThroughProps {
  clickedOutside: boolean;
  setClickedOutside: (state: boolean) => void;
}

export interface ClickOutsideProps<T> {
  withTab?: boolean;
  initialState?: boolean;
  passThroughProps: T;
  render: (passThroughProps: T & ClickOutsidePassThroughProps) => ReactElement;
}

/**
 * Click outside wrapper is responsible for providing
 * focus state of child components.
 *
 * Use generic prop type to be aware of the props being passed through.
 *
 * @param props<T>
 */
export default function ClickOutside<T>(
  props: ClickOutsideProps<T>
): JSX.Element {
  const clickOutsideWrapper = useRef<HTMLDivElement | null>(null);
  const [clickedOutside, setClickedOutside] = useState(
    props.initialState === undefined ? true : props.initialState
  );

  const setClickedState = (
    event: KeyboardEvent | MouseEvent,
    wrapper: HTMLDivElement
  ): void => {
    setClickedOutside(!wrapper.contains(event.target as Node));
  };

  const handleClickOutside = (event: MouseEvent): void => {
    if (clickOutsideWrapper?.current) {
      setClickedState(event, clickOutsideWrapper.current);
    }
  };

  const handleTabOutside = (event: KeyboardEvent): void => {
    if (
      clickOutsideWrapper?.current &&
      props.withTab &&
      event.key === KeyCodes.Tab
    ) {
      setClickedState(event, clickOutsideWrapper.current);
    }
  };

  const clickOutsideCallback = useCallback(handleClickOutside, [
    handleClickOutside,
  ]);
  const tabOutsideCallback = useCallback(handleTabOutside, [handleTabOutside]);

  useEffect(() => {
    document.addEventListener('mousedown', clickOutsideCallback);
    if (props.withTab) {
      document.addEventListener('keyup', tabOutsideCallback);
    }

    return () => {
      document.removeEventListener('mousedown', clickOutsideCallback);
      if (props.withTab) {
        document.removeEventListener('keyup', tabOutsideCallback);
      }
    };
  }, [props.withTab, clickOutsideCallback, tabOutsideCallback]);

  return (
    <div className={classes.ClickOutsideWrapper} ref={clickOutsideWrapper}>
      {props.render({
        ...props.passThroughProps,
        clickedOutside,
        setClickedOutside,
      })}
    </div>
  );
}
