import { action } from 'typesafe-actions';
import { NotificationActionTypes, Notification } from './types';
import { AnyAction } from 'redux';

export const showNotification = (notification: Notification): AnyAction => {
  return action(NotificationActionTypes.SHOW_NOTIFICATION, notification);
};

export const showNotificationWithTimeout = (
  notification: Notification
): AnyAction => {
  return action(
    NotificationActionTypes.SHOW_NOTIFICATION_WITH_TIMEOUT,
    notification
  );
};

export const hideNotification = (id: string): AnyAction =>
  action(NotificationActionTypes.HIDE_NOTIFICATION, id);
