import { ChatRoutes, Country, Locale } from '../constants';
import { BusinessArea, LineOfBusiness, TenantMapping } from './types';

import europeiskeLogoSvg from '../assets/images/logos/europeiske-logo.svg';
import europeiskeHomeIcon from '../assets/images/homepage/europeiske.png';

import swedenMotorPartnerMapping from './swedenMotorPartnerMapping';
import finlandMapping from './finlandMapping';
import norwayMotorMapping from './norwayMotorMapping';
import testTenantMapping from './testTenantMapping';
import norwayPersonalInjuryMapping from './norwayPersonalInjuryMapping';

import swedenPropertyMapping from './swedenPropertyMapping';
import denmarkTravelMapping from './denmarkTravelMapping';

// tenantId should be in lowercase (case sensitive)
const linkToTenantMapping: TenantMapping[] = [
  {
    path: ChatRoutes.DenmarkMotor,
    pageConfig: {
      tenantId: 'cdbcc9a8-a83f-48c6-bc51-6b51415557f2',
      country: Country.DK,
      businessArea: 'Private',
      locale: Locale.DK,
    },
  },

  {
    path: ChatRoutes.DenmarkPersonalInjury,
    pageConfig: {
      tenantId: '7441f3a1-fdd5-4bf5-9dd4-e22cc102396d',
      country: Country.DK,
      businessArea: 'Private',
      locale: Locale.DK,
    },
  },

  {
    path: ChatRoutes.DenmarkProperty,
    pageConfig: {
      tenantId: '852b2de9-340b-4ce9-b665-aae67c7bff05',
      country: Country.DK,
      businessArea: 'Private',
      locale: Locale.DK,
      lineOfBusiness: 'Property',
    },
  },

  {
    path: ChatRoutes.DenmarkCargoAndLiability,
    pageConfig: {
      tenantId: 'c970b79c-3e2f-46c2-823e-0429b2403057',
      country: Country.DK,
      businessArea: 'Private',
      locale: Locale.DK,
      pageTitleTranslationKey: 'pageTitle',
    },
  },

  {
    path: ChatRoutes.DenmarkBusinessClaimsProperty,
    pageConfig: {
      tenantId: 'c970b79c-3e2f-46c2-823e-0429b2403057',
      country: Country.DK,
      businessArea: BusinessArea.COMMERCIAL,
      lineOfBusiness: LineOfBusiness.PROPERTY,
      locale: Locale.DK,
      pageTitleTranslationKey: 'pageTitle',
    },
  },

  {
    path: ChatRoutes.DenmarkBoat,
    pageConfig: {
      tenantId: '88956ac2-4bf4-4338-8565-2ad1ebbb04eb',
      pageTitleTranslationKey: 'pageTitle',
      locale: Locale.DK,
      country: Country.DK,
    },
  },

  {
    path: ChatRoutes.VikingDK,
    pageConfig: {
      tenantId: '7c1313e7-8aa7-4e3a-8d90-792e213c9b68',
      country: Country.DK,
      locale: Locale.DK,
      lineOfBusiness: 'Viking',
      businessArea: 'Private',
    },
  },

  {
    path: ChatRoutes.DenmarkAnimal,
    pageConfig: {
      tenantId: '59bbd1ce-fc19-4103-8450-a3ec795e96b7',
      locale: Locale.DK,
    },
  },

  {
    path: ChatRoutes.DenmarkCommercialProperty,
    pageConfig: {
      tenantId: '5c4ccd27-1d75-4aff-b6a1-aafd18254103',
      country: Country.DK,
      businessArea: 'Commercial',
      locale: Locale.DK,
      lineOfBusiness: 'Property',
    },
  },

  {
    path: ChatRoutes.NorwayPrivateMotor,
    pageConfig: {
      tenantId: 'f2188e6e-ef53-4c80-8a8f-1053a380ee5a',
      businessArea: 'Private',
      locale: Locale.NO,
    },
  },

  {
    path: ChatRoutes.NorwayAnimal,
    pageConfig: {
      tenantId: '8332ab9f-93f8-4341-bfda-11061b4fed0a',
      country: Country.NO,
      businessArea: 'Private',
      locale: Locale.NO,
    },
  },

  {
    path: ChatRoutes.SwedenAnimal,
    pageConfig: {
      tenantId: 'cb8311d9-b38b-4861-bed4-8fe61d2939e5',
      country: Country.SE,
      businessArea: 'Private',
      lineOfBusiness: 'Animal',
      locale: Locale.SV,
      pageTitleTranslationKey: 'pageTitle',
    },
  },

  {
    path: ChatRoutes.NorwayTravel,
    pageConfig: {
      tenantId: 'ed66dc43-71bd-46ec-9ea2-8415cbce6781',
      showPageTitle: false,
      country: Country.NO,
      locale: Locale.NO,
    },
  },

  {
    path: ChatRoutes.NorwayBoat,
    pageConfig: {
      tenantId: '0ed6d52f-f9bd-4faa-ab24-4f7778b709c2',
      country: Country.NO,
      businessArea: 'Private',
      lineOfBusiness: 'Boat',
      locale: Locale.NO,
    },
  },

  {
    path: ChatRoutes.NorwayProperty,
    pageConfig: {
      tenantId: '0a7107f3-e22e-446f-ba60-963531a5bf57',
      country: Country.NO,
      businessArea: 'Private',
      lineOfBusiness: 'Property',
      locale: Locale.NO,
    },
  },

  {
    path: ChatRoutes.NorwayCommercialProperty,
    pageConfig: {
      tenantId: '3cc28c61-842f-42eb-8b32-74002b535a9d',
      country: Country.NO,
      businessArea: 'Commercial',
      lineOfBusiness: 'Property',
      locale: Locale.NO,
    },
  },

  {
    path: ChatRoutes.SwedenMotor,
    pageConfig: {
      tenantId: '95347160-b4b6-4fcd-9fb5-8765a071791b',
      country: Country.SE,
      locale: Locale.SV,
      pageTitleTranslationKey: 'seMotorPageTitle',
      portfolio: 'if.se',
    },
  },

  {
    path: ChatRoutes.SwedenPersonalInjury,
    pageConfig: {
      tenantId: '5fd57f86-a70c-45bb-8c2f-f518160b701f',
      country: Country.SE,
      locale: Locale.SV,
      lineOfBusiness: 'PersonalInjury',
      businessArea: 'Private',
    },
  },

  {
    path: ChatRoutes.SwedenFollowUp,
    pageConfig: {
      tenantId: '47dfd1ae-aacf-4c01-9a8c-863fd0cb151d',
      country: Country.SE,
      locale: Locale.SV,
      pageTitleTranslationKey: 'seFollowUpPageTitle',
      lineOfBusiness: 'FollowUp',
      businessArea: 'Private',
    },
  },

  {
    path: ChatRoutes.SwedenCargoAndLiability,
    pageConfig: {
      tenantId: 'a3c35f3c-b534-47b3-b5e2-b7b59397c91e',
      country: Country.SE,
      locale: Locale.SV,
      businessArea: 'Private',
    },
  },

  {
    path: ChatRoutes.SwedenBusinessClaimsProperty,
    pageConfig: {
      tenantId: 'a3c35f3c-b534-47b3-b5e2-b7b59397c91e',
      country: Country.SE,
      locale: Locale.SV,
      businessArea: BusinessArea.COMMERCIAL,
      lineOfBusiness: LineOfBusiness.PROPERTY,
    },
  },

  {
    path: ChatRoutes.Viking,
    pageConfig: {
      tenantId: '20b241a3-a5c4-4a66-949c-53acba0ffc08',
      country: Country.SE,
      locale: Locale.SV,
      lineOfBusiness: 'Viking',
      businessArea: 'Private',
    },
  },

  {
    path: ChatRoutes.NorwayExpat,
    pageConfig: {
      tenantId: '977c0b48-d57d-431e-99f1-83f720222d01',
      country: Country.NO,
      businessArea: 'Private',
      lineOfBusiness: 'Expat',
      locale: Locale.EN,
      logoDesktop: europeiskeLogoSvg,
      logoMobile: europeiskeLogoSvg,
      homePageIcon: europeiskeHomeIcon,
    },
  },

  {
    path: ChatRoutes.NorwayCargoAndLiability,
    pageConfig: {
      tenantId: '00cffc0d-f2ea-48b5-84e2-df83678f865b',
      pageTitleTranslationKey: 'pageTitle',
      country: Country.NO,
      locale: Locale.NO,
    },
  },

  {
    path: ChatRoutes.NorwayBusinessClaimsProperty,
    pageConfig: {
      tenantId: '00cffc0d-f2ea-48b5-84e2-df83678f865b',
      pageTitleTranslationKey: 'pageTitle',
      country: Country.NO,
      locale: Locale.NO,
      businessArea: BusinessArea.COMMERCIAL,
      lineOfBusiness: LineOfBusiness.PROPERTY,
    },
  },

  {
    path: ChatRoutes.SwedenCommercialExpat,
    pageConfig: {
      tenantId: '25ef5c71-d817-429c-98d2-0fb6228ad211',
      country: Country.SE,
      locale: Locale.SV,
    },
  },

  {
    path: ChatRoutes.SwedenCommercialExpatEng,
    pageConfig: {
      tenantId: '25ef5c71-d817-429c-98d2-0fb6228ad211',
      country: Country.SE,
      locale: Locale.EN,
    },
  },

  {
    path: ChatRoutes.CargoAndLiabilityEng,
    pageConfig: {
      tenantId: '0245321e-9b82-4ea2-bd7c-a3d1e47d6be1',
      pageTitleTranslationKey: 'pageTitle',
      businessArea: 'Private',
      lineOfBusiness: LineOfBusiness.CARGO_AND_LIABILITY,
      country: Country.NORDIC,
      locale: Locale.EN,
    },
  },

  {
    path: ChatRoutes.BusinessClaimsPropertyEng,
    pageConfig: {
      tenantId: '0245321e-9b82-4ea2-bd7c-a3d1e47d6be1',
      pageTitleTranslationKey: 'pageTitle',
      businessArea: BusinessArea.COMMERCIAL,
      lineOfBusiness: LineOfBusiness.PROPERTY,
      country: Country.NORDIC,
      locale: Locale.EN,
    },
  },

  {
    path: ChatRoutes.NordicCare,
    pageConfig: {
      tenantId: 'fcf772ad-a68f-46df-9aa0-df0419e2cd6f',
      pageTitleTranslationKey: 'pageTitle',
      businessArea: BusinessArea.PRIVATE,
      lineOfBusiness: LineOfBusiness.NORDIC_CARE,
      country: Country.NORDIC,
      locale: Locale.EN,
    },
  },

  {
    path: ChatRoutes.NordicCommon,
    pageConfig: {
      tenantId: 'da59d870-de49-43e8-bf32-958440b1f2c5',
      pageTitleTranslationKey: 'nordicCommonPageTitle',
      locale: Locale.EN,
      country: Country.NORDIC,
      businessArea: BusinessArea.PRIVATE,
      lineOfBusiness: LineOfBusiness.COMMON,
    },
  },

  // Order matters - If there is modal with tenants,
  // First tenant there will be queried by order
  // and only then all the list will be applied.
  // TODO: This should be reworked to not include such logic
  ...denmarkTravelMapping,
  ...norwayMotorMapping,
  ...swedenMotorPartnerMapping,
  ...finlandMapping,
  ...norwayPersonalInjuryMapping,
  ...swedenPropertyMapping,

  ...testTenantMapping,
];

export default linkToTenantMapping;
