import React, { useState } from 'react';
import Icon from '@mdi/react';
import { mdiSend } from '@mdi/js';
import { BotPassThroughProps } from '../../../../containers/BotMessageBase/BotMessageBase';
import ClickOutsideTyped, {
  ClickOutsidePassThroughProps,
} from '../../../../containers/ClickOutside/ClickOutside';
import { sendMessageBack } from '../../../../utils/directLineUtils';
import { canBeEditedCustomUserResponse } from '../../../../utils';
import MessageLengthIndicator from './MessageLengthIndicator';
import TextInputBox, { TextInputBoxProps } from './TextInputBox';
import { findCustomActivity } from '../../../../middleware/activityMiddleware';
import { DEFAULT_INPUT_LENGTH } from '../../../../constants';
import {
  Card,
  CustomActivityConfig,
  MessageTypes,
  ValidationType,
} from '../../../../middleware/types';
import classes from './TextInput.module.scss';

import { translate, Translations } from '@/locale';

import RoundButton from '@/components/RoundButton/RoundButton';

interface InputTextConfig extends CustomActivityConfig {
  placeholder?: string;
  prefillText?: string;
}

interface TextInputProps {
  messageType: MessageTypes;
}

function sendValue(
  card: Card,
  inputValue: string,
  setInputValue: (value: string) => void,
  hideElement: () => void
): void {
  hideElement();
  sendMessageBack(
    inputValue,
    {
      canBeEdited: canBeEditedCustomUserResponse(card),
    },
    card.activity
  );
  setInputValue('');
}

export default function TextInput(
  props: TextInputProps & BotPassThroughProps
): JSX.Element {
  const inputTextConfig = findCustomActivity(
    props.card.activity
  ) as InputTextConfig;

  const [inputValue, setInputValue] = useState(
    (inputTextConfig && inputTextConfig.prefillText) || ''
  );

  const maxLengthRule =
    inputTextConfig &&
    inputTextConfig.propertyValidationRules &&
    inputTextConfig.propertyValidationRules.filter(
      (validationRule) =>
        validationRule.validationType === ValidationType.MaxLength
    )[0];
  const maxLength =
    (maxLengthRule && (maxLengthRule.expression as unknown as number)) ||
    DEFAULT_INPUT_LENGTH;

  const submitResponse = () => {
    sendValue(
      props.card,
      inputValue,
      setInputValue,
      props.hideChildrenOfElement
    );
  };

  const textInputBoxProps: TextInputBoxProps = {
    inputValue,
    setInputValue,
    placeholder: inputTextConfig?.placeholder,
    maxLength,
    submitResponse,
  };

  return (
    <div className={classes.InputContainer}>
      <div
        className={classes.TextInput}
        data-custom-activity="true"
        data-message-type={props.messageType}
      >
        {props.lastBotActivityId === props.card.activity.id ? (
          <ClickOutsideTyped
            withTab={true}
            initialState={true}
            passThroughProps={textInputBoxProps}
            render={(
              passThroughProps: TextInputBoxProps & ClickOutsidePassThroughProps
            ) => (
              <TextInputBox
                inputValue={passThroughProps.inputValue}
                setInputValue={passThroughProps.setInputValue}
                placeholder={passThroughProps.placeholder}
                maxLength={passThroughProps.maxLength}
                submitResponse={passThroughProps.submitResponse}
                clickedOutside={passThroughProps.clickedOutside}
                setClickedOutside={passThroughProps.setClickedOutside}
              />
            )}
          />
        ) : null}

        <RoundButton
          disabled={inputValue.length === 0}
          ariaLabel={translate(Translations.send)}
          onClick={() =>
            sendValue(
              props.card,
              inputValue,
              setInputValue,
              props.hideChildrenOfElement
            )
          }
        >
          <Icon path={mdiSend} size={1} className={classes.SubmitButtonIcon} />
        </RoundButton>
      </div>

      <MessageLengthIndicator
        inputLength={inputValue.length}
        maxLength={maxLength}
      />
    </div>
  );
}
