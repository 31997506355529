import { AnyAction, Dispatch } from 'redux';
import { AxiosError } from 'axios';

import { setAuthSessionHash, setUsername } from '@/store/bffAuthentication/actions';

import { appInsights } from '@/appInsights/appInsights';
import {
  postSilentAuthEvent,
  postAuthEvent as callPostAuthEvent,
} from '@/api/directLine/directLineMethods';
import resetConversation from '../resetConversation';
import { ResponseStatus } from '@/api/types';

export interface PostAuthEventArgs {
  dispatch: Dispatch<AnyAction>;
  conversationId: string;
  isSilentAuth?: boolean;
}

const postAuthEvent = async ({
  dispatch,
  conversationId,
  isSilentAuth = false,
}: PostAuthEventArgs): Promise<void> => {
  try {
    const { data: userInformation } = isSilentAuth
      ? await postSilentAuthEvent({ body: { conversationId } })
      : await callPostAuthEvent({ body: { conversationId } });

    if (userInformation?.isAuthenticated) {
      const userName = `${userInformation.givenName} ${userInformation.surname}`;
      dispatch(setUsername(userName));
      dispatch(setAuthSessionHash(userInformation.authSessionHash));
    }
  } catch (e) {
    const error = e as AxiosError;
    if (error.response?.status === ResponseStatus.GONE) {
      appInsights.trackTrace({
        message: 'Session expired, starting new conversation',
      });
    } else {
      appInsights.trackException({ exception: error });
    }

    resetConversation(dispatch);
    window.location.reload();
  }
};

export default postAuthEvent;
