export const isIos = (): boolean => {
  const iOS1 = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const iOS2 =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

  return iOS1 && iOS2;
};

export const isElementVisible = (element: HTMLDivElement): boolean => {
  return window.getComputedStyle(element).display !== 'none';
};
