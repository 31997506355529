import classNames from 'classnames';

import classes from './ListFromCarouselInput.module.scss';

import { AriaLive } from '../../../constants/aria';
import { RootState } from '../../../store/types';
import { CarouselChoiceItem } from './ListFromCarouselInput';

import { useSelector } from 'react-redux';
import { translate, Translations } from '../../../locale';

interface ListNodeItemProps {
  index: number;
  item: CarouselChoiceItem;
  columnCount: number;
  itemMarkedForDeletion: boolean;
  toggleMarkedForDeletionHandler: (itemDeleteKey: string) => void;
  removeButtonsVisible: boolean;
  removeButtonText: string;
  undoRemoveButtonText: string;
}

export default function ListNodeItem(props: ListNodeItemProps): JSX.Element {
  const { item, columnCount, itemMarkedForDeletion } = props;

  const isMobile = useSelector<RootState, boolean>(
    (state) => state.globalConfigState.isMobile
  );

  const addMainDisplayValueColumn = () => {
    return (
      <div
        role="gridcell"
        aria-colindex={1}
        className={classNames(
          classes.Column,
          classes.DisplayColumn,
          itemMarkedForDeletion ? classes.MarkedForDeletion : null
        )}
      >
        {item.displayValue}
      </div>
    );
  };

  const addRegularColumn = (column: string, colIndex: number) => {
    const columnText =
      column.length > 10 ? column : column.replace(' ', '\u00A0');

    return (
      <div
        role="gridcell"
        aria-colindex={colIndex + 2}
        key={colIndex}
        className={classNames(
          classes.Column,
          classes.DataColumn,
          itemMarkedForDeletion ? classes.MarkedForDeletion : null
        )}
      >
        {columnText}
      </div>
    );
  };

  const addDeleteButton = () => {
    return (
      <div
        aria-live={AriaLive.OFF}
        role="gridcell"
        tabIndex={-1}
        className={classNames(classes.RemoveColumn)}
        aria-colindex={columnCount + 1}
      >
        <button
          className={classNames(classes.RemoveButtonContent)}
          onClick={() => {
            props.toggleMarkedForDeletionHandler(item.deleteKey);
          }}
          data-send-button={true}
          aria-label={translate(
            itemMarkedForDeletion
              ? Translations.itemList.recoverDeletedRow
              : Translations.itemList.deleteCurrentRow
          )}
        >
          <div
            className={classNames(
              classes.ItemButtonIcon,
              itemMarkedForDeletion ? classes.Undo : classes.Remove
            )}
          />
          <span className={classNames(classes.RemoveItemButtonText)}>
            {itemMarkedForDeletion
              ? props.undoRemoveButtonText
              : props.removeButtonText}
          </span>
        </button>
      </div>
    );
  };

  return isMobile ? (
    <div role="row">
      <div className={classes.MobileColumn} role="none">
        {addMainDisplayValueColumn()}
        {item.displayColumns ? item.displayColumns.map(addRegularColumn) : null}
      </div>
      {props.removeButtonsVisible ? (
        <div className={classNames(classes.MobileColumn, classes.LastColumn)}>
          {addDeleteButton()}
        </div>
      ) : null}
    </div>
  ) : (
    <div role="row">
      {addMainDisplayValueColumn()}
      {item.displayColumns ? item.displayColumns.map(addRegularColumn) : null}

      {props.removeButtonsVisible ? addDeleteButton() : null}
    </div>
  );
}
