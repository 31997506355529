import { AppInsightsEventNames } from '@/constants';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import TelemetryMeasurementEvent, {
  TelemetryEventMeasurementEvent,
  TelemetryExceptionMeasurementEvent,
  TelemetryTimingEndMeasurementEvent,
  TelemetryTimingStartMeasurementEvent,
} from 'botframework-webchat-api/lib/types/TelemetryMeasurementEvent';

interface TelemetryEventDimensions {
  ['capability:downscaleImage:workerType']: string;
  ['capability:renderer']: string;
  ['prop:locale']: string;
  ['prop:speechRecognition']: string;
  ['prop:speechSynthesis']: string;
}

const handleWebchatTelemetry = (
  event: TelemetryMeasurementEvent,
  appInsights: ApplicationInsights
): void => {
  const { type } = event;

  switch (type) {
    case 'event': {
      const eventMeasurementEvent = event as TelemetryEventMeasurementEvent;
      const { data, level, name } = eventMeasurementEvent;

      //Typing is broken, nothing to do here
      const dimensions =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        eventMeasurementEvent.dimensions as TelemetryEventDimensions;

      const fullName = `${
        AppInsightsEventNames.WebchatUIPrefix
      }${JSON.stringify(name)}`;

      const appInsightsData =
        typeof data === 'number' || typeof data === 'string'
          ? { [name]: data }
          : Object.entries(data || {}).reduce(
              (entry, [key, value]) => ({
                ...entry,
                [`${JSON.stringify(name)}:${key}`]: value,
              }),
              {}
            );

      appInsights.trackEvent({
        name: fullName,
        properties: {
          ...dimensions,
          ...appInsightsData,
          'webchat:level': level,
        },
      });

      break;
    }
    case 'exception': {
      const eventMeasurementEvent = event as TelemetryExceptionMeasurementEvent;
      const { error } = eventMeasurementEvent;
      appInsights.trackException({ exception: error });
      break;
    }
    case 'timingstart': {
      const eventMeasurementEvent =
        event as TelemetryTimingStartMeasurementEvent;
      const { name } = eventMeasurementEvent;
      const fullName = `${
        AppInsightsEventNames.WebchatUIPrefix
      }${JSON.stringify(name)}`;

      appInsights.startTrackEvent(fullName);
      break;
    }
    case 'timingend': {
      const eventMeasurementEvent = event as TelemetryTimingEndMeasurementEvent;
      const { name } = eventMeasurementEvent;
      //Typing is broken, nothing to do here
      const dimensions =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        eventMeasurementEvent.dimensions as TelemetryEventDimensions;

      const fullName = `${
        AppInsightsEventNames.WebchatUIPrefix
      }${JSON.stringify(name)}`;

      appInsights.stopTrackEvent(fullName, { ...dimensions });
      break;
    }
  }
};

export default handleWebchatTelemetry;
