import { ReactElement } from 'react';
import { Dispatch, Middleware } from 'redux';
import { Message } from 'botframework-directlinejs';

import {
  CustomActivityConfig,
  ActivityEntity,
  CUSTOM_ACTIVITY,
  MessageTypes,
  ActivityRoles,
  CustomUserResponse,
  Card,
  ActivityTypes,
  CustomActivity,
} from './types';
import ActionButtons from '../containers/BotMessageBase/ActionButtons/ActionButtons';
import DateInput, {
  DateInputProps,
} from '../components/CustomActivities/Inputs/DateInput/DateInput';
import CustomInputPortal from '../containers/CustomInputPortal/CustomInputPortal';
import ClickOutsideTyped, {
  ClickOutsidePassThroughProps,
} from '../containers/ClickOutside/ClickOutside';
import { getCardActions, shouldHideActivity } from '../utils/cardHelper';
import postAuthEvent from '../utils/authentication/postAuthEvent';

import SummaryActivity from '../components/CustomActivities/SummaryActivity/SummaryActivity';
import ListFromCarouselInput from '../components/CustomActivities/ListFromCarousel/ListFromCarouselInput';
import RatingStars from '../components/CustomActivities/Inputs/RatingStars/RatingStars';
import RatingStarsResponse from '../components/CustomActivities/Inputs/RatingStars/RatingStarsResponse/RatingStarsResponse';
import InputClassifier from '../components/CustomActivities/Inputs/InputClassifier/InputClassifierComponent';
import FileUploadActivity from '../components/CustomActivities/Inputs/FileUploadActivity/FileUploadActivity';
import FileUploadUserResponse from '../components/CustomActivities/Inputs/FileUploadActivity/FileUploadUserResponse/FileUploadUserResponse';
import CurrencyInputComponent from '../components/CustomActivities/Inputs/CurrencyInput/CurrencyInputComponent';
import TimeInput from '../components/CustomActivities/Inputs/TimeInput/TimeInput';
import TextInput from '../components/CustomActivities/Inputs/TextInput/TextInput';
import MultipleChoice from '../components/CustomActivities/MultipleChoice/MultipleChoice';
import BotMessageBase, {
  BotPassThroughProps,
} from '../containers/BotMessageBase/BotMessageBase';
import { configuredStore } from '@/store';
import BffAuthenticationActivity from '../components/CustomActivities/BffAuthentication/BffAuthenticationActivity';
import {
  addHandledAuthenticationActivityId,
  addHandledSilentAuthActivityId,
} from '../store/bffAuthentication/actions';
import LongListActivity from '@/components/CustomActivities/LongList/LongListActivity';
import UserActivity from '@/components/UserActivity/UserActivity';
import TextResponse from '@/containers/TextResponse/TextResponse';
import PhoneNumberInput from '@/components/CustomActivities/Inputs/PhoneNumberInput/PhoneNumberInput';

export const activityMiddleware: Middleware =
  () =>
  (
    next: Dispatch<Card>
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any*/
  ): Dispatch<Card> =>
  (card: Card): any => {
    if (shouldHideActivity(card)) {
      return false;
    }

    switch (card.activity.from.role) {
      case ActivityRoles.Bot: {
        if (card.activity.type === ActivityTypes.Typing) {
          return false;
        }

        if (card.activity.type === ActivityTypes.StatisticsEvent) {
          return false;
        }
        if (card.activity.type === ActivityTypes.CustomTyping) {
          return false;
        }
        if (card.activity.type === ActivityTypes.Event) {
          return next(card);
        }

        return handleCustomBotActivity(card);
      }

      case ActivityRoles.User: {
        if (card.activity.type === ActivityTypes.Retry) {
          return false;
        }

        return handleCustomUserActivity(next, card);
      }
    }
    return next(card);
  };

const handleCustomBotActivity = (card: Card): (() => ReactElement) | false => {
  const activity = card.activity;
  const customActivity = findCustomActivity(activity);
  const actions = getCardActions(card);
  const hasActionButtons = actions && actions.length > 0;

  if (customActivity) {
    switch (customActivity.messageType) {
      case MessageTypes.inputText:
      case MessageTypes.inputTextArea:
      case MessageTypes.inputSsn:
      case MessageTypes.inputDateTime:
      case MessageTypes.finlandInputBankAccount:
      case MessageTypes.ssnValidation:
      case MessageTypes.WeakAuthentication: {
        return () => (
          <BotMessageBase
            card={card}
            alwaysRoundedCorners={!hasActionButtons}
            render={(botProps: BotPassThroughProps) => (
              <ActionButtons {...botProps} actions={actions}>
                <CustomInputPortal>
                  <TextInput
                    {...botProps}
                    messageType={customActivity.messageType}
                  />
                </CustomInputPortal>
              </ActionButtons>
            )}
          />
        );
      }

      case MessageTypes.inputDate: {
        return () => (
          <BotMessageBase
            card={card}
            childName={DateInput.name}
            alwaysRoundedCorners={true}
            render={(botProps: BotPassThroughProps) => (
              <CustomInputPortal>
                <ClickOutsideTyped
                  passThroughProps={botProps}
                  render={(dateInputProps: DateInputProps) => (
                    <DateInput {...dateInputProps} />
                  )}
                />
              </CustomInputPortal>
            )}
          />
        );
      }

      case MessageTypes.inputTime:
        return () => (
          <BotMessageBase
            card={card}
            alwaysRoundedCorners={true}
            render={(botProps: BotPassThroughProps) => (
              <CustomInputPortal>
                <TimeInput {...botProps} />
              </CustomInputPortal>
            )}
          />
        );

      case MessageTypes.inputCurrency:
        return () => (
          <BotMessageBase
            card={card}
            alwaysRoundedCorners={true}
            render={(botPassThroughProps: BotPassThroughProps) => {
              return (
                <CustomInputPortal>
                  <CurrencyInputComponent {...botPassThroughProps} />
                </CustomInputPortal>
              );
            }}
          />
        );

      case MessageTypes.inputPhone:
        return () => (
          <BotMessageBase
            card={card}
            alwaysRoundedCorners={true}
            render={(botPassThroughProps: BotPassThroughProps) => {
              return (
                <CustomInputPortal horizontalPosition="end">
                  <PhoneNumberInput
                    {...botPassThroughProps}
                    preferredCountries={['DK', 'FI', 'NO', 'SE']}
                  />
                </CustomInputPortal>
              );
            }}
          />
        );

      case MessageTypes.listFromCarousel: {
        return () => (
          <BotMessageBase
            card={card}
            fullWidth={true}
            persistChildrenAfterUserResponse={true}
            render={(botProps: BotPassThroughProps) => (
              <ListFromCarouselInput {...botProps} />
            )}
          />
        );
      }

      case MessageTypes.ratingStars: {
        return () => (
          <BotMessageBase
            card={card}
            render={(botPassThroughProps: BotPassThroughProps) => {
              return (
                <ActionButtons {...botPassThroughProps} actions={actions}>
                  <CustomInputPortal>
                    <RatingStars {...botPassThroughProps} />
                  </CustomInputPortal>
                </ActionButtons>
              );
            }}
          />
        );
      }

      case MessageTypes.multipleChoice: {
        return () => (
          <BotMessageBase
            card={card}
            childName={MultipleChoice.name}
            render={(botProps: BotPassThroughProps) => (
              <MultipleChoice {...botProps} />
            )}
          />
        );
      }

      case MessageTypes.longList: {
        return () => (
          <BotMessageBase
            card={card}
            childName={LongListActivity.name}
            render={(botProps: BotPassThroughProps) => (
              <LongListActivity {...botProps} />
            )}
          />
        );
      }

      case MessageTypes.swedenCountryClassifier:
      case MessageTypes.finlandMotorCountryClassifier:
      case MessageTypes.finlandTravelCountryClassifier:
      case MessageTypes.finlandPostalCodeClassifier:
      case MessageTypes.finlandCityClassifier:
      case MessageTypes.finlandSportCodeClassifier:
      case MessageTypes.finlandDiagnosisCodeClassifier:
      case MessageTypes.finlandMedicineCodeClassifier:
      case MessageTypes.finlandCatBreedCodeClassifier:
      case MessageTypes.finlandDogBreedCodeClassifier:
      case MessageTypes.finlandAnimalDiagnosis:
      case MessageTypes.finlandBodyPartCodeClassifier:
      case MessageTypes.finlandTradeUnionCodeClassifier:
      case MessageTypes.norwayTravelCountryClassifier:
      case MessageTypes.norwayExpatriatesCountryClassifier:
      case MessageTypes.selectCountryClassifier:
      case MessageTypes.testCountry: {
        return () => (
          <BotMessageBase
            card={card}
            alwaysRoundedCorners={true}
            render={(botPassThroughProps: BotPassThroughProps) => {
              return (
                <ActionButtons {...botPassThroughProps} actions={actions}>
                  <CustomInputPortal>
                    <ClickOutsideTyped
                      passThroughProps={botPassThroughProps}
                      withTab={true}
                      initialState={false}
                      render={(
                        inputClassifierProps: BotPassThroughProps &
                          ClickOutsidePassThroughProps
                      ) => <InputClassifier {...inputClassifierProps} />}
                    />
                  </CustomInputPortal>
                </ActionButtons>
              );
            }}
          />
        );
      }

      case MessageTypes.norwaySummary:
      case MessageTypes.swedenSummary:
      case MessageTypes.denmarkSummary:
      case MessageTypes.finlandSummary:
      case MessageTypes.summary:
        return () => (
          <BotMessageBase
            persistChildrenAfterUserResponse
            card={card}
            render={(props: BotPassThroughProps) => {
              return <SummaryActivity {...props} />;
            }}
          />
        );

      case MessageTypes.EmployeeAuthentication:
      case MessageTypes.norwayAuthentication:
      case MessageTypes.denmarkAuthentication:
      case MessageTypes.finlandAuthentication:
      case MessageTypes.swedenAuthentication: {
        const { store } = configuredStore;
        const { getState, dispatch } = store;
        const { id: activityId } = activity;
        const {
          displayedAuthenticationActivityIds,
          handledAuthenticationActivityIds,
        } = getState().bffAuthenticationState;
        const { conversationId } = getState().botConfigState;

        if (!activityId) {
          return false;
        }

        // Show Action buttons if user has not clicked on any of them
        // Clicking on any action button will assume user tried to authenticate and will not show action buttons for this activity in future
        if (!displayedAuthenticationActivityIds.includes(activityId)) {
          return () => (
            <BotMessageBase
              card={card}
              render={(passThroughProps: BotPassThroughProps) => (
                <BffAuthenticationActivity {...passThroughProps} />
              )}
            />
          );
        }

        // After user has clicked "Log in" button
        // user is redirected to identity provider - leaves the chat
        // When returning back to chat ALL activities will be re-iterated once again, so when the authentication activity appears
        // we now must post an event to bot service with results of authorization (BUT ONLY ONCE)
        // NOTE : Page refreshes also trigger activity re-iteration
        if (!handledAuthenticationActivityIds.includes(activityId)) {
          dispatch(addHandledAuthenticationActivityId(activityId));
          conversationId && postAuthEvent({ dispatch, conversationId });
        }

        return false;
      }

      case MessageTypes.norwaySilentAuth:
      case MessageTypes.denmarkSilentAuth:
      case MessageTypes.finlandSilentAuth:
      case MessageTypes.swedenSilentAuth:
      case MessageTypes.silentAuthentication:
      case MessageTypes.silentEmployeeAuthentication: {
        const { store } = configuredStore;
        const { id: activityId } = activity;
        const { getState, dispatch } = store;
        const {
          handledSilentAuthenticationActivityIds: handledSilentAuthActivityIds,
        } = getState().bffAuthenticationState;
        const { conversationId } = getState().botConfigState;

        if (
          activityId &&
          !handledSilentAuthActivityIds.some(
            (handledActivityId) => handledActivityId === activityId
          )
        ) {
          dispatch(addHandledSilentAuthActivityId(activityId));
          conversationId &&
            postAuthEvent({
              dispatch,
              conversationId,
              isSilentAuth: true,
            });
        }

        return false;
      }

      case MessageTypes.inputAttachment:
      case MessageTypes.fileUpload: {
        return () => (
          <BotMessageBase
            card={card}
            render={(botPassThroughProps: BotPassThroughProps) => {
              return (
                <ActionButtons {...botPassThroughProps} actions={actions}>
                  <CustomInputPortal>
                    <FileUploadActivity {...botPassThroughProps} />
                  </CustomInputPortal>
                </ActionButtons>
              );
            }}
          />
        );
      }
    }
  }

  if (actions) {
    return () => (
      <BotMessageBase
        card={card}
        render={(botProps: BotPassThroughProps) => (
          <ActionButtons focusFirstAction actions={actions} {...botProps} />
        )}
      />
    );
  }

  return () => <BotMessageBase card={card} alwaysRoundedCorners={true} />;
};

const handleCustomUserActivity = (next: Function, card: Card): Function => {
  if (card.activity.type === ActivityTypes.Event) {
    return next(card);
  }

  return () => {
    const activity = card.activity as Message;
    const response = activity.value as CustomUserResponse;
    const text = (card.activity as Message).text;

    if (response && response.customEntity) {
      switch (response.customEntity.messageType) {
        case MessageTypes.inputAttachment:
        case MessageTypes.fileUpload: {
          return (
            <UserActivity card={card}>
              <FileUploadUserResponse card={card}></FileUploadUserResponse>
            </UserActivity>
          );
        }
        case MessageTypes.ratingStars: {
          return (
            <UserActivity card={card}>
              <RatingStarsResponse card={card}></RatingStarsResponse>
            </UserActivity>
          );
        }
      }
    }

    return (
      text && (
        <UserActivity card={card}>
          <TextResponse text={text}></TextResponse>
        </UserActivity>
      )
    );
  };
};
export const findCustomActivity = (
  activity: CustomActivity
): CustomActivityConfig | undefined => {
  if (!activity) {
    return;
  }

  const entities: ActivityEntity[] | undefined = (activity as Message).entities;

  return entities
    ? entities.find((entity: any) => entity && entity.type === CUSTOM_ACTIVITY)
    : undefined;
};
