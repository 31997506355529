import React, { useState, useEffect } from 'react';
import classes from './Console.module.scss';
import { sendMessage } from '../../../utils/directLineUtils';
import { KeyCodes } from '../../../constants';
import { translate, Translations } from '../../../locale';
import ClickOutside, {
  ClickOutsidePassThroughProps,
} from '../../../containers/ClickOutside/ClickOutside';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { setConsoleVisibility } from '../../../store/bot-config/actions';
import focusOnFocusSink from '../../../utils/focusOnFocusSink';

interface ConsoleProps extends ClickOutsidePassThroughProps {
  setVisible: (state: boolean) => void;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
}

const inputHistory: string[] = [];

function sendValue(props: ConsoleProps, directValue?: string) {
  const { inputValue, setInputValue, setVisible } = props;
  const value = directValue || inputValue;
  const historyValueIndex = inputHistory.findIndex((item) => item === value);

  setVisible(false);
  sendMessage(value);

  if (historyValueIndex > -1) {
    inputHistory.splice(historyValueIndex, 1);
  }

  inputHistory.unshift(value);
  setInputValue('');
}

function ConsoleInternal(props: ConsoleProps) {
  const { inputValue, setInputValue, clickedOutside, setVisible } = props;

  useEffect(() => {
    if (clickedOutside) {
      setVisible(false);
    }
  }, [clickedOutside, setVisible]);

  const filteredList = !inputValue
    ? inputHistory
    : inputHistory.filter((item) => item.startsWith(inputValue));

  return (
    <div className={classes.Console} data-console="true">
      <div
        className={classNames(classes.ConsoleInputContainer, classes.Focused)}
      >
        <input
          className={classes.ConsoleInput}
          autoFocus={true}
          placeholder={translate(Translations.inputBoxPlaceholder)}
          aria-placeholder={translate(Translations.inputBoxPlaceholder)}
          value={inputValue}
          onChange={(event) => setInputValue(event.target.value)}
          onKeyDown={(event) => {
            if (event.key === KeyCodes.ArrowUp && filteredList.length > 0) {
              setInputValue(filteredList[0]);
              event.currentTarget.focus();
            }
          }}
          onKeyPress={(event: React.KeyboardEvent) => {
            if (event.key !== KeyCodes.Enter) {
              return;
            }

            event.preventDefault();
            event.stopPropagation();

            if (!inputValue) return;

            focusOnFocusSink();

            setTimeout(() => {
              sendValue(props);
            }, 200);
          }}
        />
      </div>

      {filteredList.length > 0 ? (
        <div className={classes.HistoryList}>
          {filteredList.map((item, index) => {
            return (
              <div
                className={classes.HistoryItem}
                key={index}
                onClick={() => {
                  sendValue(props, item);
                }}
                tabIndex={0}
                onKeyPress={(event: React.KeyboardEvent) => {
                  if (event.key !== KeyCodes.Enter) {
                    return;
                  }

                  sendValue(props, item);
                }}
              >
                {item}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

export default function Console(): JSX.Element {
  const [inputValue, setInputValue] = useState<string>('');
  const dispatch = useDispatch();
  const setVisible = (state: boolean) => dispatch(setConsoleVisibility(state));

  return (
    <ClickOutside
      initialState={false}
      passThroughProps={null}
      render={(passThroughProps: ClickOutsidePassThroughProps) => {
        return (
          <ConsoleInternal
            inputValue={inputValue}
            setInputValue={setInputValue}
            setVisible={setVisible}
            {...passThroughProps}
          />
        );
      }}
    />
  );
}
