import { FunctionComponent, useState, useRef, useEffect } from 'react';

import classes from './ButtonGroup.module.scss';
import { CustomActivityAction } from '@/middleware/types';

import StyledActionButton from './StyledActionButton';
import LinearProgressBar from '../UI/LinearProgressBar/LinearProgressBar';

export interface ButtonGroupProps {
  actions: CustomActivityAction[];
  showLoader?: boolean;
  disableOnClick?: boolean;
  onClick: (action: CustomActivityAction) => void;
  focusFirstButton?: boolean;
  disabledButtonsIndices?: number[];
}

const ButtonGroup: FunctionComponent<ButtonGroupProps> = ({
  actions,
  showLoader,
  onClick,
  disableOnClick,
  focusFirstButton,
  disabledButtonsIndices,
}: ButtonGroupProps) => {
  const firstActionRef = useRef<HTMLButtonElement | null>(null);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (focusFirstButton) {
      firstActionRef?.current?.focus();
    }
  }, [focusFirstButton, firstActionRef.current]);

  return (
    <div className={classes.ButtonGroup}>
      {showLoader && (
        <div className={classes.Loader}>
          <LinearProgressBar />
        </div>
      )}
      {actions.map((action, index) => {
        return (
          <StyledActionButton
            key={index}
            onClick={() => {
              if (disableOnClick) {
                setDisabled(true);
              }
              onClick(action);
            }}
            className={classes.Button}
            disabled={disabled || disabledButtonsIndices?.includes(index)}
            ref={index === 0 ? firstActionRef : undefined}
          >
            {action.title}
          </StyledActionButton>
        );
      })}
    </div>
  );
};

export default ButtonGroup;
