import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';

import classes from './SignOut.module.scss';

import { RootState } from '../../../store/types';
import { PageConfig } from '../../../store/page-config/types';
import { Theme } from '../../../styles/types';

import { translate, Translations } from '../../../locale';
import { overrideTheme } from '../../../store/theme/actions';
import { setOrReplaceFavoritesIcon } from '../../../utils/utils';
import { setCssVariables } from '../../../styles';

import Header from '../../Navigation/Header/Header';

import ButtonPrimary from '@/components/Buttons/ButtonPrimary/ButtonPrimary';

export const SignOutCallback = (): JSX.Element => {
  const pageConfig = useSelector<RootState, PageConfig | undefined>(
    (state) => state.pageConfigState.config
  );

  const theme = useSelector<RootState, Theme | undefined>(
    (state) => state.themeState.theme
  );

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { search } = useLocation();

  const query = qs.parse(search);
  const redirectUri = decodeURIComponent(query.redirectUri as string);

  const bffAuthLogoutSection = () => {
    return (
      <>
        {redirectUri && (
          <div>
            <div className={classes.FlexContainer}>
              <p className={classes.SubHeading}>
                {translate(Translations.signOut.subHeading1)}
              </p>
              <p className={classes.SubHeading}>
                {translate(Translations.signOut.subHeading2)}
              </p>
            </div>
            <div className={classes.FlexContainer}>
              <div className={classes.Button}>
                <ButtonPrimary
                  onClick={() => {
                    navigate(redirectUri);
                  }}
                >
                  {translate(Translations.signOut.buttonText)}
                </ButtonPrimary>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (pageConfig?.customTheme && theme) {
      dispatch(overrideTheme(pageConfig.customTheme));
      const { customTheme } = pageConfig;
      const customColors = { ...customTheme.colors };
      const mergedTheme = customTheme
        ? ({
            ...theme,
            colors: customColors,
            fonts: customTheme.fonts,
          } as Theme)
        : theme;
      setCssVariables(mergedTheme);
      setOrReplaceFavoritesIcon(pageConfig.icon);
    }
  }, [theme, pageConfig]);

  return (
    <div>
      {pageConfig && (
        <Header
          showTitle={false}
          logo
          menu
          invertedColors={pageConfig.headerColorsInverted}
        />
      )}

      <div className={classes.Container}>
        <div className={classes.FlexContainer}>
          <h3>{translate(Translations.signOut.heading)}</h3>
        </div>

        {bffAuthLogoutSection()}
      </div>
    </div>
  );
};

export default SignOutCallback;
