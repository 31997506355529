import classes from './Menu.module.scss';
import classNames from 'classnames';

import { KeyCodes } from '@/constants';
import { mdiChevronDown, mdiChevronUp, mdiMenu } from '@mdi/js';
import Icon from '@mdi/react';
import { translate, Translations } from '@/locale';

import { MenuButtonProps } from './types';

const MenuButton: React.FC<MenuButtonProps> = ({
  showDropdownMenu,
  menuId,
  invertedColors,
  toggleDropDownMenu,
  buttonRef,
}) => (
  <div className={classes.MenuHeader}>
    <div
      ref={buttonRef}
      role="button"
      className={classNames({
        [classes.MenuIconClose]: showDropdownMenu,
        [classes.Inverted]: showDropdownMenu && invertedColors,
      })}
      onClick={toggleDropDownMenu}
      onKeyDown={(event: React.KeyboardEvent) => {
        if (event.key === KeyCodes.Enter || event.key === KeyCodes.Spacebar) {
          event.preventDefault();
          toggleDropDownMenu();
        }
      }}
      tabIndex={0}
      aria-expanded={showDropdownMenu}
      aria-haspopup="true"
      aria-controls={menuId}
    >
      <div className={classNames(classes.MenuDropdownButton)}>
        <div className={classes.DropdownTitle}>
          {translate(Translations.menu.title)}
        </div>
        <Icon
          path={showDropdownMenu ? mdiChevronUp : mdiChevronDown}
          size={1}
          className={classNames(
            classes.DropdownArrow,
            invertedColors ? classes.Inverted : null
          )}
        />
      </div>
      <Icon
        path={mdiMenu}
        size={1}
        className={classNames(
          classes.HamburgerMenuIcon,
          showDropdownMenu ? classes.MenuIconHidden : classes.MenuIcon,
          invertedColors ? classes.Inverted : null
        )}
      />
    </div>
  </div>
);

export default MenuButton;
