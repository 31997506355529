import { Component } from 'react';
import { Property } from 'csstype';
import classes from './Header.module.scss';
import Logo from '../../UI/Logo/Logo';
import Menu from '../Menu/Menu';
import ClickOutside, {
  ClickOutsidePassThroughProps,
} from '../../../containers/ClickOutside/ClickOutside';
import dimensions from '../../../styles/dimensions.module.scss';
import { Dimensions } from '../../../styles/types';

const { headerZIndex } = dimensions as unknown as Dimensions;

interface HeaderProps {
  title?: string;
  showTitle?: boolean;
  logo?: boolean;
  menu?: boolean;
  invertedColors?: boolean;
}

interface HeaderState {
  zindex: Property.ZIndex;
}

class Header extends Component<HeaderProps, HeaderState> {
  state: HeaderState = {
    zindex: headerZIndex,
  };

  showOnTop = (state: boolean): void => {
    const zindex = state ? headerZIndex : 'auto';

    this.setState({ zindex });
  };

  render(): JSX.Element {
    return (
      <header
        data-testid={classes.Header}
        className={classes.Header}
        style={{ zIndex: this.state.zindex }}
      >
        {this.props.logo && <Logo />}

        {this.props.title && this.props.showTitle === true && (
          <h1 className={classes.Title}>{this.props.title}</h1>
        )}

        <div className={classes.MenuWrapper}>
          {this.props.menu && (
            <ClickOutside
              withTab={true}
              passThroughProps={null}
              render={(passThroughProps: ClickOutsidePassThroughProps) => {
                return (
                  <Menu
                    invertedColors={this.props.invertedColors}
                    setHeaderOnTop={this.showOnTop}
                    {...passThroughProps}
                  />
                );
              }}
            />
          )}
        </div>
      </header>
    );
  }
}

export default Header;
