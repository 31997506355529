import { Dispatch, Middleware } from 'redux';

import { ActivityClientState, ActivityStatusCard } from './types';
import Sending from '../containers/ActivityStatus/Sending';
import FailedToSend from '../containers/ActivityStatus/FailedToSend';

/* eslint-disable  @typescript-eslint/no-explicit-any*/
export const activityStatusMiddleware: Middleware = () => (
  next: Dispatch<ActivityStatusCard>
): Dispatch<ActivityStatusCard> => (
  activityStatusCard: ActivityStatusCard
): any => {
  /* eslint-enable  @typescript-eslint/no-explicit-any*/
  const { sendState } = activityStatusCard;

  if (sendState === ActivityClientState.SENDING) {
    return <Sending />;
  }

  if (sendState === ActivityClientState.SEND_FAILED) {
    return <FailedToSend activityStatusCard={activityStatusCard} />;
  }

  return next(activityStatusCard);
};
