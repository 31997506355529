import styled from 'styled-components';

import { getGlobalCssVariable } from '@/utils';
import { CssGlobalVariables } from '@/styles/types';

import { ButtonPrimary } from '@ids-react/button';

const StyledActionButton = styled(ButtonPrimary)`
  &&&& {
    max-width: 100%;
    font-size: 1rem;
    padding: 0.7rem;
    color: ${() => getGlobalCssVariable(CssGlobalVariables.COLOR_ACTION)};
    font-family: ${() => getGlobalCssVariable(CssGlobalVariables.FONT_STACK)} !important;
    background-color: ${() =>
      getGlobalCssVariable(CssGlobalVariables.COLOR_BOT_ACTION_BACKGROUND)} !important;
    margin: 0;
    justify-content: left;

    border: 0.0625rem solid
      ${() => getGlobalCssVariable(CssGlobalVariables.COLOR_BORDER_LIGHT)};

    text-align: left;

    &:last-child {
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }

    //not the best solution, but is required to override If design system hover state
    &:hover {
      box-shadow: ${() =>
          getGlobalCssVariable(
            CssGlobalVariables.COLOR_BOT_ACTION_BACKGROUND_HOVER
          )}
        0px 0px 0px 100rem inset;
    }

    &:active {
      color: ${() => getGlobalCssVariable(CssGlobalVariables.COLOR_ACTION)};
    }

    &:active:hover {
      border-color: inherit;
    }

    &:focus-visible {
      z-index: 100;
      outline-color: ${() =>
        getGlobalCssVariable(CssGlobalVariables.COLOR_FOCUS_OUTLINE)};
    }

    &:disabled {
      border: 0.0625rem solid
        ${() => getGlobalCssVariable(CssGlobalVariables.COLOR_BORDER_LIGHT)} !important;
      background-color: ${() =>
        getGlobalCssVariable(
          CssGlobalVariables.COLOR_BOT_ACTION_BACKGROUND
        )} !important;
    }
  }
`;

export default StyledActionButton;
