import { useDropzone } from 'react-dropzone';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import classNames from 'classnames';

import { FileUploadDropZoneProps } from '../types';
import classes from './FileUploadDropZone.module.scss';
import { DataCustomActivity } from '../../../../../constants/testEnums';
import { useInitialFocusCallback } from '../../../../../utils/customHooks';

export const FILE_UPLOAD_RESULT_LIST_ID = 'file-upload-result-list';
export const FILE_UPLOAD_INPUT_ID = 'file-upload-input';

const FileUploadDropZone = (props: FileUploadDropZoneProps): JSX.Element => {
  const {
    dropZoneContainerText,
    browseFilesButtonText,
    handleOnAccepted,
    handleOnRejected,
    dropZoneClassName,
    buttonClassName,
    uploadInProgress,
    allowedFileExtensions,
    maxFileSizeInBytes,
  } = props;

  const { isDragActive, getRootProps, getInputProps, open } = useDropzone({
    onDropRejected: handleOnRejected,
    onDropAccepted: handleOnAccepted,
    accept: allowedFileExtensions,
    maxSize: maxFileSizeInBytes,
    minSize: 1,
    disabled: uploadInProgress,
  });

  const [, setRef] = useInitialFocusCallback();

  return (
    <div
      className={classNames(
        classes.FileUploadDropZone,
        dropZoneClassName ? dropZoneClassName : null
      )}
      data-testid={classes.FileUploadDropZone}
      data-custom-activity={DataCustomActivity.UPLOAD_DROPZONE}
    >
      <div
        className={classNames(
          classes.DropZoneArea,
          !uploadInProgress && isDragActive ? classes.DragActive : null,
          uploadInProgress ? classes.Disabled : null
        )}
        ref={setRef}
        {...getRootProps()}
        aria-controls={FILE_UPLOAD_INPUT_ID}
        aria-disabled={uploadInProgress}
      >
        <input
          {...getInputProps()}
          aria-hidden="true"
          aria-describedby={FILE_UPLOAD_RESULT_LIST_ID}
          id={FILE_UPLOAD_INPUT_ID}
          tabIndex={-1}
        />

        <div className={classes.DropZoneIcon} />
        <span className={classes.DropZoneText}>{dropZoneContainerText}</span>
      </div>

      <div className={classNames(classes.DropZoneButtonContainer)}>
        <button
          className={classNames(
            classes.DropZoneButton,
            buttonClassName ? buttonClassName : null,
            uploadInProgress ? classes.Disabled : null
          )}
          onClick={open}
          disabled={uploadInProgress}
          aria-controls={FILE_UPLOAD_INPUT_ID}
        >
          <Icon path={mdiPlus} />
          {browseFilesButtonText}
        </button>
      </div>
    </div>
  );
};

export default FileUploadDropZone;
