import { CustomActivity } from '../../middleware/types';

export interface RecoveryState {
  retryRejectedActivitiesLimit: number;
  lastUserRespondedActivity: string;
  receivedClientActivityIds: string[];
  currentFailedActivity: CustomActivity | undefined;
  previousActivity: CustomActivity | undefined;
}

export enum RecoveryActionTypes {
  SET_RETRY_REJECTED_ACTIVITIES_LIMIT = 'RECOVERY/SET_RETRY_REJECTED_ACTIVITIES_LIMIT',
  SET_LAST_USER_RESPONDED_ACTIVITY = 'RECOVERY/SET_LAST_USER_RESPONDED_ACTIVITY',
  SET_CURRENT_FAILED_ACTIVITY = 'RECOVERY/SET_CURRENT_FAILED_ACTIVITY',
  SET_RECEIVED_CLIENT_ACTIVITY_IDS = 'RECOVERY/SET_RECEIVED_CLIENT_ACTIVITY_IDS',
  RESET_STATE = 'RECOVERY/RESET_STATE',
  SET_PREVIOUS_ACTIVITY = 'RECOVERY/SET_PREVIOUS_ACTIVITY',
}
