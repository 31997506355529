import { useEffect, useState } from 'react';
import classes from './ListFromCarouselInput.module.scss';
import { isNumber } from '@microsoft/applicationinsights-core-js';

export interface ListFromCarouselTotalSumProps {
  summableItems: number[];
  showTotalSum: boolean;
  totalSumLabel: string;
  totalSumUnits: string;
}

/**
 * @param props {ListFromCarouselTotalSumProps}
 */
export default function ListFromCarouselTotalSum(
  props: ListFromCarouselTotalSumProps
): JSX.Element {
  const { summableItems, showTotalSum, totalSumLabel, totalSumUnits } = props;

  const [totalSum, setTotalSum] = useState(0);

  const countDecimals = (x: number): number => {
    if (Math.floor(x) === x) return 0;
    return x.toString().split('.')[1].length || 0;
  };

  // get max actual number of decimal digits provided in
  // the data. It's needed to avoid "flickering" of values.
  // For example, 5.45 + 4.55 should result in 10.00, not 10
  const maxDecimals = summableItems
    .filter((item) => isNumber(item))
    .map((item) => countDecimals(item))
    .reduce((maxVal, currentVal) => Math.max(maxVal, currentVal), 0);

  // this fixes javascript rounding error, to avoid
  // 5.45 + 4.55 resulting in 10.0000000000001
  const safeSum = (a: number, b: number, maxDecimals: number): number => {
    const factor = Math.pow(10, maxDecimals);
    return Math.round(a * factor + b * factor) / factor;
  };

  const recalculateTotalSum = (): number => {
    return summableItems.reduce(
      (sum, item) => safeSum(sum, isNumber(item) ? item : 0, maxDecimals),
      0
    );
  };

  useEffect(() => {
    setTotalSum(recalculateTotalSum());
  }, [summableItems]);

  return (
    <>
      {showTotalSum ? (
        <div className={classes.TotalSum}>
          <span className={classes.TotalSumLabel}>{totalSumLabel}:</span>
          <span className={classes.TotalSumValue}>
            {totalSum ? totalSum.toFixed(maxDecimals) : '0'}
          </span>
          <span className={classes.TotalSumUnits}>{totalSumUnits}</span>
        </div>
      ) : null}
    </>
  );
}
