import { createRef, FunctionComponent, useEffect, useState } from 'react';

import classes from './CategoryList.module.scss';

import { Category, ListSelectionStyle, Record } from '../types';

import useWindowSize from '@/utils/customHooks';

import CategoryComponent from '../Category/Category';
import ValidationResult from '../ValidationResult/ValidationResult';

export interface CategoryListProps {
  category: Category;
  selectionStyle: ListSelectionStyle;
  selectedRecords: Record[];
  validationMessage: string | null;
  onRecordSingleChoiceClick: (item: Record) => void;
  onRecordMultipleChoiceClick: (item: Record) => void;
}

const CategoryList: FunctionComponent<CategoryListProps> = ({
  category,
  selectionStyle,
  selectedRecords,
  validationMessage,
  onRecordSingleChoiceClick,
  onRecordMultipleChoiceClick,
}: CategoryListProps) => {
  const { width, height } = useWindowSize();
  const [validationResultClientHeight, setValidationResultClientHeight] =
    useState(0);

  const validationResultElementRef = createRef<HTMLDivElement>();

  useEffect(() => {
    setValidationResultClientHeight(
      validationResultElementRef.current?.clientHeight ?? 0
    );
  }, [validationMessage, width, height]);

  return (
    <>
      <div className={classes.ItemList}>
        <CategoryComponent
          selectionStyle={selectionStyle}
          category={category}
          paddingBottomPx={validationResultClientHeight}
          selectedRecords={selectedRecords}
          onRecordSingleChoiceClick={onRecordSingleChoiceClick}
          onRecordMultipleChoiceClick={onRecordMultipleChoiceClick}
        />
        <div
          ref={validationResultElementRef}
          className={classes.ValidationResultWrapper}
        >
          {validationMessage && (
            <ValidationResult validationMessage={validationMessage} />
          )}
        </div>
      </div>
    </>
  );
};

export default CategoryList;
