export const APP_NAME = 'If.ClaimsForms.WebChat';
export const EXTENSION_PDF = '.pdf';
export const DEFAULT_INPUT_LENGTH = 2000;
export const PHONE_MAX_PIXEL_WIDTH = 599;
export const DESKTOP_MIN_PIXEL_WIDTH = 1200;

export enum Locale {
  NO = 'nb-NO',
  FI = 'fi-FI',
  SV = 'sv-SE',
  DK = 'da-DK',
  EN = 'en-EN',
}

export enum Country {
  SE = 'SE',
  NO = 'NO',
  DK = 'DK',
  FI = 'FI',
  NORDIC = 'Nordic',
  TEST = 'Test',
}

export enum Currency {
  DKK = 'DKK',
  NOK = 'NOK',
  SEK = 'SEK',
  EUR = 'EUR',
  USD = 'USD',
}

export enum MonthMapping {
  JANUARY = 0,
  FEBRUARY = 1,
  MARCH = 2,
  APRIL = 3,
  MAY = 4,
  JUNE = 5,
  JULY = 6,
  AUGUST = 7,
  SEPTEMBER = 8,
  OCTOBER = 9,
  NOVEMBER = 10,
  DECEMBER = 11,
}

export enum Environments {
  LOCALHOST = 'LOCALHOST',
  ITEST = 'ITEST',
  STEST = 'STEST',
  ATEST = 'ATEST',
  PROD = 'PROD',
}

export enum Hostname {
  LocalHost = 'localhost',

  Itest = 'claims-bot-webchat-itest.azurewebsites.net',
  Stest = 'claims-bot-webchat-stest.azurewebsites.net',
  Atest = 'claims-bot-webchat-atest.azurewebsites.net',

  ItestExternal = 'itest.atlas.ifext.biz',
  StestExternal = 'stest.atlas.ifext.biz',
  AtestExternal = 'atest.atlas.ifext.biz',

  DenmarkHostname = 'anmeld-skade.if.dk',
  SwedenHostname = 'anmal-skada.if.se',
  SwedenHostKontaktname = 'kontakt.if.se',
  NorwayHostname = 'meld-skade.if.no',
  FinlandHostname = 'vahinkoilmoitus.if.fi',

  SwedenAudi = 'anmal-skada.app.audibilforsakring.se',
  SwedenAudiKontakt = 'kontakt.app.audibilforsakring.se',
  SwedenAudiKontaktTest = 'kontakt-test.app.audibilforsakring.se',
  SwedenBmw = 'anmal-skada.app.bmworiginalforsakring.se',
  SwedenCupra = 'anmal-skada.app.cuprabilforsakring.se',
  SwedenCupraKontakt = 'kontakt.app.cuprabilforsakring.se',
  SwedenCupraKontaktTest = 'kontakt-test.app.cuprabilforsakring.se',
  SwedenDacia = 'anmal-skada.app.daciaforsakring.se',
  SwedenDaciaKontakt = 'kontakt.app.daciaforsakring.se',
  SwedenDaciaKontaktTest = 'kontakt-test.app.daciaforsakring.se',
  SwedenFord = 'anmal-skada.app.fordforsakring.se',
  SwedenFordKontakt = 'kontakt.app.fordforsakring.se',
  SwedenFordKontaktTest = 'kontakt-test.app.fordforsakring.se',
  SwedenIsuzu = 'anmal-skada.app.isuzuforsakring.se',
  SwedenIsuzuKontakt = 'kontakt.app.isuzuforsakring.se',
  SwedenIsuzuKontaktTest = 'kontakt-test.app.isuzuforsakring.se',
  SwedenKia = 'anmal-skada.app.kiabilforsakring.com',
  SwedenKiaKontakt = 'kontakt.app.kiabilforsakring.com',
  SwedenKiaKontaktTest = 'kontakt-test.app.kiabilforsakring.com',
  SwedenMercedesBenz = 'anmal-skada.app.mercedes-benzforsakring.se',
  SwedenMercedesBenzKontakt = 'kontakt.app.mercedes-benzforsakring.se',
  SwedenMercedesBenzKontaktTest = 'kontakt.app.mercedes-benzforsakring.se',
  SwedenMg = 'anmal-skada.app.mgbilforsakring.se',
  SwedenMgTest = 'anmal-skada-test.app.mgbilforsakring.se',
  SwedenMgKontakt = 'kontakt.app.mgbilforsakring.se',
  SwedenMgKontaktTest = 'kontakt-test.app.mgbilforsakring.se',
  SwedenMini = 'anmal-skada.app.minioriginalforsakring.se',
  SwedenNissan = 'anmal-skada.app.nissanforsakring.se',
  SwedenNissanKontakt = 'kontakt.app.nissanforsakring.se',
  SwedenNissanKontaktTest = 'kontakt-test.app.nissanforsakring.se',
  SwedenOpel = 'anmal-skada.app.opelforsakring.se',
  SwedenPorsche = 'anmal-skada.app.porschebilforsakring.se',
  SwedenPorscheKontakt = 'kontakt.app.porschebilforsakring.se',
  SwedenPorscheKontaktTest = 'kontakt-test.app.porschebilforsakring.se',
  SwedenRenault = 'anmal-skada.app.renaultforsakring.se',
  SwedenRenaultKontakt = 'kontakt.app.renaultforsakring.se',
  SwedenRenaultKontaktTest = 'kontakt-test.app.renaultforsakring.se',
  SwedenSaab = 'anmal-skada.app.saabforsakring.se',
  SwedenSaabKontakt = 'kontakt.app.saabforsakring.se',
  SwedenSaabKontaktTest = 'kontakt-test.app.saabforsakring.se',
  SwedenSeat = 'anmal-skada.app.seatbilforsakring.se',
  SwedenSeatKontakt = 'kontakt.app.seatbilforsakring.se',
  SwedenSeatKontaktTest = 'kontakt-test.app.seatbilforsakring.se',
  SwedenSkoda = 'anmal-skada.app.skodabilforsakring.se',
  SwedenSkodaKontakt = 'kontakt.app.skodabilforsakring.se',
  SwedenSkodaKontaktTest = 'kontakt-test.app.skodabilforsakring.se',
  SwedenSubaru = 'anmal-skada.app.subaruforsakring.se',
  SwedenSubaruKontakt = 'kontakt.app.subaruforsakring.se',
  SwedenSubaruKontaktTest = 'kontakt-test.app.subaruforsakring.se',
  SwedenVolkswagen = 'anmal-skada.app.volkswagenbilforsakring.se',
  SwedenVolkswagenKontakt = 'kontakt.app.volkswagenbilforsakring.se',
  SwedenVolkswagenKontaktTest = 'kontakt-test.app.volkswagenbilforsakring.se',
  SwedenVolkswagenTransport = 'anmal-skada.app.volkswagentransportbilarforsakring.se',


  SwedenVolvia = 'anmal-skada.app.volvia.se',
  SwedenVolviaKontakt = 'kontakt.app.volvia.se',
  SwedenVolviaKontaktTest = 'kontakt-test.app.volvia.se',
}

export enum Selectors {
  ChatBotRoot = 'webChatRoot',
}

export enum AppRoutes {
  Root = '/',
  HomePage = '/homepage',
  NotFound = '*',
}

export enum AuthenticationRoutes {
  SignOutCallback = '/sign-out-callback',
}

export enum ChatRoutes {
  Chat = '/chat',
  DenmarkPersonalInjury = '/personskade',
  DenmarkMotor = '/koeretoejer',
  DenmarkPrivateTravel = '/rejseskade',
  DenmarkProperty = '/bygning-indbo',
  DenmarkBoat = '/baad',
  DenmarkCommercialTravel = '/rejse-erhverv',
  DenmarkAnimal = '/dyr',
  DenmarkCargoAndLiability = '/cargo-and-liability-dk',
  DenmarkBusinessClaimsProperty = '/bc-property-dk',
  DenmarkCommercialProperty = '/erhverv-bygning-og-indbo',
  FinlandMotor = '/ajoneuvovahinko',
  FinlandMotorRentalCar = '/sijaisauto',
  FinlandMotorTowing = '/hinaus',
  FinlandMotorGlass = '/lasi',
  FinlandTravelOpenPages = '/matkavahinko',
  FinlandTravelMyPages = '/matkavahinkomp',
  FinlandBoat = '/vene',
  FinlandProfesionalAthletes = '/Ammattiurheilijan-vahinkoilmoitus',
  FinlandProfesionalAthletesEng = '/professional-athletes-claims',
  FinlandHealthNavigator = '/suorakorvaus',
  FinlandPrivatePersonalInjury = '/henkilovahinko',
  FinlandPrivatePersonalInjurySE = '/personskador-fi',
  FinlandCommercialPersonalInjury = '/tyotapaturmailmoitus-henkilokorvaukset',
  FinlandCommercialPersonalInjuryEB = '/yritystenhenkilovahinko',
  FinlandClaimsCenter = '/korvauskeskus',
  FinlandPropertyOpenPages = '/omaisuusvahinko',
  FinlandPropertyMyPages = '/omaisuusvahinkomp',
  FinlandForest = '/metsavahinko',
  FinlandAnimal = '/elainvahinko',
  FinlandCargoAndLiability = '/cargo-and-liability-fi',
  FinlandBusinessClaimsProperty = '/bc-property-fi',
  FinlandCommercialProperty = '/yritystenomaisuusvahinko',
  NorwayPrivateMotor = '/ved-bilskade',
  NorwayCommercialMotor = '/kjoretoybedrift',
  NorwayTravel = '/hjelp-ved-skade',
  NorwayVolvia = '/volvia',
  NorwayPolestar = '/polestar',
  NorwayAnimal = '/husdyr',
  NorwayPrivatePersonalInjury = '/personforsikring',
  NorwayCommercialPersonalInjury = '/skademeldinger-personalforsikring',
  NorwayBoat = '/ved-baatskade',
  NorwayProperty = '/eiendom',
  NorwayCargoAndLiability = '/cargo-and-liability-no',
  NorwayBusinessClaimsProperty = '/bc-property-no',
  NorwayCommercialProperty = '/eiendombedrift',
  SwedenMotor = '/motorskada',
  SwedenProperty = '/property',
  SwedenCommercialProperty = '/commercialProperty',
  SwedenFollowUp = '/foljminskada',
  SwedenPersonalInjury = '/person',
  SwedenAnimal = '/animal',
  SwedenCargoAndLiability = '/cargo-and-liability-se',
  SwedenBusinessClaimsProperty = '/bc-property-se',
  SwedenCommercialExpat = '/personal-insurance',
  SwedenCommercialExpatEng = '/personal-insurance-en',
  Viking = '/viking',
  VikingDK = '/vikingDK',
  Bc = '/bc',
  UserTest = '/usertest',
  LegalAndLiability = '/oikeusturvajavastuuvahinko',
  NorwayExpat = '/expat',
  CargoAndLiabilityEng = '/cargo-and-liability-eng',
  BusinessClaimsPropertyEng = '/bc-property-eng',
  NordicCare = '/nordiccarejourneys',
  NordicCommon = '/contact',
}

export enum AppRoutesSwedenMotorPartners {
  Audi = '/sweden-motor-audi',
  Bmw = '/sweden-motor-bmw',
  Cupra = '/sweden-motor-cupra',
  Dacia = '/sweden-motor-dacia',
  Ford = '/sweden-motor-ford',
  Isuzu = '/sweden-motor-isuzu',
  Kia = '/sweden-motor-kia',
  MercedesBenz = '/sweden-motor-mercedes-benz',
  Mg = '/sweden-motor-mg',
  Mini = '/sweden-motor-mini',
  Nissan = '/sweden-motor-nissan',
  Opel = '/sweden-motor-opel',
  Porsche = '/sweden-motor-porsche',
  Renault = '/sweden-motor-renault',
  Saab = '/sweden-motor-saab',
  Seat = '/sweden-motor-seat',
  Skoda = '/sweden-motor-skoda',
  Subaru = '/sweden-motor-subaru',
  Volkswagen = '/sweden-motor-volkswagen',
  VolkswagenTransport = '/sweden-motor-volkswagen-transport',
  Volvia = '/sweden-motor-volvia',

}

export enum ErrorMessage {
  Unknown = 'An unknown error occured.',
  BadRequest = 'Server responded with bad request.',
  RequestTimedOut = 'Request timed out. Server did not respond.',
  NoClientConfig = 'No client configuration.',
  ChatStoreUndefined = 'chatStore is undefined.',
  ChatInitFailed = 'Chat init failed, showing error page',
}

export enum KeyCodes {
  Enter = 'Enter',
  Backspace = 'Backspace',
  Spacebar = ' ',
  Escape = 'Escape',
  Tab = 'Tab',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Home = 'Home',
  PageUp = 'PageUp',
  PageDown = 'PageDown',
  End = 'End',
  F9 = 'F9',
  Colon = ':',
}

export enum HTMLElementAttribute {
  TAB_INDEX = 'tabindex',
}

export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
}

export enum ResponseReturnType {
  JSON = 'json',
  BLOB = 'blob',
  TEXT = 'text',
}

export enum StorageKeys {
  WebChatStore = 'webchat-store',
}

export enum StatisticsNodeEventTypes {
  Started = 'started',
  ClaimStarted = 'claimStarted',
  ClaimSent = 'claimSent',
}

export enum GoogleAnalyticsCountry {
  SE = 'Sweden',
  NO = 'Norway',
  DK = 'Denmark',
  FI = 'Finland',
}

export enum GoogleAnalyticsBusinessArea {
  Commercial = 'COM',
  Private = 'PRI',
}

export enum GoogleAnalyticsLanguage {
  en = 'EN',
  no = 'NO',
  fi = 'FI',
  sv = 'SV',
  dk = 'DA',
}

export enum AppInsightsUserInteraction {
  ActionButtonClick = 'Action Button Click',

  DateModalOpened = 'Date Modal Opened',
  DateModalClosed = 'Date Modal Closed',
  DateModalSelected = 'Date Modal Selected',

  DateModalConfirmed = 'Date Modal Confirmed',
  DateConfirmed = 'Date Confirmed',

  ClassifierValueEntered = 'Classifier Value Entered',
  ClassifierValueChosen = 'Classifier Value Chosen',
  ClassifierValueConfirmed = 'Classifier Value Confirmed',

  MultipleChoiceValueChosen = 'Multiple Choice Value Chosen',
  LongListItemSelected = 'Long list item selected',
  MultipleChoiceNoTogglesAvailableForDisplay = 'Multiple Choice No Toggles Available For Display',

  TimeSubmitted = 'Time input value submitted', // -> TODO: ValueSubmitted

  ValueSubmitted = 'Value Submitted',
  OverlayToggled = 'Overlay toggled',
}

export enum AppInsightsEventNames {
  WebchatUIPrefix = 'webchatui:',

  ComponentMounted = 'Component mounted',
  ComponentUnmounted = 'Component unmounted',
  ComponentInteraction = 'Component interaction',

  FirstBotMessageReceived = 'First Bot Message Received',
  PostingFailedActivity = 'Posting Failed Activity',
  PostEvent = 'Post Event',

  DirectlineConnected = 'Directline Connected',
  DirectlineFailedToConnect = 'Directline Failed To Connect',
  DirectlineIncomingActivity = 'Directline incoming activity',
  DirectlineIncomingTraffic = 'Directline incoming traffic',
  DirectlineReconnectWebSocket = 'Directline Reconnect WebSocket',

  Retry = 'Retry',
  UserClickedRetryButton = 'User clicked Retry button',

  BugReport = 'Bug Report',

  FilesWereRejected = 'Files Were Rejected',
  ProcessFileUpload = 'Process File Upload',
  FileSuccessfullyUploaded = 'File successfully uploaded',

  ChatInitEventTriggered = 'Chat Init Event Triggered',
  ChatInitEvent = 'Chat initialization event',
  ChatStoreInitialization = 'Chat Store Initialization',

  FirstBotMessageNotReceived = 'First Bot Message Not Received',

  PageConfigurationChanged = 'Page configuration changed',
  SessionExpired = 'Session expired',
  SessionExpiredError = 'Session expired error'
}

export enum AppStatus {
  OverlayOpened = 'OverlayOpened',
  ConversationStarted = 'ConversationStarted',
  ConversationEnded = 'ConversationEnded',
  ClaimStarted = 'ClaimStarted',
  ClaimSubmitted = 'ClaimSubmitted',
}

export enum NodeType {
  InputClassifier = 'InputClassifier',
  DateInput = 'DateInput',
  FileUpload = 'FileUpload',
  LongList = 'LongList',
  Statistics = 'Statistics',
  End = 'End',
}

export enum CustomEvent {
  OverlayOpened = 'overlay-opened',
  OverlayClosed = 'overlay-closed',
}
