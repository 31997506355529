import { TenantMapping } from './types';
import { ChatRoutes, Country, Locale } from '../constants';

import userTestTheme from './themes/userTest';
import userTestLogo from '../assets/images/logos/non-branded-logo.svg';
import userTestFavIcon from '../assets/images/favicons/non-branded.png';

const testTenantMapping: TenantMapping[] = [
  {
    path: ChatRoutes.Chat,
    pageConfig: {
      tenantId: 'f3b1cca3-03a8-4b11-b59a-7c4ee85145cd',
      country: Country.SE,
      locale: Locale.SV,
    },
  },
  {
    path: ChatRoutes.Chat,
    pageConfig: {
      tenantId: '3603de21-04ac-431c-9419-0f6a6204db2d',
      country: Country.DK,
      locale: Locale.DK,
    },
  },
  {
    path: ChatRoutes.Chat,
    pageConfig: {
      tenantId: 'ac69c70b-6312-4e52-9536-0fb3453aac5c',
      country: Country.FI,
      locale: Locale.FI,
    },
  },
  {
    path: ChatRoutes.Chat,
    pageConfig: {
      tenantId: 'ec970953-4b76-4b89-a56c-c977d845c30c',
      country: Country.NO,
      locale: Locale.NO,
    },
  },
  {
    path: ChatRoutes.Chat,
    pageConfig: {
      tenantId: '1ea8424b-7116-4caa-90e8-ea06711b1209',
      locale: Locale.EN,
      brandName: 'Private - Test',
    },
  },
  {
    path: ChatRoutes.Chat,
    pageConfig: {
      tenantId: '13a84593-0052-4da7-9ed2-7a76a9c83b1d',
      locale: Locale.EN,
      brandName: 'Practice',
    },
  },
  {
    path: ChatRoutes.UserTest,
    pageConfig: {
      tenantId: 'cc7c51aa-af80-47fc-9fe8-81930e079ce7',
      country: Country.TEST,
      locale: Locale.EN,
      customTheme: userTestTheme,
      logoDesktop: userTestLogo,
      icon: userTestFavIcon,
      brandName: 'UserTest',
    },
  },
  {
    path: ChatRoutes.Chat,
    pageConfig: {
      tenantId: 'ca623068-4eb4-4cf8-a26f-f02f60024275',
      locale: Locale.EN,
      brandName: 'TellUs - Test',
    },
  },
];

export default testTenantMapping;
