import { ActionCommandType, Card, CUSTOM_ACTIVITY } from '@/middleware/types';
import { Message, AdaptiveCard } from 'botframework-directlinejs';
import { CustomActivityAction } from '../middleware/types';
import { configuredStore } from '@/store';
import { appInsights } from '@/appInsights/appInsights';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

export const getEntity = (card: Card): any | undefined => {
  if (!card || !card.activity) {
    return;
  }
  const entities = (card.activity as Message).entities;
  if (!entities) {
    return;
  }

  return entities;
};

export const getCustomActivity = (card: Card): any | undefined => {
  if (!card || !card.activity) {
    return;
  }
  const entities = (card.activity as Message).entities;
  if (!entities) {
    return;
  }

  return entities.filter((entity) => entity.type === CUSTOM_ACTIVITY);
};

export const getCardActions = (
  card: Card
): CustomActivityAction[] | undefined => {
  const adaptiveCards = getAdaptiveCards(card);
  if (!adaptiveCards || adaptiveCards.length === 0) {
    return;
  }

  //actions may contain type != Action.Submit at the moment
  const actions = adaptiveCards[0].content.actions as
    | CustomActivityAction[]
    | undefined;

  return actions?.filter(
    (action) => action?.data?.CommandType !== ActionCommandType.OK
  );
};

const getAdaptiveCards = (card?: Card): AdaptiveCard[] | undefined => {
  if (!card || !card.activity) {
    return;
  }

  const message = card.activity as Message;
  const adaptiveCards = message.attachments as AdaptiveCard[];

  if (!adaptiveCards || adaptiveCards.length === 0) {
    return;
  }

  return adaptiveCards;
};

export const isEditedActivity = (card: Card): boolean => {
  try {
    const activityId = card.activity.id;
    if (!activityId) {
      return false;
    }
    const editActivityIds =
      configuredStore.store.getState().botConfigState.editActivityIds;
    const isEditedActivity = editActivityIds.filter(
      (item) => item === activityId
    );
    return isEditedActivity && isEditedActivity.length >= 1;
  } catch (e) {
    const error = e as Error;
    appInsights.trackException({
      error,
      severityLevel: SeverityLevel.Error,
    });
    return false;
  }
};

export const shouldHideActivity = (card: Card): boolean => {
  const activityId = card.activity.id;
  if (!activityId) {
    return false;
  }

  const editActivityIds =
    configuredStore.store.getState().conversationState.activityIdsToHide;
  const isEditedActivity = editActivityIds.filter(
    (item) => item === activityId
  );
  return isEditedActivity && isEditedActivity.length >= 1;
};
