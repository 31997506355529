import React from 'react';
import classNames from 'classnames';

import { translate, Translations } from '../../../../../locale';

import { TimeFields, TimePart, TimePreset } from '../types';

import classes from './TimeInputBox.module.scss';
import { KeyCodes } from '../../../../../constants';

export interface TimeInputBoxProps {
  isError: boolean;
  timeFields: TimeFields;
  setTime: (
    timePart: TimePart,
    inputRefs: HTMLInputElement[],
    inputIndex: number,
    value: string
  ) => void;
  submitTime: () => void;
  timePreset: TimePreset;
  clickedOutside: boolean;
}

export default function TimeInputBox(props: TimeInputBoxProps): JSX.Element {
  const inputRefs: HTMLInputElement[] = [];
  const { clickedOutside } = props;

  const backTrackFocus = (
    inputRefs: HTMLInputElement[],
    inputIndex: number
  ) => {
    const value = inputRefs[inputIndex].value;
    if (value === '' && typeof inputRefs[inputIndex - 1] !== 'undefined') {
      inputRefs[inputIndex - 1].focus();
    }
  };

  const onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
    timePart: TimePart,
    inputIndex: number
  ) => {
    props.setTime(timePart, inputRefs, inputIndex, event.target.value);
  };

  const padZero = (
    timePart: TimePart,
    inputRefs: HTMLInputElement[],
    inputIndex: number
  ) => {
    let value = inputRefs[inputIndex]?.value;
    if (value?.length === 1) {
      value = value.padStart(2, '0');
      props.setTime(timePart, inputRefs, inputIndex, value);
    }
  };

  const onBlurHandler = (
    timePart: TimePart,
    inputRefs: HTMLInputElement[],
    inputIndex: number
  ) => {
    padZero(timePart, inputRefs, inputIndex);
  };

  const onKeyUpHandler = (event: React.KeyboardEvent) => {
    if (event.key === KeyCodes.Enter) {
      props.submitTime();
    }
  };

  const onKeyDownHandler = (
    event: React.KeyboardEvent,
    timePart: TimePart,
    inputRefs: HTMLInputElement[],
    inputIndex: number
  ) => {
    switch (event.key) {
      case KeyCodes.Backspace:
        backTrackFocus(inputRefs, inputIndex);
        break;

      case KeyCodes.Colon:
        padZero(timePart, inputRefs, inputIndex);
        break;

      default:
        break;
    }
  };

  const onClickHandler = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    event.currentTarget.select();
  };

  return (
    <div
      className={classNames(
        classes.TimeInputBox,
        clickedOutside ? null : classes.Focused,
        props.isError ? classes.Error : null
      )}
      aria-label={translate(Translations.manualTimeInput)}
    >
      {Object.keys(props.timeFields)
        .sort((timePart) => {
          return timePart === TimePart.HOURS ? -1 : 1;
        })
        .map((value, index, array) => {
          const timePart = value as TimePart;
          const timePartConfig = props.timePreset[timePart];
          const label = timePartConfig.translationKey;
          return (
            <React.Fragment key={index}>
              <input
                id={String(timePart)}
                ref={(inputRef) => (inputRef ? inputRefs.push(inputRef) : null)}
                className={classNames(classes.InputItem)}
                inputMode={'numeric'}
                type={'text'}
                pattern={timePartConfig.regex}
                value={props.timeFields[timePart]}
                aria-label={label}
                title={label}
                placeholder={timePartConfig.placeholder}
                autoFocus={index === 0}
                autoComplete="off"
                onChange={(e) => onChangeHandler(e, timePart, index)}
                onBlur={() => onBlurHandler(timePart, inputRefs, index)}
                onKeyUp={(e) => onKeyUpHandler(e)}
                onKeyDown={(e) =>
                  onKeyDownHandler(e, timePart, inputRefs, index)
                }
                onClick={(e) => onClickHandler(e)}
              />
              {array.length - 1 === index ? null : (
                <span className={classes.InputSeparator}>
                  {props.timePreset.separator}
                </span>
              )}
            </React.Fragment>
          );
        })}
    </div>
  );
}
