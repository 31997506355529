import { RootState } from '../types';
import { KnownSearchParams, PageConfig } from './types';

export function getLocaleFromPageConfig(state: RootState): string | undefined {
  return state.pageConfigState?.config
    ? state.pageConfigState.config[KnownSearchParams.LOCALE]
    : undefined;
}

export const getTenantId = (pageConfig: PageConfig): string | undefined => {
  if (!pageConfig) {
    return;
  }

  return pageConfig[KnownSearchParams.TENANT_ID] || undefined;
};
