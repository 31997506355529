import {
  ApplicationInsights,
  ITelemetryItem,
} from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

let appInsights: ApplicationInsights;
const reactPlugin = new ReactPlugin();

export const initializeAppInsights = ({
  connectionString,
}: {
  connectionString: string;
}): void => {
  const browserHistory = createBrowserHistory({ window });
  appInsights = new ApplicationInsights({
    config: {
      connectionString: connectionString,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });

  appInsights.loadAppInsights();
  addDeviceScreenResolutionTelemetry(appInsights);
};

const addDeviceScreenResolutionTelemetry = (
  applicationInsights: ApplicationInsights
) => {
  applicationInsights.addTelemetryInitializer((envelope) => {
    envelope.data = envelope.data || {};
    envelope.data.devicePixelRatio = window.devicePixelRatio;
    envelope.data.screenWidth = window.screen.width;
    envelope.data.screenHeight = window.screen.height;
  });
};

export const addConversationIdTelemetry = (conversationId: string): void => {
  appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
    envelope.data = envelope.data || {};
    envelope.data.conversationId = conversationId;
  });
};

// https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript-react-plugin for docs on usage
export { reactPlugin, appInsights };
