import React, { ReactElement, useCallback, useEffect } from 'react';

import classNames from 'classnames';
import classes from './Modal.module.scss';

import FocusTrap from 'focus-trap-react';
import { RemoveScroll } from 'react-remove-scroll';

import { KeyCodes } from '@/constants';
import { DataModalAttribute } from '@/constants/testEnums';
import {
  shouldCloseOnEscapeValidator,
  shouldShowDismissAtHeaderValidator,
} from './Modal.Validations';

export interface ModalProps {
  modalVisible: boolean;
  modalBody: ReactElement;

  title?: string;
  modalFooter?: ReactElement;

  shouldCloseOnEscape?: boolean;
  shouldShowDismissAtHeader?: boolean;
  closeButtonAriaLabel?: string;

  closeHandler?: () => void;

  headerTestId?: string;
  disableFocusTrap?: boolean;
}

const keyDownCloseHandler = (
  event: React.KeyboardEvent,
  closeHandler?: () => void
) => {
  if (event.repeat || !closeHandler) {
    return;
  }
  switch (event.key) {
    case KeyCodes.Enter:
    case KeyCodes.Spacebar:
      closeHandler();
      event.preventDefault();
      event.stopPropagation();
      break;
  }
};

const onClickCloseHandler = (closeHandler?: () => void) =>
  closeHandler && closeHandler();

export const renderDismissButton = (
  shouldShowDismissAtHeader: boolean | undefined = undefined,
  closeHandler: (() => void) | undefined,
  closeButtonAriaLabel: string | undefined
): ReactElement | null => {
  return shouldShowDismissAtHeader ? (
    <div
      className={classes.CloseButton}
      onClick={() => onClickCloseHandler(closeHandler)}
      onKeyDown={(e: React.KeyboardEvent) =>
        keyDownCloseHandler(e, closeHandler)
      }
      role="button"
      aria-label={closeButtonAriaLabel}
      tabIndex={0}
    />
  ) : null;
};

const renderFooter = (modalFooter: React.ReactElement | undefined) => {
  return modalFooter ? (
    <div className={classes.Footer}>{modalFooter}</div>
  ) : null;
};

function Modal(props: ModalProps) {
  const {
    closeHandler,
    shouldShowDismissAtHeader,
    shouldCloseOnEscape,
    modalVisible,
    disableFocusTrap,
  } = props;

  const handleEscapeCallback = useCallback(
    (event: KeyboardEvent) => {
      if (
        shouldCloseOnEscape &&
        event.key === KeyCodes.Escape &&
        closeHandler
      ) {
        closeHandler();
      }
    },
    [closeHandler, shouldCloseOnEscape]
  );

  useEffect(() => {
    document.addEventListener('keyup', handleEscapeCallback);
    return () => {
      document.removeEventListener('keyup', handleEscapeCallback);
    };
  }, [handleEscapeCallback]);

  const modalTitle = 'MODAL_TITLE';
  return modalVisible ? (
    <RemoveScroll>
      <div className={classes.Anchor}>
        <div className={classes.BackDrop} />
        <FocusTrap
          active={!disableFocusTrap}
          focusTrapOptions={{
            fallbackFocus:"#modal-wrapper"
        }}>
          <div
            className={classes.Modal}
            data-custom-element={DataModalAttribute.MODAL_ROOT_ELEMENT}
            role={'dialog'}
            aria-modal="true"
            aria-labelledby={modalTitle}
            aria-describedby={modalTitle}
            id="modal-wrapper"
          >
            <header
              data-testid={props.headerTestId}
              className={classNames(classes.Header)}
            >
              <div
                id={modalTitle}
                className={classes.Title}
                aria-label={props.title}
              >
                {props.title}
              </div>

              {renderDismissButton(
                shouldShowDismissAtHeader,
                closeHandler,
                props.closeButtonAriaLabel
              )}
            </header>

            <div className={classes.Body}>{props.modalBody}</div>

            {renderFooter(props.modalFooter)}
          </div>
        </FocusTrap>
      </div>
    </RemoveScroll>
  ) : null;
}

Modal.propTypes = {
  shouldCloseOnEscape: shouldCloseOnEscapeValidator,
  shouldShowDismissAtHeader: shouldShowDismissAtHeaderValidator,
};

export default Modal;
