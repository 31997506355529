import { Action } from 'redux';
import {
  IActivity,
  Message,
  Typing,
  EventActivity,
} from 'botframework-directlinejs';

export const ADAPTIVE_CARD = 'application/vnd.microsoft.card.adaptive';
export const CUSTOM_ACTIVITY = 'CustomActivity';
export const DEBUG_PREFIX = 'Debug:';

export interface CustomActivityConfig {
  /**
   * value to filter entities array for custom activity configuration
   */
  type: 'CustomActivity';
  /**
   * node id from editor
   */
  id: string;
  /**
   * node type enum from editor
   */
  messageType: number;
  /**
   * Is the specific activity type can be edited after submission
   */
  canBeEdited?: boolean;
  /**
   * List of validation rules from backend how to validate user input
   */
  propertyValidationRules?: PropertyValidationRule[];

  /**
   * Object that contains all data required for pre-filling activity value
   */
  prefillText?: string;
}

export interface CustomTyping extends IActivity {
  type: ActivityTypes.CustomTyping;
  entities?: any[];
}

export interface RetryActivity extends IActivity {
  type: ActivityTypes.Retry;
  retryId: string;
}

export interface StatisticsEvent extends IActivity {
  type: ActivityTypes.StatisticsEvent;
  value: StatisticsEventData;
}

export declare type CustomActivity =
  | Message
  | Typing
  | EventActivity
  | CustomTyping
  | StatisticsEvent
  | RetryActivity;

export interface Card extends Action {
  //activity should be of type WebchatActivity
  activity: CustomActivity;
  showTimestamp?: boolean;
}

export interface ActivityStatusCard extends Action {
  activity: CustomActivity;
  hideTimestamp: boolean;
  nextVisibleActivity?: CustomActivity;
  sameTimestampGroup: boolean;
  sendState:
  | ActivityClientState.SENDING
  | ActivityClientState.SEND_FAILED
  | ActivityClientState.SENT;
}

export enum ActivityClientState {
  SEND_FAILED = 'send failed',
  SENDING = 'sending',
  SENT = 'sent',
}

export interface CustomActivityAction {
  type: string;
  data: any;
  title: string;
  fileName?: string;
}

export enum ActionCommandType {
  //Used for emulator
  OK = 1,

  EDIT = 2,
}

export interface PropertyValidationRule {
  validationType: number;
  message: string;
  expression?: string;
}

export type ActivityEntity = CustomActivityConfig | undefined;
export type ActivityAction = CustomActivityAction | undefined;

export enum ValidationType {
  General = 0,
  MaxLength = 1,
}

export enum MessageTypes {
  inputText = 2,
  inputTextArea = 20,
  inputDate = 4,
  inputTime = 5,
  inputDateTime = 6,
  inputSsn = 7,
  inputAttachment = 9,
  fileUpload = 10,
  longList = 11,
  endOfConversation = 16,
  multipleChoice = 17,
  ratingStars = 18,
  listFromCarousel = 23,
  inputCurrency = 26,
  selectCountryClassifier = 27,
  summary = 33,
  EmployeeAuthentication = 35,
  WeakAuthentication = 37,
  silentAuthentication = 38,
  silentEmployeeAuthentication = 39,
  inputPhone = 40,
  finlandMotorCountryClassifier = 409,
  finlandTravelCountryClassifier = 4006,
  finlandPostalCodeClassifier = 413,
  finlandCityClassifier = 410,
  finlandSportCodeClassifier = 701,
  finlandDiagnosisCodeClassifier = 702,
  finlandMedicineCodeClassifier = 703,
  finlandCatBreedCodeClassifier = 720,
  finlandDogBreedCodeClassifier = 722,
  finlandAnimalDiagnosis = 723,
  finlandBodyPartCodeClassifier = 704,
  finlandTradeUnionCodeClassifier = 705,
  swedenCountryClassifier = 509,
  norwayTravelCountryClassifier = 123,
  norwayAuthentication = 101,
  denmarkAuthentication = 301,
  finlandAuthentication = 401,
  swedenAuthentication = 501,
  norwaySummary = 104,
  denmarkSummary = 304,
  finlandSummary = 404,
  swedenSummary = 504,
  norwaySilentAuth = 199,
  denmarkSilentAuth = 399,
  finlandSilentAuth = 499,
  swedenSilentAuth = 599,
  finlandInputBankAccount = 412,
  ssnValidation = 417,
  testCountry = 900,
  norwayExpatriatesCountryClassifier = 2100,
}

export enum ActivityRoles {
  User = 'user',
  Bot = 'bot',
}

export enum WebChatActionTypes {
  SEND_EVENT = 'WEB_CHAT/SEND_EVENT',
  SEND_MESSAGE = 'WEB_CHAT/SEND_MESSAGE',
  SEND_MESSAGE_BACK = 'WEB_CHAT/SEND_MESSAGE_BACK',
  POST_ACTIVITY = 'DIRECT_LINE/POST_ACTIVITY',
}

export enum PostActivityMethod {
  Keyboard = 'keyboard' /* default */,
}

export enum DirectLineActionTypes {
  CONNECTION_STATUS_UPDATE = 'DIRECT_LINE/CONNECTION_STATUS_UPDATE',
  UPDATE_CONNECTION_STATUS = 'DIRECT_LINE/UPDATE_CONNECTION_STATUS',
  INCOMING_ACTIVITY = 'DIRECT_LINE/INCOMING_ACTIVITY',
  QUEUE_INCOMING_ACTIVITY = 'DIRECT_LINE/QUEUE_INCOMING_ACTIVITY',
  POST_ACTIVITY = 'DIRECT_LINE/POST_ACTIVITY',
  POST_ACTIVITY_FULFILLED = 'DIRECT_LINE/POST_ACTIVITY_FULFILLED',
  POST_ACTIVITY_REJECTED = 'DIRECT_LINE/POST_ACTIVITY_REJECTED',
  POST_ACTIVITY_PENDING = 'DIRECT_LINE/POST_ACTIVITY_PENDING',
  DELETE_ACTIVITY = 'DIRECT_LINE/DELETE_ACTIVITY',
  CONNECT_FULFILLED = 'DIRECT_LINE/CONNECT_FULFILLED',
  CONNECT_REJECTED = 'DIRECT_LINE/CONNECT_REJECTED',
  CONNECT = 'DIRECT_LINE/CONNECT',
}

export enum ChatCommands {
  Init = 'Init',
  AuthDone = 'AuthDone',
  AuthSilent = 'AuthSilent',
  EndOfConversation = 'endOfConversation',
  SignOutDone = 'SignOutDone',
  Edit = 'Edit',
  Debug = 'Debug',
}

export enum ActivityTypes {
  Message = 'message',
  Typing = 'typing',
  CustomTyping = 'customTyping',
  Event = 'event',
  StatisticsEvent = 'statisticsEvent',
  Retry = 'retry',
}

export enum ActivityNames {
  INIT = 'Init',
  EDIT = 'Edit',
}

export enum DirectLineConnectionStatus {
  Uninitialized,
  Connecting,
  Online,
  ExpiredToken,
  FailedToConnect,
  Ended,
}

export interface EventPayload {
  value: any;
  name?: string;
}

export interface MessagePayload {
  text: string;
}

export interface CustomUserResponsePayload {
  text?: string;
  displayText?: string;
  value?: CustomUserResponse;
}

export interface CustomUserResponse {
  customEntity?: CustomActivityConfig;
  [key: string]: any;
}

export interface KeyValue {
  key?: string;
  value: string;
}

export interface StatisticsEventData {
  StatisticsEventType: string;
  StatisticsData?: StatisticsData[];
}

export interface StatisticsData {
  Property: string;
  Value: string;
}

export interface WebChatStoreActivity {
  id: string;
  timestamp: Date;
}

declare global {
  interface Window {
    webkit: any;
  }
}
