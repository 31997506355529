import { isDevEnvironment, isTestEnvironment } from '../../../utils/utils';
import { configuredStore } from '@/store';
import { setConversationId } from '../../../store/bot-config/actions';
import { PageConfig } from '../../../store/page-config/types';
import { DirectLineConfiguration } from '@/api/types';

import {
  appInsights,
  addConversationIdTelemetry,
} from '@/appInsights/appInsights';
import { DirectLine } from 'botframework-directlinejs';
import {
  generateDirectlineToken,
  getReconnectToken,
} from '@/api/directLine/directLineMethods';
import { ThrowErrorFunction } from '@/hooks/useThrowError';

export interface DirectLineExtendedConfig extends DirectLineConfiguration {
  conversationStartProperties: ConversationStartProperties;
}

interface ConversationStartProperties {
  pageConfig: PageConfig;
  statisticsValues: string[];
}

export const persistConversationId = (conversationId: string): void => {
  const { dispatch } = configuredStore.store;

  dispatch(setConversationId(conversationId));
};

export const connectToDirectline = async (): Promise<DirectLine> => {
  const state = configuredStore.store.getState();
  const conversationId = state.botConfigState.conversationId;

  let directLineConfig: DirectLineConfiguration | null = null;

  if (conversationId) {
    directLineConfig = (await getReconnectToken({ conversationId })).data;

    // If reconnect fails in backend, regenerate token
    if (!directLineConfig?.conversationId || !directLineConfig?.token) {
      directLineConfig = (await generateDirectlineToken()).data;
    }
  } else {
    directLineConfig = (await generateDirectlineToken()).data;
  }

  if (!directLineConfig) {
    throw new Error('Failed to get directLine config');
  }

  persistConversationId(directLineConfig.conversationId);
  if (isDevEnvironment() || isTestEnvironment()) {
    console.info('ConversationId:', directLineConfig.conversationId);
  }

  addConversationIdTelemetry(directLineConfig.conversationId);

  return new DirectLine(prepareDirectLineConfig(directLineConfig));
};

export const reconnectToDirectline = async (
  watermark: number,
  throwError: ThrowErrorFunction
): Promise<DirectLine> => {
  appInsights.trackEvent(
    { name: 'reconnectToDirectline called' },
    { watermark }
  );

  const state = configuredStore.store.getState();
  const conversationId = state.botConfigState.conversationId!;
  try {
    const directLineConfig = (
      await getReconnectToken({ conversationId, watermark })
    ).data;
    if (!directLineConfig) {
      throw new Error('Failed to retrieve directline token on reconnect');
    }

    return new DirectLine(directLineConfig);
  } catch (e) {
    const error = e as Error;
    throwError(error);
    throw e;
  }
};

const prepareDirectLineConfig = (
  directLineConfig: DirectLineConfiguration
): DirectLineConfiguration => {
  // If there is streamURl than all props should be passed to directline.
  // This is used to debug with Emulator
  if (directLineConfig.streamUrl) {
    return directLineConfig;
  }

  // ConversationId is unaccessible form DirectLine object so we need to retrieve when generating token.
  // We don't need to pass it to DirectLine as it only needs token.
  directLineConfig.conversationId = '';
  return directLineConfig;
};
