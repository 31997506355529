import { useEffect, useRef, FunctionComponent } from 'react';
import classes from './BaseTranscript.module.scss';
import {
  AriaAtomic,
  AriaLive,
  AriaRelevant,
  AriaRoleDescription,
  AriaDocumentStructureRoles,
} from '../../constants/aria';

export const TRANSCRIPT_MESSAGE = 'TranscriptMessage';

export interface BaseTranscriptProps {
  transcriptMessage: string;
  politenessSetting?: AriaLive;
  announceImmediately?: boolean;
}

export const BaseTranscript: FunctionComponent<BaseTranscriptProps> = ({
  transcriptMessage,
  announceImmediately,
  politenessSetting,
}: BaseTranscriptProps) => {
  const sectionRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    while (
      sectionRef.current?.firstChild &&
      sectionRef.current.lastElementChild
    ) {
      sectionRef.current.removeChild(sectionRef.current.lastElementChild);
    }

    const element = document.createElement('div');
    element.setAttribute('aria-atomic', AriaAtomic.TRUE);
    element.setAttribute('aria-roledescription', AriaRoleDescription.MESSAGE);
    element.setAttribute('role', AriaDocumentStructureRoles.REGION);
    element.setAttribute('data-testid', TRANSCRIPT_MESSAGE);

    element.textContent = transcriptMessage;

    if (announceImmediately) {
      sectionRef.current?.appendChild(element);
    } else {
      setTimeout(() => {
        sectionRef.current?.appendChild(element);
      }, 1000);
    }
  }, [transcriptMessage]);

  return (
    <section
      data-testid={classes.Transcript}
      className={classes.Transcript}
      aria-atomic={AriaAtomic.FALSE}
      aria-live={politenessSetting ? politenessSetting : AriaLive.POLITE}
      aria-relevant={AriaRelevant.ADDITIONS}
      ref={sectionRef}
      role="log"
    />
  );
};

export default BaseTranscript;
