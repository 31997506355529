import { FunctionComponent } from 'react';

import classes from './EditButton.module.scss';

import { translate, Translations } from '@/locale';

export interface EditButtonProps {
  disabled: boolean;
  onClick: () => void;
  actionColor: string | undefined;
}

const EditButton: FunctionComponent<EditButtonProps> = ({
  disabled,
  actionColor,
  onClick,
}: EditButtonProps) => {
  return (
    <div className={classes.EditButton}>
      <button disabled={disabled} className={classes.Button} onClick={onClick}>
        {/* Workaround to support partner site coloring with if design system icon (scss mixin was not importing dynamically adjusted colors)*/}
        <svg
          className={classes.EditIcon}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <title>{translate(Translations.edit)}</title>
          <g
            className="nc-icon-wrapper"
            strokeLinecap="square"
            strokeLinejoin="miter"
            strokeWidth="1.5"
            fill={disabled ? 'gray' : actionColor}
            stroke={disabled ? 'gray' : actionColor}
          >
            <line
              data-color="color-2"
              x1="3"
              y1="31"
              x2="29"
              y2="31"
              fill="none"
              strokeMiterlimit="10"
            />
            <line
              data-color="color-2"
              x1="19"
              y1="6"
              x2="24"
              y2="11"
              fill="none"
              strokeMiterlimit="10"
            />
            <polygon
              points="11 24 4 26 6 19 23 2 28 7 11 24"
              fill="none"
              stroke={disabled ? 'gray' : actionColor}
              strokeMiterlimit="10"
            />
          </g>
        </svg>
        {translate(Translations.edit)}
      </button>
    </div>
  );
};

export default EditButton;
