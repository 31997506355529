import classNames from 'classnames';
import React from 'react';
import { DEFAULT_INPUT_LENGTH } from '../../../../constants';
import classes from './TextInput.module.scss';

interface MessageLengthIndicatorProps {
  maxLength: number;
  inputLength: number;
}

export default function MessageLengthIndicator(
  props: MessageLengthIndicatorProps
) {
  const className = classNames(
    classes.MessageLengthIndicator,
    props.maxLength > DEFAULT_INPUT_LENGTH ? classes.Hidden : null
  );
  return (
    <span className={className}>
      {props.inputLength}/{props.maxLength}
    </span>
  );
}
