import { Reducer, AnyAction } from 'redux';
import { GlobalConfigState, GlobalConfigActionTypes } from './types';

export const globalConfigInitialState: GlobalConfigState = {
  isMobile: false,
  isDesktop: false,
  hasAlert: false,
  clientSideConfiguration: null,
};

export const globalConfigReducer: Reducer<GlobalConfigState> = (
  state: GlobalConfigState = globalConfigInitialState,
  action: AnyAction
): GlobalConfigState => {
  switch (action.type) {
    case GlobalConfigActionTypes.SET_IS_MOBILE: {
      return {
        ...state,
        isMobile: action.payload,
      };
    }
    case GlobalConfigActionTypes.SET_IS_DESKTOP: {
      return {
        ...state,
        isDesktop: action.payload,
      };
    }
    case GlobalConfigActionTypes.SET_HAS_ALERT: {
      return {
        ...state,
        hasAlert: action.payload,
      };
    }

    case GlobalConfigActionTypes.SET_CLIENT_SIDE_CONFIG: {
      return {
        ...state,
        clientSideConfiguration: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
