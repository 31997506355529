import { Record } from '@/components/CustomActivities/LongList/types';

const createUserResponseText = (selectedItems: Record[]): string => {
  const separatorCharacter = ' ';

  return selectedItems.length === 1
    ? selectedItems[0].text
    : selectedItems
        .map((item, index) => {
          return `${index + 1}.${item.text}`;
        })
        .join(separatorCharacter);
};

export default createUserResponseText;
