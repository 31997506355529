import React, { FunctionComponent, useEffect, useRef } from 'react';
import classes from './AutoComplete.module.scss';
import { AutoCompleteItem } from '../types';
import AutoCompleteTranscript from './AutoCompleteTranscript';
import classNames from 'classnames';
import SearchItem from './SearchItem';
import Alert from '../../../../UI/Alert/Alert';

export const AUTO_COMPLETE_LIST_ID = 'auto-complete-list-id';
export const CHOICE_ID = 'choice-id';

export interface AutoCompleteProps {
  data: AutoCompleteItem[];
  searchValue: string;
  selectClassifier: (selectedClassifier: AutoCompleteItem | null) => void;
  highlightedClassifierIndex: number;
  noResultsFoundMessage: string;
  autoCompleteItemRefs: React.MutableRefObject<(HTMLLIElement | null)[]>;
  allowAnyValue: boolean;
}

export const AutoComplete: FunctionComponent<AutoCompleteProps> = (
  props: AutoCompleteProps
) => {
  let listRef: HTMLUListElement | null = null;

  const {
    data,
    searchValue,
    highlightedClassifierIndex,
    noResultsFoundMessage,
    autoCompleteItemRefs,
    selectClassifier,
    allowAnyValue,
  } = props;

  const mapSearchItems = () => {
    return data.map((item: AutoCompleteItem, index: number) => {
      return (
        <SearchItem
          id={item.key}
          key={item.key}
          index={index}
          activeIndex={highlightedClassifierIndex}
          item={item}
          searchValue={searchValue}
          selectClassifier={selectClassifier}
          autoCompleteItemRefs={autoCompleteItemRefs}
        />
      );
    });
  };

  const prevSearch = useRef<string | undefined>('');
  useEffect(() => {
    prevSearch.current = searchValue;
  });

  autoCompleteItemRefs.current = [];

  useEffect(() => {
    if (prevSearch.current !== searchValue && listRef) {
      listRef.scrollTop = 0;
    }
  }, [searchValue, listRef]);

  if (data.length === 0 && allowAnyValue) {
    return null;
  } else if (data.length === 0) {
    return <Alert message={noResultsFoundMessage} />;
  }

  return (
    <div
      className={classNames(
        classes.AutoComplete,
        data.length === 0 ? classes.Alert : null
      )}
    >
      <div className={classes.ItemContainer}>
        <ul
          className={classNames(
            classes.ItemList,
            data.length > 0 ? null : classes.NoResults
          )}
          role="listbox"
          id={AUTO_COMPLETE_LIST_ID}
          ref={(ref) => (listRef = ref)}
        >
          {mapSearchItems()}
        </ul>
        <AutoCompleteTranscript
          dataSet={data}
          searchValue={searchValue}
          noResultsFoundMessage={noResultsFoundMessage}
        />
      </div>
    </div>
  );
};
