import { PageConfig } from '../store/page-config/types';

import {
  mdiHome,
  mdiCar,
  mdiDogSide,
  mdiAirplane,
  mdiBandage,
  mdiSailBoat,
  mdiRun,
  mdiTestTube,
  mdiAccountQuestion,
  mdiFileDocumentOutline,
  mdiHeadset,
  mdiFaceAgent,
  mdiNavigation,
  mdiTree,
  mdiScaleBalance,
  mdiEarth,
  mdiTruck,
  mdiFormSelect,
} from '@mdi/js';

export interface TenantMapping {
  path: string;
  pageConfig: PageConfig;
}

export enum LineOfBusiness {
  PROPERTY = 'property',
  MOTOR = 'motor',
  ANIMAL = 'animal',
  TRAVEL = 'travel',
  PERSONAL_INJURY = 'personalinjury',
  HEALTH_NAVIGATOR = 'healthnavigator',
  CLAIMS_CENTER = 'claimscenter',
  BOAT = 'boat',
  PROF_ATHLETES = 'profathletes',
  PROF_ATHLETES_EN = 'profathletesen',
  TEST = 'test',
  PRACTICE = 'practice',
  FOLLOWUP = 'followup',
  VIKING = 'viking',
  BC = 'bc',
  FOREST = 'forest',
  LEGAL_AND_LIABILITY = 'legalandliability',
  EXPAT = 'expat',
  CARGO_AND_LIABILITY = 'businessclaims',
  NORDIC_CARE = 'nordiccare',
  COMMON = 'common',
}

type LobIcons = { [key in LineOfBusiness]: string };

export const lineOfBusinessIcons: LobIcons = {
  [LineOfBusiness.PROPERTY]: mdiHome,
  [LineOfBusiness.MOTOR]: mdiCar,
  [LineOfBusiness.ANIMAL]: mdiDogSide,
  [LineOfBusiness.TRAVEL]: mdiAirplane,
  [LineOfBusiness.PERSONAL_INJURY]: mdiBandage,
  [LineOfBusiness.HEALTH_NAVIGATOR]: mdiNavigation,
  [LineOfBusiness.PROF_ATHLETES]: mdiRun,
  [LineOfBusiness.PROF_ATHLETES_EN]: mdiRun,
  [LineOfBusiness.BOAT]: mdiSailBoat,
  [LineOfBusiness.TEST]: mdiTestTube,
  [LineOfBusiness.PRACTICE]: mdiTestTube,
  [LineOfBusiness.FOLLOWUP]: mdiAccountQuestion,
  [LineOfBusiness.VIKING]: mdiHeadset,
  [LineOfBusiness.CLAIMS_CENTER]: mdiFaceAgent,
  [LineOfBusiness.BC]: mdiFileDocumentOutline,
  [LineOfBusiness.FOREST]: mdiTree,
  [LineOfBusiness.LEGAL_AND_LIABILITY]: mdiScaleBalance,
  [LineOfBusiness.EXPAT]: mdiEarth,
  [LineOfBusiness.CARGO_AND_LIABILITY]: mdiTruck,
  [LineOfBusiness.NORDIC_CARE]: mdiBandage,
  [LineOfBusiness.COMMON]: mdiFormSelect,
};

export enum BusinessArea {
  COMMERCIAL = 'Commercial',
  PRIVATE = 'Private',
}
