import { ConfigurableTheme } from '../../styles/types';

// Sync with If.ClaimsForms.Bot.WebChat.Models.Enums.KnownSearchParams
export enum KnownSearchParams {
  TENANT_ID = 'tenantId',
  BASE_URL = 'baseUrl',
  BUSINESS_AREA = 'businessArea',
  COUNTRY = 'country',
  LINE_OF_BUSINESS = 'lineOfBusiness',
  LOCALE = 'locale',
  PORTFOLIO = 'portfolio',
  PARTNER = 'partner',
  FILE_NAME = 'fileName',
  BRAND_NAME = 'brandName',
  ID = 'id',
  CLAIM_TYPE = 'claimType',
  DC = 'dc',
  ANIMAL_TYPE = 'animalType',
  ENCRYPTED_MY_PAGES_DATA = 'EMPD',
  CHANNEL = 'channel',
  IS_ORCHESTRATOR_CLAIM = 'isOrchestratorClaim',
}

export enum StatisticsProperties {
  INSURANCE_PRICE = 'price',
  INSURANCE_COVERAGE = 'coverage',
  PRODUCT_NAME = 'product',
  CUSTOMER_AGE = 'customerAge',
  CUSTOMER_GENER = 'customerGender',
  CURRENCY = 'currencyCode',
}

//It seems that properties that are not KnownSearchParams can't be referenced in flow like {Start.xxxx}
export interface PageConfig {
  [KnownSearchParams.TENANT_ID]?: string | null;
  [KnownSearchParams.BASE_URL]?: string | null;
  [KnownSearchParams.BUSINESS_AREA]?: string | null;
  [KnownSearchParams.LINE_OF_BUSINESS]?: string | null;
  [KnownSearchParams.LOCALE]?: string;
  [KnownSearchParams.COUNTRY]?: string;
  [KnownSearchParams.FILE_NAME]?: string;
  [KnownSearchParams.BRAND_NAME]?: string;
  [KnownSearchParams.PORTFOLIO]?: string;
  [KnownSearchParams.CLAIM_TYPE]?: string;
  [KnownSearchParams.DC]?: string;
  [KnownSearchParams.ANIMAL_TYPE]?: string;
  [KnownSearchParams.ENCRYPTED_MY_PAGES_DATA]?: string;
  [KnownSearchParams.CHANNEL]?: string;
  [KnownSearchParams.PARTNER]?: string;
  [KnownSearchParams.ID]?: string;

  pageTitleTranslationKey?: string;
  customTheme?: ConfigurableTheme | null;
  logoDesktop?: string;
  logoMobile?: string;
  icon?: string;
  homePageIcon?: string;
  homePageSvgIcon?: string;
  headerColorsInverted?: boolean;
  showPageTitle?: boolean;
  contactSupportLinks?: {
    default: string;
    en?: string;
    dk?: string;
    no?: string;
    sv?: string;
    fi?: string;
  };
  accessibilityStatementUrls?: {
    default: string;
    en?: string;
    dk?: string;
    no?: string;
    sv?: string;
    fi?: string;
  };
  productionUrl?: string;
  testUrl?: string;
  idmProfile?: string;
  contactSupportText?: string;
}

export enum PageConfigActions {
  CLEAR_CONFIG = 'PAGE_CONFIG/CLEAR_CONFIG',
  SET_PAGE_CONFIG = 'PAGE_CONFIG/PERSIST_PAGE_CONFIG',
}

export interface PageConfigState {
  readonly config: PageConfig | undefined;
}
