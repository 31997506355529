import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classes from './Notification.module.scss';
import { RootState, ConnectedReduxProps } from '../../../store/types';
import { NotificationState } from '../../../store/notification/types';
import { connect } from 'react-redux';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { hideNotification } from '../../../store/notification/actions';

export interface NotificationOwnProps {
  notificationState: NotificationState;
}

type AllProps = NotificationOwnProps & ConnectedReduxProps;

class Notification extends React.Component<AllProps> {
  dismiss = (id: string) => {
    this.props.dispatch(hideNotification(id));
  };

  render() {
    return (
      <div className={classes.NotificationContainer}>
        <TransitionGroup component={null}>
          {this.props.notificationState.notifications.map(notification => (
            <CSSTransition
              key={notification.id}
              timeout={{ enter: 500, exit: 1000 }}
              classNames={{
                enter: classes.NotificationEnter,
                exit: classes.NotificationExit,
              }}
              unmountOnExit
            >
              <div role="alert" className={classes.Notification}>
                <div className={classes.Text}>{notification.text}</div>
                {notification.dismissable && (
                  <div onClick={() => this.dismiss(notification.id)}>
                    <Icon
                      className={classes.DismissIcon}
                      path={mdiClose}
                      size={1}
                    />
                  </div>
                )}
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    );
  }
}

const mapStateToProps = ({
  notificationState,
}: RootState): NotificationOwnProps => {
  return { notificationState };
};

export default connect(mapStateToProps)(Notification);
