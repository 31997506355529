import { useEffect, useState } from 'react';
import { DropEvent, FileRejection } from 'react-dropzone';

import { appInsights } from '@/appInsights/appInsights';
import { sendMessageBack } from '../../../../utils/directLineUtils';
import { findCustomActivity } from '../../../../middleware/activityMiddleware';
import {
  FILE_UPLOAD_EVENT_NAME,
  FILE_UPLOAD_RESULT_KEY_NAME,
  extendRejectedFile,
  abortController,
  resetAbortController,
  extendAcceptedFile,
} from './fileUploadHelper';
import { uploadFiles } from './fileUploadMethods';
import { canBeEditedCustomUserResponse } from '../../../../utils';
import FileUploadDialog from './FileUploadDialog/FileUploadDialog';
import FileUploadDropZone from './FileUploadDropZone/FileUploadDropZone';
import AcceptedFilesTranscript from './AcceptedFilesTranscript';
import {
  RejectedFile,
  FileUploadConfig,
  AcceptedFile,
  FileUploadState,
  UploadedFile,
} from './types';
import {
  AppInsightsEventNames,
  AppStatus,
  CustomEvent,
  NodeType,
} from '../../../../constants';

import classes from './FileUploadActivity.module.scss';
import { BotPassThroughProps } from '../../../../containers/BotMessageBase/BotMessageBase';
import { sendStatus } from '@/middleware/helpers/mobileAppHelper';
import { removeLastChatStatus } from '@/store/chat-activities-config/actions';
import { useDispatch } from 'react-redux';
import { emitCustomEvent } from 'react-custom-events';
import useEmitOverlayOpened from '@/hooks/useEmitOverlayOpened';

const UPLOAD_DELAY = 400;

const FileUploadActivity = (props: BotPassThroughProps): JSX.Element => {
  const { card, hideChildrenOfElement } = props;
  const dispatch = useDispatch();

  const [showDropZone, setShowDropZone] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [failedUpload, setFailedUpload] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);
  const [acceptedFiles, setAcceptedFiles] = useState<AcceptedFile[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<RejectedFile[]>([]);
  const [config] = useState<FileUploadConfig>(
    findCustomActivity(card.activity) as FileUploadConfig
  );

  useEffect(() => {
    if (
      acceptedFiles.some(
          (acceptedFile) => acceptedFile.state === FileUploadState.FAILED
      )
    ) {
      setFailedUpload(true);
    }
  }, [acceptedFiles]);

  useEffect(() => {
    if (!uploadInProgress && !failedUpload
      && uploadedFiles.length > 0 && allFilesUploaded()
    )
      submitAnswer();
  }, [uploadInProgress, failedUpload, uploadedFiles]);

  const submitAnswer = () => {
    sendMessageBack(
      FILE_UPLOAD_EVENT_NAME,
      {
        customEntity: findCustomActivity(card.activity),
        [FILE_UPLOAD_RESULT_KEY_NAME]: uploadedFiles,
        canBeEdited: canBeEditedCustomUserResponse(card),
      },
      card.activity
    );

    setTimeout(() => {
      setShowModal(false);
      hideChildrenOfElement();
      emitCustomEvent(CustomEvent.OverlayClosed);
      dispatch(removeLastChatStatus());
    }, UPLOAD_DELAY);
  };

  const attemptFileUpload = async () => {
    await uploadFiles({
      setFailedUpload,
      setUploadInProgress,
      filesToUpload: acceptedFiles.filter(
        (acceptedFile) => acceptedFile.state !== FileUploadState.DONE
      ),
      abortController,
      conversationId: card.activity.conversation?.id ?? '',
      setFileUploadState,
      setUploadedFiles,
    });
  };

  const allFilesUploaded=() => {
    return  acceptedFiles.every(
      (acceptedFile) => {
        return acceptedFile.state === FileUploadState.DONE;  });
      };

  const cancelUploadCleanup = () => {
    abortController.abort();
    resetAbortController();

    if (showModal) {
      setShowModal(false);
      emitCustomEvent(CustomEvent.OverlayClosed);
      dispatch(removeLastChatStatus());
    }

    if (!showDropZone) {
      setShowDropZone(true);
    }

    if (uploadInProgress) {
      setUploadInProgress(false);
    }

    resetFilesToUpload();

    if (failedUpload) {
      setFailedUpload(false);
    }
  };

  const resetFilesToUpload = () => {
    if (acceptedFiles.length > 0) {
      setAcceptedFiles([]);
    }

    if (uploadedFiles.length > 0) {
      setUploadedFiles([]);
    }

    clearRejectedFiles();
  };

  const clearRejectedFiles = () => {
    if (rejectedFiles.length > 0) {
      setRejectedFiles([]);
    }
  };

  const emitOverlayOpened = useEmitOverlayOpened();

  const openModal = () => {
    if (showDropZone) {
      setShowDropZone(false);
    }
    if (!showModal) {
      setShowModal(true);
    }

    emitOverlayOpened(() => {
      setShowModal(false);
      setShowDropZone(true);
    });
    sendStatus(NodeType.FileUpload, AppStatus.OverlayOpened);
  };

  const handleOnAccepted = async (files: File[]) => {
    if (files.length === 0) {
      return;
    }

    openModal();

    const fileCount = files.length;
    const currentFileCount = acceptedFiles.length;
    const fileLimit = config.maxFileCount;
    let failedFiles: File[];

    if (fileCount + currentFileCount > fileLimit) {
      const spliceStart =
        currentFileCount === fileLimit
          ? 0
          : currentFileCount < fileLimit
          ? fileLimit - currentFileCount
          : fileLimit;
      const spliceCount =
        fileCount > fileLimit
          ? fileCount + currentFileCount - fileLimit
          : fileCount;

      failedFiles = files.splice(spliceStart, spliceCount);
      const fileRejections = failedFiles.map((file) => {
        return { file: file, errors: [] } as FileRejection;
      });

      if (fileRejections.length > 0) {
        const message = `${config.fileCountErrorMessage} ${config.maxFileCount}`;
        handleOnRejected(fileRejections, null, message);
      }
    }

    const newFiles = await Promise.all(
      files.map((file) => extendAcceptedFile(file))
    );
    setAcceptedFiles((currentFiles) => [...currentFiles, ...newFiles]);
  };

  const handleOnRejected = (
    fileRejections: FileRejection[],
    _dropEvent: DropEvent | null,
    customRejectReason?: string
  ) => {
    if (fileRejections.length === 0) {
      return;
    }

    openModal();

    const newlyRejectedFiles = fileRejections.map((fileRejection) =>
      extendRejectedFile(fileRejection.file, config, customRejectReason)
    );

    appInsights.trackEvent(
      { name: AppInsightsEventNames.FilesWereRejected },
      { rejectedFiles: newlyRejectedFiles }
    );
    setRejectedFiles((currentFiles) => [
      ...currentFiles,
      ...newlyRejectedFiles,
    ]);
  };

  const setFileUploadState = (file: AcceptedFile, state: FileUploadState) => {
    setAcceptedFiles((files) => {
      const newFiles = [...files];
      const newFile = newFiles.find((f) => file.id === f.id);
      if (newFile) {
        newFile.state = state;
      }
      return newFiles;
    });
  };

  const removeFileFromList = (fileIndex: number) => {
    const newFileList = [...acceptedFiles];
    newFileList.splice(fileIndex, 1);
    setAcceptedFiles(newFileList);
  };

  return (
    <div className={classes.FileUploadActivity}>
      <FileUploadDialog
        config={config}
        showModal={showModal}
        acceptedFiles={acceptedFiles}
        handleOnAccepted={handleOnAccepted}
        rejectedFiles={rejectedFiles}
        handleOnRejected={handleOnRejected}
        attemptFileUpload={attemptFileUpload}
        uploadInProgress={uploadInProgress}
        setUploadInProgress={setUploadInProgress}
        removeFileFromList={removeFileFromList}
        setFileUploadState={setFileUploadState}
        clearRejectedFiles={clearRejectedFiles}
        failedUpload={failedUpload}
        cancelUploadCleanup={cancelUploadCleanup}
      />

      {showDropZone ? (
        <FileUploadDropZone
          dropZoneContainerText={config.dropZoneContainerText}
          browseFilesButtonText={config.browseFilesButtonText}
          handleOnAccepted={handleOnAccepted}
          handleOnRejected={handleOnRejected}
          dropZoneClassName={classes.DropZone}
          buttonClassName={classes.DropZoneButton}
          allowedFileExtensions={config.allowedFileExtensions}
          maxFileSizeInBytes={config.maxFileSizeInBytes}
          uploadInProgress={uploadInProgress}
        />
      ) : null}
      <AcceptedFilesTranscript acceptedFiles={acceptedFiles} />
    </div>
  );
};

export default FileUploadActivity;
