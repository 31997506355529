import { FunctionComponent, useEffect, useRef } from 'react';
import { AcceptedFile } from './types';
import { usePrevious } from '../../../../utils/customHooks';
import { translate, Translations } from '../../../../locale';
import classes from './AcceptedFilesTranscript.module.scss';
import { AriaAtomic, AriaLive, AriaRelevant } from '../../../../constants/aria';

interface AcceptedFilesTranscriptProps {
  acceptedFiles: AcceptedFile[];
}

const ARTICLE_DISAPPEAR_INTERVAL_MILLISEDONDS = 3000;

export const AcceptedFilesTranscript: FunctionComponent<AcceptedFilesTranscriptProps> =
  (props: AcceptedFilesTranscriptProps) => {
    const { acceptedFiles } = props;
    const sectionRef = useRef<HTMLElement | null>(null);

    const previousAcceptedFiles = usePrevious<AcceptedFile[]>(acceptedFiles);

    const articleRefs: HTMLElement[] = [];

    useEffect(() => {
      if (!acceptedFiles || acceptedFiles.length === 0) {
        return;
      }

      const newFiles = acceptedFiles.filter(
        (acceptedFile) => !previousAcceptedFiles?.includes(acceptedFile)
      );

      newFiles.forEach((newFile) => {
        const fileIsAddedLabel = translate(
          Translations.fileUpload.fileIsAddedToListScreenReader,
          null,
          [newFile.name]
        );

        const element = document.createElement('article');
        element.setAttribute('aria-atomic', AriaAtomic.TRUE);
        element.setAttribute('aria-roledescription', 'message');
        element.setAttribute('role', 'region');

        element.textContent = fileIsAddedLabel;
        articleRefs.push(element);

        sectionRef.current?.appendChild(element);
      });

      setTimeout(() => {
        articleRefs.forEach((articleRef) => {
          sectionRef.current?.removeChild(articleRef);
        });
      }, ARTICLE_DISAPPEAR_INTERVAL_MILLISEDONDS);
    }, [acceptedFiles, articleRefs, previousAcceptedFiles]);

    return (
      <section
        className={classes.AcceptedFilesTranscript}
        aria-atomic={AriaAtomic.FALSE}
        aria-live={AriaLive.POLITE}
        aria-relevant={AriaRelevant.ADDITIONS}
        role="log"
        ref={sectionRef}
      />
    );
  };

export default AcceptedFilesTranscript;
