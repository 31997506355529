import { ConfigurableTheme } from '../../styles/types';

export default {
  colors: {
    error: '#C82E2D',
    errorBackground: '#FCE8E8',
    errorDark: '#9f2322',

    background: '#ffffff',
    backgroundSecondary: '#ffffff',
    headerBackground: '#ffffff',
    headerText: '#000000',
    menuArrow: '#000000',
    modalBackdrop: 'rgba(0, 0, 0, 0.4)',

    secondary: '#000000',
    secondaryLight: 'rgba(0, 0, 0, 0.7)',
    secondarySupportLight: '#ffffff',
    secondarySupport: '#8a8a8a',

    action: '#0054F0',
    actionDark: '#003cc9',
    actionLight: '#007bff',
    actionLightest: 'rgba(0, 86, 179, 0.8)',

    actionSecondary: '#ffffff',
    actionSecondaryDark: '#fafafa',
    actionSecondaryDarker: '#f5f5f5',

    actionText: '#ffffff',
    actionTextDark: 'rgba(0, 0, 0, 0.8)',
    actionTextLight: 'rgba(0, 0, 0, 0.5)',

    focusOutline: '#00a7ff',

    inputBackground: '#ffffff',
    inputPlaceholderFocus: 'rgba(0, 0, 0, 0.2)',
    inputSupport: 'rgba(0, 0, 0, 0.2)',

    botMessageBackground: 'rgba(0, 0, 0, 0.07)',
    userMessageBackground: '#D6D6D6',
    messageText: '#000000',

    botActionBackground: '#ffffff',
    botActionText: '#007AB8',
    botActionBackgroundHover: '#fafafa',
    botActionBackgroundActive: '#fafafa',

    calendarItemHover: '#ffffff',
    calendarItemBackgroundHover: '#00a7ff',
    calendarItemCurrent: '#006ca2',
    calendarItemSelected: '#ffffff',
    calendarItemSelectedBackground: '#006ca2',
    calendarItemOutside: 'rgba(0, 0, 0, 0.2)',
    calendarSecondaryItem: '#616161',

    borderDark: 'rgba(0, 0, 0, .6)',
    borderMedium: 'rgba(0, 0, 0, .4)',
    borderLight: 'rgba(0, 0, 0, .18)',
  },
  fonts: {
    fontStack: '"default-font"',
  },
} as ConfigurableTheme;
