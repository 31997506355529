// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonGroup-module_ButtonGroup--KASrE{display:flex;flex-direction:column}.ButtonGroup-module_ButtonGroup--KASrE .ButtonGroup-module_Loader--uG2xB{width:100%;z-index:101}.ButtonGroup-module_ButtonGroup--KASrE .ButtonGroup-module_Button--p_o3w{white-space:normal !important;word-wrap:break-word !important;height:fit-content !important;max-width:100%}", "",{"version":3,"sources":["webpack://./src/components/ButtonGroup/ButtonGroup.module.scss"],"names":[],"mappings":"AAAA,uCACI,YAAA,CACA,qBAAA,CAEA,yEACI,UAAA,CACA,WAAA,CAGJ,yEACI,6BAAA,CACA,+BAAA,CACA,6BAAA,CACA,cAAA","sourcesContent":[".ButtonGroup {\r\n    display: flex;\r\n    flex-direction: column;\r\n\r\n    .Loader {\r\n        width: 100%;\r\n        z-index: 101;\r\n    }\r\n\r\n    .Button {\r\n        white-space: normal !important;\r\n        word-wrap: break-word !important;\r\n        height: fit-content !important;\r\n        max-width: 100%;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ButtonGroup": "ButtonGroup-module_ButtonGroup--KASrE",
	"Loader": "ButtonGroup-module_Loader--uG2xB",
	"Button": "ButtonGroup-module_Button--p_o3w"
};
export default ___CSS_LOADER_EXPORT___;
