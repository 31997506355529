import { callApi } from '../callApi';

import {
  ApiResponse,
  Endpoints,
  RequestMethod,
  GetPrefillDataRequest,
} from '../types';

export const getPrefillData = ({
  body,
  abortController,
}: {
  body: GetPrefillDataRequest;
  abortController?: AbortController;
}): Promise<ApiResponse<Record<string, string> | null>> => {
  return callApi({
    url: Endpoints.GET_PREFILL_DATA,
    method: RequestMethod.POST,
    body,
    abortController,
  });
};
