import { useEffect, useState } from 'react';

import classes from './DateInput.module.scss';

import { translate, Translations } from '../../../../locale';
import CalendarPicker from './CalendarPicker/CalendarPicker';
import Moment from 'moment';

import { canBeEditedCustomUserResponse } from '../../../../utils';
import { sendMessageBack } from '../../../../utils/directLineUtils';
import { Message } from 'botframework-directlinejs';
import { DateInputActivityConfig, ManualInputState } from './types';
import { DateInputButtons } from './DateInputButtons/DateInputButtons';
import { DateInputBox } from './DateInputBox/DateInputBox';
import { getDateProps, sendManualValue, setupDateObj } from './dateInputHelper';
import { MessageTypes } from '../../../../middleware/types';
import { findCustomActivity } from '../../../../middleware/activityMiddleware';
import { RemoveScroll } from 'react-remove-scroll';
import { trackDateComponentInteracted } from '../../../../utils/appInsightHelper';
import {
  AppInsightsUserInteraction,
  AppStatus,
  CustomEvent,
  NodeType,
} from '../../../../constants';
import ClickOutside, {
  ClickOutsidePassThroughProps,
} from '../../../../containers/ClickOutside/ClickOutside';
import Alert from '../../../UI/Alert/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store/types';
import { BotPassThroughProps } from '../../../../containers/BotMessageBase/BotMessageBase';

import Modal from '@/components/UI/Modal/Modal';

import ButtonPrimary from '@/components/Buttons/ButtonPrimary/ButtonPrimary';
import ButtonSecondary from '@/components/Buttons/ButtonSecondary/ButtonSecondary';
import { sendStatus } from '@/middleware/helpers/mobileAppHelper';
import { removeLastChatStatus } from '@/store/chat-activities-config/actions';
import { setHasAlert } from '@/store/global-config/actions';
import { emitCustomEvent } from 'react-custom-events';
import useEmitOverlayOpened from '@/hooks/useEmitOverlayOpened';

export type DateInputProps = BotPassThroughProps & ClickOutsidePassThroughProps;

export const DateInput = (props: any): JSX.Element => {
  const dispatch = useDispatch();

  const config = findCustomActivity(
    props.card.activity
  ) as DateInputActivityConfig;
  const dateProps = getDateProps(config);

  const hasAlert = useSelector<RootState, boolean>(
    (state) => state.globalConfigState.hasAlert
  );

  const [manualInput, setManualInput] = useState<ManualInputState>({
    isError: false,
    isValidDate: false,
    dateValue: setupDateObj(getDateProps(config)),
  });
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isYearPickerOpen, setIsYearPickerOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Moment.Moment | null>(null);

  const emitOverlayOpened = useEmitOverlayOpened();

  useEffect(() => {
    if (isModalVisible) {
      trackDateComponentInteracted(
        config.id,
        AppInsightsUserInteraction.DateModalOpened
      );
      emitOverlayOpened(() => setIsModalVisible(false));
      sendStatus(NodeType.DateInput, AppStatus.OverlayOpened);
    }
  }, [isModalVisible, config.id]);

  useEffect(() => {
    const dateForAppInsights = selectedDate?.format(dateProps.dateFormat);

    trackDateComponentInteracted(
      config.id,
      AppInsightsUserInteraction.DateModalSelected,
      dateForAppInsights
    );
  }, [selectedDate, config.id, dateProps.dateFormat]);

  useEffect(() => {
    dispatch(setHasAlert(manualInput.isError));
  }, [dispatch, manualInput.isError]);

  const onAcceptDateHandler = () => {
    if (!dateProps || !selectedDate) {
      return;
    }

    const { card } = props;

    setIsModalVisible(false);
    const dateFormatted = selectedDate.format(dateProps.dateFormat);

    sendMessageBack(
      dateFormatted,
      {
        canBeEdited: canBeEditedCustomUserResponse(card),
      },
      card.activity
    );

    trackDateComponentInteracted(
      config.id,
      AppInsightsUserInteraction.DateModalConfirmed,
      dateFormatted
    );

    props.hideChildrenOfElement();
    emitCustomEvent(CustomEvent.OverlayClosed);
    dispatch(removeLastChatStatus());
  };

  const onDateChangeHandler = (date: Moment.Moment | null) => {
    if (!date) {
      return;
    }
    setSelectedDate(date);
  };

  const sendValue = () => {
    sendManualValue(
      props.card,
      dateProps,
      manualInput.dateValue,
      config.id,
      props.hideChildrenOfElement
    );
  };

  const modalBody = (
    <RemoveScroll>
      <CalendarPicker
        isYearPickerOpen={isYearPickerOpen}
        allowedPastDate={
          config.allowedPastDate ? Moment(config.allowedPastDate) : null
        }
        allowedFutureDate={
          config.allowedFutureDate ? Moment(config.allowedFutureDate) : null
        }
        setIsYearPickerOpen={setIsYearPickerOpen}
        onDateChangeHandler={onDateChangeHandler}
        onAcceptHandler={onAcceptDateHandler}
      />
    </RemoveScroll>
  );

  const modalFooter = !isYearPickerOpen ? (
    <div className={classes.ButtonBox}>
      <ButtonSecondary
        onClick={() => {
          setIsModalVisible(false);
          emitCustomEvent(CustomEvent.OverlayClosed);
          dispatch(removeLastChatStatus());
        }}
      >
        {translate(Translations.cancel)}
      </ButtonSecondary>
      <ButtonPrimary disabled={!selectedDate} onClick={onAcceptDateHandler}>
        {config.confirmButton}
      </ButtonPrimary>
    </div>
  ) : undefined;

  return (
    <div className={classes.DateInputWrapper}>
      <div
        className={classes.DateInput}
        data-custom-activity="true"
        data-message-type={MessageTypes.inputDate}
      >
        <ClickOutside
          withTab
          initialState={false}
          passThroughProps={null}
          render={(passThroughProps: ClickOutsidePassThroughProps) => {
            return (
              <DateInputBox
                card={props.card}
                config={config}
                dateProps={dateProps}
                dateValue={manualInput.dateValue}
                hideElement={props.hideChildrenOfElement}
                isError={manualInput.isError}
                isValidDate={manualInput.isValidDate}
                setManualState={setManualInput}
                {...passThroughProps}
              />
            );
          }}
        />

        <DateInputButtons
          dateValue={manualInput.dateValue}
          hideElement={props.hideChildrenOfElement}
          isValidDate={manualInput.isValidDate}
          sendValue={sendValue}
          setIsModalVisible={setIsModalVisible}
        />

        <Modal
          modalVisible={isModalVisible}
          title={(props.card.activity as Message).text}
          shouldCloseOnEscape={true}
          closeHandler={() => {
            setIsModalVisible(false);
            emitCustomEvent(CustomEvent.OverlayClosed);
            dispatch(removeLastChatStatus());
          }}
          modalBody={modalBody}
          modalFooter={modalFooter}
        />
      </div>
      {hasAlert ? (
        <Alert
          stickToRightSideOnDesktop
          message={
            manualInput.errorMessage ??
            translate(Translations.dateInputAlerts.enterValidDate)
          }
        />
      ) : null}
    </div>
  );
};

export default DateInput;
