// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonSecondary-module_ButtonSecondary--S7U7p{font-family:var(--font-stack) !important;color:var(--color-action) !important;border-color:currentColor !important}.ButtonSecondary-module_ButtonSecondary--S7U7p:hover{box-shadow:0 0 0 .0625rem currentColor !important}", "",{"version":3,"sources":["webpack://./src/components/Buttons/ButtonSecondary/ButtonSecondary.module.scss"],"names":[],"mappings":"AAEA,+CACE,wCAAA,CACA,oCAAA,CACA,oCAAA,CAEA,qDACE,iDAAA","sourcesContent":["@import \"~styles/colors\";\r\n\r\n.ButtonSecondary {\r\n  font-family: v(font-stack) !important;\r\n  color: v(color-action) !important;\r\n  border-color: currentColor !important;\r\n\r\n  &:hover {\r\n    box-shadow: 0 0 0 0.0625rem currentColor !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ButtonSecondary": "ButtonSecondary-module_ButtonSecondary--S7U7p"
};
export default ___CSS_LOADER_EXPORT___;
