import { forwardRef } from 'react';

import classes from './ButtonSecondary.module.scss';

import {
  ButtonSecondary as IdsButtonSecondary,
  ButtonProps,
} from '@ids-react/button';

const ButtonSecondary: React.ForwardRefExoticComponent<
  ButtonProps & React.RefAttributes<HTMLButtonElement>
> = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  return (
    <IdsButtonSecondary
      className={classes.ButtonSecondary}
      ref={ref}
      {...props}
    />
  );
});

export default ButtonSecondary;
