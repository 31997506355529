import { FunctionComponent, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Activity, Message } from 'botframework-directlinejs';
import { hooks } from 'botframework-webchat-component';

const { useActivities } = hooks;

import { chatStore } from '@/containers/Chat/initialization/chatStore';

import classes from './UserActivity.module.scss';

import { PointOfNoReturnInfo } from '@/store/bot-config/types';
import {
  ActionCommandType,
  Card,
  ChatCommands,
  CustomUserResponse,
} from '@/middleware/types';
import { RootState } from '@/store/types';
import { postEvent, FeatureToggles, getToggle } from '@/utils';
import { deleteActivity, setEditActivityIds } from '@/store/bot-config/actions';
import { appendActivityIdsToHide } from '@/store/conversation/actions';

import EditButton from './EditButton';
import { useCreateActivityStatusRenderer } from 'botframework-webchat-api/lib/hooks';
import { WebChatActivity } from 'botframework-webchat-core';

interface UserActivityProps {
  children: ReactNode;
  card: Card;
}

const isLastUserActivity = (
  pointOfNoReturnInfo: PointOfNoReturnInfo[],
  activityId: string
) => {
  return (
    pointOfNoReturnInfo.length > 0 &&
    pointOfNoReturnInfo[pointOfNoReturnInfo.length - 1].userActivityId ===
      activityId
  );
};

const isLastEditableActivity = (
  pointOfNoReturnInfo: PointOfNoReturnInfo[],
  activityId: string
) => {
  const lastEditablePoint = [...pointOfNoReturnInfo]
    .reverse()
    .find((point) => point.canBeEdited && !point.postActivityRejected);

  return !!lastEditablePoint && lastEditablePoint.userActivityId === activityId;
};

const isActivityEditable = (
  pointOfNoReturnInfo: PointOfNoReturnInfo[],
  activityId: string
) => {
  const activityPointOfNoReturnInfo = pointOfNoReturnInfo.filter(
    (pointOfNoReturnForActivity) =>
      pointOfNoReturnForActivity.userActivityId === activityId
  )[0];

  return (
    activityPointOfNoReturnInfo &&
    activityPointOfNoReturnInfo.canBeEdited &&
    !activityPointOfNoReturnInfo.postActivityRejected
  );
};

const useDrawEditButton = (
  card: Card,
  pointOfNoReturnInfo: PointOfNoReturnInfo[]
): boolean => {
  const activityFromCard = card.activity as Message;
  const activityId = activityFromCard.id as string;

  const customUserResponse = activityFromCard.value as CustomUserResponse;
  const canBeEdited = customUserResponse?.canBeEdited as boolean;

  if (getToggle(FeatureToggles.removeUsageOfCanBeEditedFlag)) {
    return (
      canBeEdited && isLastEditableActivity(pointOfNoReturnInfo, activityId)
    );
  } else {
    return (
      canBeEdited &&
      isLastUserActivity(pointOfNoReturnInfo, activityId) &&
      isActivityEditable(pointOfNoReturnInfo, activityId)
    );
  }
};

const UserActivity: FunctionComponent<UserActivityProps> = ({
  card,
  children,
}: UserActivityProps) => {
  const pointOfNoReturnInfo = useSelector<RootState, PointOfNoReturnInfo[]>(
    (state) => state.botConfigState.pointOfNoReturnInfo
  );

  const actionColor = useSelector<RootState, string | undefined>(
    (state) => state.themeState.theme?.colors.action
  );

  //Same as with on TODO for botframework webchat hooks
  /* eslint-disable @typescript-eslint/no-unsafe-call */
  /* eslint-disable @typescript-eslint/no-unsafe-member-access */
  const activities = useActivities()[0] as Activity[];
  /* eslint-enable @typescript-eslint/no-unsafe-call */
  /* eslint-enable @typescript-eslint/no-unsafe-member-access */

  const isPostActivityFulfilled = useSelector<RootState, boolean>(
    (state) => state.botConfigState.isPostActivityFulfilled
  );

  const dispatch = useDispatch();
  const statusRenderer = useCreateActivityStatusRenderer();

  const activityFromCard = card.activity as Message;
  const activityId = activityFromCard.id as string;

  const drawButton = useDrawEditButton(card, pointOfNoReturnInfo);

  const onClick = () => {
    const activityIds = activities.map((activity) => activity.id) as string[];
    const indexOfCurrentActivityId = activityIds.indexOf(activityId);

    const activityIdsToHide = activityIds.slice(
      indexOfCurrentActivityId - 1,
      activityIds.length
    );

    if (activityIdsToHide.length > 0) {
      postEvent({
        command: ChatCommands.Edit,
        commandType: ActionCommandType.EDIT,
      });

      activityIdsToHide.forEach((id) => {
        chatStore?.dispatch(deleteActivity(id));
      });

      dispatch(setEditActivityIds(activityIdsToHide));
      dispatch(appendActivityIdsToHide(activityIdsToHide));
    }
  };

  const statusRenderOptions = {
    activity: card.activity as WebChatActivity,
    nextVisibleActivity: {} as WebChatActivity,
  };

  const status = getToggle(FeatureToggles.enableStateFix) ? (
    statusRenderer(statusRenderOptions)({ hideTimestamp: true })
  ) : (
    <></>
  );

  return (
    <div className={classes.UserMessageWrapper}>
      <div className={classes.UserMessage}>
        <div className={classes.UserMessageContent}>
          <div>{status}</div>
          {children}
        </div>

        {drawButton && (
          <EditButton
            onClick={onClick}
            actionColor={actionColor}
            disabled={!isPostActivityFulfilled}
          />
        )}
      </div>
    </div>
  );
};

export default UserActivity;
