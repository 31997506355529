import React, { useEffect, useState } from 'react';
import { sendMessageBack } from '../../../../utils/directLineUtils';
import { canBeEditedCustomUserResponse } from '../../../../utils';
import classes from './CurrencyInputComponent.module.scss';
import { Card, CustomActivityConfig } from '../../../../middleware/types';
import { mdiSend } from '@mdi/js';
import Icon from '@mdi/react';
import { findCustomActivity } from '../../../../middleware/activityMiddleware';
import CurrencyInput from './CurrencyInput';
import { KeyCodes } from '../../../../constants';
import { BotPassThroughProps } from '../../../../containers/BotMessageBase/BotMessageBase';
import ClickOutsideTyped, {
  ClickOutsidePassThroughProps,
} from '../../../../containers/ClickOutside/ClickOutside';

import { translate, Translations } from '@/locale';

import RoundButton from '@/components/RoundButton/RoundButton';

export interface CurrencyInputConfig extends CustomActivityConfig {
  placeholder: string;
  defaultCurrency: string;
}

export const CURRENCY_VALIDATION_RULE_1 = /^(?:\d+(([\s'])?)(?![,.]))*$/;
export const CURRENCY_VALIDATION_RULE_2 =
  /^(?:\d+(([\s'])?)(?![,.])|(\d+(?=[,.])))*([,.]?)$/;
export const CURRENCY_VALIDATION_RULE_3 =
  /^(?:\d+(([\s'])?)(?![,.])|(\d+(?=[,.])))*(?:[,.]{1}\d{1,2})?$/;

const COMMA_DIGIT_RULE = /[,.]\d/;
const COMMA_RULE = /[,.]/;

export const cleanInput = (input: string): string => {
  let retVal = input;
  const lastChar = input.charAt(input.length);

  if (!/\d/.exec(lastChar)) {
    const lastCharInverted = input
      .split('')
      .reverse()
      .findIndex((char) => /\d/.exec(char));
    const lastCharIndex = input.length - lastCharInverted;
    retVal = retVal.slice(0, lastCharIndex);
  }
  return retVal;
};

const sendValue = (
  hideElement: () => void,
  inputValue: string,
  setInputValue: (value: string) => void,
  card: Card
): void => {
  hideElement();

  sendMessageBack(
    cleanInput(inputValue),
    {
      canBeEdited: canBeEditedCustomUserResponse(card),
    },
    card.activity
  );

  setInputValue('');
};

export const validateInput = (inputValue: string): boolean => {
  let testResult = false;

  if (RegExp(COMMA_DIGIT_RULE).test(inputValue)) {
    testResult = RegExp(CURRENCY_VALIDATION_RULE_3).test(inputValue);
  } else if (RegExp(COMMA_RULE).test(inputValue)) {
    testResult = RegExp(CURRENCY_VALIDATION_RULE_2).test(inputValue);
  } else {
    testResult = RegExp(CURRENCY_VALIDATION_RULE_1).test(inputValue);
  }

  return testResult;
};

/**
 * @param props {BotMessageBaseProps}
 */
export default function CurrencyInputComponent(
  props: BotPassThroughProps
): JSX.Element {
  const { card, hideChildrenOfElement } = props;

  const currencyInputConfig = findCustomActivity(
    card.activity
  ) as CurrencyInputConfig;

  const [inputValue, setInputValue] = useState(
    (currencyInputConfig && currencyInputConfig.prefillText) || ''
  );

  const [submitEnabled, setSubmitEnabled] = useState(false);

  const currencyLabel = currencyInputConfig.defaultCurrency;

  useEffect(() => {
    setSubmitEnabled(inputValue.length > 0);
  }, [inputValue]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const isInputValid = validateInput(newValue) || newValue.length === 0;

    if (isInputValid) {
      setInputValue(newValue);
    }
  };

  const keyPressHandler = (event: React.KeyboardEvent) => {
    if (event.key === KeyCodes.Enter && submitEnabled) {
      sendValue(hideChildrenOfElement, inputValue, setInputValue, card);
    }
  };

  return (
    <div
      className={classes.CurrencyInputComponent}
      data-custom-activity="true"
      data-message-type={currencyInputConfig.messageType}
    >
      <ClickOutsideTyped
        withTab={true}
        initialState={false}
        passThroughProps={props}
        render={(
          currencyInputProps: BotPassThroughProps & ClickOutsidePassThroughProps
        ) => (
          <CurrencyInput
            clickedOutside={currencyInputProps.clickedOutside}
            inputValue={inputValue}
            currencyLabel={currencyLabel}
            inputPlaceholder={
              (currencyInputConfig && currencyInputConfig.placeholder) || ''
            }
            onChangeHandler={onChangeHandler}
            keyPressHandler={keyPressHandler}
          />
        )}
      />

      <RoundButton
        disabled={!submitEnabled}
        onClick={() =>
          sendValue(hideChildrenOfElement, inputValue, setInputValue, card)
        }
        ariaLabel={translate(Translations.send)}
      >
        <Icon path={mdiSend} size={1} />
      </RoundButton>
    </div>
  );
}
