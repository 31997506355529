export enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export enum ResponseStatus {
  ABORTED = 20,
  OK = 200,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409,
  GONE = 410,
  SERVER_ERROR = 500,
  TIMEOUT = 504,
}

export enum ResponseType {
  ARRAY_BUFFER = 'arraybuffer',
  BLOB = 'blob',
  DOCUMENT = 'document',
  JSON = 'json',
  TEXT = 'text',
  STREAM = 'stream',
}

export interface ApiResponse<T> {
  status: ResponseStatus;
  data: T | null;
  error?: string;
}

export interface ApiResponse<T> {
  status: ResponseStatus;
  data: T | null;
  error?: string;
}

export enum Endpoints {
  UPLOAD_FILE = 'claim/UploadFile',
  GET_CLIENT_SIDE_CONFIGURATION = '/claim/GetClientSideConfiguration',
  DOWNLOAD_PDF = 'claim/DownloadPdf',

  GET_PREFILL_DATA = 'prefill/get-prefill-data',

  TENANTS = 'tenants',

  BUG_REPORT = 'bug-report',

  GENERATE_DIRECTLINE_TOKEN = 'directline/generate-token',
  GET_RECONNECT_TOKEN = 'directline/get-reconnect-token',
  POST_AUTH_EVENT = 'directline/post-auth-event',
  POST_SILENT_AUTH_EVENT = 'directline/post-silent-auth-event',
  BOT_CONFIG = 'directline/bot-config',

  SIGN_OUT = 'auth/sign-out-url',
  //deprecated, will be removed when Bff auth is enabled in prod
  GET_AUTH_NONCE_DATA = 'auth/GetAuthNonceData',
  CHECK_AUTH_SESSION = 'auth/IsSessionActive',
  CUSTOM_CODE = 'auth/custom-code',
}

/**
 * UploadFile API response object
 */
export interface UploadFileResponse {
  filePath: string;
}

export interface BugReportRequest {
  conversationId: string | null;
  tenantId: string | null;
  isStarted: string;
  local?: string;
  brand?: string;
  isAuthenticated: string;
  url: string;
  text?: string;
}

export interface BugReportResponse {
  isSuccess: boolean;
}

//GetClientSideConfiguration API response object
export interface ClientSideConfiguration {
  featureFlags: Record<string, string>;
}

//PostAuthEvent and PostSilentAuth API response object
export interface UserInformation {
  isAuthenticated: boolean;
  givenName: string;
  surname: string;
  authSessionHash: string;
}

export interface PostAuthEventRequest {
  conversationId: string;
}

export interface GetPrefillDataRequest {
  token: string | null;
}

export interface DirectLineConfiguration {
  conversationId: string;
  token: string;
  streamUrl: string;
  domain: string;
  webSocket: boolean;
}

export interface Tenant {
  tenantId: string;
  country?: string;
  businessArea?: string;
  lineOfBusiness?: string;
  locale?: string;
  isInProduction?: boolean;
}
