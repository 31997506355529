import { FunctionComponent } from 'react';

import classes from './ValidationResult.module.scss';
import classNames from 'classnames';

export interface ValidationResultProps {
  validationMessage: string;
}

const ValidationResult: FunctionComponent<ValidationResultProps> = ({
  validationMessage,
}: ValidationResultProps) => {
  return (
    <div
      className={classNames(classes.ValidationResult, classes.ValidationError)}
    >
      <div className={classes.ValidationResultStatusIcon}></div>
      <span>{validationMessage}</span>
    </div>
  );
};

export default ValidationResult;
