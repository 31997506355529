import qs from 'query-string';
import {KnownSearchParams, PageConfig} from '../store/page-config/types';
import tenantMapping from '../mapping/linkToTenantMapping';
import {configuredStore} from '@/store';
import {setPageConfig} from '../store/page-config/actions';
import {AppInsightsEventNames, ChatRoutes, Environments} from '../constants';
import {TenantMapping} from '../mapping/types';

import {appInsights} from '@/appInsights/appInsights';
import getEnvironment from "@/utils/getEnvironment";

export const getPageConfig = (): PageConfig => {
  const { location } = window;
  const query = qs.parse(location.search);
  const queryTenantId = query[KnownSearchParams.TENANT_ID] as string;
  const pageConfig: PageConfig = { ...query };

  const tenantData = getTenantData(queryTenantId, location);

  if (!tenantData && !queryTenantId) {
    throw new Error(
      `Mandatory search parameter '${KnownSearchParams.TENANT_ID}' is missing in URL!`
    );
  }

  if (tenantData) {
    for (const prop in tenantData.pageConfig) {
      if (!(prop in pageConfig)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        pageConfig[prop] = tenantData.pageConfig[prop];
      }
    }
  }

  if (pageConfig.showPageTitle === undefined) {
    pageConfig.showPageTitle = true;
  }

  appInsights.trackEvent(
    {
      name: AppInsightsEventNames.ChatInitEvent,
    },
    {
      message: 'Page config',
      pageConfig,
    }
  );

  return pageConfig;
};

export const getTenantData = (
  queryTenantId: string,
  location: Location
): TenantMapping | undefined => {
  let tenantData = getEnvironment() == Environments.PROD ? tenantMapping.find(
    (mapping) => mapping.pageConfig.productionUrl === location.origin
  ) : tenantMapping.find(
    (mapping) => mapping.pageConfig.testUrl === location.origin
  );

  if (!tenantData) {
    tenantData = tenantMapping.find(
      (mapping) =>
        location.pathname.endsWith(mapping.path) &&
        mapping.path !== ChatRoutes.Chat
    );
  }

  if (!tenantData && queryTenantId) {
    tenantData = tenantMapping.find(
      (mapping) => mapping.pageConfig.tenantId === queryTenantId
    );
  }

  return tenantData;
};

export const storePageConfig = (pageConfig: PageConfig): void => {
  const { dispatch } = configuredStore.store;

  dispatch(setPageConfig(pageConfig));
};
