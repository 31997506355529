import {
  mdiFileOutline,
  mdiFilePdfBox,
  mdiFileWordOutline,
  mdiFileVideoOutline,
  mdiFileImageOutline,
  mdiFileDocumentOutline,
} from '@mdi/js';
import { generateId } from '../../../../utils/utils';
import {
  RejectedFile,
  AcceptedFile,
  FileUploadState,
  FileUploadConfig,
} from './types';
import { translate, Translations } from '../../../../locale';
import { makeThumbnail } from './makeThumbnail';
import { bytesToSize } from '../../../../utils/bytesToSize';

const UPLOAD_THUMBNAIL_MAX_WIDTH = 32;
const UPLOAD_THUMBNAIL_MAX_HEIGHT = 32;
const UPLOAD_THUMBNAIL_CONTENT_TYPE = 'image/png';
const UPLOAD_THUMBNAIL_QUALITY = 0.5;
export const FILE_UPLOAD_EVENT_NAME = 'fileUpload';
export const FILE_UPLOAD_RESULT_KEY_NAME = 'files';

export let abortController: AbortController = new AbortController();

export const resetAbortController = (): void => {
  if (abortController.signal.aborted) {
    abortController = new AbortController();
  }
};

export const extendAcceptedFile = async (
  file: File
): Promise<AcceptedFile> => ({
  name: file.name,
  size: file.size,
  lastModified: file.lastModified,
  type: file.type,
  id: generateId(),
  data: file,
  state: FileUploadState.ADDED,
  thumbnail: await makeThumbnail(
    file,
    UPLOAD_THUMBNAIL_MAX_WIDTH,
    UPLOAD_THUMBNAIL_MAX_HEIGHT,
    UPLOAD_THUMBNAIL_CONTENT_TYPE,
    UPLOAD_THUMBNAIL_QUALITY
  ),
});

const determineRejectionReason = (
  file: File,
  config: FileUploadConfig
): string => {
  if (file.size > config.maxFileSizeInBytes) {
    return `${config.fileSizeErrorMessage} ${bytesToSize(
      config.maxFileSizeInBytes
    )}`;
  }

  if (file.size === 0) {
    return config.fileEmptyErrorMessage;
  }

  return config.fileTypeErrorMessage;
};

const determineRejectionReasonForScreenReader = (
  file: File,
  config: FileUploadConfig
): string => {
  if (file.size > config.maxFileSizeInBytes) {
    return translate(Translations.fileUpload.fileIsTooBigScreenReader, null, [
      file.name,
      bytesToSize(config.maxFileSizeInBytes),
    ]);
  }

  if (file.size === 0) {
    return translate(Translations.fileUpload.fileIsEmptyScreenReader, null, [
      file.name,
    ]);
  }

  return translate(Translations.fileUpload.fileTypeIsNotSupported, null, [
    file.name,
    config.allowedFileExtensions,
  ]);
};

export const extendRejectedFile = (
  file: File,
  config: FileUploadConfig,
  customReason?: string
): RejectedFile =>
  ({
    name: file.name,
    size: file.size,
    lastModified: file.lastModified,
    type: file.type,
    id: generateId(),
    reason: customReason || determineRejectionReason(file, config),
    reasonForScreenReader:
      customReason || determineRejectionReasonForScreenReader(file, config),
    state: FileUploadState.REJECTED,
  } as RejectedFile);

export enum MimeTypes {
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  DOC = 'application/msword',
  PDF = 'application/pdf',
}

export const getFileTypeIcon = (mimeType: string): string => {
  if (mimeType.startsWith('image')) {
    return mdiFileImageOutline;
  } else if (mimeType.startsWith('video')) {
    return mdiFileVideoOutline;
  } else if (mimeType.startsWith('text')) {
    return mdiFileDocumentOutline;
  }

  switch (mimeType) {
    case MimeTypes.PDF:
      return mdiFilePdfBox;

    case MimeTypes.DOC:
    case MimeTypes.DOCX:
      return mdiFileWordOutline;

    default:
      return mdiFileOutline;
  }
};
