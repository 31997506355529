import { callApi } from '../callApi';

import {
  ApiResponse,
  UploadFileResponse,
  ClientSideConfiguration,
  Endpoints,
  RequestMethod,
} from '../types';

export const uploadFile = ({
  file,
  conversationId,
  abortController,
}: {
  file: File;
  conversationId?: string;
  abortController?: AbortController;
}): Promise<ApiResponse<UploadFileResponse>> => {
  const form = new FormData();
  form.append('file', file);

  if (conversationId) {
    form.append('conversationId', conversationId);
  }

  return callApi({
    url: Endpoints.UPLOAD_FILE,
    method: RequestMethod.POST,
    body: form,
    abortController,
    timeout: 120000,
  });
};

export const getClientSideConfiguration = (params?: {
  abortController?: AbortController;
}): Promise<ApiResponse<ClientSideConfiguration>> => {
  return callApi({
    url: Endpoints.GET_CLIENT_SIDE_CONFIGURATION,
    abortController: params?.abortController,
    retry: true,
  });
};
