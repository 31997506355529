import { Dispatch } from 'redux';

import { appInsights } from '@/appInsights/appInsights';

import { clearBotConfig } from '../store/bot-config/actions';
import { resetRecoveryState } from '../store/recovery/actions';
import { resetConversationState } from '../store/conversation/actions';
import { clearBffAuthenticationState } from '../store/bffAuthentication/actions';

const resetConversation = (dispatch: Dispatch): void => {
  appInsights.trackEvent({
    name: 'Clearing conversation state',
  });

  dispatch(clearBotConfig());
  dispatch(resetRecoveryState());
  dispatch(resetConversationState());
  dispatch(clearBffAuthenticationState());

  //fix to clear webchat store synchronously
  window.sessionStorage.removeItem('persist:webchat-store');
};

export default resetConversation;
