import { ConfigurableTheme } from '../../styles/types';

export default {
  colors: {
    error: '#C82E2D',
    errorBackground: '#FCE8E8',
    errorDark: '#9f2322',

    background: '#ffffff',
    backgroundSecondary: '#ffffff',
    headerBackground: '#ffffff',
    headerText: '#000000',
    menuArrow: '#000000',
    modalBackdrop: 'rgba(0, 0, 0, 0.4)',

    secondary: '#101820',
    secondaryLight: 'rgba(0, 0, 0, 0.7)',
    secondarySupportLight: '#ffffff',
    secondarySupport: '#8a8a8a',

    action: '#101820',
    actionDark: '#53565A',
    actionLight: '#97999b',
    actionLightest: '#ffffff',

    actionSecondary: '#ffffff',
    actionSecondaryDark: '#fafafa',
    actionSecondaryDarker: '#f5f5f5',

    actionText: '#ffffff',
    actionTextDark: '#ffffff',
    actionTextLight: 'rgba(0, 0, 0, 0.5)',

    focusOutline: '#6a8b89',

    inputBackground: '#ffffff',
    inputPlaceholderFocus: '#efeeee',
    inputSupport: 'rgba(0, 0, 0, 0.2)',

    botMessageBackground: '#ECECE7',
    userMessageBackground: '#C8C9C7',
    messageText: '#101820',

    botActionBackground: '#ffffff',
    botActionText: '#101820',
    botActionBackgroundHover: '#f8f8f8',
    botActionBackgroundActive: '#fafafa',

    calendarItemHover: '#ffffff',
    calendarItemBackgroundHover: '#53565A',
    calendarItemCurrent: '#E9E3D7',
    calendarItemSelected: '#ffffff',
    calendarItemSelectedBackground: '#101820',
    calendarItemOutside: 'rgba(0, 0, 0, 0.2)',
    calendarSecondaryItem: '#4c6361',

    borderDark: 'rgba(0, 0, 0, .6)',
    borderMedium: 'rgba(0, 0, 0, .4)',
    borderLight: 'rgba(0, 0, 0, .18)',
  },
  fonts: {
    fontStack: '"no-motor-polestar"',
  },
} as ConfigurableTheme;
