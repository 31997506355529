import { ReactNode } from 'react';
import { translate, Translations } from '../../../locale';
import {
  CustomActivityAction,
  CustomActivityConfig,
} from '../../../middleware/types';
import { useLifecycleTracking } from '../../../utils/customHooks';
import { BotPassThroughProps } from '../BotMessageBase';
import { canBeEditedCustomUserResponse } from '../../../utils';
import { sendMessageBack } from '../../../utils/directLineUtils';
import { findCustomActivity } from '../../../middleware/activityMiddleware';
import { trackComponentInteracted } from '../../../utils/appInsightHelper';
import { AppInsightsUserInteraction } from '../../../constants';

import classes from './ActionButtons.module.scss';

import ButtonGroup from '@/components/ButtonGroup/ButtonGroup';

export interface ActionButtonsProps {
  // TODO: this can be extracted from Card directly, remove this
  actions?: CustomActivityAction[];

  onClickHandler?: (action: CustomActivityAction) => void;
  disableOnClick?: boolean;
  showLoader?: boolean;
  children?: ReactNode;
  focusFirstAction?: boolean;
  disabledButtonsIndices?: number[];
}

export default function ActionButtons(
  props: ActionButtonsProps & BotPassThroughProps
): JSX.Element {
  const {
    card,
    hideChildrenOfElement,
    actions,
    onClickHandler,
    showLoader,
    disableOnClick,
    focusFirstAction,
    disabledButtonsIndices,
  } = props;

  const customActivity = findCustomActivity(
    card.activity
  ) as CustomActivityConfig;

  useLifecycleTracking(customActivity.id);

  const handleSubmit = (customActivityAction: CustomActivityAction): void => {
    if (!disableOnClick) {
      hideChildrenOfElement();
    }

    trackComponentInteracted(
      card.activity.id ?? '',
      AppInsightsUserInteraction.ActionButtonClick,
      `Action Submitted ${customActivityAction.title}`
    );

    if (onClickHandler) {
      onClickHandler(customActivityAction);
    } else {
      sendMessageBack(
        customActivityAction.title,
        {
          canBeEdited: canBeEditedCustomUserResponse(card),
        },
        card.activity
      );
    }
  };

  const actionButtonContainerLabel = translate(
    Translations.actionButtonOptionsAvailable,
    actions?.length,
    [String(actions?.length)]
  );

  const renderActions = (
    customActivityActions: CustomActivityAction[]
  ): JSX.Element => {
    return (
      <div
        className={classes.ActionGroup}
        aria-label={actionButtonContainerLabel}
      >
        <ButtonGroup
          showLoader={showLoader}
          disableOnClick={disableOnClick}
          actions={customActivityActions}
          onClick={handleSubmit}
          focusFirstButton={focusFirstAction}
          disabledButtonsIndices={disabledButtonsIndices}
        />
        {props.children}
      </div>
    );
  };

  return actions ? renderActions(actions) : <>{props.children}</>;
}
