import { CustomActivityConfig } from '../../../../middleware/types';

export enum TimePart {
  HOURS = 'hours',
  MINUTES = 'minutes',
}

export interface TimeFields {
  [TimePart.HOURS]: string;
  [TimePart.MINUTES]: string;
}

export interface TimePreset {
  separator: string;
  hours: TimePartDefinition;
  minutes: TimePartDefinition;
}

export interface TimePartDefinition {
  placeholder: string;
  translationKey: string;
  regex: string;
}

export interface TimeInputActivityConfig extends CustomActivityConfig {
  separator: string;
  timePreset: TimePreset;
}
