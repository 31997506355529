import { emitCustomEvent } from "react-custom-events";
import { CustomEvent } from "../constants";

const useEmitOverlayOpened = () => {
  const emitOverlayOpened = (onCloseCallback: () => void) => {
    emitCustomEvent(CustomEvent.OverlayOpened, () => {
      onCloseCallback();
      emitCustomEvent(CustomEvent.OverlayClosed);
    });
  }

  return emitOverlayOpened;
};

export default useEmitOverlayOpened;
