import { action } from 'typesafe-actions';
import { AppStatus } from '@/constants';
import { ChatActivitiesActionTypes } from './types';

export const addChatStatus = (status: AppStatus) => {
  return action(ChatActivitiesActionTypes.ADD_CHAT_STATUS, status);
};

export const removeLastChatStatus = () => {
  return action(ChatActivitiesActionTypes.REMOVE_LAST_CHAT_STATUS);
};
