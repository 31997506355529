import { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomActivityAction } from '../../../middleware/types';
import { BotPassThroughProps } from '../../../containers/BotMessageBase/BotMessageBase';
import { AppInsightsEventNames } from '../../../constants';

import { appInsights } from '@/appInsights/appInsights';
import { getCardActions, sendMessage } from '../../../utils';

import ActionButtons from '../../../containers/BotMessageBase/ActionButtons/ActionButtons';
import { addDisplayedAuthenticationActivityId } from '../../../store/bffAuthentication/actions';
import { getAuthLoginUri } from '../../../utils/authentication';
import { getLocaleShort } from '@/locale';
import { RootState } from '@/store/types';

const BffAuthenticationActivity: FunctionComponent<BotPassThroughProps> = (
  botPassThroughProps: BotPassThroughProps
) => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  const dispatch = useDispatch();

  const { card } = botPassThroughProps;

  let actions = getCardActions(card);

  const isDesktop = useSelector<RootState, boolean>(
    (state) => state.globalConfigState.isDesktop
  );

  if (!isDesktop) {
    actions = actions?.filter(
      (action) =>
        action?.data?.CommandReference?.localeCompare(
          'SeMobileQrLogin',
          undefined,
          { sensitivity: 'accent' }
        ) !== 0
    );
  }

  // @ts-ignore
  const brandName = useSelector<RootState, string | undefined>((state) =>
    state.pageConfigState.config?.brandName
      ? state.pageConfigState.config.brandName
      : ''
  );

  const sendActionTitleToBot = (action: CustomActivityAction) => {
    sendMessage(action.title);

    appInsights.trackEvent(
      {
        name: AppInsightsEventNames.ComponentInteraction,
      },
      {
        message: 'AuthenticationActivity buttonClicked Send Message',
        action,
      }
    );
  };

  const handleClick = (action: CustomActivityAction) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const commandReference = action.data.CommandReference as string | undefined;
    card.activity.id &&
      dispatch(addDisplayedAuthenticationActivityId(card.activity.id));

    if (commandReference) {
      window.location.replace(
        getAuthLoginUri(commandReference, getLocaleShort(), brandName)
      );
    } else {
      sendActionTitleToBot(action);
    }
    setIsLoaderVisible(true);
  };

  //Bad, botPassThroughProps are now being dragged along too many levels when a lot of it is not needed
  return (
    <ActionButtons
      focusFirstAction
      actions={actions}
      onClickHandler={handleClick}
      disableOnClick={true}
      showLoader={isLoaderVisible}
      {...botPassThroughProps}
    />
  );
};

export default BffAuthenticationActivity;
