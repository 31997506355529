// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Checkbox-module_CheckBoxContainer--_FHpa{display:flex;align-items:center}.Checkbox-module_CheckBoxContainer--_FHpa label{cursor:pointer}.Checkbox-module_CheckBoxContainer--_FHpa .Checkbox-module_IconBackground--qK7I5{background-color:var(--color-secondary-support-light);display:inherit;min-width:15px;min-height:15px;position:relative}.Checkbox-module_CheckBoxContainer--_FHpa .Checkbox-module_CheckBoxLabel--G9LlY{margin-left:12px;color:var(--color-action)}.Checkbox-module_CheckBoxContainer--_FHpa .Checkbox-module_CheckBoxIcon--NxbEn{position:absolute;left:-5px;top:-5px;color:var(--color-action)}.Checkbox-module_CheckBoxContainer--_FHpa .Checkbox-module_CheckBoxIcon--NxbEn.Checkbox-module_CheckBoxIconDisabled--sI0GY{pointer-events:none;color:var(--color-action-light)}", "",{"version":3,"sources":["webpack://./src/components/UI/Checkbox/Checkbox.module.scss"],"names":[],"mappings":"AAEA,0CACE,YAAA,CACA,kBAAA,CAEA,gDACE,cAAA,CAGF,iFACE,qDAAA,CACA,eAAA,CACA,cAAA,CACA,eAAA,CACA,iBAAA,CAGF,gFACE,gBAAA,CACA,yBAAA,CAGF,+EACE,iBAAA,CACA,SAAA,CACA,QAAA,CACA,yBAAA,CAEA,2HACE,mBAAA,CACA,+BAAA","sourcesContent":["@import \"~styles/colors\";\r\n\r\n.CheckBoxContainer {\r\n  display: flex;\r\n  align-items: center;\r\n\r\n  label {\r\n    cursor: pointer;\r\n  }\r\n\r\n  .IconBackground {\r\n    background-color: v(color-secondary-support-light);\r\n    display: inherit;\r\n    min-width: 15px;\r\n    min-height: 15px;\r\n    position: relative;\r\n  }\r\n\r\n  .CheckBoxLabel {\r\n    margin-left: 12px;\r\n    color: v(color-action);\r\n  }\r\n\r\n  .CheckBoxIcon {\r\n    position: absolute;\r\n    left: -5px;\r\n    top: -5px;\r\n    color: v(color-action);\r\n\r\n    &.CheckBoxIconDisabled {\r\n      pointer-events: none;\r\n      color: v(color-action-light);\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CheckBoxContainer": "Checkbox-module_CheckBoxContainer--_FHpa",
	"IconBackground": "Checkbox-module_IconBackground--qK7I5",
	"CheckBoxLabel": "Checkbox-module_CheckBoxLabel--G9LlY",
	"CheckBoxIcon": "Checkbox-module_CheckBoxIcon--NxbEn",
	"CheckBoxIconDisabled": "Checkbox-module_CheckBoxIconDisabled--sI0GY"
};
export default ___CSS_LOADER_EXPORT___;
