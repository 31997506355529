import { AppStatus } from '@/constants';

export interface ChatActivitiesState {
  chatStatuses: AppStatus[];
}

export enum ChatActivitiesActionTypes {
  ADD_CHAT_STATUS = 'CHAT_ACTIVITIES/ADD_CHAT_STATUS',
  REMOVE_LAST_CHAT_STATUS = 'CHAT_ACTIVITIES/REMOVE_LAST_CHAT_STATUS'
}
