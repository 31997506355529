import RoundButton from '../../../../components/RoundButton/RoundButton';
import { BotPassThroughProps } from '@/containers/BotMessageBase/BotMessageBase';
import { findCustomActivity } from '@/middleware/activityMiddleware';
import { Card, CustomActivityConfig } from '@/middleware/types';
import { canBeEditedCustomUserResponse } from '../../../../utils';
import { sendMessageBack } from '@/utils';
import { useEffect, useRef, useState } from 'react';
import Icon from '@mdi/react';
import classes from './PhoneNumberInput.module.scss';
import { CountryCode, PhoneNumberInputField } from '@ids-react/input-fields';
import parsePhoneNumber from 'libphonenumber-js';
import { translate, Translations } from '@/locale';
import { mdiSend } from '@mdi/js';

interface InputPhoneNumberConfig extends CustomActivityConfig {
  defaultCountry: CountryCode;
  placeholder?: string;
}

export interface PhoneNumberInputProps {
  preferredCountries: Array<CountryCode>;
}

function sendValue(
  card: Card,
  inputValue: string,
  setInputValue: (value: string) => void,
  hideElement: () => void
): void {
  hideElement();
  sendMessageBack(
    inputValue,
    {
      canBeEdited: canBeEditedCustomUserResponse(card),
    },
    card.activity
  );
  setInputValue('');
}

export default function PhoneNumberInput(
  props: PhoneNumberInputProps & BotPassThroughProps
): JSX.Element {
  const inputPhoneNumberConfig = findCustomActivity(
    props.card.activity
  ) as InputPhoneNumberConfig;

  const [inputValue, setInputValue] = useState(
    isPossibleGetCountry(inputPhoneNumberConfig.prefillText ?? '')
      ? (inputPhoneNumberConfig && inputPhoneNumberConfig.prefillText) || ''
      : ''
  );

  const [submitEnabled, setSubmitEnabled] = useState(inputValue?.length > 0);

  const wasAutoFilled = useRef(false);

  const [selectedCountry, setSelectedCountry] = useState(
    parsePhoneNumber(inputPhoneNumberConfig.prefillText ?? '')?.country
  );

  const onRawValueChangeHandler = (newRawValue: string) => {
    if (newRawValue.startsWith('+') && isPossibleGetCountry(newRawValue)) {
      setInputValue(newRawValue);
      setSubmitEnabled(true);

      wasAutoFilled.current = true
    }
  }

  const onValueChangeHandler = (newValue: string) => {
    if (wasAutoFilled.current === true) {
      wasAutoFilled.current = false;
      return;
    }

    if (isPossibleGetCountry(newValue)) {
      const currentSelectedCountry = parsePhoneNumber(newValue ?? '')?.country;
      setSelectedCountry(currentSelectedCountry);
    }

    setSubmitEnabled(newValue?.length > 0);
    setInputValue(newValue);
  };

  const onCountryChangeHandler = (newCountry: CountryCode) => {
    setSelectedCountry(newCountry);
  }

  function isPossibleGetCountry(phoneNumber: string) {
    const country = parsePhoneNumber(phoneNumber ?? '')?.country;
    return country !== undefined;
  }

  const enterPressHandler = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && submitEnabled) {
      sendValue(
        props.card,
        inputValue,
        setInputValue,
        props.hideChildrenOfElement
      );
    }
  };

  const inputPhoneRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    inputPhoneRef.current?.getElementsByClassName(classes.PhoneNumberInput)[0]?.focus()
  }, []);

  return (
    <div className={classes.InputContainer}>
      <div
        className={classes.TextInput}
        data-custom-activity="true"
        data-message-type={inputPhoneNumberConfig.messageType}
        ref={inputPhoneRef}
      >
        {props.lastBotActivityId === props.card.activity.id ? (
          <PhoneNumberInputField
            id={'phone-number-input'}
            name="phoneNumberInput"
            value={inputValue}
            defaultCountry={
              selectedCountry ?? inputPhoneNumberConfig.defaultCountry
            }
            preferredCountries={props.preferredCountries}
            includePreferredCountries={true}
            showNumberExtensionInList={true}
            inputMode={'numeric'}
            inputClassName={classes.PhoneNumberInput}
            inputWrapperClassName={classes.PhoneNumberInputWrapper}
            countrySelectButtonClassName={classes.CountrySelectButton}
            countryListClassName={classes.CountryList}
            onRawValueChange={onRawValueChangeHandler}
            onValueChange={onValueChangeHandler}
            onCountryChange={onCountryChangeHandler}
            onKeyDown={enterPressHandler}
            autoComplete='off'
          />
        ) : null}
        <RoundButton
          disabled={!submitEnabled}
          ariaLabel={translate(Translations.send)}
          onClick={() =>
            sendValue(
              props.card,
              inputValue,
              setInputValue,
              props.hideChildrenOfElement
            )
          }
        >
          <Icon path={mdiSend} size={1} className={classes.SubmitButtonIcon} />
        </RoundButton>
      </div>
    </div>
  );
}
