import { Theme } from '../../styles/types';

export enum ThemeActionTypes {
  SET_THEME = 'THEME/SET_THEME',
  OVERRIDE_THEME = 'THEME/OVERRIDE_THEME',
}

export interface ThemeState {
  theme: Theme | undefined;
}
