import { appInsights } from '@/appInsights/appInsights';
import {
  AppInsightsEventNames,
  AppInsightsUserInteraction,
} from '../constants';
import { getToggle, FeatureToggles } from '../utils/featureToggles';

export function trackComponentMounted(nodeId: string): void {
  if (getToggle(FeatureToggles.webchatLogMountUnmountEvents)) {
    setTimeout(() => {
      appInsights.trackEvent(
        { name: AppInsightsEventNames.ComponentMounted },
        { node: nodeId }
      );
    }, 500);
  }
}

export function trackComponentUnmounted(nodeId: string): void {
  if (getToggle(FeatureToggles.webchatLogMountUnmountEvents)) {
    setTimeout(() => {
      appInsights.trackEvent(
        { name: AppInsightsEventNames.ComponentUnmounted },
        { node: nodeId }
      );
    }, 500);
  }
}

export function trackComponentInteracted(
  nodeId: string,
  eventName: AppInsightsUserInteraction,
  eventData = ''
): void {
  setTimeout(() => {
    appInsights.trackEvent(
      { name: AppInsightsEventNames.ComponentInteraction },
      { node: nodeId, eventName: eventName, eventData: eventData }
    );
  }, 500);
}

export function trackDateComponentInteracted(
  nodeId: string,
  eventName: AppInsightsUserInteraction,
  eventData = ''
): void {
  // remove trackDateComponentInteracted when FT webchatLogDateEvents removed and use trackComponentInteracted
  if (getToggle(FeatureToggles.webchatLogDateEvents)) {
    trackComponentInteracted(nodeId, eventName, eventData);
  }
}

export function trackClassifierComponentInteracted(
  nodeId: string,
  eventName: AppInsightsUserInteraction,
  eventData = ''
): void {
  // remove trackDateComponentInteracted when FT webchatLogClassifierEvents removed and use trackComponentInteracted
  if (getToggle(FeatureToggles.webchatLogClassifierEvents)) {
    trackComponentInteracted(nodeId, eventName, eventData);
  }
}

export function trackLongListOverlayToggled(
  eventData: "opened" | "closed"
): void {
  if (getToggle(FeatureToggles.logLongListOverlayToggled)) {
    trackOverlayToggled("LongListNode", eventData);
  }
}

export function trackOverlayToggled(
  nodeId: string,
  eventData: "opened" | "closed"
): void {
  setTimeout(() => {
    appInsights.trackEvent(
      { name: AppInsightsEventNames.ComponentInteraction },
      { node: nodeId, eventName: AppInsightsUserInteraction.OverlayToggled, eventData: eventData }
    );
  }, 500);
}
