// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TimeInput-module_TimeInputWrapper--YpEGN{display:inherit;width:inherit;height:inherit;justify-content:flex-end;position:relative}.TimeInput-module_TimeInput--HwunD{display:flex;width:inherit;height:inherit;align-items:center;max-width:10rem}.TimeInput-module_TimeInput--HwunD>div:nth-child(1){flex:auto}.TimeInput-module_TimeInput--HwunD>div:nth-child(2){width:auto}", "",{"version":3,"sources":["webpack://./src/components/CustomActivities/Inputs/TimeInput/TimeInput.module.scss"],"names":[],"mappings":"AAEA,0CACE,eAAA,CACA,aAAA,CACA,cAAA,CACA,wBAAA,CACA,iBAAA,CAGF,mCACE,YAAA,CACA,aAAA,CACA,cAAA,CACA,kBAAA,CACA,eAAA,CAEA,oDACE,SAAA,CAGF,oDACE,UAAA","sourcesContent":["@import \"~styles/mixins\";\r\n\r\n.TimeInputWrapper {\r\n  display: inherit;\r\n  width: inherit;\r\n  height: inherit;\r\n  justify-content: flex-end;\r\n  position: relative;\r\n}\r\n\r\n.TimeInput {\r\n  display: flex;\r\n  width: inherit;\r\n  height: inherit;\r\n  align-items: center;\r\n  max-width: 10rem;\r\n\r\n  & > div:nth-child(1) {\r\n    flex: auto;\r\n  }\r\n\r\n  & > div:nth-child(2) {\r\n    width: auto;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TimeInputWrapper": "TimeInput-module_TimeInputWrapper--YpEGN",
	"TimeInput": "TimeInput-module_TimeInput--HwunD"
};
export default ___CSS_LOADER_EXPORT___;
