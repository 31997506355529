import axios, { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';

import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { RequestMethod, ApiResponse, ResponseType } from './types';
import { appInsights } from '@/appInsights/appInsights';

export const API_PREFIX = '/api';
export const TIMEOUT_IN_MS = 30000;
export const DEFAULT_RETRY_COUNT = 1;

const baseHeaders = {
  Accept: 'application/json',
  'Cache-Control': 'no-cache',
};

export const CALL_API_ABORTED = 'Api call aborted';

export interface CallApiParams<T> {
  url: string;
  body?: T;
  method?: RequestMethod;
  abortController?: AbortController;
  responseType?: ResponseType;
  timeout?: number;
  baseURL?: string;
  retry?: boolean;
}

/**
 * Calls the webchat api with the specified request method
 *
 * @template T
 * @template K
 * @param {CallApiParams<K>} {
 *   url,
 *   body,
 *   method,
 *   cancelToken,
 * }
 * @return {*}  {Promise<ApiResponse<T>>}
 */
export const callApi = async <T, K>({
  url,
  body,
  method,
  responseType,
  abortController,
  baseURL = API_PREFIX,
  timeout = TIMEOUT_IN_MS,
  retry,
}: CallApiParams<K>): Promise<ApiResponse<T>> => {
  const isFormData = body instanceof FormData;

  const headers = {
    ...baseHeaders,
    'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
  };

  const data = body ? (isFormData ? body : JSON.stringify(body)) : null;

  try {
    const client = axios.create();

    if (retry) {
      axiosRetry(client, {
        retries: 1,
        retryCondition: (_axiosError) => {
          return true;
        },
        onRetry: (retryCount, error, requestConfig) => {
          appInsights.trackTrace(
            {
              message: 'API Request retry',
              severityLevel: SeverityLevel.Warning,
            },
            { retryCount, error, requestConfig }
          );
        },
      });
    }

    return await client({
      headers,
      baseURL,
      method,
      url,
      data,
      timeout,
      responseType,
      signal: abortController?.signal,
    });
  } catch (e) {
    const error = e as AxiosError<T>;

    const { Information, Error } = SeverityLevel;

    const isRequestCanceled = axios.isCancel(e);

    const severityLevel = isRequestCanceled ? Information : Error;

    if (!appInsights) {
      throw error;
    }

    if (isRequestCanceled) {
      appInsights.trackEvent(
        { name: CALL_API_ABORTED },
        { requestUrl: url, requestBody: body }
      );
    }

    if (!isRequestCanceled && !retry) {
      appInsights.trackException({
        exception: error,
        severityLevel,
        properties: {
          message: error.message,
          requestUrl: url,
          requestBody: body,
        },
      });
    }

    throw error;
  }
};
