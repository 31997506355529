import { FunctionComponent } from 'react';

import Icon from '@mdi/react';
import { mdiChevronDown, mdiChevronUp } from '@mdi/js';

import classNames from 'classnames';

import classes from './YearPicker.module.scss';

import { KeyCodes } from '../../../../../constants';
import { translate, Translations } from '../../../../../locale';

import {
  YearPickerAnimationClass,
  YearPickerAnimationDelay,
} from './yearPickerHelper';

interface YearPickerNavigationProps {
  isOpen: boolean;
  isPreviousButtonDisabled: boolean;
  isNextButtonDisabled: boolean;
  currentYear: number;
  currentPageIndex: number;
  animationClass: YearPickerAnimationClass;
  setCurrentPageIndex: (page: number) => void;
  setAnimationClass: (animationCLass: YearPickerAnimationClass) => void;
  setIsOpen: (isOpen: boolean) => void;
}

const closeYearPicker = (
  setAnimationClass: (animationClass: YearPickerAnimationClass) => void,
  setIsOpen: (isOpen: boolean) => void
) => {
  setAnimationClass(YearPickerAnimationClass.EXIT);
  setTimeout(() => {
    setIsOpen(false);
  }, YearPickerAnimationDelay.ON_EXIT);
};

export const YearPickerNavigation: FunctionComponent<YearPickerNavigationProps> =
  (props: YearPickerNavigationProps) => {
    const {
      isOpen,
      currentYear,
      isPreviousButtonDisabled,
      isNextButtonDisabled,
      currentPageIndex,
      animationClass,
      setCurrentPageIndex,
      setAnimationClass,
      setIsOpen,
    } = props;

    const goToNextPage = () => {
      if (isNextButtonDisabled) {
        return;
      }

      setCurrentPageIndex(currentPageIndex + 1);
    };

    const goToPreviousPage = () => {
      if (isPreviousButtonDisabled) {
        return;
      }

      setCurrentPageIndex(currentPageIndex - 1);
    };

    return (
      <div
        className={classNames(
          classes.NavContainer,
          animationClass === YearPickerAnimationClass.ENTER
            ? classes.Enter
            : null,
          animationClass === YearPickerAnimationClass.EXIT ? classes.Exit : null
        )}
      >
        <div className={classes.NavRow}>
          <div className={classes.NavDate}>
            <div
              className={classes.YearPicker}
              onClick={() => closeYearPicker(setAnimationClass, setIsOpen)}
              onKeyUp={(e) => {
                switch (e.key) {
                  case KeyCodes.Enter:
                  case KeyCodes.Spacebar:
                    closeYearPicker(setAnimationClass, setIsOpen);
                }
              }}
              tabIndex={0}
            >
              <span>{currentYear}</span>

              <Icon size={1} path={isOpen ? mdiChevronUp : mdiChevronDown} />
            </div>
          </div>

          <div className={classes.NavButtons}>
            <div
              className={classNames(
                classes.PrevButton,
                isPreviousButtonDisabled ? classes.Disabled : null
              )}
              onClick={() => goToPreviousPage()}
              onKeyUp={(e) => {
                switch (e.key) {
                  case KeyCodes.Enter:
                  case KeyCodes.Spacebar:
                    goToPreviousPage();
                }
              }}
              tabIndex={isPreviousButtonDisabled ? -1 : 0}
              role="button"
              aria-label={translate(Translations.previousYears)}
              aria-disabled={isPreviousButtonDisabled}
            >
              <Icon path={mdiChevronUp} size={1} />
            </div>

            <div
              className={classNames(
                classes.NextButton,
                isNextButtonDisabled ? classes.Disabled : null
              )}
              onClick={() => goToNextPage()}
              onKeyUp={(e) => {
                switch (e.key) {
                  case KeyCodes.Enter:
                  case KeyCodes.Spacebar:
                    goToNextPage();
                }
              }}
              tabIndex={isNextButtonDisabled ? -1 : 0}
              role="button"
              aria-label={translate(Translations.nextYears)}
              aria-disabled={isNextButtonDisabled}
            >
              <Icon path={mdiChevronDown} size={1} />
            </div>
          </div>
        </div>
      </div>
    );
  };
