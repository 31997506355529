import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classes from './Menu.module.scss';
import { RootState } from '../../../store/types';
import classNames from 'classnames';
import { PageConfigState } from '../../../store/page-config/types';
import { setConsoleVisibility } from '../../../store/bot-config/actions';
import { resetConversation } from '@/utils';

import BugReport from '../../UI/BugReport/BugReport';
import { MenuProps, MenuItem } from './types';
import MenuButton from './MenuButton';
import MenuDropdown from './MenuDropdown';
import { Country } from '@/constants';

const DROPDOWN_MENU_ID = 'dropdown-menu';

const Menu = (props: MenuProps): JSX.Element => {
  const [showDropdownMenu, setShowDropdownMenu] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [focusedMenuItemIndex, setFocusedMenuItemIndex] = useState<number>(0);

  const menuItems = useRef<MenuItem[]>([]);

  const buttonRef = useRef<HTMLDivElement | null>(null);

  const isMobile = useSelector<RootState, boolean>(
    (state) => state.globalConfigState.isMobile
  );

  const pageConfigState = useSelector<RootState, PageConfigState>(
    (state) => state.pageConfigState
  );

  const username = useSelector<RootState, string | null>(
    (state) => state.bffAuthenticationState.username
  );

  const country = useSelector<RootState, string | null>(
    (state) => state.pageConfigState.config?.country || null
  );

  const dispatch = useDispatch();

  const { clickedOutside, setClickedOutside, invertedColors, setHeaderOnTop } =
    props;

  useEffect(() => {
    if (clickedOutside && !showModal) {
      setShowDropdownMenu(false);
    }
  }, [clickedOutside, showModal]);

  useEffect(() => {
    if (showDropdownMenu) {
      menuItems.current[focusedMenuItemIndex].ref?.focus();
    }
  }, [focusedMenuItemIndex, showDropdownMenu]);

  const openDropDownMenu = () => {
    setShowDropdownMenu(true);
    setClickedOutside && setClickedOutside(false);

    setTimeout(() => {
      menuItems.current[0]?.ref.focus();
      selectFirstMenuItem();
    }, 0);
  };

  const closeDropDownMenu = () => {
    setShowDropdownMenu(false);
  };

  const toggleDropDownMenu = () => {
    if (showDropdownMenu) {
      closeDropDownMenu();
    } else {
      openDropDownMenu();
    }
  };

  const selectNextMenuItem = () => {
    const itemToSelect =
      menuItems.current.length === focusedMenuItemIndex + 1
        ? 0
        : focusedMenuItemIndex + 1;
    setFocusedMenuItemIndex(itemToSelect);
  };

  const selectPreviousMenuItem = () => {
    const itemToSelect =
      focusedMenuItemIndex - 1 < 0
        ? menuItems.current.length - 1
        : focusedMenuItemIndex - 1;
    setFocusedMenuItemIndex(itemToSelect);
  };

  const selectFirstMenuItem = () => {
    setFocusedMenuItemIndex(0);
  };

  const selectLastMenuItem = () => {
    setFocusedMenuItemIndex(menuItems.current.length - 1);
  };

  const selectByFirstCharacter = (char: string) => {
    const allMenuItemsByCharacter = menuItems.current.filter((menuItem) =>
      menuItem.textContent.toLowerCase().startsWith(char.toLowerCase())
    );
    if (allMenuItemsByCharacter.length === 0) {
      return;
    }

    if (allMenuItemsByCharacter.length === 1) {
      setFocusedMenuItemIndex(
        menuItems.current.indexOf(allMenuItemsByCharacter[0])
      );
    }

    const currentlySelectedItem = menuItems.current[focusedMenuItemIndex];
    if (
      currentlySelectedItem.textContent
        .toLowerCase()
        .startsWith(char.toLowerCase())
    ) {
      const remainingSubset = menuItems.current.slice(focusedMenuItemIndex + 1);
      const nextItem = remainingSubset.filter((menuItem) =>
        menuItem.textContent.toLowerCase().startsWith(char.toLowerCase())
      )[0];

      if (nextItem) {
        setFocusedMenuItemIndex(menuItems.current.indexOf(nextItem));
      } else {
        setFocusedMenuItemIndex(
          menuItems.current.indexOf(allMenuItemsByCharacter[0])
        );
      }
    } else {
      setFocusedMenuItemIndex(
        menuItems.current.indexOf(allMenuItemsByCharacter[0])
      );
    }
  };

  const openModal = () => {
    setShowDropdownMenu(false);
    setShowModal(true);
    setHeaderOnTop && setHeaderOnTop(false);
  };

  const closeModal = () => {
    setShowModal(false);
    setHeaderOnTop && setHeaderOnTop(true);
  };

  const signOut = () => {
    resetConversation(dispatch);

    const redirectUri = window.location.pathname + window.location.search;

    const signOutUri = `/sign-out-callback?redirectUri=${encodeURIComponent(
      redirectUri
    )}`;

    window.location.replace(
      `/auth/curitylogout?redirectUri=${encodeURIComponent(signOutUri)}`
    );
  };

  const openConsole = () => {
    closeModal();
    closeDropDownMenu();
    setClickedOutside && setClickedOutside(true);

    setTimeout(() => {
      dispatch(setConsoleVisibility(true));
    });
  };

  return (
    <div className={classes.Menu}>
      <BugReport showModal={showModal} close={closeModal} />
      {!isMobile && username && (
        <div
          className={classNames(
            classes.Username,
            invertedColors ? classes.Inverted : null
          )}
        >
          {username}
        </div>
      )}

      <MenuButton
        menuId={DROPDOWN_MENU_ID}
        showDropdownMenu={showDropdownMenu}
        toggleDropDownMenu={toggleDropDownMenu}
        invertedColors={invertedColors}
        buttonRef={buttonRef}
      />
      {showDropdownMenu && !clickedOutside && (
        <MenuDropdown
          id={DROPDOWN_MENU_ID}
          username={username}
          openConsole={openConsole}
          openModal={openModal}
          signOut={signOut}
          showLoader={false}
          pageConfigState={pageConfigState}
          menuItems={menuItems}
          selectNextMenuItem={selectNextMenuItem}
          selectPreviousMenuItem={selectPreviousMenuItem}
          selectFirstMenuItem={selectFirstMenuItem}
          selectLastMenuItem={selectLastMenuItem}
          selectByFirstCharacter={selectByFirstCharacter}
          buttonRef={buttonRef}
          closeDropDownMenu={closeDropDownMenu}
          country={country as Country}
        />
      )}
    </div>
  );
};

export default Menu;
