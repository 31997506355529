import { mdiAlertCircleOutline } from '@mdi/js';
import Icon from '@mdi/react';
import classNames from 'classnames';

import FileListItem from './FileListItem/FileListItem';
import FileUploadDropZone from '../FileUploadDropZone/FileUploadDropZone';
import RejectedFileItem from './RejectedFileItem/RejectedFileItem';
import Modal from '../../../../UI/Modal/Modal';
import { FileUploadDialogProps } from '../types';
import { AriaDocumentStructureRoles } from '../../../../../constants/aria';
import { translate, Translations } from '../../../../../locale';

import classes from './FileUploadDialog.module.scss';

import ButtonPrimary from '@/components/Buttons/ButtonPrimary/ButtonPrimary';
import ButtonSecondary from '@/components/Buttons/ButtonSecondary/ButtonSecondary';

const REJECTED_FILES_ID = 'rejected-files';
const ACCEPTED_FILES_ID = 'accepted-files';

const FileUploadDialog = (props: FileUploadDialogProps): JSX.Element => {
  const {
    showModal,
    config,
    acceptedFiles,
    handleOnAccepted,
    rejectedFiles,
    handleOnRejected,
    attemptFileUpload,
    uploadInProgress,
    removeFileFromList,
    failedUpload,
    cancelUploadCleanup,
  } = props;

  const rejectedFilesMessage = translate(
    Translations.fileUpload.fileCanNotBeUploaded,
    rejectedFiles.length,
    [rejectedFiles.length.toString()]
  );

  const acceptedFilesMessage = translate(
    Translations.fileUpload.fileWillBeUploaded,
    acceptedFiles.length,
    [acceptedFiles.length.toString()]
  );

  const renderRejectedFiles = () => {
    return (
      <div
        id={REJECTED_FILES_ID}
        role={AriaDocumentStructureRoles.LIST}
        className={classNames(
          classes.RejectedFileList,
          failedUpload ? classes.UploadError : null
        )}
        aria-label={rejectedFilesMessage}
      >
        <div className={classes.RejectedFilesLabelContainer}>
          <span className={classNames(classes.FileListLabel, classes.Rejected)}>
            {rejectedFilesMessage}
          </span>
        </div>

        {rejectedFiles.map((file, index) => (
          <RejectedFileItem key={index} file={file} />
        ))}
      </div>
    );
  };

  const renderAcceptedFiles = () => {
    return (
      <div
        id={ACCEPTED_FILES_ID}
        role={AriaDocumentStructureRoles.LIST}
        aria-label={acceptedFilesMessage}
      >
        <span
          className={classNames(
            classes.FileListLabel,
            rejectedFiles.length > 0 ? classes.Margin : null
          )}
        >
          {acceptedFilesMessage}
        </span>

        {acceptedFiles.map((file, index) => (
          <FileListItem
            key={index}
            index={index}
            file={file}
            removeFile={() => removeFileFromList(index)}
          />
        ))}
      </div>
    );
  };

  const modalBody = (
    <div className={classes.FileUploadDialog}>
      <FileUploadDropZone
        dropZoneContainerText={config.dropZoneContainerText}
        browseFilesButtonText={config.browseFilesButtonText}
        handleOnAccepted={handleOnAccepted}
        handleOnRejected={handleOnRejected}
        dropZoneClassName={classes.DropZone}
        buttonClassName={classes.BrowseFilesButton}
        allowedFileExtensions={config.allowedFileExtensions}
        maxFileSizeInBytes={config.maxFileSizeInBytes}
        uploadInProgress={uploadInProgress}
      />

      <div className={classes.FileListContainer}>
        {rejectedFiles.length > 0 && renderRejectedFiles()}
        {acceptedFiles.length > 0 && renderAcceptedFiles()}
      </div>
    </div>
  );

  const uploadFailedMessage = () => {
    return (
      <div className={classNames(classes.UploadFailedMessage, classes.Visible)}>
        {failedUpload}
        <Icon path={mdiAlertCircleOutline} size={1} className={classes.Icon} />
        {config.uploadFailedErrorMessage}
      </div>
    );
  };

  const modalFooter = (
    <div className={classes.Footer}>
      {failedUpload && uploadFailedMessage()}

      <div className={classes.Actions}>
        <ButtonSecondary onClick={cancelUploadCleanup}>
          {translate(Translations.cancel)}
        </ButtonSecondary>

        <ButtonPrimary
          disabled={uploadInProgress || acceptedFiles.length === 0}
          onClick={attemptFileUpload}
          aria-busy={uploadInProgress}
        >
          {!uploadInProgress
            ? config.uploadFilesButtonText
            : config.uploadInProgressButtonText}
        </ButtonPrimary>
      </div>
    </div>
  );

  return (
    <Modal
      modalVisible={showModal}
      shouldCloseOnEscape={false}
      title={config.modalHeaderText}
      modalBody={modalBody}
      modalFooter={modalFooter}
    />
  );
};

export default FileUploadDialog;
