import { useEffect, useState } from 'react';
import ClickOutsideTyped from '../../../../containers/ClickOutside/ClickOutside';
import { MessageTypes } from '../../../../middleware/types';
import TimeInputBox from './TimeInputBox/TimeInputBox';
import { TimeFields, TimeInputActivityConfig, TimePart } from './types';
import {
  areAllPartsValid,
  numbersOnlyRegex,
  timeFieldsHaveValue,
} from './validateTimeInput';
import classes from './TimeInput.module.scss';
import { sendMessageBack } from '../../../../utils/directLineUtils';
import { canBeEditedCustomUserResponse } from '../../../../utils';
import { findCustomActivity } from '../../../../middleware/activityMiddleware';
import { mdiSend } from '@mdi/js';
import Icon from '@mdi/react';
import Alert from '../../../UI/Alert/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/types';
import { setHasAlert } from '../../../../store/global-config/actions';
import { BotPassThroughProps } from '../../../../containers/BotMessageBase/BotMessageBase';

import { translate, Translations } from '@/locale';

import RoundButton from '@/components/RoundButton/RoundButton';

export const TimeInput = (props: BotPassThroughProps): JSX.Element => {
  const dispatch = useDispatch();

  const [config, setConfig] = useState(
    findCustomActivity(props.card.activity) as TimeInputActivityConfig
  );
  const [isError, setIsError] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [timeFields, setTimeFields] = useState<TimeFields>({
    [TimePart.HOURS]: '',
    [TimePart.MINUTES]: '',
  });

  const { timePreset } = config;
  const { separator } = timePreset;
  const hasAlert = useSelector<RootState, boolean>(
    (state) => state.globalConfigState.hasAlert
  );

  const submitTimeValue = () => {
    if (isError || !isValid) {
      return;
    } else {
      const submittedTime =
        timeFields[TimePart.HOURS] + separator + timeFields[TimePart.MINUTES];

      sendMessageBack(
        submittedTime,
        {
          canBeEdited: canBeEditedCustomUserResponse(props.card),
        },
        props.card.activity
      );

      props.hideChildrenOfElement();
    }
  };

  const setTime = (
    timePart: TimePart,
    inputRefs: HTMLInputElement[],
    inputIndex: number,
    value: string
  ): TimeFields | null => {
    if (!numbersOnlyRegex.test(value)) {
      return null;
    }
    const allowedInputLength = timePreset[timePart].placeholder.length;
    if (value.length > allowedInputLength) {
      return null;
    }
    if (
      value.length === allowedInputLength &&
      inputRefs[inputIndex + 1] !== undefined
    ) {
      inputRefs[inputIndex + 1].focus();
    }
    const newTimeValue = {
      ...timeFields,
      [timePart]: value,
    };
    setTimeFields(newTimeValue);
    return newTimeValue;
  };
  const setTimeInputState = (
    timePart: TimePart,
    inputRefs: HTMLInputElement[],
    inputIndex: number,
    value: string
  ) => {
    const newTime = setTime(timePart, inputRefs, inputIndex, value);
    if (newTime) {
      setIsValid(timeFieldsHaveValue(newTime));
      const hasError = !areAllPartsValid(newTime, timePreset);
      setIsError(hasError);
    }
  };

  useEffect(() => {
    if (!config) {
      setConfig(
        findCustomActivity(props.card.activity) as TimeInputActivityConfig
      );
    }
  }, [config, props.card.activity]);

  useEffect(() => {
    dispatch(setHasAlert(isError));
  }, [dispatch, isError]);

  return (
    <div className={classes.TimeInputWrapper}>
      <div
        className={classes.TimeInput}
        data-custom-activity="true"
        data-message-type={MessageTypes.inputTime}
      >
        <ClickOutsideTyped
          withTab={true}
          initialState={false}
          passThroughProps={{}}
          render={(clickOutsideProps) => {
            return (
              <TimeInputBox
                clickedOutside={clickOutsideProps.clickedOutside}
                isError={isError}
                timeFields={timeFields}
                setTime={setTimeInputState}
                submitTime={submitTimeValue}
                timePreset={timePreset}
              />
            );
          }}
        />
        <RoundButton
          disabled={isError || !isValid}
          ariaLabel={translate(Translations.send)}
          onClick={submitTimeValue}
        >
          <Icon path={mdiSend} size={1} />
        </RoundButton>
      </div>
      {hasAlert && (
        <Alert
          stickToRightSideOnDesktop
          stickToRightSideOnMobile
          message={translate(Translations.enterRealTime)}
        />
      )}
    </div>
  );
};

export default TimeInput;
