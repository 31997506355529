import { callApi } from '../callApi';

import {
  ApiResponse,
  BugReportRequest,
  BugReportResponse,
  Endpoints,
  RequestMethod,
} from '../types';

export const bugReport = ({
  body,
  abortController,
}: {
  body: BugReportRequest;
  abortController?: AbortController;
}): Promise<ApiResponse<BugReportResponse>> => {
  return callApi({
    url: Endpoints.BUG_REPORT,
    method: RequestMethod.POST,
    body,
    abortController,
  });
};
