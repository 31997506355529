import { Country } from '@/constants';

export enum SupportLinkUrl {
  DK = 'https://www.if.dk/privat/kontakt',
  FI = 'https://www.if.fi/henkiloasiakkaat/asiakaspalvelu',
  NO = 'https://www.if.no/privat/kundeservice',
  SE = 'https://www.if.se/privat/kundservice',
  SE_FI = 'https://www.if.fi/sv/privat/kundservice'
}

const getDefaultSupportLink = (country: Country): string => {
  switch (country) {
    case Country.DK: {
      return SupportLinkUrl.DK;
    }
    case Country.FI: {
      return SupportLinkUrl.FI;
    }
    case Country.NO: {
      return SupportLinkUrl.NO;
    }
    case Country.SE: {
      return SupportLinkUrl.SE;
    }
    default: {
      return SupportLinkUrl.NO;
    }
  }
};

export default getDefaultSupportLink;
