import { botConfigReducer } from './bot-config/reducers';
import { pageConfigReducer } from './page-config/reducers';
import { globalConfigReducer } from './global-config/reducers';
import { chatActivitiesReducer } from './chat-activities-config/reducers';
import themeReducer from './theme/reducers';
import {
  Store,
  createStore,
  applyMiddleware,
  compose,
  AnyAction,
  Reducer,
} from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import {
  persistStore,
  PersistConfig,
  persistCombineReducers,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import { ConfiguredStore, RootState } from './types';
import { createBlacklistFilter } from 'redux-persist-transform-filter';
import { notificationReducer } from './notification/reducers';
import notificationSaga from './notification/sagas';
import { StorageKeys } from '../constants';
import { isDevEnvironment, isTestEnvironment } from '../utils/utils';
import { recoveryReducer } from './recovery/reducers';
import { conversationReducer } from './conversation/reducers';
import { Persistor } from 'redux-persist/es/types';
import { bffAuthenticationReducer } from './bffAuthentication/reducers';

const nestedKeysBlacklist = createBlacklistFilter<RootState, unknown>(
  'botConfigState',
  [
    'isConfigValid',
    'isTokenValid',
    'isFetching',
    'isDirectLineReady',
    'isConsoleVisible',
  ]
);

const persistConfig: PersistConfig<RootState, unknown> = {
  key: StorageKeys.WebChatStore,
  storage: storage,
  blacklist: ['themeState'],
  transforms: [nestedKeysBlacklist],
  version: 37,
};

export const persistenceReducer: Reducer = persistCombineReducers<RootState>(
  persistConfig,
  {
    botConfigState: botConfigReducer,
    pageConfigState: pageConfigReducer,
    globalConfigState: globalConfigReducer,
    themeState: themeReducer,
    notificationState: notificationReducer,
    recoveryState: recoveryReducer,
    bffAuthenticationState: bffAuthenticationReducer,
    conversationState: conversationReducer,
    chatActivitiesState: chatActivitiesReducer
  }
);

function* rootSaga() {
  const sagas = [notificationSaga].filter((saga) => saga !== undefined);

  yield all(sagas.map((saga) => fork(saga)));
}

const sagaMiddleware = createSagaMiddleware();

export function configureStore(initialState?: RootState): ConfiguredStore {
  const store: Store<RootState, AnyAction> = createStore(
    persistenceReducer,
    initialState,
    isDevEnvironment() || isTestEnvironment()
      ? composeWithDevTools(compose(applyMiddleware(sagaMiddleware)))
      : compose(applyMiddleware(sagaMiddleware))
  );
  sagaMiddleware.run(rootSaga);

  const persistor: Persistor = persistStore(store);
  return { store, persistor };
}

export const configuredStore = configureStore();
