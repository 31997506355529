import { Reducer, AnyAction } from 'redux';
import { ConversationState, ConversationActionTypes } from './types';

export const conversationInitialState: ConversationState = {
  activityIdsToHide: [],
};

export const conversationReducer: Reducer<ConversationState> = (
  state: ConversationState = conversationInitialState,
  action: AnyAction
): ConversationState => {
  switch (action.type) {
    case ConversationActionTypes.RESET_STATE: {
      return conversationInitialState;
    }

    case ConversationActionTypes.APPEND_ACTIVITY_IDS_TO_HIDE: {
      const activitiesToHide = state.activityIdsToHide.slice();
      (action.payload as string[]).forEach(activity => {
        if (activitiesToHide.indexOf(activity) === -1) {
          activitiesToHide.push(activity);
        }
      });

      return {
        ...state,
        activityIdsToHide: activitiesToHide,
      };
    }

    default: {
      return state;
    }
  }
};
