import React, { useRef, useCallback } from 'react';
import classes from './Menu.module.scss';
import Icon from '@mdi/react';
import { mdiConsole } from '@mdi/js';
import classNames from 'classnames';
import { KeyCodes } from '../../../constants';
import { isDevEnvironment, isTestEnvironment } from '../../../utils/utils';
import { localizeUrl, translate, Translations } from '../../../locale';
import LinearProgressBar from '../../UI/LinearProgressBar/LinearProgressBar';
import { ContactSupport } from './MenuItems/ContactSupport';
import { AccessibilityStatement } from './MenuItems/AccessibilityStatement';
import { MenuDropdownProps, MenuItem } from './types';
import getDefaultSupportLink from '@/utils/getDefaultSupportLink';

const isPrintableCharacter = (str: string) => {
  return str.length === 1 && /\S/.exec(str);
};

const DROPDOWN_ITEM_SELECTOR =
  'div[data-drop-down-item]:not([data-drop-down-item=""])';

const useFillMenuItems = (menuItems: React.MutableRefObject<MenuItem[]>) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const setRef = useCallback<(node: HTMLDivElement | null) => void>(
    (node) => {
      if (node) {
        const tempMenuItems: MenuItem[] = [];
        const menuElements = node.querySelectorAll(DROPDOWN_ITEM_SELECTOR);
        menuElements.forEach((menuElement) => {
          tempMenuItems.push({
            ref: menuElement,
            textContent: menuElement.textContent,
          } as MenuItem);
        });
        menuItems.current = tempMenuItems;
      }

      ref.current = node;
    },
    [menuItems]
  );

  return [setRef];
};

export const MenuDropdown = (props: MenuDropdownProps): JSX.Element => {
  const {
    id,
    username,
    openConsole,
    openModal,
    showLoader,
    signOut,
    pageConfigState,
    menuItems,
    selectNextMenuItem,
    selectPreviousMenuItem,
    selectFirstMenuItem,
    selectLastMenuItem,
    selectByFirstCharacter,
    buttonRef,
    closeDropDownMenu,
    country,
  } = props;

  const [ref] = useFillMenuItems(menuItems);

  const contactSupportText =
    pageConfigState.config?.contactSupportText ??
    translate(Translations.menu.contactSupport);

  const showDeveloperConsole = isDevEnvironment() || isTestEnvironment();

  const handleKeyUp = (e: React.KeyboardEvent) => {
    let flag = false;
    const char = e.key;

    if (
      e.ctrlKey ||
      e.altKey ||
      e.metaKey ||
      e.key === KeyCodes.Spacebar ||
      e.key === KeyCodes.Enter
    ) {
      return;
    }

    if (e.shiftKey) {
      if (isPrintableCharacter(char)) {
        selectByFirstCharacter(char);
        flag = true;
      }
    } else {
      switch (e.key) {
        case KeyCodes.Escape:
          buttonRef.current?.focus();
          closeDropDownMenu();
          flag = true;
          break;

        case KeyCodes.ArrowUp:
          selectPreviousMenuItem();
          flag = true;
          break;

        case KeyCodes.ArrowDown:
          selectNextMenuItem();
          flag = true;
          break;

        case KeyCodes.Home:
        case KeyCodes.PageUp:
          selectFirstMenuItem();
          flag = true;
          break;

        case KeyCodes.End:
        case KeyCodes.PageDown:
          selectLastMenuItem();
          flag = true;
          break;

        default:
          if (isPrintableCharacter(char)) {
            selectByFirstCharacter(char);
          }
          break;
      }
    }

    if (flag) {
      e.stopPropagation();
      e.preventDefault();
    }
  };

  const customContactSupportLink = pageConfigState.config?.contactSupportLinks
    ? localizeUrl(pageConfigState.config.contactSupportLinks)
    : undefined;

  const contactSupportLink = customContactSupportLink
    ? customContactSupportLink
    : getDefaultSupportLink(country);

  const accessibilityStatementUrl = pageConfigState.config
    ?.accessibilityStatementUrls
    ? localizeUrl(pageConfigState.config.accessibilityStatementUrls)
    : undefined;

  return (
    <div
      ref={ref}
      className={classes.DropdownMenu}
      id={id}
      tabIndex={-1}
      role="menu"
      onKeyUp={handleKeyUp}
    >
      <ContactSupport
        contactSupportText={contactSupportText}
        contactSupportUrl={contactSupportLink}
      />
      <div
        onKeyUp={handleKeyUp}
        tabIndex={-1}
        role="menuitem"
        data-drop-down-item={'report-bug'}
        className={classNames(
          classes.DropdownMenuItem,
          classes.DropdownMenuItemBug
        )}
        onClick={openModal}
        onKeyPress={(e) =>
          (e.key === KeyCodes.Spacebar || e.key === KeyCodes.Enter) &&
          openModal()
        }
      >
        <span>{translate(Translations.bugReport.title)}</span>
      </div>
      {showDeveloperConsole ? (
        <div
          onKeyUp={handleKeyUp}
          tabIndex={-1}
          role="menuitem"
          data-drop-down-item={'dev-console'}
          className={classNames(
            classes.DropdownMenuItem,
            classes.DropdownMenuItemConsole
          )}
          onClick={openConsole}
          onKeyPress={(e) =>
            (e.key === KeyCodes.Spacebar || e.key === KeyCodes.Enter) &&
            openConsole()
          }
        >
          <Icon path={mdiConsole} className={classes.ConsoleIcon} size={1} />
          <span>{translate(Translations.console)}</span>
        </div>
      ) : null}
      {accessibilityStatementUrl && (
        <AccessibilityStatement
          accessibilityStatementText={translate(
            Translations.menu.accessibilityStatement
          )}
          accessibilityStatementUrl={accessibilityStatementUrl}
        />
      )}

      {showLoader && (
        <div data-drop-down-item={'loader'} className={classes.Loader}>
          <LinearProgressBar />
        </div>
      )}
      {username && (
        <div
          className={classNames(
            classes.DropdownMenuItem,
            classes.DropdownMenuItemUsername
          )}
        >
          <span>{username}</span>
          <div
            tabIndex={-1}
            data-drop-down-item={'username'}
            onKeyUp={handleKeyUp}
            onClick={signOut}
            onKeyPress={(e) =>
              (e.key === KeyCodes.Spacebar || e.key === KeyCodes.Enter) &&
              signOut()
            }
            role="menuitem button"
            className={classNames(
              classes.SignOutButton,
              showLoader ? classes.Disabled : null
            )}
            aria-label={translate(Translations.menu.signOut)}
          >
            <div className={classes.SignOutIcon}></div>
            <span className={classes.SignOutLabel}>
              {translate(Translations.menu.signOut)}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuDropdown;
