import { ReactElement } from 'react';
import { mdiCalendar, mdiSend } from '@mdi/js';
import Icon from '@mdi/react';

import { canResetValue } from '../dateInputHelper';
import { translate, Translations } from '@/locale';

import RoundButton from '@/components/RoundButton/RoundButton';

interface DateInputButtonProps {
  dateValue: { [key: string]: string };
  isValidDate: boolean;
  sendValue: () => void;
  hideElement: () => void;
  setIsModalVisible: (isModalVisible: boolean) => void;
}

export function DateInputButtons(props: DateInputButtonProps) {
  const renderCalendarIcon = () => {
    return <Icon path={mdiCalendar} size={1} />;
  };

  const renderSendIcon = (): ReactElement => {
    return <Icon path={mdiSend} size={1} />;
  };

  return (
    <>
      {canResetValue(props.dateValue) ? (
        <RoundButton
          disabled={!props.isValidDate}
          onClick={() => props.sendValue()}
          ariaLabel={translate(Translations.send)}
        >
          {renderSendIcon()}
        </RoundButton>
      ) : (
        <RoundButton
          onClick={() => props.setIsModalVisible(true)}
          ariaLabel={translate(Translations.openDatePicker)}
        >
          {renderCalendarIcon()}
        </RoundButton>
      )}
    </>
  );
}
