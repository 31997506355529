import { Reducer, AnyAction } from 'redux';
import {
  BotConfigState,
  BotConfigActionTypes,
  PointOfNoReturnInfo,
} from './types';
import { appInsights } from '@/appInsights/appInsights';
import { AppInsightsEventNames } from '../../constants';

const botConfigInitialState: BotConfigState = {
  error: undefined,
  lastBotMessageId: undefined,
  editActivityIds: [],
  pointOfNoReturnInfo: [],
  isPostActivityFulfilled: true,
  lastPostReachedBotService: true,
  setDoNotAddNextPointOfNoReturn: false,
  firstVisibleMessageReceived: false,
  lastMessageId: undefined,
  isConsoleVisible: false,
  isTypingVisible: false,
  conversationId: null,
};

export const getMessageSequenceFromId = (activityId: string): number => {
  if (!activityId) {
    return 0;
  }

  const activityNumberParts = activityId.split('|');
  const result = +activityNumberParts[activityNumberParts.length - 1];
  return result ? result : 0;
};

const botConfigReducer: Reducer<BotConfigState> = (
  state: BotConfigState = botConfigInitialState,
  action: AnyAction
): BotConfigState => {
  switch (action.type) {
    case BotConfigActionTypes.CLEAR_CONFIG: {
      return botConfigInitialState;
    }

    case BotConfigActionTypes.SET_FIRST_VISIBLE_MESSAGE_RECEIVED: {
      appInsights.trackEvent(
        {
          name: AppInsightsEventNames.FirstBotMessageReceived,
        },
        {
          firstVisibleMessageReceived: action.payload,
        }
      );

      return { ...state, firstVisibleMessageReceived: action.payload };
    }

    case BotConfigActionTypes.SET_LAST_BOT_ACTIVITY_ID: {
      return { ...state, lastBotMessageId: action.payload };
    }

    case BotConfigActionTypes.SET_EDIT_ACTIVITY_IDS: {
      const oldIdsWithoutNewOnes = [...state.editActivityIds].filter(
        (existingId) =>
          !(action.payload as string[]).some((newId) => newId === existingId)
      );
      const newIds = oldIdsWithoutNewOnes.concat(action.payload);

      const copy = [...state.pointOfNoReturnInfo];
      const newItem = copy.slice(0, copy.length - 1);

      return {
        ...state,
        editActivityIds: newIds,
        pointOfNoReturnInfo: newItem,
      };
    }

    case BotConfigActionTypes.DISABLE_ALL_PREVIOUS_NODE_EDITING: {
      const newPointOfNoReturnInfo = state.pointOfNoReturnInfo
        .filter(
          (point) =>
            point.messageSequence <
            getMessageSequenceFromId(action.payload as string)
        )
        .map((point) => ({ ...point, canBeEdited: false }));

      return {
        ...state,
        pointOfNoReturnInfo: newPointOfNoReturnInfo,
      };
    }

    case BotConfigActionTypes.SET_POINT_OF_NO_RETURN_INFO: {
      const copy = [...state.pointOfNoReturnInfo];
      if (state.setDoNotAddNextPointOfNoReturn) {
        return { ...state, setDoNotAddNextPointOfNoReturn: false };
      }

      const existingItems = copy.filter(
        (item) => item.userActivityId === action.payload
      );

      if (!!existingItems && existingItems.length > 0) {
        return state;
      }

      const userActivityId = action.payload as string;

      const newItem: PointOfNoReturnInfo = {
        userActivityId: userActivityId,
        messageSequence: getMessageSequenceFromId(userActivityId),
        canBeEdited: true,
        postActivityRejected: false,
      };

      const fullCopy = copy.concat(newItem);
      return {
        ...state,
        pointOfNoReturnInfo: fullCopy,
      };
    }

    case BotConfigActionTypes.SET_DO_NOT_ADD_NEXT_POINT_OF_NO_RETURN: {
      if (action.payload === state.setDoNotAddNextPointOfNoReturn) {
        return state;
      }

      return { ...state, setDoNotAddNextPointOfNoReturn: action.payload };
    }

    case BotConfigActionTypes.SET_IS_POST_ACTIVITY_FULFILLED: {
      const modifiedArray = state.pointOfNoReturnInfo.map((point) => {
        point.postActivityRejected = false;
        return point;
      });
      return {
        ...state,
        pointOfNoReturnInfo: modifiedArray,
        isPostActivityFulfilled: action.payload,
      };
    }

    case BotConfigActionTypes.SET_LAST_POST_REACHED_BOT_SERVICE: {
      return {
        ...state,
        lastPostReachedBotService: action.payload,
      };
    }

    case BotConfigActionTypes.SET_LAST_USER_MESSAGE_POST_FULFILL_REJECTED: {
      const existingItems = [...state.pointOfNoReturnInfo];
      if (!existingItems || existingItems.length === 0) {
        return state;
      }
      existingItems[existingItems.length - 1].postActivityRejected = true;
      return {
        ...state,
        pointOfNoReturnInfo: existingItems,
        isPostActivityFulfilled: true,
      };
    }

    case BotConfigActionTypes.SET_LAST_USER_MESSAGE_CAN_BE_EDITED: {
      const existingItems = [...state.pointOfNoReturnInfo];
      if (!existingItems.length) {
        return state;
      }
      const existingItem = existingItems[existingItems.length - 1];
      if (existingItem.canBeEdited === action.payload) {
        return state;
      }

      existingItem.canBeEdited = action.payload;

      return {
        ...state,
        pointOfNoReturnInfo: existingItems,
      };
    }

    case BotConfigActionTypes.SET_LAST_ACTIVITY_ID: {
      return { ...state, lastMessageId: action.payload };
    }

    case BotConfigActionTypes.SET_CONVERSATION_ID: {
      return { ...state, conversationId: action.payload };
    }
    case BotConfigActionTypes.SET_CONSOLE_VISIBILITY: {
      return { ...state, isConsoleVisible: action.payload };
    }

    case BotConfigActionTypes.SET_TYPING_VISIBILITY: {
      return { ...state, isTypingVisible: action.payload };
    }

    default: {
      return state;
    }
  }
};

export { botConfigReducer, botConfigInitialState };
