import { LongListActivityConfig } from '../LongListActivity';

import { Record } from '@/components/CustomActivities/LongList/types';

export const DEFAULT_SEPARATOR = ',';

const getPreviousUserResponse = (
  longListActivity: LongListActivityConfig,
  categoryRecords: Record[]
): Record[] => {
  const codeSeparatorCharacter =
    longListActivity.selectedCodesSeparatorCharacter || DEFAULT_SEPARATOR;
  const selectedCodes = longListActivity.selectedCodes?.split(
    codeSeparatorCharacter
  );

  return selectedCodes
    ? categoryRecords.filter((record) =>
        selectedCodes.some((code) => record.code === code)
      )
    : [];
};

export default getPreviousUserResponse;
