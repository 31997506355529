import classNames from 'classnames';
import { chunkArray } from '../../../../../utils/utils';
import { KeyCodes } from '../../../../../constants';
import classes from './YearPicker.module.scss';
import Moment from 'moment';
import {
  YearPickerAnimationClass,
  YearPickerAnimationDelay,
  YearPickerChunkType,
} from './yearPickerHelper';
import { FunctionComponent } from 'react';

interface YearPickerBodyProps {
  today: Moment.Moment;
  currentYear: number;
  currentPageIndex: number;
  yearGrid: number[][];
  allowedYearRange: number[];
  animationClass: YearPickerAnimationClass;
  setAnimationClass: (animationClass: YearPickerAnimationClass) => void;
  selectYear: (year: number) => void;
}

const submitYear = (
  year: number,
  setAnimationClass: (animationClass: YearPickerAnimationClass) => void,
  selectYear: (year: number) => void
) => {
  setTimeout(() => {
    setAnimationClass(YearPickerAnimationClass.EXIT);
    setTimeout(() => {
      selectYear(year);
    }, YearPickerAnimationDelay.ON_EXIT);
  }, YearPickerAnimationDelay.ON_SELECT);
};

export const YearPickerBody: FunctionComponent<YearPickerBodyProps> = (
  props: YearPickerBodyProps
) => {
  const {
    today,
    currentYear,
    currentPageIndex,
    yearGrid,
    allowedYearRange,
    animationClass,
    setAnimationClass,
    selectYear,
  } = props;
  const page = [...yearGrid[currentPageIndex]];
  const groupedData = chunkArray(page, YearPickerChunkType.ROW);
  const thisYear = today.year();

  return (
    <div
      className={classNames(
        classes.Body,
        animationClass === YearPickerAnimationClass.ENTER
          ? classes.Enter
          : null,
        animationClass === YearPickerAnimationClass.EXIT ? classes.Exit : null
      )}
    >
      {groupedData.map((group: number[], groupIndex: number) => {
        return (
          <div key={groupIndex} className={classes.YearGroup}>
            {group.map((year: number, itemIndex: number) => {
              const isYearDisabled = !allowedYearRange.includes(year);

              return (
                <div
                  role={'tab'}
                  key={itemIndex}
                  className={classNames(
                    classes.YearItem,
                    year === thisYear ? classes.ThisYear : null,
                    year === currentYear ? classes.SelectedYear : null,
                    isYearDisabled ? classes.OutsideYear : null
                  )}
                  onClick={() => {
                    !isYearDisabled &&
                      submitYear(year, setAnimationClass, selectYear);
                  }}
                  aria-selected={year === currentYear}
                  aria-disabled={isYearDisabled}
                  tabIndex={isYearDisabled ? -1 : 0}
                  onKeyUp={(e) => {
                    switch (e.key) {
                      case KeyCodes.Enter:
                      case KeyCodes.Spacebar:
                        !isYearDisabled &&
                          submitYear(year, setAnimationClass, selectYear);
                    }
                  }}
                >
                  <span>{year}</span>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
