import { memo, useEffect, useState } from 'react';

import classNames from 'classnames';

import classes from '../CalendarPicker.module.scss';

import Moment from 'moment';
import { KeyCodes, MonthMapping } from '../../../../../../constants';
import Icon from '@mdi/react';
import { mdiChevronUp, mdiChevronDown } from '@mdi/js';
import { translate, Translations } from '../../../../../../locale';
import { Weekdays } from '../Weekdays/Weekdays';
import { Translation } from '../../../../../../locale/types';
import { NavbarElementProps } from 'react-day-picker';

interface NavigationProps {
  locale: string;

  showNextButton: boolean;
  showPreviousButton: boolean;

  isYearPickerOpen: boolean;
  setIsYearPickerOpen: (isYearPickerOpen: boolean) => void;
  onMonthChange: (month: Date) => void;
}

export const Navigation = memo(
  (props: NavigationProps & NavbarElementProps) => {
    const {
      month,
      previousMonth,
      nextMonth,

      showNextButton,
      showPreviousButton,
      isYearPickerOpen,
      setIsYearPickerOpen,
      onMonthChange,
    } = props;

    const isNextButtonDisabled = !showNextButton;
    const isPreviousButtonDisabled = !showPreviousButton;

    const [currentMonthIndex, setCurrentMonthIndex] = useState(
      Moment(month).month()
    );
    const [previousMonthIndex, setPreviousMonthIndex] = useState(
      Moment(previousMonth).month()
    );
    const [nextMonthIndex, setNextMonthIndex] = useState(
      Moment(nextMonth).month()
    );

    useEffect(() => {
      const index = Moment(month).month();
      setCurrentMonthIndex(index);
    }, [month]);

    useEffect(() => {
      const index = Moment(previousMonth).month();
      setPreviousMonthIndex(index);
    }, [previousMonth]);

    useEffect(() => {
      const index = Moment(nextMonth).month();
      setNextMonthIndex(index);
    }, [nextMonth]);

    const getLocalizedMonthName = (monthIndex: number): string => {
      return translate(Translations.monthMapping[monthIndex as MonthMapping]);
    };

    const getLocalizedMonthNavButtonLabel = (
      monthNavKey: Translation,
      monthIndex: number
    ): string => {
      return `${translate(monthNavKey)}: ${getLocalizedMonthName(monthIndex)}`;
    };

    const renderCurrentMonth = () => {
      const localizedMonthName = getLocalizedMonthName(currentMonthIndex);

      return (
        <div className={classes.Month} aria-label={localizedMonthName}>
          {localizedMonthName}
        </div>
      );
    };

    const selectPreviousMonth = () => {
      onMonthChange(previousMonth);
    };

    const selectNextMonth = () => {
      onMonthChange(nextMonth);
    };

    return (
      <div className={classes.NavContainer}>
        <div className={classes.NavRow}>
          <div className={classes.NavDate}>
            <div
              className={classes.YearPicker}
              onClick={() => setIsYearPickerOpen(true)}
              onKeyUp={(e) => {
                switch (e.key) {
                  case KeyCodes.Enter:
                  case KeyCodes.Spacebar:
                    setIsYearPickerOpen(true);
                }
              }}
              tabIndex={0}
            >
              <span>{Moment(month).year()}</span>

              <Icon
                size={1}
                path={isYearPickerOpen ? mdiChevronUp : mdiChevronDown}
              />
            </div>
          </div>

          {renderCurrentMonth()}

          <div className={classes.NavButtons}>
            <div
              className={classNames(
                classes.PrevButton,
                isPreviousButtonDisabled ? classes.Disabled : null
              )}
              onClick={() => selectPreviousMonth()}
              onKeyPress={(e) => {
                switch (e.key) {
                  case KeyCodes.Enter:
                  case KeyCodes.Spacebar:
                    selectPreviousMonth();
                }
              }}
              tabIndex={isPreviousButtonDisabled ? -1 : 0}
              role="button"
              aria-label={getLocalizedMonthNavButtonLabel(
                Translations.previousMonth,
                previousMonthIndex
              )}
              aria-disabled={isPreviousButtonDisabled}
            >
              <Icon path={mdiChevronUp} size={1} />
            </div>

            <div
              className={classNames(
                classes.NextButton,
                isNextButtonDisabled ? classes.Disabled : null
              )}
              onClick={() => selectNextMonth()}
              onKeyPress={(e) => {
                switch (e.key) {
                  case KeyCodes.Enter:
                  case KeyCodes.Spacebar:
                    selectNextMonth();
                }
              }}
              tabIndex={isNextButtonDisabled ? -1 : 0}
              role="button"
              aria-label={getLocalizedMonthNavButtonLabel(
                Translations.nextMonth,
                nextMonthIndex
              )}
              aria-disabled={isNextButtonDisabled}
            >
              <Icon path={mdiChevronDown} size={1} />
            </div>
          </div>
        </div>

        <div className={classes.WeekRow}>
          <Weekdays locale={props.locale} />
        </div>
      </div>
    );
  }
);
