import React, { FunctionComponent } from 'react';
import classes from '../InputClassifier.module.scss';
import { InputClassiferActivityConfig, AutoCompleteItem } from '../types';
import { AppStatus, KeyCodes, NodeType } from '../../../../../constants';
import { AUTO_COMPLETE_LIST_ID } from '../AutoComplete/AutoComplete';
import { DEFAULT_HIGHLIGHTED_CLASSIFIER_INDEX } from '../inputClassifierHelper';
import { sendStatus } from '@/middleware/helpers/mobileAppHelper';
import useEmitOverlayOpened from '@/hooks/useEmitOverlayOpened';
import { focusSinkRef } from '@/containers/Chat/Chat';

export interface ClassifierInputProps {
  searchValue: string;
  setSearchValue: (searchValue: string) => void;
  config: InputClassiferActivityConfig;
  setInputFocused: (inputFocused: boolean) => void;
  highlightedClassifierIndex: number;
  setHighlightedClassifierIndex: (highlightedClassifierIndex: number) => void;
  filteredData: AutoCompleteItem[];
  inputFocused: boolean;
  autoCompleteItemRefs: React.MutableRefObject<(HTMLLIElement | null)[]>;
  sendValue: () => void;
  setInputRef: (element: HTMLElement | null) => void;
  selectClassifier: (selectedClassifier: AutoCompleteItem | null) => void;
}

export const ClassifierInput: FunctionComponent<ClassifierInputProps> = (
  props: ClassifierInputProps
) => {
  const {
    setSearchValue,
    searchValue,
    config,
    setInputFocused,
    setHighlightedClassifierIndex,
    highlightedClassifierIndex,
    filteredData,
    inputFocused,
    autoCompleteItemRefs,
    sendValue,
    setInputRef,
    selectClassifier,
  } = props;

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === KeyCodes.Enter) {
      if (highlightedClassifierIndex !== DEFAULT_HIGHLIGHTED_CLASSIFIER_INDEX) {
        selectClassifier(filteredData[highlightedClassifierIndex]);
      } else {
        sendValue();
      }
    }
  };

  const selectItemAtIndex = (index: number) => {
    setHighlightedClassifierIndex(index);

    autoCompleteItemRefs.current[index]?.setAttribute('aria-selected', 'true');
    autoCompleteItemRefs.current[index]?.scrollIntoView({
      block: 'nearest',
      inline: 'nearest',
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (filteredData.length === 0) {
      return;
    }

    if (event.key === KeyCodes.ArrowUp) {
      event.preventDefault();

      const previousIndex = highlightedClassifierIndex - 1;
      const indexToSet =
        previousIndex <= DEFAULT_HIGHLIGHTED_CLASSIFIER_INDEX
          ? filteredData.length - 1
          : previousIndex;

      selectItemAtIndex(indexToSet);
    }

    if (event.key === KeyCodes.ArrowDown) {
      event.preventDefault();

      const nextIndex = highlightedClassifierIndex + 1;
      const indexToSet = nextIndex > filteredData.length - 1 ? 0 : nextIndex;

      selectItemAtIndex(indexToSet);
    }
  };

  const selectedKey =
    highlightedClassifierIndex > -1 && filteredData && inputFocused
      ? filteredData[highlightedClassifierIndex].key
      : undefined;

  const emitOverlayOpened = useEmitOverlayOpened();

  return (
    <input
      ref={setInputRef}
      className={classes.Input}
      type="text"
      placeholder={config.placeholderMessage}
      aria-label={config.placeholderMessage}
      aria-autocomplete="list"
      aria-activedescendant={selectedKey}
      aria-controls={AUTO_COMPLETE_LIST_ID}
      value={searchValue}
      onChange={(event) => setSearchValue(event.target.value)}
      onFocus={(_) => {
        setInputFocused(true);
        emitOverlayOpened(() => {
          setInputFocused(false);
          focusSinkRef.current && focusSinkRef.current.focus();
        });
        sendStatus(NodeType.InputClassifier, AppStatus.OverlayOpened);
      }}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
    />
  );
};
