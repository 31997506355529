import { ChatRoutes, Country, Locale } from '../constants';
import { TenantMapping } from './types';
import { mdiTruck } from '@mdi/js';

import volviaTheme from './themes/volvia';
import volviaHomeIcon from '../assets/images/homepage/volvia.png';
import volviaLogo from '../assets/images/logos/volvia-logo.svg';
import volviaFavIcon from '../assets/images/favicons/volvia.png';

import polestarHomeIcon from '../assets/images/homepage/polestar.png';
import polestarLogo from '../assets/images/logos/polestar-logo.svg';
import polestarFavIcon from '../assets/images/favicons/polestar.png';
import polestarTheme from './themes/polestar';

const norwayMotorMapping: TenantMapping[] = [
  {
    path: ChatRoutes.NorwayCommercialMotor,
    pageConfig: {
      tenantId: 'f2188e6e-ef53-4c80-8a8f-1053a380ee5a',
      homePageSvgIcon: mdiTruck,
      country: Country.NO,
      locale: Locale.NO,
      businessArea: 'Commercial',
    },
  },

  {
    path: ChatRoutes.NorwayVolvia,
    pageConfig: {
      tenantId: 'f2188e6e-ef53-4c80-8a8f-1053a380ee5a',
      customTheme: volviaTheme,
      logoDesktop: volviaLogo,
      brandName: 'Volvia',
      icon: volviaFavIcon,
      showPageTitle: false,
      homePageIcon: volviaHomeIcon,
      contactSupportLinks: {
        default: 'https://www.volviaforsikring.no/kundeservice',
      },
      portfolio: 'VRF',
      country: Country.NO,
      locale: Locale.NO,
    },
  },
  {
    path: ChatRoutes.NorwayPolestar,
    pageConfig: {
      tenantId: 'f2188e6e-ef53-4c80-8a8f-1053a380ee5a',
      customTheme: polestarTheme,
      logoDesktop: polestarLogo,
      brandName: 'Polestar',
      icon: polestarFavIcon,
      showPageTitle: false,
      homePageIcon: polestarHomeIcon,
      contactSupportLinks: {
        default: 'https://www.polestarinsurance.no/kundeservice/kontakt-oss',
      },
      portfolio: 'Polestar',
      country: Country.NO,
      locale: Locale.NO,
    },
  },
];

export default norwayMotorMapping;
