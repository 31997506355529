import { StyleOptions } from 'botframework-webchat-api';

import chatTheme, { setCssVariables } from '../../../styles';
import { CssGlobalVariables, Theme } from '../../../styles/types';

import { configuredStore } from '@/store';
import { overrideTheme } from '../../../store/theme/actions';
import { setOrReplaceFavoritesIcon } from '../../../utils/utils';
import { getGlobalCssVariable } from '@/utils';

export const createTheme = (): void => {
  const state = configuredStore.store.getState();
  const pageConfig = state.pageConfigState.config;

  if (!pageConfig) {
    return;
  }

  const { customTheme } = pageConfig;
  let mergedTheme = chatTheme;

  if (customTheme) {
    const customColors = { ...customTheme.colors };
    mergedTheme = {
      ...chatTheme,
      colors: customColors,
      fonts: customTheme.fonts,
    } as Theme;

    configuredStore.store.dispatch(overrideTheme(customTheme));
  }

  setCssVariables(mergedTheme);
  setOrReplaceFavoritesIcon(pageConfig.icon);
};

export const getWebchatStyleOptions = (): StyleOptions => {
  return {
    hideSendBox: true,
    groupTimestamp: false,
    scrollToEndButtonBehavior: false,

    transcriptActivityVisualKeyboardIndicatorColor: getGlobalCssVariable(
      CssGlobalVariables.COLOR_FOCUS_OUTLINE
    ),
    transcriptActivityVisualKeyboardIndicatorStyle: 'dashed',
    transcriptActivityVisualKeyboardIndicatorWidth: 1,
    transcriptVisualKeyboardIndicatorColor: 'transparent',
    transcriptVisualKeyboardIndicatorStyle: 'solid',
    transcriptVisualKeyboardIndicatorWidth: 2,
  };
};
