import React, { useEffect, useState } from 'react';
import { Action } from 'redux';

import classNames from 'classnames';
import classes from './Chat.module.scss';

import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/types';
import { PageConfig } from '../../store/page-config/types';
import { AppInsightsEventNames, KeyCodes } from '../../constants';
import { ConfigurableTheme } from '@/styles/types';

import { translate, Translations } from '../../locale';
import { setConsoleVisibility } from '../../store/bot-config/actions';
import {
  getPageConfig,
  hasKnownSearchParamsChanged,
  resetConversation,
  storePageConfig,
} from '@/utils';
import { appInsights } from '@/appInsights/appInsights';

import TypingIndicator from '../../components/UI/TypingIndicator/TypingIndicator';
import Notification from '../../components/UI/Notification/Notification';
import Console from '../../components/UI/Console/Console';
import Header from '../../components/Navigation/Header/Header';
import WebChat from './WebChat';
import FullPageLoader from '@/components/UI/FullPageLoader/FullPageLoader';
import NavigationGuard from '@/components/Navigation/NavigationGuard/NavigationGuard';

const CustomPortalContainer = React.forwardRef<HTMLDivElement>((_, ref) => (
  <div ref={ref} className={classes.CustomPortalContainer} />
));
export const customPortalRef = React.createRef<HTMLDivElement>();

const FocusSink = React.forwardRef<HTMLDivElement>((_, ref) => (
  <div
    ref={ref}
    aria-disabled="true"
    role="textbox"
    className={classes.FocusSink}
  />
));
export const focusSinkRef = React.createRef<HTMLDivElement>();

const Chat = (): JSX.Element => {
  const [isPageConfigInitiated, setIsPageConfigInitiated] = useState(false);

  const pageConfig = useSelector<RootState, PageConfig | null>(
    (state) => state.pageConfigState.config || null
  );

  const hasAlert = useSelector<RootState, boolean>((state) => {
    return state.globalConfigState.hasAlert;
  });

  const isConsoleVisible = useSelector<RootState, boolean>(
    (state) => state.botConfigState.isConsoleVisible
  );

  const firstVisibleMessageReceived = useSelector<RootState, boolean>(
    (state) => state.botConfigState.firstVisibleMessageReceived
  );

  const isTypingVisible = useSelector<RootState, boolean>(
    (state) => state.botConfigState.isTypingVisible
  );

  const locale = useSelector<RootState, string | undefined>(
    (state) => state.pageConfigState.config?.locale
  );

  const chatTheme = useSelector<RootState, ConfigurableTheme | null>(
    (state) => state.themeState.theme || null
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const currentPageConfig = getPageConfig();
    const { tenantId, locale: currentPageConfigLocale } = currentPageConfig;

    if (
      pageConfig &&
      hasKnownSearchParamsChanged(currentPageConfig, pageConfig)
    ) {
      appInsights.trackEvent({
        name: AppInsightsEventNames.PageConfigurationChanged,
      });
      resetConversation(dispatch);
    }

    storePageConfig(currentPageConfig);
    setIsPageConfigInitiated(true);

    if (!tenantId || !currentPageConfigLocale) {
      throw new Error(
        'Missing mandatory page config params, review tenant mapping'
      );
    }
  }, []);

  useEffect(() => {
    const consoleVisibilityHandler = (
      storeDispatch: React.Dispatch<Action>,
      event: KeyboardEvent
    ) => {
      if (event.ctrlKey && event.shiftKey && event.key === KeyCodes.F9) {
        storeDispatch(setConsoleVisibility(true));
      }

      if (event.key === KeyCodes.Escape) {
        storeDispatch(setConsoleVisibility(false));
      }
    };

    document.addEventListener(
      'keyup',
      consoleVisibilityHandler.bind(null, dispatch)
    );

    return () => {
      document.removeEventListener(
        'keyup',
        consoleVisibilityHandler.bind(null, dispatch)
      );
    };
  }, [dispatch]);

  const showPageTitle = !!pageConfig?.showPageTitle;
  const headerColorsInverted = !!pageConfig?.headerColorsInverted;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const titleKey =
    (pageConfig &&
      pageConfig.pageTitleTranslationKey &&
      //@ts-ignore
      Translations[pageConfig.pageTitleTranslationKey]) ||
    Translations.pageTitle;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const title = translate(titleKey);
  document.title = title;

  return (
    <div
      className={classNames(classes.ChatContainer, {
        [classes.ChatAlert]: hasAlert,
      })}
    >
      <NavigationGuard />
      <Header
        title={title}
        showTitle={showPageTitle}
        logo={true}
        menu={true}
        invertedColors={headerColorsInverted}
      />

      <Notification />

      {isConsoleVisible && <Console />}

      <div className={classes.Chat}>
        {!firstVisibleMessageReceived && <FullPageLoader />}
        {isPageConfigInitiated && (
          <WebChat locale={locale} chatTheme={chatTheme} />
        )}

        {isTypingVisible && <TypingIndicator />}
        <FocusSink ref={focusSinkRef} />
        <CustomPortalContainer ref={customPortalRef} />
      </div>
    </div>
  );
};

export default Chat;
