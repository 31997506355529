import { ChatRoutes, Locale } from '../constants';
import { TenantMapping } from './types';
import { mdiAirplane, mdiAirplaneSettings } from '@mdi/js';

const denmarkTravelMapping: TenantMapping[] = [
  {
    path: ChatRoutes.DenmarkPrivateTravel,
    pageConfig: {
      tenantId: '79749363-d341-4f89-8f9e-26b19bb271f9',
      locale: Locale.DK,
      brandName: 'Private',
      homePageSvgIcon: mdiAirplane
    },
  },

  {
    path: ChatRoutes.DenmarkCommercialTravel,
    pageConfig: {
      tenantId: '8e48ea4b-1ced-4bef-9f37-77537724ba17',
      locale: Locale.DK,
      brandName: 'Commercial',
      homePageSvgIcon: mdiAirplaneSettings
    },
  }
];

export default denmarkTravelMapping;
