import {
  forwardRef,
  ForwardRefExoticComponent,
  ReactNode,
  RefAttributes,
} from 'react';

import classes from './RoundButton.module.scss';

import classNames from 'classnames';

interface RoundButtonProps {
  onClick: () => void;
  onFocus?: () => void;
  disabled?: boolean;
  children: ReactNode;
  ariaLabel?: string;
}

const RoundButton: ForwardRefExoticComponent<
  RoundButtonProps & RefAttributes<HTMLButtonElement>
> = forwardRef<HTMLButtonElement, RoundButtonProps>(
  (
    { onClick, onFocus, disabled, children, ariaLabel }: RoundButtonProps,
    ref
  ) => {
    return (
      <button
        aria-label={ariaLabel}
        className={classNames('if button primary', classes.Button, {
          [classes.Disabled]: disabled,
        })}
        onFocus={onFocus}
        onClick={onClick}
        disabled={disabled}
        ref={ref}
      >
        {children}
      </button>
    );
  }
);

export default RoundButton;
