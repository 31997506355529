export enum DataCustomActivity {
  SUBMIT_TEXT_INPUT = 'SUBMIT_TEXT_INPUT',
  SUBMIT_INPUT_CLASSIFIER = 'SUBMIT_INPUT_CLASSIFIER',
  SUBMIT_TIME_INPUT = 'SUBMIT_TIME_INPUT',
  SUBMIT_MANUAL_DATE_INPUT = 'SUBMIT_MANUAL_DATE_INPUT',
  SUBMIT_RATING_STARS = 'SUBMIT_RATING_STARS',
  SUBMIT_MULTIPLE_CHOICE = 'SUBMIT_MULTIPLE_CHOICE',
  SUBMIT_BUG_REPORT = 'SUBMIT_BUG_REPORT',
  SUBMIT_FILE_UPLOAD = 'SUBMIT_FILE_UPLOAD',
  SUBMIT_CURRENCY_INPUT = 'SUBMIT_CURRENCY_INPUT',

  SELECT_ACTION_ITEM_AT_INDEX = 'SELECT_ACTION_ITEM_AT_INDEX',
  SELECT_SUMMARY_ITEM_AT_INDEX = 'SELECT_SUMMARY_ITEM_AT_INDEX',
  SELECT_MULTIPLE_CHOICE_ITEM_AT_INDEX = 'SELECT_MULTIPLE_CHOICE_ITEM_AT_INDEX',

  OPEN_DATE_PICKER = 'OPEN_DATE_PICKER',
  UPLOAD_DROPZONE = 'UPLOAD_DROPZONE',

  CLOSE_BUG_REPORT = 'CLOSE_BUG_REPORT',
  CLOSE_FILE_UPLOAD = 'CLOSE_FILE_UPLOAD',

  CLEAR_REJECTED_FILES = 'CLEAR_REJECTED_FILES',
  REMOVE_FILE_AT_INDEX = 'REMOVE_FILE_AT_INDEX',

  SIGN_OUT = 'SIGN_OUT',
  SOMETHING_WENT_WRONG = 'SOMETHING_WENT_WRONG',
}

export enum DataModalAttribute {
  MODAL_ROOT_ELEMENT = 'MODAL_ROOT_ELEMENT',
}
