// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FullPageLoader-module_FullPageLoader--FAFbo{position:absolute;top:0;left:0;display:flex;justify-content:center;align-items:center;width:100vw;height:100vh}", "",{"version":3,"sources":["webpack://./src/components/UI/FullPageLoader/FullPageLoader.module.scss"],"names":[],"mappings":"AAAA,6CACE,iBAAA,CACA,KAAA,CACA,MAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,WAAA,CACA,YAAA","sourcesContent":[".FullPageLoader {\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  width: 100vw;\r\n  height: 100vh;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FullPageLoader": "FullPageLoader-module_FullPageLoader--FAFbo"
};
export default ___CSS_LOADER_EXPORT___;
