import { FunctionComponent, ReactElement, useState, useEffect } from 'react';
interface SilentAuthenticationProps {
  children: ReactElement;
}

export const WEBCHAT_SESSION_STORAGE_AUTH_REDIRECT_KEY =
  'webchat-is-silent-auth-redirect-done';

export const CURITY_AUTH_BASE_URI = '/auth/curitylogin';

const SilentAuthentication: FunctionComponent<SilentAuthenticationProps> = ({
  children,
}: SilentAuthenticationProps) => {
  const [silentAuthComplete] = useState(
    window.sessionStorage.getItem(WEBCHAT_SESSION_STORAGE_AUTH_REDIRECT_KEY) ===
      'true'
  );

  useEffect(() => {
    const redirectUrl = window.location.pathname + window.location.search;
    if (!silentAuthComplete) {
      window.sessionStorage.setItem(
        WEBCHAT_SESSION_STORAGE_AUTH_REDIRECT_KEY,
        JSON.stringify(true)
      );

      window.location.replace(
        `${CURITY_AUTH_BASE_URI}?redirectUri=${encodeURIComponent(
          redirectUrl
        )}&prompt=none`
      );
    }
  }, []);

  return <>{silentAuthComplete && children}</>;
};

export default SilentAuthentication;
