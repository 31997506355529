import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import classes from './CurrencyInputComponent.module.scss';
import { translate, Translations } from '../../../../locale';

import { Currency } from '../../../../constants';

export interface CurrencyInputProps {
  inputValue: string;
  inputPlaceholder: string;
  currencyLabel: string;
  clickedOutside: boolean;

  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  keyPressHandler: (event: React.KeyboardEvent) => void;
}

export default function CurrencyInput(props: CurrencyInputProps): JSX.Element {
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setIsFocused(!props.clickedOutside);
  }, [props.clickedOutside]);

  return (
    <div
      className={classNames(
        classes.CurrencyContainer,
        isFocused ? classes.Focused : null
      )}
      data-testid={classes.CurrencyContainer}
    >
      <span
        className={classes.CurrencyLabel}
        aria-label={props.currencyLabel}
        data-testid={classes.CurrencyLabel}
      >
        {props.currencyLabel}
      </span>

      <input
        className={classes.CurrencyInput}
        value={props.inputValue}
        aria-required="true"
        aria-autocomplete="none"
        aria-multiline="false"
        aria-label={translate(
          Translations.currencyLabelMap[props.currencyLabel as Currency]
        )}
        placeholder={props.inputPlaceholder}
        autoFocus={true}
        autoComplete="off"
        onChange={props.onChangeHandler}
        onKeyPress={props.keyPressHandler}
        data-testid={classes.CurrencyInput}
      />
    </div>
  );
}
