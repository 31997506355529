import { FunctionComponent, useEffect, useState } from 'react';

import { ListSelectionStyle, Record, Category } from './types';

import getCategoriesFromDataSource from './utils/getCategoriesFromDataSource';
import getPreviousUserResponse from './utils/getPreviousUserResponse';
import createUserResponseText from './utils/createUserResponseText';

import { CustomActivityConfig } from '@/middleware/types';
import { BotPassThroughProps } from '@/containers/BotMessageBase/BotMessageBase';
import { findCustomActivity } from '@/middleware/activityMiddleware';
import { canBeEditedCustomUserResponse, sendMessageBack } from '@/utils';
import { translate, Translations } from '@/locale';

import Modal from '@/components/UI/Modal/Modal';
import ModalFooter from './ModalFooter/LongListActivityModalFooter';
import ActionButtons from '@/containers/BotMessageBase/ActionButtons/ActionButtons';
import CategoryList from './CategoryList/CategoryList';
import { sendStatus } from '@/middleware/helpers/mobileAppHelper';
import { AppStatus, CustomEvent, NodeType } from '@/constants';
import { removeLastChatStatus } from '@/store/chat-activities-config/actions';
import { useDispatch } from 'react-redux';
import { emitCustomEvent } from 'react-custom-events';
import useEmitOverlayOpened from '@/hooks/useEmitOverlayOpened';
import { trackLongListOverlayToggled } from '@/utils/appInsightHelper';

export interface LongListActivityConfig extends CustomActivityConfig {
  dataSource?: string;
  title: string;
  openButtonText?: string;
  maxItemsToSelect: number;
  maxItemsToSelectErrorMessage: string;
  selectedCodes?: string;
  selectedCodesSeparatorCharacter?: string;
}

const LongListActivity: FunctionComponent<BotPassThroughProps> = (
  props: BotPassThroughProps
) => {
  const longListActivity = findCustomActivity(
    props.card.activity
  ) as LongListActivityConfig;
  const longListCategories = getCategoriesFromDataSource(longListActivity);
  const dispatch = useDispatch();

  //Currently only 1 category is supported
  const firstCategory = longListCategories[0] as Category | undefined;

  const previousUserResponse = getPreviousUserResponse(
    longListActivity,
    firstCategory?.records ?? []
  );

  const [selectedRecords, setSelectedRecords] = useState(previousUserResponse);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const selectionStyle =
    longListActivity.maxItemsToSelect > 0
      ? ListSelectionStyle.MultipleChoices
      : ListSelectionStyle.SingleChoice;

  useEffect(() => {
    const getSingleChoiceError = () => {
      return selectedRecords.length > 1
        ? longListActivity.maxItemsToSelectErrorMessage
        : null;
    };

    const getMultipleChoiceError = () => {
      return selectedRecords.length > longListActivity.maxItemsToSelect
        ? longListActivity.maxItemsToSelectErrorMessage
        : null;
    };

    setError(
      selectionStyle === ListSelectionStyle.SingleChoice
        ? getSingleChoiceError()
        : getMultipleChoiceError()
    );
  }, [selectedRecords.length]);

  const onModalSubmit = () => {
    setIsModalVisible(false);
    submit();
    emitCustomEvent(CustomEvent.OverlayClosed);
    dispatch(removeLastChatStatus())
  };

  const submit = () => {
    sendMessageBack(
      createUserResponseText(selectedRecords),
      {
        records: selectedRecords,
        canBeEdited: canBeEditedCustomUserResponse(props.card),
      },
      props.card.activity
    );
  };

  const onModalCancel = () => {
    setIsModalVisible(false);
    setSelectedRecords(previousUserResponse);
    emitCustomEvent(CustomEvent.OverlayClosed);
    dispatch(removeLastChatStatus())
  };

  const onRecordMultipleChoiceClick = (item: Record) => {
    setSelectedRecords((previousRecords) => {
      const foundItemIndex = previousRecords.findIndex(
        (previousRecord) => previousRecord.code === item.code
      );

      if (foundItemIndex > -1) {
        return previousRecords.filter(
          (previousRecord) => previousRecord.code !== item.code
        );
      } else {
        return [...previousRecords, item].sort((record1, record2) => {
          if (record1.order === undefined) {
            return 1;
          } else if (record2.order === undefined) {
            return -1;
          }
          return record1.order - record2.order;
        });
      }
    });
  };

  const onRecordSingleChoiceClick = (item: Record) => {
    setSelectedRecords([item]);
  };

  const emitOverlayOpened = useEmitOverlayOpened();

  return (
    <>
      {firstCategory && (
        <Modal
          modalVisible={isModalVisible}
          disableFocusTrap
          title={longListActivity.title}
          shouldCloseOnEscape={true}
          closeHandler={() => {
            setIsModalVisible(false);
            emitCustomEvent(CustomEvent.OverlayClosed);
            dispatch(removeLastChatStatus());
          }}
          modalBody={
            <CategoryList
              category={firstCategory}
              selectedRecords={selectedRecords}
              selectionStyle={selectionStyle}
              validationMessage={error}
              onRecordSingleChoiceClick={onRecordSingleChoiceClick}
              onRecordMultipleChoiceClick={onRecordMultipleChoiceClick}
            />
          }
          modalFooter={
            <ModalFooter
              disablePrimaryButton={selectedRecords.length === 0 || !!error}
              submit={onModalSubmit}
              cancel={onModalCancel}
            />
          }
        />
      )}

      <ActionButtons
        {...props}
        focusFirstAction={!isModalVisible}
        disableOnClick={isModalVisible}
        hideChildrenOfElement={() => {
          //empty callback
        }}
        actions={[
          {
            type: '',
            data: null,
            title:
              longListActivity.openButtonText ?? translate(Translations.select),
          },
        ]}
        onClickHandler={() => {
          setIsModalVisible(true);

          trackLongListOverlayToggled('opened');

          emitOverlayOpened(() => {
            // need more logs to understand if `onpopstate` event doesn't get triggered immediately for some devices.
            trackLongListOverlayToggled('closed');
            setIsModalVisible(false);
          });
          sendStatus(NodeType.LongList, AppStatus.OverlayOpened);
        }}
      />
    </>
  );
};

export default LongListActivity;
