import { ClientSideConfiguration } from '@/api/types';

export interface GlobalConfigState {
  isMobile: boolean;
  isDesktop: boolean;
  hasAlert: boolean;
  clientSideConfiguration: ClientSideConfiguration | null;
}

export enum GlobalConfigActionTypes {
  SET_IS_MOBILE = 'GLOBAL_CONFIG/SET_IS_MOBILE',
  SET_IS_DESKTOP = 'GLOBAL_CONFIG/SET_IS_DESKTOP',
  SET_CLIENT_SIDE_CONFIG = 'GLOBAL_CONFIG/SET_CLIENT_SIDE_CONFIG',
  SET_HAS_ALERT = 'GLOBAL_CONFIG/SET_HAS_ALERT',
}
