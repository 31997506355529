import { ConfigurableTheme } from '../../styles/types';

export default {
  colors: {
    error: '#FF0012',
    errorBackground: '#FFD6D9',
    errorDark: '#FF0012',

    background: '#F5F5F5',
    backgroundSecondary: '#FFFFFF',
    headerBackground: '#FFFFFF',
    headerText: '#141414',
    menuArrow: '#141414',
    modalBackdrop: 'rgba(20, 20, 20, 0.25)',

    secondary: '#141414',
    secondaryLight: '#D6D6D6',
    secondarySupportLight: '#FFFFFF',
    secondarySupport: '#858585',

    action: '#141414',
    actionDark: '#5C5C5C',
    actionLight: '#858585',
    actionLightest: '#ADADAD',

    actionSecondary: '#FFFFFF',
    actionSecondaryDark: '#FFFFFF',
    actionSecondaryDarker: '#F5F5F5',

    actionText: '#FFFFFF',
    actionTextDark: '#141414',
    actionTextLight: '#858585',

    focusOutline: '#141414',

    inputBackground: '#FFFFFF',
    inputPlaceholderFocus: '#ADADAD',
    inputSupport: '#ADADAD',

    botMessageBackground: '#D6D6D6',
    userMessageBackground: '#FFFFFF',
    messageText: '#333333',

    botActionBackground: '#FFFFFF',
    botActionText: '#141414',
    botActionBackgroundHover: 'rgba(20, 20, 20, 0.02)',
    botActionBackgroundActive: 'rgba(20, 20, 20, 0.02)',

    calendarItemHover: '#D6D6D6',
    calendarItemBackgroundHover: '#FFFFFF',
    calendarItemCurrent: '#141414',
    calendarItemSelected: '#141414',
    calendarItemSelectedBackground: '#F5F5F5',
    calendarItemOutside: '#ADADAD',
    calendarSecondaryItem: '#141414',

    borderDark: '#5C5C5C',
    borderMedium: '#858585',
    borderLight: '#D6D6D6',
  },
  fonts: {
    fontStack: '"se-motor-mg"',
  },
} as ConfigurableTheme;
