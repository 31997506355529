import { useState, FunctionComponent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/store/types';
import { AppStatus, CustomEvent } from '@/constants';
import { ButtonPrimary, ButtonSecondary } from '@ids-react/button';
import { IconSize, Icon } from '@ids-react/icon';
import UI_ICONS from '@ids-core/icons/src/ui-icons.js';
import classes from './NavigationGuard.module.scss';
import classNames from 'classnames';
import { translate, Translations } from '@/locale';
import { useNavigate } from 'react-router';
import { useCustomEventListener } from 'react-custom-events';
import Modal from "@/components/UI/Modal/Modal";

const NavigationGuard: FunctionComponent = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const chatStatuses = useSelector<RootState, AppStatus[]>(
    (state) => state.chatActivitiesState.chatStatuses
  );

  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [
    additionalHistoryStateItemWasPushed,
    setAdditionalHistoryStateItemWasPushed,
  ] = useState(false);

  useEffect(() => {
    setShouldBlockNavigation(
        chatStatuses.includes(AppStatus.ClaimStarted) &&
        !chatStatuses.includes(AppStatus.ClaimSubmitted)
    );
  }, [chatStatuses]);

  useCustomEventListener(CustomEvent.OverlayOpened, (callback: () => void) => {
      if (!additionalHistoryStateItemWasPushed) {
        window.history.pushState(null, '', window.location.href);
      }
      window.onpopstate = callback;
  });

  useCustomEventListener(CustomEvent.OverlayClosed, () => {
      if (shouldBlockNavigation) {
        window.history.pushState(null, '', window.location.href);
        window.onpopstate = showExitPrompt;
      } else {
        window.onpopstate = null;
      }
  });

  const confirmNavigation = () => {
    setIsModalVisible(false);
    window.onpopstate = null;
    navigate(-2);
  };

  const cancelNavigation = () => {
    setIsModalVisible(false);
  };

  const showExitPrompt = (_: PopStateEvent) => {
    setIsModalVisible(true);
    window.history.pushState(null, '', window.location.href);
  };

  useEffect(() => {
    if (shouldBlockNavigation) {
      if (!additionalHistoryStateItemWasPushed) {
        window.history.pushState(null, '', window.location.href);
        setAdditionalHistoryStateItemWasPushed(true);
      }
      window.onpopstate = showExitPrompt;
    }

    return () => {
      if (shouldBlockNavigation) {
        window.onpopstate = null;
        navigate(-1);
      }
    };
  }, [shouldBlockNavigation]);

  return (
    <>
      {isModalVisible && (
        <Modal modalVisible={isModalVisible}
        title={translate(Translations.navigationGuard.title)}
               modalBody={
          <div style={{ display: 'flex' }}>
            <div className={classes.Icon}>
              <Icon className={UI_ICONS.warning} size={IconSize[28]} />
            </div>
            <span className={classes.ModalText}>
              {translate(Translations.navigationGuard.text)}
            </span>
          </div>
        } modalFooter={
          <div className={classNames(classes.ModalFooterWrapper)}>
          <div
            className={classNames(
              classes.SecondaryButtonWrapper,
              classes.ButtonWrapper
            )}
          >
            <ButtonSecondary
              onClick={confirmNavigation}
              className={classes.Button}
              disabled={false}
            >
              {translate(Translations.navigationGuard.confirm)}
            </ButtonSecondary>
          </div>
            <div className={classes.ButtonWrapper}>
              <ButtonPrimary
                onClick={cancelNavigation}
                className={classes.Button}
              >
                {translate(Translations.navigationGuard.cancel)}
              </ButtonPrimary>
            </div>
          </div>}/>
      )}
    </>
  );
};
export default NavigationGuard;
