import { FunctionComponent } from 'react';

import classes from '../Menu.module.scss';
import classNames from 'classnames';
import { KeyCodes } from '../../../../constants';

export interface AccessibilityStatementProps {
  accessibilityStatementUrl: string;
  accessibilityStatementText: string;
}

export const AccessibilityStatement: FunctionComponent<
  AccessibilityStatementProps
> = (props: AccessibilityStatementProps) => {
  const { accessibilityStatementUrl, accessibilityStatementText } = props;

  return (
    <div
      tabIndex={-1}
      role="menuitem link"
      data-drop-down-item={'accessibility-statement'}
      className={classNames(
        classes.DropdownMenuItem,
        classes.DropdownMenuItemAnchor
      )}
      onKeyPress={(e) => {
        if (e.key === KeyCodes.Spacebar || e.key === KeyCodes.Enter) {
          const win = window.open(accessibilityStatementUrl);
          win?.focus();
        }
      }}
    >
      <a
        tabIndex={-1}
        href={accessibilityStatementUrl}
        title={accessibilityStatementText}
        target="_blank"
        rel="noopener noreferrer"
      >
        {accessibilityStatementText}
      </a>
    </div>
  );
};

export default AccessibilityStatement;
