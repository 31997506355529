import { memo } from 'react';
import classes from './AutoComplete.module.scss';
import { AutoCompleteItem } from '../types';

const SearchItemValue = memo(
  (props: { search: string; item: AutoCompleteItem }) => {
    const { item, search } = props;
    const searchIndex = item.value.toLowerCase().indexOf(search.toLowerCase());
    const firstPart = item.value.substr(0, searchIndex);
    const searchPart = item.value.substr(searchIndex, search.length);
    const lastPart = item.value.substring(
      searchIndex + search.length,
      item.value.length
    );

    return search.length > 0 ? (
      <span className={classes.ItemValue} aria-label={item.value}>
        {firstPart}
        <span className={classes.SearchPart}>{searchPart}</span>
        {lastPart}
      </span>
    ) : (
      <span className={classes.ItemValue}>{item.value}</span>
    );
  }
);

export default SearchItemValue;
