import { NotificationActionTypes, NotificationState } from './types';
import { Reducer, AnyAction } from 'redux';

export const notificationInitialState: NotificationState = {
  notifications: [],
};

const notificationReducer: Reducer<NotificationState> = (
  state: NotificationState = notificationInitialState,
  action: AnyAction
): NotificationState => {
  switch (action.type) {
    case NotificationActionTypes.SHOW_NOTIFICATION: {
      return { notifications: [...state.notifications, action.payload] };
    }
    case NotificationActionTypes.HIDE_NOTIFICATION: {
      return {
        notifications: state.notifications.filter(x => x.id !== action.payload),
      };
    }
    default: {
      return state;
    }
  }
};

export { notificationReducer };
