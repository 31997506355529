import { ChatRoutes, Country, Locale } from '../constants';
import { TenantMapping } from './types';
import { mdiHome, mdiHomeCity } from '@mdi/js';

const swedenPropertyMapping: TenantMapping[] = [
  {
    path: ChatRoutes.SwedenProperty,
    pageConfig: {
      tenantId: 'cb7d01ab-b592-4d9e-977f-84525c1b6b4b',
      locale: Locale.SV,
      homePageSvgIcon: mdiHome,
      brandName: 'Private',
      country: Country.SE,
    },
  },
  {
    path: ChatRoutes.SwedenCommercialProperty,
    pageConfig: {
      tenantId: 'ef254b02-c8f0-4c8e-b889-8170eb65bdc7',
      locale: Locale.SV,
      homePageSvgIcon: mdiHomeCity,
      brandName: 'Commercial',
      country: Country.SE,
    },
  },
];

export default swedenPropertyMapping;
