import { translate, Translations } from '../locale';

export const bytesToSize = (bytes: number): string => {
  if (bytes < 0) {
    return translate(Translations.invalidSize);
  }

  if (bytes === 0) {
    return `0 ${translate(Translations.bytes)}`;
  }

  const sizes = [translate(Translations.bytes, bytes), 'KB', 'MB', 'GB', 'TB'];
  const power = Math.floor(Math.log(bytes) / Math.log(1024));
  if (power === 0) return `${bytes} ${sizes[power]}`;
  return `${(bytes / 1024 ** power).toFixed(1)} ${sizes[power]}`;
};

export default bytesToSize;
