import { setRetryRejectedActivitiesLimit } from '../../../store/recovery/actions';
import { FeatureToggles, getIntToggle } from '../../../utils/featureToggles';
import { configuredStore } from '@/store';

export const setupRecovery = () => {
  const { dispatch } = configuredStore.store;

  dispatch(
    setRetryRejectedActivitiesLimit(
      getIntToggle(FeatureToggles.retryRejectedActivitiesLimit)
    )
  );
};
