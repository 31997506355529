import { Dispatch, SetStateAction } from 'react';
import { CustomActivityConfig } from '../../../../middleware/types';
import { ClickOutsidePassThroughProps } from '../../../../containers/ClickOutside/ClickOutside';
import { BotPassThroughProps } from '../../../../containers/BotMessageBase/BotMessageBase';

export const NO_RATING: NoRating = undefined;

export type NoRating = undefined;
export type RatingValue = number | NoRating;

export interface RatingStarsActivityConfig extends CustomActivityConfig {
  starCount: number;
  skipValue: string;
  submitHint: string;
  ratingHint: string;
}

export type RatingStarsProps = BotPassThroughProps &
  ClickOutsidePassThroughProps & {
    rating: RatingValue;
    setRating: Dispatch<SetStateAction<RatingValue>>;
    hoverRating: RatingValue;
    setHoverRating: Dispatch<SetStateAction<RatingValue>>;
    keyboardRating: RatingValue;
    setKeyboardRating: Dispatch<SetStateAction<RatingValue>>;
    config: RatingStarsActivityConfig;
  };
