import { TimeFields, TimePreset, TimePart } from './types';

export const numbersOnlyRegex = /^[0-9]*$/;

export const areAllPartsValid = (
  fields: TimeFields,
  timePreset: TimePreset
): boolean =>
  Object.entries(fields).every(
    ([timePart, timePartValue]) =>
      timePartValue.length <
        timePreset[timePart as TimePart].placeholder.length ||
      RegExp(timePreset[timePart as TimePart].regex).test(timePartValue)
  );

export const timeFieldsHaveValue = (fields: TimeFields): boolean =>
  Object.keys(fields).every((t) => fields[t as TimePart].length > 0);
