import { forwardRef } from 'react';

import classes from './ButtonPrimary.module.scss';

import {
  ButtonPrimary as IdsButtonPrimary,
  ButtonProps,
} from '@ids-react/button';

const ButtonPrimary: React.ForwardRefExoticComponent<
  ButtonProps & React.RefAttributes<HTMLButtonElement>
> = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  return (
    <IdsButtonPrimary className={classes.ButtonPrimary} ref={ref} {...props} />
  );
});

export default ButtonPrimary;
