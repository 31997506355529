import { canBeEditedCustomUserResponse } from '../../../../utils';
import { sendMessageBack } from '../../../../utils/directLineUtils';
import {
  Card,
  CustomActivity,
  CustomUserResponse,
} from '../../../../middleware/types';
import { AutoCompleteItem, InputClassifierSendValueProps } from './types';
import { trackClassifierComponentInteracted } from '../../../../utils/appInsightHelper';
import { AppInsightsUserInteraction } from '../../../../constants';
import { translate, Translations } from '../../../../locale';
import { callApi } from '@/api/callApi';

export const DEFAULT_HIGHLIGHTED_CLASSIFIER_INDEX = -1;
const SEARCH_RESULT_DEFAULT_MAX_ITEM_COUNT = 50;

export const sendValue = (props: InputClassifierSendValueProps): void => {
  const {
    card,
    allowAnyValue,
    hideElement,
    hasDataSet,
    selectedClassifier,
    searchValue,
    dataSet,
  } = props;

  if (!searchValue) {
    return;
  }

  const resultClassifier = getResultingClassifier(
    selectedClassifier,
    dataSet,
    hasDataSet,
    searchValue
  );

  if (resultClassifier) {
    sendResponseFromClassifier(resultClassifier, card, hideElement);
    return;
  }

  if ((hasDataSet && allowAnyValue) || !hasDataSet) {
    sendFreeTextResponse(searchValue, card, hideElement);
  }
};

const getResultingClassifier = (
  selectedClassifier: AutoCompleteItem | null,
  dataSet: AutoCompleteItem[],
  hasDataSet: boolean,
  searchValue: string
) => {
  if (selectedClassifier) {
    return selectedClassifier;
  }

  if (hasDataSet) {
    return dataSet.find((item) => compareString(item, searchValue));
  }

  return null;
};

const sendFreeTextResponse = (
  text: string,
  card: Card,
  hideElement: () => void
) => {
  const customResponse = {
    canBeEdited: canBeEditedCustomUserResponse(card),
    code: '',
    text,
  } as CustomUserResponse;

  sendCustomClassifierMessage(text, customResponse, hideElement, card.activity);
};

const sendResponseFromClassifier = (
  selectedClassifier: AutoCompleteItem,
  card: Card,
  hideElement: () => void
) => {
  const customResponse = {
    canBeEdited: canBeEditedCustomUserResponse(card),
    code: selectedClassifier.key,
    text: selectedClassifier.value,
  } as CustomUserResponse;

  sendCustomClassifierMessage(
    selectedClassifier.value,
    customResponse,
    hideElement,
    card.activity
  );
};

const sendCustomClassifierMessage = (
  text: string,
  customResponse: CustomUserResponse,
  hideElement: () => void,
  activity?: CustomActivity
) => {
  sendMessageBack(text, customResponse, activity);

  trackClassifierComponentInteracted(
    customResponse.customEntity ? customResponse.customEntity.id : '',
    AppInsightsUserInteraction.ClassifierValueConfirmed,
    text
  );

  hideElement();
};

export const transformDataSet = (
  dataSet: Record<string, string>
): AutoCompleteItem[] => {
  return Object.keys(dataSet).map((key, index) => {
    return {
      key,
      value: dataSet[key],
      order: index,
    } as AutoCompleteItem;
  });
};

export const getFromApi = async (url: string): Promise<AutoCompleteItem[]> => {
  const { data: apiResponse } = await callApi({ url, baseURL: '' });
  return transformDataSet(apiResponse as Record<string, string>);
};

export const orderedSearchResults = (
  data: AutoCompleteItem[],
  search: string
): AutoCompleteItem[] => {
  if (!search) {
    return data;
  }

  search = search.toLowerCase();

  return data
    .filter(
      (item: AutoCompleteItem) =>
        item.value.toLowerCase().startsWith(search) ||
        anyWordStartsWith(item.value, search)
    )
    .map((item: AutoCompleteItem): AutoCompleteItem => {
      item.order = item.value.toLowerCase().indexOf(search);
      return item;
    })
    .sort((itemOne, itemTwo): number => {
      return itemOne.order > itemTwo.order ||
        (itemOne.order === itemTwo.order && itemOne.value > itemTwo.value)
        ? 1
        : -1;
    })
    .slice(0, SEARCH_RESULT_DEFAULT_MAX_ITEM_COUNT);
};

const anyWordStartsWith = (input: string, search: string) => {
  const wordsInInput = input.toLocaleLowerCase().split(' ');
  return wordsInInput.filter((word) => word.startsWith(search)).length > 0;
};

export const getAutoCompleteResultsTranscript = (
  searchValue: string,
  dataSet: AutoCompleteItem[],
  noResultsFoundMessage: string
) => {
  if (dataSet.length === 0) {
    return noResultsFoundMessage;
  }

  if (searchValue.length <= 1) {
    return '';
  }

  const separator = ', ';
  const resultsCombined = dataSet
    .map((autoCompleteItem) => autoCompleteItem.value)
    .join(separator);

  return translate(Translations.inputClassifier.availableOptions).replace(
    '{resultsCombined}',
    resultsCombined
  );
};

export const compareString = (
  autoCompleteItem: AutoCompleteItem,
  searchValue: string
): boolean => {
  return (
    autoCompleteItem.value.localeCompare(searchValue, undefined, {
      sensitivity: 'accent',
    }) === 0
  );
};
