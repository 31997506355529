import { createStore } from 'botframework-webchat-core';
import { chatStoreMiddlewareWrapper } from '../../../middleware/chatStoreMiddleware';
import { Store } from 'redux';

import { ThrowErrorFunction } from '@/hooks';

export let chatStore: Store | undefined;

export const createChatStore = ({
  triggerReconnect,
  throwError,
}: {
  triggerReconnect: () => void;
  throwError: ThrowErrorFunction;
}): Store => {
  chatStore = createStore(
    null,
    ...[chatStoreMiddlewareWrapper(triggerReconnect, throwError)]
  );

  return chatStore;
};
