import { forwardRef } from 'react';

import Icon from '@mdi/react';
import { mdiSend } from '@mdi/js';
import { Card } from '../../../../../middleware/types';

import { AutoCompleteItem } from '../types';
import { compareString } from '../inputClassifierHelper';

import { translate, Translations } from '@/locale';

import RoundButton from '@/components/RoundButton/RoundButton';

export interface ClassifierSendButtonProps {
  hasDataSet: boolean;
  searchValue: string;
  dataSet: AutoCompleteItem[];
  setInputFocused: (inputFocused: boolean) => void;
  card: Card;
  hideElement: () => void;
  sendValue: () => void;
  allowAnyValue: boolean;
}

export const ClassifierInputSendButton = forwardRef<
  HTMLButtonElement,
  ClassifierSendButtonProps
>((props: ClassifierSendButtonProps, ref) => {
  const {
    hasDataSet,
    searchValue,
    dataSet,
    setInputFocused,
    sendValue,
    allowAnyValue,
  } = props;

  const isEnabled =
    (hasDataSet &&
      searchValue !== '' &&
      dataSet.findIndex((item) => compareString(item, searchValue)) > -1) ||
    (!hasDataSet && !!searchValue) ||
    (!!searchValue && allowAnyValue);

  return (
    <RoundButton
      ref={ref}
      disabled={!isEnabled}
      onClick={sendValue}
      ariaLabel={translate(Translations.send)}
      onFocus={() => {
        setInputFocused(false);
      }}
    >
      <Icon path={mdiSend} size={1} />
    </RoundButton>
  );
});
