import { KeyCodes } from '@/constants';

const ARROW_KEY_CODES = [
  KeyCodes.ArrowDown,
  KeyCodes.ArrowUp,
  KeyCodes.ArrowLeft,
  KeyCodes.ArrowRight,
];

const isArrowKey = (key: string): boolean =>
  ARROW_KEY_CODES.includes(key as KeyCodes);

export default isArrowKey;
