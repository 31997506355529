// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorDark": "#bb320c",
	"error": "#ff5044",
	"errorBackground": "#fcdbd3",
	"background": "#f1ece8",
	"backgroundSecondary": "#faf9f7",
	"headerBackground": "#faf9f7",
	"headerText": "#331e11",
	"headerTextInverted": "#faf9f7",
	"menuArrow": "#0054f0",
	"menuArrowInverted": "#faf9f7",
	"modalBackdrop": "rgba(51,30,17,.4)",
	"secondary": "#331e11",
	"secondaryLight": "#58504d",
	"secondarySupport": "#a8a19e",
	"secondarySupportLight": "#faf9f7",
	"action": "#0054f0",
	"actionDark": "#004080",
	"actionLight": "#6aa0e2",
	"actionLightest": "#eaeff4",
	"actionSecondary": "#faf9f7",
	"actionSecondaryDark": "#f6f3f0",
	"actionSecondaryDarker": "#f1ece8",
	"actionText": "#faf9f7",
	"actionTextDark": "#6aa0e2",
	"actionTextLight": "#eaeff4",
	"focusOutline": "#0054f0",
	"inputBackground": "#faf9f7",
	"inputPlaceholder": "#6e625e",
	"inputPlaceholderFocus": "#eaeff4",
	"inputSupport": "#58504d",
	"botMessageBackground": "#e8e0d9",
	"userMessageBackground": "#faf9f7",
	"messageText": "#331e11",
	"botActionBackground": "#faf9f7",
	"botActionText": "#0054f0",
	"botActionBackgroundHover": "#f6f3f0",
	"botActionBackgroundActive": "#f6f3f0",
	"calendarItemHover": "#331e11",
	"calendarItemBackgroundHover": "#e8e0d9",
	"calendarItemCurrent": "#faf9f7",
	"calendarItemSelected": "#faf9f7",
	"calendarItemSelectedBackground": "#0054f0",
	"calendarItemOutside": "#ede6e1",
	"calendarSecondaryItem": "#a8a19e",
	"borderDark": "#331e11",
	"borderMedium": "#a8a19e",
	"borderLight": "#e8e0d9"
};
export default ___CSS_LOADER_EXPORT___;
