import { action } from 'typesafe-actions';
import { BffAuthenticationActions } from './types';

export type BffAuthenticationActionTypes =
  | AddHandledSilentAuthActivityIdState
  | AddDisplayedAuthenticationActivityIdState
  | AddHandledAuthenticationActivityIdState
  | SetUsernameState
  | SetAuthSessionHashState
  | ClearBffAuthenticationState;

interface SetUsernameState {
  type: BffAuthenticationActions.SET_USERNAME;
  payload: string;
}

interface SetAuthSessionHashState {
  type: BffAuthenticationActions.SET_AUTH_SESSION_HASH;
  payload: string;
}

interface AddHandledSilentAuthActivityIdState {
  type: BffAuthenticationActions.ADD_HANDLED_SILENT_AUTH_ACTIVITY_ID;
  payload: string;
}

interface AddDisplayedAuthenticationActivityIdState {
  type: BffAuthenticationActions.ADD_DISPLAYED_AUTHENTICATION_ACTIVITY_ID;
  payload: string;
}

interface AddHandledAuthenticationActivityIdState {
  type: BffAuthenticationActions.ADD_HANDLED_AUTHENTICATION_ACTIVITY_ID;
  payload: string;
}

interface ClearBffAuthenticationState {
  type: BffAuthenticationActions.CLEAR_AUTHENTICATION_STATE;
}

export const addHandledSilentAuthActivityId = (
  activityId: string
): BffAuthenticationActionTypes => {
  return action(
    BffAuthenticationActions.ADD_HANDLED_SILENT_AUTH_ACTIVITY_ID,
    activityId
  );
};

export const addDisplayedAuthenticationActivityId = (
  activityId: string
): BffAuthenticationActionTypes => {
  return action(
    BffAuthenticationActions.ADD_DISPLAYED_AUTHENTICATION_ACTIVITY_ID,
    activityId
  );
};

export const addHandledAuthenticationActivityId = (
  activityId: string
): BffAuthenticationActionTypes => {
  return action(
    BffAuthenticationActions.ADD_HANDLED_AUTHENTICATION_ACTIVITY_ID,
    activityId
  );
};

export const setUsername = (username: string): BffAuthenticationActionTypes => {
  return action(BffAuthenticationActions.SET_USERNAME, username);
};

export const setAuthSessionHash = (authSessionHash: string): BffAuthenticationActionTypes => {
  return action(BffAuthenticationActions.SET_AUTH_SESSION_HASH, authSessionHash);
};

export const clearBffAuthenticationState = (): BffAuthenticationActionTypes => {
  return action(BffAuthenticationActions.CLEAR_AUTHENTICATION_STATE);
};
