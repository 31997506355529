import { PageConfigActions, PageConfig } from './types';

export type PageConfigActionTypes = ClearPageConfig | SetPageConfig;

interface ClearPageConfig {
  type: PageConfigActions.CLEAR_CONFIG;
}

interface SetPageConfig {
  type: PageConfigActions.SET_PAGE_CONFIG;
  payload: PageConfig;
}

export const clearPageConfig = (): PageConfigActionTypes => {
  return {
    type: PageConfigActions.CLEAR_CONFIG,
  };
};

export const setPageConfig = (
  pageConfig: PageConfig
): PageConfigActionTypes => {
  return {
    type: PageConfigActions.SET_PAGE_CONFIG,
    payload: pageConfig,
  };
};
