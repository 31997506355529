import { appInsights } from '@/appInsights/appInsights';

export const makeThumbnail = async (
  file: File,
  width: number,
  height: number,
  contentType: string,
  quality: number
): Promise<string | undefined> => {
  if (/\.(gif|jpe?g|png)$/iu.test(file.name)) {
    try {
      return await downscaleImageToDataURLUsingBrowser(
        file,
        width,
        height,
        contentType,
        quality
      );
    } catch (e) {
      const error = e as Error;
      appInsights.trackException(
        { exception: error },
        {
          file,
          contentType,
        }
      );
    }
  }
  return undefined;
};

export const keepAspectRatio = (
  width: number,
  height: number,
  maxWidth: number,
  maxHeight: number
): { height: number; width: number } => {
  if (width < maxWidth && height < maxHeight) {
    return {
      height,
      width,
    };
  }

  const aspectRatio = width / height;

  if (aspectRatio > maxWidth / maxHeight) {
    return {
      height: maxWidth / aspectRatio,
      width: maxWidth,
    };
  }

  return {
    height: maxHeight,
    width: maxHeight * aspectRatio,
  };
};

export const downscaleImageToDataURLUsingBrowser = async (
  blob: Blob,
  maxWidth: number,
  maxHeight: number,
  type: string,
  quality: number
): Promise<string> => {
  const image = await loadImageFromBlob(blob);
  const { height, width } = keepAspectRatio(
    image.width,
    image.height,
    maxWidth,
    maxHeight
  );
  const canvas = createCanvas(width, height);
  const context = canvas.getContext('2d');
  context?.drawImage(image, 0, 0, width, height);

  return canvas.toDataURL(type, quality);
};

export const loadImageFromBlob = (blob: Blob): Promise<HTMLImageElement> => {
  const blobURL = URL.createObjectURL(blob);

  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = document.createElement('img');
    image.addEventListener('error', ({ error }) => {
      URL.revokeObjectURL(blobURL);
      return reject(error);
    });
    image.addEventListener('load', () => {
      URL.revokeObjectURL(blobURL);
      return resolve(image);
    });
    image.setAttribute('src', blobURL);
  });
};

export const createCanvas = (
  width: number,
  height: number
): HTMLCanvasElement => {
  const canvas = document.createElement('canvas');

  canvas.height = height;
  canvas.width = width;

  return canvas;
};
