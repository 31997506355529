import colors from './colors.module.scss';
import dimensions from './dimensions.module.scss';
import fonts from './fonts.module.scss';
import breakpoints from './breakpoints.module.scss';
import {
  Theme,
  Colors,
  Dimensions,
  Fonts,
  Breakpoints,
  ConfigurableTheme,
} from './types';
import cssVars from 'css-vars-ponyfill';
import { camelToKebabCase } from '../utils/utils';

const COLOR_PREFIX = 'color';
const FONT_STACK = '--font-stack';

const theme: Theme = {
  colors: colors as unknown as Colors,
  dimensions: dimensions as unknown as Dimensions,
  fonts: fonts as unknown as Fonts,
  breakpoints: breakpoints as unknown as Breakpoints,
};

export default theme;

export const setCssVariables = (theme: ConfigurableTheme): void => {
  const { colors, fonts } = theme;

  const newColors: Record<string, unknown> = Object.keys(colors).reduce(
    (result, key) => {
      //@ts-ignore
      result[`--${COLOR_PREFIX}-${camelToKebabCase(key)}`] = colors[key];
      return result;
    },
    {}
  );

  cssVars({ variables: { ...newColors, [FONT_STACK]: fonts.fontStack } });
};
