import { memo } from 'react';
import Moment from 'moment';
import classes from '../CalendarPicker.module.scss';

const DAYS_IN_WEEK = 7;

export const Weekdays = memo((props: { locale: string }) => {
  return (
    <>
      {Array.from(Array(DAYS_IN_WEEK).keys()).map((day: number) => {
        day += 1;
        return (
          <div
            key={day}
            className={classes.WeekDay}
            data-weekday={Moment().locale('en').isoWeekday(day).format('dddd')}
          >
            {Moment().locale(props.locale).isoWeekday(day).format('dd')}
          </div>
        );
      })}
    </>
  );
});
