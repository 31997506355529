import React, { memo } from 'react';
import classes from './AutoComplete.module.scss';
import { AutoCompleteItem } from '../types';
import classNames from 'classnames';
import SearchItemValue from './SearchItemValue';

export const AUTO_COMPLETE_LIST_ID = 'auto-complete-list-id';
export const CHOICE_ID = 'choice-id';

export interface AutoCompleteSearchItemProps {
  id?: string;
  searchValue: string;
  item: AutoCompleteItem;
  selectClassifier: (selectedClassifier: AutoCompleteItem | null) => void;
  index: number;
  activeIndex: number;
  autoCompleteItemRefs: React.MutableRefObject<(HTMLLIElement | null)[]>;
}

const SearchItem = memo((props: AutoCompleteSearchItemProps) => {
  const {
    item,
    searchValue,
    selectClassifier,
    index,
    activeIndex,
    autoCompleteItemRefs,
  } = props;
  const isActive = index === activeIndex;

  return (
    <li
      id={props.id}
      className={classNames(classes.Item, isActive ? classes.Active : null)}
      aria-selected={isActive}
      role="option button"
      onClick={() => selectClassifier(item)}
      tabIndex={-1}
      ref={(ref) => (ref ? autoCompleteItemRefs.current.push(ref) : null)}
    >
      <SearchItemValue item={item} search={searchValue} />
    </li>
  );
});

export default SearchItem;
