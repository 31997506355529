import { WebChatActivity } from 'botframework-webchat-core';
import { AnyAction, Dispatch } from 'redux';
import { MessageType } from '@riga-claims/atlas-models';

import {
  ActivityNames,
  ActivityRoles,
  ActivityTypes,
} from '@/middleware/types';

import { setTypingVisibility } from '@/store/bot-config/actions';

import getCustomActivityEntity from '../getCustomActivityEntity';
import { FeatureToggles, getIntToggle, getToggle } from '../featureToggles';

let typingIndicatorTimeoutId: NodeJS.Timeout | null = null;

export interface HandleTypingActivityArgs {
  activity: WebChatActivity;
  dispatch: Dispatch<AnyAction>;
}

const handleTypingForActivity = ({
  activity,
  dispatch,
}: HandleTypingActivityArgs): void => {
  const customActivityEntity = getCustomActivityEntity(activity);

  const isNonActionableMessageFromBot =
    getToggle(FeatureToggles.webchatTypingForStatistics)
      ? (customActivityEntity === undefined ||
            customActivityEntity?.messageType === MessageType.Message ||
            activity?.type as string === ActivityTypes.StatisticsEvent) &&
          activity?.from?.role === ActivityRoles.Bot
      : customActivityEntity?.messageType === MessageType.Message &&
          activity?.from?.role === ActivityRoles.Bot;

  const isInitActivity =
    activity?.type === ActivityTypes.Event &&
    activity?.name === ActivityNames.INIT;

  const isEditActivity =
    activity?.type === ActivityTypes.Event &&
    activity?.name === ActivityNames.EDIT;

  const isUserVisualActivity =
    activity?.from?.role === ActivityRoles.User && !isInitActivity;

  if (isNonActionableMessageFromBot || isUserVisualActivity || isEditActivity) {
    if (typingIndicatorTimeoutId) {
      clearTimeout(typingIndicatorTimeoutId);
    }

    typingIndicatorTimeoutId = setTimeout(() => {
      dispatch(setTypingVisibility(true));
    }, getIntToggle(FeatureToggles.webchatTypingIndicatorDelayMilliseconds));
  } else {
    if (typingIndicatorTimeoutId) {
      clearTimeout(typingIndicatorTimeoutId);
      typingIndicatorTimeoutId = null;
    }

    dispatch(setTypingVisibility(false));
  }
};

export default handleTypingForActivity;
