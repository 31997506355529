import { useEffect, useRef, useCallback, useState } from 'react';
import { HTMLElementAttribute } from '../constants';
import {
  trackComponentMounted,
  trackComponentUnmounted,
} from './appInsightHelper';
import debounce from './debounce';

export const useIsInitialMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

/**
 * Set initial focus on an element [ref]
 *
 * @param preventDefaultFocus
 */
export const useInitialFocusCallback = (preventDefaultFocus?: boolean) => {
  const ref = useRef<HTMLElement | null>(null);
  const setRef = useCallback<(node: HTMLElement | null) => void>(
    (node) => {
      ref.current = node;

      if (preventDefaultFocus) {
        return;
      }
      ref.current?.setAttribute(HTMLElementAttribute.TAB_INDEX, '0');

      setTimeout(() => {
        ref.current?.focus();
      });
    },
    [preventDefaultFocus]
  );

  return [ref, setRef] as const;
};

/**
 * Track component lifecycle.
 * It is used when querying app insights
 * to know when the component was presented to user,
 * and when - destroyed.
 *
 * @param activityId
 */
export const useLifecycleTracking = (activityId?: string) => {
  useEffect(() => {
    if (activityId) {
      trackComponentMounted(activityId);
    }
    return () => {
      if (activityId) {
        trackComponentUnmounted(activityId);
      }
    };
  }, [activityId]);
};

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

const RESIZE_DELAY = 300;

interface WindowSize {
  height: number;
  width: number;
}

const useWindowSize = (): WindowSize => {
  const getSize = () => {
    return {
      width: window.innerWidth || 0,
      height: window.innerHeight || 0,
    };
  };

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    const handleResize = debounce(() => setWindowSize(getSize()), RESIZE_DELAY);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;
