import React, { createRef, useEffect } from 'react';
import classes from '../InputClassifier.module.scss';
import { InputClassiferActivityConfig, AutoCompleteItem } from '../types';
import classNames from 'classnames';
import { translate, Translations } from '../../../../../locale';
import { KeyCodes, CustomEvent } from '../../../../../constants';
import Icon from '@mdi/react';
import { mdiCloseCircle, mdiChevronLeft } from '@mdi/js';
import { focusSinkRef } from '../../../../../containers/Chat/Chat';
import { AUTO_COMPLETE_LIST_ID } from '../AutoComplete/AutoComplete';
import { isElementVisible, isIos } from '../../../../../utils/iOSHelpers';
import { ClassifierInput } from '../Input/ClassifierInput';
import { useInitialFocusCallback } from '../../../../../utils/customHooks';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store/types';
import { removeLastChatStatus } from '@/store/chat-activities-config/actions';
import { configuredStore } from '@/store';
import { emitCustomEvent } from 'react-custom-events';

export interface ClassifierInputBoxProps {
  activityId?: string;
  searchValue: string;
  setSearchValue: (searchValue: string) => void;
  lastBotActivityId: string | undefined;
  config: InputClassiferActivityConfig;
  hasDataSet: boolean;
  inputFocused: boolean;
  setInputFocused: (inputFocused: boolean) => void;
  filteredData: AutoCompleteItem[];
  highlightedClassifierIndex: number;
  setHighlightedClassifierIndex: (highlightedClassifierIndex: number) => void;
  selectClassifier: (selectedClassifier: AutoCompleteItem | null) => void;
  autoCompleteItemRefs: React.MutableRefObject<(HTMLLIElement | null)[]>;
  sendValue: () => void;
}

const onBackButtonClick = (
  setInputFocused: (inputFocused: boolean) => void
) => {
  setTimeout(() => {
    focusSinkRef.current && focusSinkRef.current.focus();
    setInputFocused(false);
    emitCustomEvent(CustomEvent.OverlayClosed);
    configuredStore.store.dispatch(removeLastChatStatus())
  });
};

const onBackButtonKeyUp = (
  event: React.KeyboardEvent,
  setInputFocused: (inputFocused: boolean) => void
) => {
  if (event.key === KeyCodes.Enter) {
    setTimeout(() => {
      focusSinkRef.current && focusSinkRef.current.focus();
      setInputFocused(false);
      emitCustomEvent(CustomEvent.OverlayClosed);
      configuredStore.store.dispatch(removeLastChatStatus())
    });
  }
};

const onResetButtonKeyUp = (
  event: React.KeyboardEvent,
  resetValue: () => void
) => {
  if (event.key === KeyCodes.Enter) {
    resetValue();
  }
};

export function ClassifierInputBox(
  props: ClassifierInputBoxProps
): JSX.Element {
  const {
    selectClassifier,
    searchValue,
    setSearchValue,
    config,
    hasDataSet,
    setInputFocused,
    inputFocused,
    filteredData,
    highlightedClassifierIndex,
    setHighlightedClassifierIndex,
    autoCompleteItemRefs,
    sendValue,
  } = props;

  const { allowAnyValueIfNotFound, allowAnyValue } = config;
  const allowAnyValueSetting = allowAnyValueIfNotFound || allowAnyValue;

  const backButtonRef = createRef<HTMLDivElement>();
  const isMobile = useSelector<RootState, boolean>(
    (state) => state.globalConfigState.isMobile
  );

  const [inputRef, setInputRef] = useInitialFocusCallback(isMobile);

  const resetValue = () => {
    setTimeout(() => {
      setSearchValue('');
      selectClassifier(null);
      setInputFocused(true);
      inputRef.current?.focus();
    });
  };

  useEffect(() => {
    let timeOutId: any;
    if (isIos() && isElementVisible(backButtonRef.current!)) {
      timeOutId = setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }

    return () => {
      clearTimeout(timeOutId);
    };
  }, [backButtonRef]);

  return (
    <div
      className={classNames(
        classes.InputClassifierBox,
        inputFocused ? classes.InputFocused : null,
        filteredData.length === 0 && hasDataSet && !allowAnyValueSetting
          ? classes.Error
          : null
      )}
      role="combobox"
      aria-expanded={
        hasDataSet && inputFocused && searchValue.trim().length > 0
      }
      aria-owns={AUTO_COMPLETE_LIST_ID}
      aria-controls={AUTO_COMPLETE_LIST_ID}
      aria-haspopup="listbox"
    >
      <div
        ref={backButtonRef}
        className={classes.BackButton}
        onClick={() => onBackButtonClick(setInputFocused)}
        onKeyUp={(event) => onBackButtonKeyUp(event, setInputFocused)}
        role="button"
        tabIndex={0}
      >
        <Icon path={mdiChevronLeft} size={1} />
      </div>

      <ClassifierInput
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        config={config}
        setInputFocused={setInputFocused}
        filteredData={filteredData}
        inputFocused={inputFocused}
        autoCompleteItemRefs={autoCompleteItemRefs}
        highlightedClassifierIndex={highlightedClassifierIndex}
        setHighlightedClassifierIndex={setHighlightedClassifierIndex}
        sendValue={sendValue}
        setInputRef={setInputRef}
        selectClassifier={selectClassifier}
      />
      {searchValue ? (
        <span
          className={classes.InputValueResetContainer}
          role="button"
          onClick={resetValue}
          onKeyUp={(event) => onResetButtonKeyUp(event, resetValue)}
          tabIndex={0}
          aria-label={translate(Translations.inputClassifier.clearSearch)}
        >
          <Icon path={mdiCloseCircle} />
        </span>
      ) : null}
    </div>
  );
}
