import { useCallback, useEffect, useRef, useState } from 'react';
import { hooks } from 'botframework-webchat-component';
import { WebChatActivity } from 'botframework-webchat-core';

import classes from './FailedToSend.module.scss';

import { ActivityStatusCard } from '../../middleware/types';
import { translate, Translations } from '../../locale';
import { AppInsightsEventNames } from '../../constants';
import { appInsights } from '@/appInsights/appInsights';

const { usePostActivity } = hooks;

export interface FailedToSendProps {
  activityStatusCard: ActivityStatusCard;
}

export const FailedToSend = (props: FailedToSendProps): JSX.Element | null => {
  const [hidden, setHidden] = useState(false);
  const { activityStatusCard } = props;
  const { activity } = activityStatusCard;
  const buttonRef = useRef<HTMLButtonElement>(null);

  const postActivity = usePostActivity();

  useEffect(() => {
    buttonRef.current?.focus();
  }, []);

  const handleRetryClick = useCallback(() => {
    postActivity(activity as WebChatActivity);
    setHidden(true);

    appInsights.trackEvent(
      {
        name: AppInsightsEventNames.UserClickedRetryButton,
      },
      {
        activity,
      }
    );
  }, [activity, postActivity]);

  if (hidden) {
    return null;
  }

  return (
    <div className={classes.FailedToSend}>
      <div>
        <div className={classes.ErrorText} role="alert">
          {translate(Translations.failedToSendMessage)}
        </div>
      </div>
      <button
        ref={buttonRef}
        className={classes.RetryButton}
        onClick={handleRetryClick}
      >
        <div className={classes.RetryIcon}></div>
        <div>{translate(Translations.tryAgain)}</div>
      </button>
    </div>
  );
};

export default FailedToSend;
