import { createRef, FunctionComponent, useEffect, useState } from 'react';

import classNames from 'classnames';

import classes from './Category.module.scss';

import useWindowSize from '@/utils/customHooks';

import { Category, ListSelectionStyle, Record } from '../types';

import CategoryMultipleChoiceRecord from '../CategoryMultipleChoiceRecord/CategoryMultipleChoiceRecord';
import CategorySingleChoiceRecord from '../CategorySingleChoiceRecord/CategorySingleChoiceRecord';

export interface CategoryProps {
  category: Category;
  selectedRecords: Record[];
  selectionStyle: ListSelectionStyle;
  paddingBottomPx: number;
  onRecordSingleChoiceClick: (record: Record) => void;
  onRecordMultipleChoiceClick: (record: Record) => void;
}

const CategoryComponent: FunctionComponent<CategoryProps> = ({
  category,
  selectionStyle,
  selectedRecords,
  onRecordSingleChoiceClick,
  onRecordMultipleChoiceClick,
  paddingBottomPx,
}) => {
  const { width, height } = useWindowSize();

  const [useBottomPadding, setUseBottomPadding] = useState(true);
  const [maxListHeight, setMaxListHeight] = useState(-1);
  const categoryListRef = createRef<HTMLDivElement>();

  const isSingleChoice = selectionStyle === ListSelectionStyle.SingleChoice;

  useEffect(() => {
    const currentListHeight = categoryListRef?.current?.clientHeight;
    console.log(currentListHeight);
    if (currentListHeight && currentListHeight !== maxListHeight) {
      setMaxListHeight(currentListHeight);
    }
  }, [maxListHeight]);

  useEffect(() => {
    setMaxListHeight(0);
  }, [width, height, categoryListRef?.current?.clientHeight]);

  const onListScrolled = (e: React.UIEvent<HTMLDivElement>) => {
    const list = e.nativeEvent.target as HTMLDivElement;
    const currentListHeight = list?.scrollTop + list?.clientHeight;

    setUseBottomPadding(
      list?.scrollHeight - currentListHeight <= paddingBottomPx
    );
  };
  return (
    <div
      style={{
        maxHeight: maxListHeight ? maxListHeight : 'initial',
      }}
      ref={categoryListRef}
      className={classes.Category}
      onScroll={onListScrolled}
    >
      <ul
        style={{
          paddingBottom: useBottomPadding ? `${paddingBottomPx}px` : '0',
        }}
        key={category.name}
        className={classNames(classes.CategoryItemList, {
          [classes.CategoryTable]: isSingleChoice,
        })}
      >
        {category.records.map((record) => {
          const isRecordSelected =
            selectedRecords.findIndex(
              (selectedRecord) => selectedRecord.code === record.code
            ) > -1;

          return isSingleChoice ? (
            <CategorySingleChoiceRecord
              key={record.code}
              record={record}
              selected={isRecordSelected}
              onRecordClick={onRecordSingleChoiceClick}
            />
          ) : (
            <CategoryMultipleChoiceRecord
              key={record.code}
              record={record}
              selected={isRecordSelected}
              onRecordClick={onRecordMultipleChoiceClick}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default CategoryComponent;
