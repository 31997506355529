import { LongListActivityConfig } from '../LongListActivity';
import { Category, LongListClassifier } from '../types';

const getCategoriesFromDataSource = (
  activity: LongListActivityConfig
): Category[] => {
  if (!activity.dataSource) {
    return [];
  }

  const longListClassifier = JSON.parse(activity.dataSource) as
    | LongListClassifier
    | undefined;

  if (!longListClassifier) {
    throw new Error(
      "Unknown activity 'DataSource', can't be parsed to ILongListClassifier."
    );
  }

  return longListClassifier.categories.map(
    (category, _, previousCategories) => {
      const uniqueCategoryRecordsPerDataSource = category.records.filter(
        (record, recordIndex) =>
          record.code &&
          record.text &&
          previousCategories
            .flatMap((previousCategory) => previousCategory.records)
            .findIndex(
              (previousRecord) => previousRecord.code === record.code
            ) === recordIndex
      );

      return {
        ...category,
        ...{ records: uniqueCategoryRecordsPerDataSource },
      };
    }
  );
};

export default getCategoriesFromDataSource;
