import { useEffect, FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import classes from './Main.module.scss';

import { DESKTOP_MIN_PIXEL_WIDTH, PHONE_MAX_PIXEL_WIDTH } from './constants';

import { useEventListener } from './utils';
import routes from './routes';

import { useThrowError } from '@/hooks';

import { getClientSideConfiguration } from './api/claim/claimMethods';

import {
  setClientSideConfig,
  setIsDesktop,
  setIsMobile,
} from './store/global-config/actions';

import FullPageLoader from './components/UI/FullPageLoader/FullPageLoader';

const Main: FunctionComponent = () => {
  const [clientConfigRetrieved, setClientConfigRetrieved] = useState(false);
  const dispatch = useDispatch();

  const throwError = useThrowError();

  const handleResize = () => {
    setTimeout(() => {
      const mobileMode = window.innerWidth <= PHONE_MAX_PIXEL_WIDTH;
      dispatch(setIsMobile(mobileMode));

      const isDesktopMode = window.innerWidth >= DESKTOP_MIN_PIXEL_WIDTH
      dispatch(setIsDesktop(isDesktopMode));
    });
  };

  useEffect(() => {
    handleResize();

    const getConfig = async () => {
      try {
        const { data: clientSideConfig } = await getClientSideConfiguration();
        setClientConfigRetrieved(true);

        if (clientSideConfig) {
          dispatch(setClientSideConfig(clientSideConfig));
        }
      } catch (e) {
        const error = e as Error;
        throwError(error);
      }
    };

    void getConfig();
  }, []);

  useEventListener('resize', handleResize, window);

  return (
    <div className={classes.Main}>
      {clientConfigRetrieved ? (
        <BrowserRouter>
          <Routes>
            {routes.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.element}
                />
              );
            })}
          </Routes>
        </BrowserRouter>
      ) : (
        <FullPageLoader />
      )}
    </div>
  );
};

export default Main;
