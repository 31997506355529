import { FunctionComponent } from 'react';

import classes from './ActionLink.module.scss';

interface ActionLinkProps {
  text: string;
  url: string;
  actionColor: string | undefined;
}

const ActionLink: FunctionComponent<ActionLinkProps> = ({
  text,
  url,
  actionColor,
}: ActionLinkProps) => {
  return (
    <div className={classes.Action}>
      <div className={classes.ActionLink}>
        <a href={url} target="_blank" rel={'noopener noreferrer'}>
          <div className={classes.LinkContent}>
            <span className={classes.Icon}>
              {/* #606067 */}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                <g fill="none" fillRule="evenodd">
                  <g stroke={actionColor} strokeWidth="1.5">
                    <path d="M19.5 2.5v8h8" />
                    <path strokeLinecap="square" d="M19.5 2.5h-15v27h23v-19z" />
                  </g>
                  <g fill={actionColor} fillRule="nonzero">
                    <path d="M14.109 20.362c0 .479-.141.848-.423 1.11-.282.26-.683.39-1.203.39h-.326v1.421H11V19h1.483c.54 0 .947.118 1.219.355.271.237.407.571.407 1.007zm-1.952.557h.211a.577.577 0 00.415-.146.53.53 0 00.154-.404c0-.29-.16-.434-.481-.434h-.299v.984zm6.27.132c0 .714-.197 1.266-.59 1.652-.394.386-.947.58-1.66.58H14.79V19h1.482c.688 0 1.219.176 1.593.527.373.352.56.86.56 1.524zm-1.201.04c0-.391-.078-.682-.233-.872-.156-.19-.391-.284-.708-.284h-.337v2.399h.258c.352 0 .61-.102.774-.306.164-.204.246-.516.246-.936zM20.334 23.283h-1.14V19h2.531v.928h-1.391v.818h1.283v.929h-1.283z" />
                  </g>
                </g>
              </svg>
            </span>
            <span>{text}</span>
          </div>
        </a>
      </div>
    </div>
  );
};
export default ActionLink;
