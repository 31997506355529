import { Card, ActivityEntity } from '../middleware/types';
import { getEntity } from './cardHelper';

function canBeEditedCustomUserResponse(card: Card): boolean {
  const entities: ActivityEntity[] | undefined = getEntity(card);
  return canUserResponseBeEdited(entities);
}

function canUserResponseBeEdited(
  entities: ActivityEntity[] | undefined
): boolean {
  if (!entities) {
    return false;
  }

  for (let index = 0; index < entities.length; index++) {
    const element = entities[index] as any;
    if (element) {
      const customActivityCanBeEdited = element.CanBeEdited;
      if (customActivityCanBeEdited) {
        return customActivityCanBeEdited;
      }
      const entityCanBeEdited = element.canBeEdited;
      if (entityCanBeEdited) {
        return entityCanBeEdited;
      }
    }
  }

  return false;
}

export default canBeEditedCustomUserResponse;
