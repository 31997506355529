import { ActivityTypes, Card, CustomTyping } from '../types';
import { AppInsightsEventNames } from '../../constants';
import {
  setRetryRejectedActivitiesLimit,
  setCurrentFailedActivity,
  setReceivedClientActivityIds,
  setLastUserRespondedActivity,
} from '../../store/recovery/actions';
import { appInsights } from '@/appInsights/appInsights';
import { configuredStore } from '@/store';
import { FeatureToggles, getIntToggle } from '../../utils/featureToggles';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { deleteActivity } from '../../store/bot-config/actions';

export const includeBotReceivedMessages = (card: Card): void => {
  const { activity } = card;
  const { getState, dispatch } = configuredStore.store;
  const { receivedClientActivityIds } = getState().recoveryState;
  /* Custom typing is sent from chat bot side and will contain the
    client activity id which is being processed */
  if (activity.type !== ActivityTypes.CustomTyping) {
    return;
  }

  const customTypingEntity = getCustomTypingEntity(activity);
  if (
    !customTypingEntity ||
    (customTypingEntity && !customTypingEntity.clientActivityID)
  ) {
    return;
  }

  const { clientActivityID } = customTypingEntity;
  if (!receivedClientActivityIds.includes(clientActivityID)) {
    const ids = [...receivedClientActivityIds];
    ids.push(clientActivityID);
    dispatch(setReceivedClientActivityIds(ids));
  }
};

export const finalizeFailedActivity = (): void => {
  const { getState, dispatch } = configuredStore.store;
  const recoveryState = getState().recoveryState;
  const { currentFailedActivity } = recoveryState;
  if (!currentFailedActivity) {
    return;
  }

  appInsights.trackEvent(
    { name: AppInsightsEventNames.PostingFailedActivity },
    { ...currentFailedActivity }
  );

  dispatch(setCurrentFailedActivity(undefined));
  /* reset limit for future failed activities */
  configuredStore.store.dispatch(
    setRetryRejectedActivitiesLimit(
      getIntToggle(FeatureToggles.retryRejectedActivitiesLimit)
    )
  );
};

export const cleanUpRetryActivities = (
  card: Card,
  chatStoreDispatch: Dispatch<AnyAction>
): void => {
  const { activity } = card;
  if (activity.id && activity.type === ActivityTypes.Retry) {
    chatStoreDispatch(deleteActivity(activity.id));
  }
};

const getCustomTypingEntity = (activity: CustomTyping) => {
  const { entities } = activity;
  if (!entities || entities.length === 0) {
    return;
  }

  return entities.find((entity) => entity.type === ActivityTypes.CustomTyping);
};

export const updateLastUserRespondedActivity = (activityId?: string): void => {
  if (!activityId) {
    return;
  }

  const { dispatch } = configuredStore.store;
  dispatch(setLastUserRespondedActivity(activityId));
};

export const getLastUserRespondedActivityId = (): string => {
  const { store } = configuredStore;
  const { getState } = store;
  const recoveryState = getState().recoveryState;

  return recoveryState.lastUserRespondedActivity;
};

export const hasUserRespondedToLastActivity = (
  currentActivityId?: string
): boolean => currentActivityId === getLastUserRespondedActivityId();
