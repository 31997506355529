// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"smallPhoneMaxWidth": "319px",
	"phoneMaxWidth": "599px",
	"tabletPortraitMinWidth": "600px",
	"tabletPortraitMaxWidth": "899px",
	"tabletLandscapeMinWidth": "900px",
	"tabletLandscapeMaxWidth": "1199px",
	"desktopMinWidth": "1200px",
	"desktopMaxWidth": "1799px",
	"bigDesktopMinWidth": "1800px"
};
export default ___CSS_LOADER_EXPORT___;
