import React from 'react';
import Moment from 'moment';
import DayPicker, { DayModifiers, NavbarElementProps } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import classes from './CalendarPicker.module.scss';
import { getLocaleShort } from '../../../../../locale';
import { getLocale } from '../../../../../store/bot-config/selectors';
import classNames from 'classnames';
import {
  isDisabledDay,
  LOWER_YEAR_LIMIT,
  MONTH_DECEMBER,
  MONTH_JANUARY,
  UPPER_YEAR_LIMIT,
} from '../dateInputHelper';
import { YearPicker } from '../YearPicker/YearPicker';
import { Navigation } from './Navigation/Navigation';

const DAYPICKER_TODAY_SELECTOR =
  '.DayPicker-Day.DayPicker-Day--today:not(.DayPicker-Day--disabled)';

export interface CalendarPickerProps {
  headerText?: string;
  isYearPickerOpen: boolean;

  allowedPastDate: Moment.Moment | null;
  allowedFutureDate: Moment.Moment | null;

  setIsYearPickerOpen: (isYearPickerOpen: boolean) => void;
  onDateChangeHandler: (date: Moment.Moment | null) => void;
  onAcceptHandler: () => void;
}

export interface CalendarPickerState {
  locale: string;

  selectedDay?: Date;
  initialDate: Moment.Moment;
  monthToDisplay: Moment.Moment;

  lowestDate: Moment.Moment;
  highestDate: Moment.Moment;
  fromMonthDate: Date;
  toMonthDate: Date;
  monthOffset: number;
}

class CalendarPicker extends React.Component<
  CalendarPickerProps,
  CalendarPickerState
> {
  private calendarElement: DayPicker | null = null;

  constructor(props: CalendarPickerProps) {
    super(props);
    this.state = initializeState(props);
  }

  componentDidMount() {
    const todayElement = this.calendarElement?.dayPicker.querySelector(
      DAYPICKER_TODAY_SELECTOR
    );
    const todayElementAsHtmlElement = todayElement as
      | HTMLElement
      | undefined
      | null;
    todayElementAsHtmlElement?.focus();
  }

  selectYear(year: number) {
    const newDisplayMonth = this.state.monthToDisplay.clone().year(year);
    this.setState({ monthToDisplay: newDisplayMonth });
    if (this.state.selectedDay) {
      const newSelectedDay = Moment(this.state.selectedDay)
        .clone()
        .year(year)
        .toDate();
      this.setState({ selectedDay: newSelectedDay });
    }
    this.props.setIsYearPickerOpen(false);
  }

  selectDay(day: Date, modifiers: DayModifiers) {
    if (modifiers.disabled) {
      return;
    }
    this.setState({ selectedDay: day });
    this.props.onDateChangeHandler(Moment(day));
  }

  onMonthChange(month: Date) {
    this.setState({ monthToDisplay: Moment(month) });
  }

  render() {
    const {
      selectedDay,
      initialDate,
      monthToDisplay,
      locale,
      fromMonthDate,
      toMonthDate,
      monthOffset,
    } = this.state;

    const { allowedPastDate, allowedFutureDate, isYearPickerOpen } = this.props;

    const offsetFromMonth = Moment(fromMonthDate)
      .subtract(monthOffset, 'month')
      .toDate();

    return (
      <div className={classes.CalendarPicker}>
        <div
          className={classNames(
            classes.Container,
            isYearPickerOpen ? classes.YearPicker : null
          )}
        >
          {this.props.headerText ? (
            <div className={classes.Header}>{this.props.headerText}</div>
          ) : null}

          {isYearPickerOpen ? (
            <YearPicker
              isOpen={isYearPickerOpen}
              setIsOpen={this.props.setIsYearPickerOpen}
              allowedPastDate={allowedPastDate}
              allowedFutureDate={allowedFutureDate}
              selectYear={this.selectYear.bind(this)}
              showOutsideYears={true}
              selectedDay={selectedDay}
              currentYear={monthToDisplay.year()}
              initialDate={Moment(initialDate)}
            />
          ) : (
            <DayPicker
              firstDayOfWeek={1}
              navbarElement={(props: NavbarElementProps) => (
                <Navigation
                  {...props}
                  locale={locale}
                  isYearPickerOpen={isYearPickerOpen}
                  setIsYearPickerOpen={this.props.setIsYearPickerOpen}
                  onMonthChange={this.onMonthChange.bind(this)}
                />
              )}
              captionElement={<></>}
              modifiers={{ today: initialDate.toDate() }}
              numberOfMonths={1}
              showWeekDays={false}
              onDayClick={this.selectDay.bind(this)}
              selectedDays={selectedDay}
              localeUtils={MomentLocaleUtils}
              locale={getLocaleShort()}
              disabledDays={day =>
                isDisabledDay(day, allowedPastDate, allowedFutureDate)
              }
              enableOutsideDaysClick={false}
              fromMonth={offsetFromMonth}
              toMonth={toMonthDate}
              pagedNavigation={true}
              month={monthToDisplay.toDate()}
              ref={element => (this.calendarElement = element)}
            />
          )}
        </div>
      </div>
    );
  }
}

const initializeState = (props: CalendarPickerProps): CalendarPickerState => {
  const { allowedFutureDate, allowedPastDate } = props;

  Moment.locale(getLocale());
  const locale = getLocaleShort();
  const today = Moment();

  const lowestDate = Moment().year(LOWER_YEAR_LIMIT);
  const highestDate = Moment().year(UPPER_YEAR_LIMIT);

  const fromMonthDate =
    !allowedPastDate ||
    (allowedPastDate && allowedPastDate.isBefore(lowestDate))
      ? lowestDate.startOf('year')
      : allowedPastDate;

  const toMonthDate =
    !allowedFutureDate ||
    (allowedFutureDate && allowedFutureDate.isAfter(highestDate))
      ? highestDate.endOf('year')
      : allowedFutureDate;

  const initialDate = allowedFutureDate
    ? toMonthDate.clone()
    : allowedPastDate
    ? fromMonthDate.clone()
    : today.clone();

  const monthOffset = calculateMonthOffset(allowedPastDate, allowedFutureDate);

  return {
    locale,
    lowestDate,
    highestDate,
    fromMonthDate: fromMonthDate.toDate(),
    toMonthDate: toMonthDate.toDate(),
    monthOffset,
    initialDate,
    monthToDisplay: initialDate,
    selectedDay: undefined,
  };
};

/**
 The month offset is used to position the months in calendar first page to make the available dates more
 accessible compared to disabled dates.
 */
const calculateMonthOffset = (
  fromDate: Moment.Moment | null,
  toDate: Moment.Moment | null
): number => {
  let offset = 0;

  const fromMonth = (fromDate && fromDate.month()) || MONTH_JANUARY;
  const toMonth = (toDate && toDate.month()) || MONTH_DECEMBER;
  const isFromMonthEven = fromMonth % 2 === 0;
  const isToMonthEven = toMonth % 2 === 0;

  if (fromMonth === MONTH_JANUARY && toMonth === MONTH_DECEMBER) {
    return offset;
  }

  if (
    (fromMonth !== toMonth && isFromMonthEven && isToMonthEven) ||
    (fromDate &&
      toDate &&
      fromMonth !== toMonth &&
      !isFromMonthEven &&
      !isToMonthEven)
  ) {
    offset = 1;
  }

  return offset;
};

export default CalendarPicker;
