//TODO: Remove this when @microsoft/applicationinsights-react-js update typings where AppInsightsErrorBoundary contains children prop
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Suspense, useEffect, useState, FunctionComponent } from 'react';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';
import {
  withAITracking,
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from '@microsoft/applicationinsights-react-js';

import { configuredStore } from '@/store';
import { initializeAppInsights, reactPlugin } from '@/appInsights/appInsights';

import { WebchatConfigurationKeys } from './configuration/types';
import { getConfig } from './utils';

import SomethingWentWrong from './components/UI/SomethingWentWrong/SomethingWentWrong';
import Main from './Main';
import FullPageLoader from './components/UI/FullPageLoader/FullPageLoader';

const App: FunctionComponent = () => {
  const [appInsightsLoaded, setAppInsightsLoaded] = useState(false);
  const { store, persistor } = configuredStore;

  useEffect(() => {
    initializeAppInsights({
      connectionString: getConfig(
        WebchatConfigurationKeys.APP_INSIGHTS_CONNECTION_STRING
      ),
    });

    setAppInsightsLoaded(true);
  }, []);

  return (
    <Suspense fallback={<FullPageLoader />}>
      {appInsightsLoaded ? (
        <AppInsightsContext.Provider value={reactPlugin}>
          {/* @ts-ignore */}
          <AppInsightsErrorBoundary
            onError={() => <SomethingWentWrong />}
            appInsights={reactPlugin}
          >
            <Provider store={store}>
              <PersistGate persistor={persistor}>
                <Main />
              </PersistGate>
            </Provider>
          </AppInsightsErrorBoundary>
        </AppInsightsContext.Provider>
      ) : (
        <FullPageLoader />
      )}
    </Suspense>
  );
};

export default withAITracking(reactPlugin, App);
