import { ModalProps } from './Modal';

export const getShouldCloseOnEscapeValidatorErrorMessage = () =>
  'If "shouldCloseOnEscape" is truthy, "Modal" component expects to have "closeHandler" defined as well';

export const shouldCloseOnEscapeValidator = (props: ModalProps) => {
  const isEscapeHandlingLogicDefined =
    typeof props.shouldCloseOnEscape !== 'undefined';

  if (isEscapeHandlingLogicDefined) {
    const isShouldCloseOnEscapePropTrue = props.shouldCloseOnEscape === true;
    const isCloseHandlerDefined = typeof props.closeHandler !== 'undefined';

    if (isShouldCloseOnEscapePropTrue && !isCloseHandlerDefined) {
      return new Error(getShouldCloseOnEscapeValidatorErrorMessage());
    }
  }

  return null;
};

/*
 * Whenever close button at header of modal should be shown,
 * it is mandatory to provide close button label ans close handler
 */

export const getDismissAtHeaderValidatorErrorMessage = () =>
  'If "shouldHideDismissAtHeader" is truthy, Modal component expects to have "closeHandler" defined and to have "closeButtonAriaLabel" provided as string';

export const shouldShowDismissAtHeaderValidator = (props: ModalProps) => {
  const isDismissalAtHeaderDefined =
    typeof props.shouldShowDismissAtHeader !== 'undefined';

  if (isDismissalAtHeaderDefined) {
    const shouldShowDismissAtHeader = props.shouldShowDismissAtHeader === true;
    const isCloseHandlerDefined = typeof props.closeHandler !== 'undefined';
    const isCloseButtonAriaLabelProvidedAsString =
      typeof props.closeButtonAriaLabel === 'string';

    // if dismissal button at header should be shown,
    // label and close handlers are mandatory
    if (
      shouldShowDismissAtHeader &&
      (!isCloseHandlerDefined || !isCloseButtonAriaLabelProvidedAsString)
    ) {
      return new Error(getDismissAtHeaderValidatorErrorMessage());
    }
  }
  return null;
};
