import React, { useState } from 'react';
import classes from './RatingStars.module.scss';
import Icon from '@mdi/react';
import { mdiStar, mdiStarOutline } from '@mdi/js';
import classNames from 'classnames';
import { MessageTypes } from '../../../../middleware/types';
import { findCustomActivity } from '../../../../middleware/activityMiddleware';
import { KeyCodes, HTMLElementAttribute } from '../../../../constants';
import {
  RatingStarsProps,
  NoRating,
  RatingValue,
  RatingStarsActivityConfig,
} from './types';
import SubmitButton from './SubmitButton';
import { useInitialFocusCallback } from '../../../../utils/customHooks';
import { BotPassThroughProps } from '../../../../containers/BotMessageBase/BotMessageBase';
import ClickOutsideTyped, {
  ClickOutsidePassThroughProps,
} from '../../../../containers/ClickOutside/ClickOutside';

const NO_RATING: NoRating = undefined;

const RatingStarsTextBox = (props: RatingStarsProps): JSX.Element => {
  const ratingStarRefs: HTMLDivElement[] = [];

  const focusStar = (starNumber: number) => {
    props.setKeyboardRating(starNumber);
    ratingStarRefs[starNumber - 1].focus();
  };

  const selectPreviousStar = (value: number) => {
    const starToSelect = value === 1 ? props.config.starCount : value - 1;
    focusStar(starToSelect);
  };

  const selectNextStar = (value: number) => {
    const starToSelect = value === props.config.starCount ? 1 : value + 1;
    focusStar(starToSelect);
  };

  const [ref, setRef] = useInitialFocusCallback();

  return (
    <div
      className={classNames(
        classes.StarsContainer,
        props.rating && !props.clickedOutside ? classes.Focused : ''
      )}
      role="radiogroup"
      ref={setRef}
      onBlur={() =>
        ref.current?.removeAttribute(HTMLElementAttribute.TAB_INDEX)
      }
    >
      {Array.from(
        Array(props.config.starCount).keys(),
        (value: number, index: number) => {
          ++value;
          const isFilledStar: boolean =
            (props.rating !== NO_RATING && value <= props.rating) ||
            (props.hoverRating !== NO_RATING && value <= props.hoverRating);

          const canBeTabbedTo =
            props.rating === NO_RATING && value === 1
              ? true
              : props.rating === value;
          const isChecked = props.rating === value;
          return (
            <div
              className={classes.Star}
              role="radio"
              data-rating={value}
              ref={(radioButtonRef) =>
                radioButtonRef ? ratingStarRefs.push(radioButtonRef) : null
              }
              key={`${value} ${index}`}
              aria-checked={isChecked}
              tabIndex={canBeTabbedTo ? 0 : -1}
              onTouchEnd={(e) => {
                props.setRating(value);
                e.preventDefault();
              }}
              onClick={() => props.setRating(value)}
              onMouseEnter={() => props.setHoverRating(value)}
              onMouseLeave={() => props.setHoverRating(NO_RATING)}
              onKeyUp={(event: React.KeyboardEvent) => {
                switch (event.key) {
                  case KeyCodes.Enter:
                  case KeyCodes.Spacebar:
                    props.setRating(props.keyboardRating);
                    break;
                  case 'Tab':
                    props.setKeyboardRating(value);
                }
              }}
              onKeyDown={(event: React.KeyboardEvent) => {
                switch (event.key) {
                  case KeyCodes.ArrowRight:
                  case KeyCodes.ArrowDown:
                    event.preventDefault();
                    selectNextStar(value);
                    break;
                  case KeyCodes.ArrowLeft:
                  case KeyCodes.ArrowUp:
                    event.preventDefault();
                    selectPreviousStar(value);
                    break;
                }
              }}
              aria-label={`${props.config.ratingHint}: ${value}`}
            >
              <Icon path={isFilledStar ? mdiStar : mdiStarOutline} />
            </div>
          );
        }
      )}
    </div>
  );
};

const RatingStarsInput: React.FC<RatingStarsProps> = (
  props: RatingStarsProps
) => (
  <div className={classNames(classes.RatingStarsActivityWrapper)}>
    <div
      className={classes.RatingStarsActivity}
      data-custom-activity="true"
      data-message-type={MessageTypes.ratingStars}
    >
      <RatingStarsTextBox key="text-box" {...props} />
      <div className={classNames(classes.ButtonContainer)}>
        <SubmitButton
          card={props.card}
          config={props.config}
          hideChildrenOfElement={props.hideChildrenOfElement}
          rating={props.rating}
        />
      </div>
    </div>
  </div>
);

const RatingStars = (props: BotPassThroughProps): JSX.Element => {
  const [hoverRating, setHoverRating] = useState<RatingValue>(NO_RATING);
  const [keyboardRating, setKeyboardRating] = useState<RatingValue>(NO_RATING);

  const config = findCustomActivity(
    props.card.activity
  ) as RatingStarsActivityConfig;
  const prefillValue = config.prefillText as unknown as number | undefined;

  const [rating, setRating] = useState<RatingValue>(prefillValue);

  return (
    <ClickOutsideTyped
      withTab
      passThroughProps={props}
      render={(
        ratingStarsProps: BotPassThroughProps & ClickOutsidePassThroughProps
      ) => (
        <RatingStarsInput
          {...ratingStarsProps}
          config={config}
          rating={rating}
          setRating={setRating}
          hoverRating={hoverRating}
          setHoverRating={setHoverRating}
          keyboardRating={keyboardRating}
          setKeyboardRating={setKeyboardRating}
        />
      )}
    />
  );
};

export default RatingStars;
