import { FunctionComponent } from 'react';

import classes from '../Menu.module.scss';
import classNames from 'classnames';
import { KeyCodes } from '../../../../constants';

export interface ContactSupportProps {
  contactSupportUrl: string;
  contactSupportText: string;
}

export const ContactSupport: FunctionComponent<ContactSupportProps> = (
  props: ContactSupportProps
) => {
  const { contactSupportText, contactSupportUrl } = props;

  return (
    <div
      tabIndex={-1}
      role="menuitem link"
      data-drop-down-item={'contact-support'}
      className={classNames(
        classes.DropdownMenuItem,
        classes.DropdownMenuItemAnchor
      )}
      onKeyPress={(e) => {
        if (e.key === KeyCodes.Spacebar || e.key === KeyCodes.Enter) {
          const win = window.open(contactSupportUrl);
          win?.focus();
        }
      }}
    >
      <a
        tabIndex={-1}
        href={contactSupportUrl}
        title={contactSupportText}
        target="_blank"
        rel="noopener noreferrer"
      >
        {contactSupportText}
      </a>
    </div>
  );
};

export default ContactSupport;
