import { useState, useEffect, useRef } from 'react';
import classes from './InputClassifier.module.scss';
import { MessageTypes } from '../../../../middleware/types';

import { findCustomActivity } from '../../../../middleware/activityMiddleware';
import { InputClassiferActivityConfig, AutoCompleteItem } from './types';
import classNames from 'classnames';
import { AutoComplete } from './AutoComplete/AutoComplete';
import { ClassifierInputSendButton } from './InputBox/SendButton';
import { ClassifierInputBox } from './InputBox/ClassifierInputBox';
import {
  sendValue,
  orderedSearchResults,
  transformDataSet,
  getFromApi,
  DEFAULT_HIGHLIGHTED_CLASSIFIER_INDEX,
} from './inputClassifierHelper';
import { trackClassifierComponentInteracted } from '../../../../utils/appInsightHelper';
import { AppInsightsUserInteraction, CustomEvent } from '../../../../constants';
import TypingIndicator from '../../../UI/TypingIndicator/TypingIndicator';
import { BotPassThroughProps } from '../../../../containers/BotMessageBase/BotMessageBase';
import { ClickOutsidePassThroughProps } from '../../../../containers/ClickOutside/ClickOutside';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/types';
import { FeatureToggles, getToggle } from '@/utils';
import { removeLastChatStatus } from '@/store/chat-activities-config/actions';
import { emitCustomEvent } from 'react-custom-events';

const SEND_BUTTON_FOCUS_DELAY_MILLISECONDS = 100;

export interface InputClassifierState {
  config: InputClassiferActivityConfig;
  dataSet: AutoCompleteItem[];
  hasDataSet: boolean;
  isError: boolean;
  searchValue: string;
  selectedClassifier: AutoCompleteItem | null;
  inputFocused: boolean;
  highlightedClassifierIndex: number;
  isLoading: boolean;
  classifierRefs?: HTMLLIElement[];
  addAutoCompleteItemRef: (element: HTMLLIElement | null) => void;
}

export interface InputClassifierProps {
  messageType: MessageTypes;
  filteredData: AutoCompleteItem[];
}

export const InputClassifierComponent = (
  props: BotPassThroughProps & ClickOutsidePassThroughProps
): JSX.Element => {
  const { card, clickedOutside, hideChildrenOfElement } = props;
  const dispatch = useDispatch();

  const config = findCustomActivity(
    card.activity
  ) as InputClassiferActivityConfig;

  const { allowAnyValue } = config;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasDataSet, setHasDataSet] = useState<boolean>(true);
  const [dataSet, setDataSet] = useState<AutoCompleteItem[]>([]);
  const [orderedFilterData, setOrderedFilterData] = useState<
    AutoCompleteItem[]
  >([]);
  const [searchValue, setSearchValue] = useState<string>(
    config.prefillText || ''
  );
  const [selectedClassifier, setSelectedClassifier] =
    useState<AutoCompleteItem | null>(null);
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  const [highlightedClassifierIndex, setHighlightedClassifierIndex] =
    useState<number>(DEFAULT_HIGHLIGHTED_CLASSIFIER_INDEX);

  const lastBotActivityId = useSelector<RootState, string | undefined>(
    (state) => state.botConfigState.lastBotMessageId
  );

  const sendButtonRef = useRef<HTMLButtonElement | null>(null);

  const updateSearchValue = (value: string) => {
    setSearchValue(value);
    setOrderedFilterData(orderedSearchResults(dataSet, value));
    setHighlightedClassifierIndex(DEFAULT_HIGHLIGHTED_CLASSIFIER_INDEX);
    setSelectedClassifier(null);
  };

  useEffect(() => {
    if (config.apiUrl) {
      getFromApi(config.apiUrl)
        .then((response: AutoCompleteItem[]) => {
          setDataSetAndFilterData(response);
          setHasDataSet(true);
          setIsLoading(false);
        })
        .catch(() => {
          setDataSetAndFilterData([]);
          setHasDataSet(false);
          setIsLoading(false);
        });
    } else {
      setDataSetAndFilterData(
        config.dataSet &&
          transformDataSet(config.dataSet as unknown as Record<string, string>)
      );
      setIsLoading(false);
    }
  }, [config.apiUrl, config.dataSet]);

  const setDataSetAndFilterData = (data: AutoCompleteItem[]) => {
    setDataSet(data);
    setOrderedFilterData(orderedSearchResults(data, ''));
  };

  useEffect(() => {
    if (clickedOutside) {
      setInputFocused(false);
      emitCustomEvent(CustomEvent.OverlayClosed);
      dispatch(removeLastChatStatus());
    }
  }, [clickedOutside]);

  const prevSelectedClassifierIndex = useRef(
    DEFAULT_HIGHLIGHTED_CLASSIFIER_INDEX
  );
  const prevSearchValue = useRef('');

  useEffect(() => {
    prevSelectedClassifierIndex.current = highlightedClassifierIndex;
    prevSearchValue.current = searchValue;
  }, [searchValue]);

  useEffect(() => {
    if (
      prevSelectedClassifierIndex.current >
        DEFAULT_HIGHLIGHTED_CLASSIFIER_INDEX &&
      prevSearchValue.current !== searchValue
    ) {
      setHighlightedClassifierIndex(DEFAULT_HIGHLIGHTED_CLASSIFIER_INDEX);
    }
  }, [highlightedClassifierIndex, searchValue]);

  const selectClassifier = (classifier: AutoCompleteItem | null) => {
    const selectedClassifierValue = classifier ? classifier.value : '';

    setSelectedClassifier(classifier);
    setSearchValue(selectedClassifierValue);
    setHighlightedClassifierIndex(DEFAULT_HIGHLIGHTED_CLASSIFIER_INDEX);
    setInputFocused(false);

    setTimeout(() => {
      sendButtonRef.current?.focus();
    }, SEND_BUTTON_FOCUS_DELAY_MILLISECONDS);

    trackClassifierComponentInteracted(
      config.id,
      AppInsightsUserInteraction.ClassifierValueChosen,
      selectedClassifierValue
    );

    emitCustomEvent(CustomEvent.OverlayClosed);
    dispatch(removeLastChatStatus());
  };

  const autoCompleteItemRefs = useRef<Array<HTMLLIElement | null>>([]);

  const sendValueToChat = () => {
    sendValue({
      dataSet,
      hasDataSet,
      allowAnyValue: allowAnyValue,
      searchValue,
      selectedClassifier,
      card,
      hideElement: hideChildrenOfElement,
    });
  };

  if (isLoading) {
    return <TypingIndicator />;
  }

  return (
    <div
      className={classNames(
        classes.InputClassifierWrapper,
        inputFocused ? classes.InputFocused : null
      )}
    >
      <div
        className={classNames(
          classes.InputClassifier,
          inputFocused ? classes.InputFocused : null
        )}
        data-custom-activity="true"
        data-message-type={config.messageType}
      >
        <ClassifierInputBox
          filteredData={orderedFilterData}
          setHighlightedClassifierIndex={setHighlightedClassifierIndex}
          setSearchValue={updateSearchValue}
          setInputFocused={setInputFocused}
          selectClassifier={selectClassifier}
          searchValue={searchValue}
          config={config}
          hasDataSet={hasDataSet}
          inputFocused={inputFocused}
          highlightedClassifierIndex={highlightedClassifierIndex}
          autoCompleteItemRefs={autoCompleteItemRefs}
          lastBotActivityId={lastBotActivityId}
          activityId={card.activity.id}
          sendValue={sendValueToChat}
        />

        <div className={classes.InputClassifierButtons}>
          <ClassifierInputSendButton
            ref={sendButtonRef}
            hasDataSet={hasDataSet}
            searchValue={searchValue}
            allowAnyValue={allowAnyValue}
            dataSet={dataSet}
            setInputFocused={setInputFocused}
            card={card}
            hideElement={hideChildrenOfElement}
            sendValue={sendValueToChat}
          />
        </div>

        {hasDataSet && inputFocused && searchValue.trim().length > 0 ? (
          <AutoComplete
            data={orderedFilterData}
            searchValue={searchValue}
            selectClassifier={selectClassifier}
            highlightedClassifierIndex={highlightedClassifierIndex}
            noResultsFoundMessage={config.noResultsFoundMessage}
            autoCompleteItemRefs={autoCompleteItemRefs}
            allowAnyValue={allowAnyValue}
          />
        ) : null}
      </div>
    </div>
  );
};

export default InputClassifierComponent;
