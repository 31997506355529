export enum StatisticsDataParams {
  URL = 'statistics-url',
  REFERRER = 'statistics-referrer',
  BROWSER = 'statistics-browser',
  BROWSER_VERSION = 'statistics-browserVersion',
  USERAGENT = 'statistics-browserUserAgent',

  LANGUAGE = 'statistics-language',
  PLATFORM = 'statistics-platform',

  SCREEN_HEIGHT = 'statistics-screenHeight',
  SCREEN_WIDTH = 'statistics-screenWidth',
}

interface StatisticsValues {
  [StatisticsDataParams.URL]: string;
  [StatisticsDataParams.REFERRER]: string;
  [StatisticsDataParams.BROWSER]: string;
  [StatisticsDataParams.BROWSER_VERSION]: string;
  [StatisticsDataParams.USERAGENT]: string;
  [StatisticsDataParams.LANGUAGE]: string;
  [StatisticsDataParams.PLATFORM]: string;
  [StatisticsDataParams.SCREEN_HEIGHT]: string;
  [StatisticsDataParams.SCREEN_WIDTH]: string;
}

const getStatisticsValues = (): StatisticsValues => {
  const location: Location = window.location;

  const statisticsValues: StatisticsValues = {
    [StatisticsDataParams.URL]: location.href,
    [StatisticsDataParams.REFERRER]: document.referrer,
    [StatisticsDataParams.BROWSER]: navigator.appName,
    [StatisticsDataParams.BROWSER_VERSION]: navigator.appVersion,
    [StatisticsDataParams.USERAGENT]: navigator.userAgent,
    [StatisticsDataParams.LANGUAGE]: navigator.language,
    [StatisticsDataParams.PLATFORM]: navigator.platform,
    [StatisticsDataParams.SCREEN_HEIGHT]: window.screen.height.toString(),
    [StatisticsDataParams.SCREEN_WIDTH]: window.screen.width.toString(),
  };

  return statisticsValues;
};

export default getStatisticsValues;
