/**
 * Returns a debounced function that will execute only its last call in a specified time period
 *
 * @template F
 * @param {F} func Function to debounce
 * @param {number} delay Period of inactivity in milliseconds until the function is executed
 * @return {*}  {((...args: unknown[]) => void)} The debounced function
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const debounce = <F extends (...args: any[]) => void>(
  func: F,
  delay: number
): ((...args: unknown[]) => void) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  return (...args: unknown[]) => {
    if (timeout !== null) {
      clearTimeout(timeout);
      timeout = null;
    }

    timeout = setTimeout(() => func(...args), delay);
  };
};

export default debounce;
