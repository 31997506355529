import { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { hooks } from 'botframework-webchat-component';

import { hasUserRespondedToLastActivity } from '../../middleware/helpers/retryActivityHelper';

import { RootState } from '../../store/types';
import {
  Card,
  CustomActivityConfig,
  ActivityRoles,
} from '../../middleware/types';
import { IActivity, Message } from 'botframework-directlinejs';

import { findCustomActivity } from '../../middleware/activityMiddleware';
import { translate, Translations } from '../../locale';
import { getCardActions } from '../../utils/cardHelper';

import classNames from 'classnames';
import classes from './BotMessageBase.module.scss';

import ReactMarkdown from 'react-markdown';

import MultipleChoice from '../../components/CustomActivities/MultipleChoice/MultipleChoice';
import { FeatureToggles, getToggle } from '@/utils/featureToggles';

const { useScrollTo, useActivities } = hooks;

export interface BotPassThroughProps {
  alwaysRoundedCorners?: boolean;
  card: Card;
  hideChildrenOfElement: () => void;
  lastBotActivityId: string | undefined;
}

export interface BotMessageBaseProps {
  card: Card;
  alwaysRoundedCorners?: boolean;
  fullWidth?: boolean;
  childName?: string; // TODO: make the rendering logic of children invisible to outside
  persistChildrenAfterUserResponse?: boolean;
  render?: (props: BotPassThroughProps) => ReactNode;
}

/**
 * Wrap chat components and pass down necessary props to children.
 *
 * Explicitly provide type for pass through props,
 * so that child components can be typed and explicitly state the input props.
 *
 * botMessageBase has a child rendering logic, which decides
 * whether or not action buttons are visible, adjusting
 * the markup used by screen readers.
 *
 * @param props {BotMessageBaseProps}
 */
function BotMessageBase(props: BotMessageBaseProps): JSX.Element {
  const [areChildrenVisible, setAreChildrenVisible] = useState(true);

  const lastBotActivityId = useSelector(
    (state: RootState) => state.botConfigState.lastBotMessageId
  );

  const { card, alwaysRoundedCorners, persistChildrenAfterUserResponse } =
    props;

  const activities = useActivities()[0] as IActivity[];
  const scrollTo = useScrollTo();

  useEffect(() => {
    const lastActivityId = activities[activities.length - 1].id;
    //TODO : Error in typing, remove ts-ignore when typing does not require `top` property
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    scrollTo({ activityID: lastActivityId }, { behavior: 'smooth' });
  }, [activities.length, useScrollTo]);

  useEffect(() => {
    if (
      hasUserRespondedToLastActivity(card.activity.id) &&
      !props.persistChildrenAfterUserResponse
    ) {
      hideChildrenOfElement();
    }
  }, [card]);

  /**
   * Set visibility of card children.
   *
   * Once the answer is submitted,
   * card should no longer display action buttons,
   * multiple choice buttons, etc.
   */
  const hideChildrenOfElement = () => {
    setAreChildrenVisible(false);
  };

  /**
   * Decide whether children [action buttons, multiple choice buttons, etc.]
   * of a card will be rendered.
   */
  const shouldRenderChildren = (): boolean => {
    return (
      persistChildrenAfterUserResponse ||
      (card.activity.id === lastBotActivityId && areChildrenVisible)
    );
  };

  /**
   * If a card has no children - all of its corners should be rounded,
   * if it has - only the top left and right corners are rounded.
   *
   * The bottom corners are taken care of by the child component.
   */
  const shouldRenderRoundedMessage = (): boolean => {
    if (alwaysRoundedCorners) {
      return true;
    }

    const actions = getCardActions(card);
    const areChildrenRendered = shouldRenderChildren();

    return !actions || actions.length === 0 || !areChildrenRendered;
  };

  const activity = card.activity as Message;
  const { text, id } = activity;

  const customActivity = findCustomActivity(activity) as CustomActivityConfig;
  const isCustomActivity = customActivity !== undefined;

  const botProps: BotPassThroughProps = {
    hideChildrenOfElement,
    lastBotActivityId,
    card,
    alwaysRoundedCorners,
  };

  const fieldsetComponents = [MultipleChoice.name];

  const renderChildren = (childComponentName?: string): ReactNode => {
    let output: JSX.Element;
    if (
      childComponentName &&
      fieldsetComponents.find((c) => c === childComponentName)
    ) {
      // render multiple choice component with a fieldset and legend
      output = (
        <fieldset>
          <div className={classNames(classes.Header)}>
            <legend>
              {text && (
                <ReactMarkdown linkTarget={'_blank'}>{text}</ReactMarkdown>
              )}
            </legend>
          </div>
          {shouldRenderChildren() && props.render && props.render(botProps)}
        </fieldset>
      );
    } else {
      // render action buttons (if any) without the fieldset
      output = (
        <>
          <div
            className={classNames(
              classes.Header,
              shouldRenderRoundedMessage() ? classes.Rounded : null
            )}
          >
            {text && (
              <ReactMarkdown linkTarget={'_blank'}>{text}</ReactMarkdown>
            )}
          </div>

          {shouldRenderChildren() && props.render && props.render(botProps)}
        </>
      );
    }

    return output;
  };

  return (
    <div
      className={classes.BotMessageBase}
      data-is-custom-activity={isCustomActivity.toString()}
      data-message-type={
        isCustomActivity ? customActivity.messageType : undefined
      }
      data-activity-id={id}
      data-user-role={ActivityRoles.Bot}
    >
      <div
        className={classNames(
          classes.Container,
          props.fullWidth
            ? getToggle(FeatureToggles.itemListButtonStates)
              ? classes.FixedFullWidth
              : classes.FullWidth
            : null
        )}
        aria-label={translate(Translations.botSaid)}
      >
        {renderChildren(props.childName)}
      </div>
    </div>
  );
}

export default BotMessageBase;
