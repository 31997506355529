import { Environments, Hostname } from '@/constants';

const getEnvironment = (): Environments | null => {
  const currentHostname = window.location.hostname;

  switch (currentHostname) {
    case Hostname.LocalHost:
      return Environments.LOCALHOST;

    case Hostname.Itest:
    case Hostname.ItestExternal:
      return Environments.ITEST;

    case Hostname.Stest:
    case Hostname.StestExternal:
      return Environments.STEST;

    case Hostname.Atest:
    case Hostname.SwedenAudiKontaktTest:
    case Hostname.SwedenCupraKontaktTest:
    case Hostname.SwedenDaciaKontaktTest:
    case Hostname.SwedenFordKontaktTest:
    case Hostname.SwedenIsuzuKontaktTest:
    case Hostname.SwedenKiaKontaktTest:
    case Hostname.SwedenMercedesBenzKontaktTest:
    case Hostname.SwedenMgKontaktTest:
    case Hostname.SwedenMgTest:
    case Hostname.SwedenNissanKontaktTest:
    case Hostname.SwedenPorscheKontaktTest:
    case Hostname.SwedenRenaultKontaktTest:
    case Hostname.SwedenSaabKontaktTest:
    case Hostname.SwedenSeatKontaktTest:
    case Hostname.SwedenSkodaKontaktTest:
    case Hostname.SwedenSubaruKontaktTest:
    case Hostname.SwedenVolkswagenKontaktTest:
    case Hostname.SwedenVolviaKontaktTest:

    case Hostname.AtestExternal:
      return Environments.ATEST;

    case Hostname.DenmarkHostname:
    case Hostname.FinlandHostname:
    case Hostname.SwedenHostname:
    case Hostname.SwedenHostKontaktname:
    case Hostname.NorwayHostname:
    case Hostname.SwedenAudi:
    case Hostname.SwedenAudiKontakt:
    case Hostname.SwedenBmw:
    case Hostname.SwedenCupra:
    case Hostname.SwedenCupraKontakt:
    case Hostname.SwedenDacia:
    case Hostname.SwedenDaciaKontakt:
    case Hostname.SwedenFord:
    case Hostname.SwedenFordKontakt:
    case Hostname.SwedenIsuzu:
    case Hostname.SwedenIsuzuKontakt:
    case Hostname.SwedenKia:
    case Hostname.SwedenKiaKontakt:
    case Hostname.SwedenMercedesBenz:
    case Hostname.SwedenMercedesBenzKontakt:
    case Hostname.SwedenMg:
    case Hostname.SwedenMgKontakt:
    case Hostname.SwedenMini:
    case Hostname.SwedenNissan:
    case Hostname.SwedenNissanKontakt:
    case Hostname.SwedenOpel:
    case Hostname.SwedenPorsche:
    case Hostname.SwedenPorscheKontakt:
    case Hostname.SwedenRenault:
    case Hostname.SwedenRenaultKontakt:
    case Hostname.SwedenSaab:
    case Hostname.SwedenSaabKontakt:
    case Hostname.SwedenSeat:
    case Hostname.SwedenSeatKontakt:
    case Hostname.SwedenSkoda:
    case Hostname.SwedenSkodaKontakt:
    case Hostname.SwedenSubaru:
    case Hostname.SwedenSubaruKontakt:
    case Hostname.SwedenVolkswagen:
    case Hostname.SwedenVolkswagenKontakt:
    case Hostname.SwedenVolkswagenTransport:
    case Hostname.SwedenVolvia:
    case Hostname.SwedenVolviaKontakt:
      return Environments.PROD;
  }

  return null;
};

export default getEnvironment;
