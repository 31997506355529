import { AppRoutesSwedenMotorPartners, Country, Locale } from '../constants';
import { TenantMapping } from './types';

import audiTheme from './themes/audi';
import audiLogo from '../assets/images/logos/audi-logo.svg';
import audiFavIcon from '../assets/images/favicons/audi.png';
import audiHomeIcon from '../assets/images/homepage/audi.png';

import bmwHomeIcon from '../assets/images/homepage/bmw.png';
import opelHomeIcon from '../assets/images/homepage/opel.png';

import cupraLogo from '../assets/images/logos/cupra-landscape-logo.svg';
import cupraHomeIcon from '../assets/images/homepage/cupra.png';
import cupraTheme from './themes/cupra';

import miniHomeIcon from '../assets/images/homepage/mini.png';

import daciaTheme from './themes/dacia';
import daciaLogo from '../assets/images/logos/dacia-logo.svg';
import daciaFavIcon from '../assets/images/favicons/dacia.png';
import daciaHomeIcon from '../assets/images/homepage/dacia.png';

import fordTheme from './themes/ford';
import fordLogo from '../assets/images/logos/ford-logo.png';
import fordFavIcon from '../assets/images/favicons/ford.png';
import fordHomeIcon from '../assets/images/homepage/ford.png';

import isuzuTheme from './themes/isuzu';
import isuzuLogo from '../assets/images/logos/isuzu-logo.svg';
import isuzuFavIcon from '../assets/images/favicons/isuzu.png';
import isuzuHomeIcon from '../assets/images/homepage/isuzu.png';

import kiaTheme from './themes/kia';
import kiaLogo from '../assets/images/logos/kia-logo.svg';
import kiaFavIcon from '../assets/images/favicons/kia.png';
import kiaHomeIcon from '../assets/images/homepage/kia.png';

import mercedesBenzTheme from './themes/mercedes-benz';
import mercedesBenzLogo from '../assets/images/logos/mercedes-benz-logo.svg';
import mercedesBenzFavIcon from '../assets/images/favicons/mercedes-benz.png';
import mercedesBenzHomeIcon from '../assets/images/homepage/mercedes-benz.png';

import mgTheme from './themes/mg';
import mgLogo from '../assets/images/logos/mg-logo.svg';
import mgFavIcon from '../assets/images/favicons/mg.png';
import mgHomeIcon from '../assets/images/homepage/mg.png';

import nissanTheme from './themes/nissan';
import nissanLogo from '../assets/images/logos/nissan-logo.svg';
import nissanFavIcon from '../assets/images/favicons/nissan.png';
import nissanHomeIcon from '../assets/images/homepage/nissan.svg';

import porscheTheme from './themes/porsche';
import porscheLogo from '../assets/images/logos/porsche-logo.svg';
import porscheFavIcon from '../assets/images/favicons/porsche.png';
import porscheHomeIcon from '../assets/images/homepage/porsche.png';

import renaultTheme from './themes/renault';
import renaultLogo from '../assets/images/logos/renault-logo.png';
import renaultFavIcon from '../assets/images/favicons/renault.png';
import renaultHomeIcon from '../assets/images/homepage/renault.png';

import saabTheme from './themes/saab';
import saabLogo from '../assets/images/logos/saab-logo.svg';
import saabFavIcon from '../assets/images/favicons/saab.png';
import saabHomeIcon from '../assets/images/homepage/saab.png';

import seatTheme from './themes/seat';
import seatLogo from '../assets/images/logos/seat-logo.svg';
import seatFavIcon from '../assets/images/favicons/seat.png';
import seatHomeIcon from '../assets/images/homepage/seat.png';

import skodaTheme from './themes/skoda';
import skodaLogo from '../assets/images/logos/skoda-logo.png';
import skodaFavIcon from '../assets/images/favicons/skoda.png';
import skodaHomeIcon from '../assets/images/homepage/skoda.png';

import subaruTheme from './themes/subaru';
import subaruLogo from '../assets/images/logos/subaru-logo.svg';
import subaruFavIcon from '../assets/images/favicons/subaru.png';
import subaruHomeIcon from '../assets/images/homepage/subaru.png';

import volkswagenTheme from './themes/volkswagen';
import volkswagenLogo from '../assets/images/logos/volkswagen-logo.png';
import volkswagenFavIcon from '../assets/images/favicons/volkswagen.png';
import volkswagenHomeIcon from '../assets/images/homepage/volkswagen.png';

import volkswagenTransportFavIcon from '../assets/images/favicons/volkswagen-transport.png';
import volkswagenTransportHomeIcon from '../assets/images/homepage/volkswagen-transport.png';

import volviaTheme from './themes/volvia';
import volviaLogo from '../assets/images/logos/volvia-logo.svg';
import volviaFavIcon from '../assets/images/favicons/volvia.png';
import volviaHomeIcon from '../assets/images/homepage/volvia.png';

const REPORT_CLAIM_PREFIX = 'anmal-skada.app';
const REPORT_CLAIM_KONTAKT_PREFIX = 'kontakt.app';
const REPORT_CLAIM_TEST_PREFIX = 'anmal-skada-test.app';
const REPORT_CLAIM_KONTAKT_TEST_PREFIX = 'kontakt-test.app';
const CONTACT_SUPPORT_SUFFIX = 'kundservice/kontakta-oss';
const SWEDEN_MOTOR_TENANT = '95347160-b4b6-4fcd-9fb5-8765a071791b';

enum SwedenMotorPartnerHostNames {
  Audi = 'audibilforsakring.se',
  Bmw = 'bmworiginalforsakring.se',
  Cupra = 'cuprabilforsakring.se',
  Dacia = 'daciaforsakring.se',
  Isuzu = 'isuzuforsakring.se',
  Ford = 'fordforsakring.se',
  Kia = 'kiabilforsakring.com',
  MercedesBenz = 'mercedes-benzforsakring.se',
  Mini = 'minioriginalforsakring.se',
  Nissan = 'nissanforsakring.se',
  Opel = 'opelforsakring.se',
  Porsche = 'porschebilforsakring.se',
  Renault = 'renaultforsakring.se',
  Saab = 'saabforsakring.se',
  Seat = 'seatbilforsakring.se',
  Skoda = 'skodabilforsakring.se',
  Subaru = 'subaruforsakring.se',
  Volkswagen = 'volkswagenbilforsakring.se',
  VolkswagenTransport = 'volkswagentransportbilarforsakring.se',
  Volvia = 'volvia.se',
  Mg = 'mgbilforsakring.se',
}

const swedenMotorPartnerMapping: TenantMapping[] = [
  {
    path: AppRoutesSwedenMotorPartners.Audi,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: audiTheme,
      logoDesktop: audiLogo,
      homePageIcon: audiHomeIcon,
      icon: audiFavIcon,
      brandName: 'audi',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Audi}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Audi}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Audi}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'audi',
      portfolio: 'IMF',
    },
  },

  {
    path: AppRoutesSwedenMotorPartners.Audi,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: audiTheme,
      logoDesktop: audiLogo,
      homePageIcon: audiHomeIcon,
      icon: audiFavIcon,
      brandName: 'audi',
      showPageTitle: false,
      pageTitleTranslationKey: 'seMotorPageTitleKontakt',
      productionUrl: `https://${REPORT_CLAIM_KONTAKT_PREFIX}.${SwedenMotorPartnerHostNames.Audi}`,
      testUrl: `https://${REPORT_CLAIM_KONTAKT_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Audi}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Audi}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'audi',
      portfolio: 'IMF',
    },
  },

  {
    path: AppRoutesSwedenMotorPartners.Bmw,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      homePageIcon: bmwHomeIcon,
      brandName: 'bmw',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Bmw}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Bmw}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Bmw}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'bmw',
      portfolio: 'IMF',
    },
  },

  {
    path: AppRoutesSwedenMotorPartners.Cupra,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: cupraTheme,
      logoDesktop: cupraLogo,
      homePageIcon: cupraHomeIcon,
      brandName: 'cupra',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Cupra}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Cupra}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Cupra}/kundservice`,
      },
      idmProfile: 'cupra',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Cupra,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: cupraTheme,
      logoDesktop: cupraLogo,
      homePageIcon: cupraHomeIcon,
      brandName: 'cupra',
      showPageTitle: false,
      pageTitleTranslationKey: 'seMotorPageTitleKontakt',
      productionUrl: `https://${REPORT_CLAIM_KONTAKT_PREFIX}.${SwedenMotorPartnerHostNames.Cupra}`,
      testUrl: `https://${REPORT_CLAIM_KONTAKT_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Cupra}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Cupra}/kundservice`,
      },
      idmProfile: 'cupra',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Dacia,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: daciaTheme,
      logoDesktop: daciaLogo,
      homePageIcon: daciaHomeIcon,
      icon: daciaFavIcon,
      brandName: 'dacia',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Dacia}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Dacia}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Dacia}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      portfolio: 'VRF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Dacia,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: daciaTheme,
      logoDesktop: daciaLogo,
      homePageIcon: daciaHomeIcon,
      icon: daciaFavIcon,
      brandName: 'dacia',
      showPageTitle: false,
      pageTitleTranslationKey: 'seMotorPageTitleKontakt',
      productionUrl: `https://${REPORT_CLAIM_KONTAKT_PREFIX}.${SwedenMotorPartnerHostNames.Dacia}`,
      testUrl: `https://${REPORT_CLAIM_KONTAKT_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Dacia}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Dacia}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      portfolio: 'VRF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Ford,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: fordTheme,
      logoDesktop: fordLogo,
      homePageIcon: fordHomeIcon,
      icon: fordFavIcon,
      brandName: 'ford',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Ford}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Ford}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Ford}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'ford',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Ford,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: fordTheme,
      logoDesktop: fordLogo,
      homePageIcon: fordHomeIcon,
      icon: fordFavIcon,
      brandName: 'ford',
      showPageTitle: false,
      pageTitleTranslationKey: 'seMotorPageTitleKontakt',
      productionUrl: `https://${REPORT_CLAIM_KONTAKT_PREFIX}.${SwedenMotorPartnerHostNames.Ford}`,
      testUrl: `https://${REPORT_CLAIM_KONTAKT_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Ford}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Ford}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'ford',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Isuzu,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: isuzuTheme,
      logoDesktop: isuzuLogo,
      homePageIcon: isuzuHomeIcon,
      icon: isuzuFavIcon,
      brandName: 'isuzu',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Isuzu}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Isuzu}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Isuzu}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'isuzu',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Isuzu,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: isuzuTheme,
      logoDesktop: isuzuLogo,
      homePageIcon: isuzuHomeIcon,
      icon: isuzuFavIcon,
      brandName: 'isuzu',
      showPageTitle: false,
      pageTitleTranslationKey: 'seMotorPageTitleKontakt',
      productionUrl: `https://${REPORT_CLAIM_KONTAKT_PREFIX}.${SwedenMotorPartnerHostNames.Isuzu}`,
      testUrl: `https://${REPORT_CLAIM_KONTAKT_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Isuzu}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Isuzu}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'isuzu',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Kia,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: kiaTheme,
      logoDesktop: kiaLogo,
      homePageIcon: kiaHomeIcon,
      icon: kiaFavIcon,
      brandName: 'kia',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Kia}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Kia}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Kia}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'kia',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Kia,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: kiaTheme,
      logoDesktop: kiaLogo,
      homePageIcon: kiaHomeIcon,
      icon: kiaFavIcon,
      brandName: 'kia',
      showPageTitle: false,
      pageTitleTranslationKey: 'seMotorPageTitleKontakt',
      productionUrl: `https://${REPORT_CLAIM_KONTAKT_PREFIX}.${SwedenMotorPartnerHostNames.Kia}`,
      testUrl: `https://${REPORT_CLAIM_KONTAKT_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Kia}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Kia}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'kia',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.MercedesBenz,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: mercedesBenzTheme,
      logoDesktop: mercedesBenzLogo,
      homePageIcon: mercedesBenzHomeIcon,
      icon: mercedesBenzFavIcon,
      brandName: 'mercedes',
      showPageTitle: false,
      headerColorsInverted: true,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.MercedesBenz}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.MercedesBenz}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.MercedesBenz}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'mercedes',
      portfolio: 'IMF',
    },
  },

  {
    path: AppRoutesSwedenMotorPartners.MercedesBenz,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: mercedesBenzTheme,
      logoDesktop: mercedesBenzLogo,
      homePageIcon: mercedesBenzHomeIcon,
      icon: mercedesBenzFavIcon,
      brandName: 'mercedes',
      showPageTitle: false,
      pageTitleTranslationKey: 'seMotorPageTitleKontakt',
      headerColorsInverted: true,
      productionUrl: `https://${REPORT_CLAIM_KONTAKT_PREFIX}.${SwedenMotorPartnerHostNames.MercedesBenz}`,
      testUrl: `https://${REPORT_CLAIM_KONTAKT_TEST_PREFIX}.${SwedenMotorPartnerHostNames.MercedesBenz}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.MercedesBenz}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'mercedes',
      portfolio: 'IMF',
    },
  },

  {
    path: AppRoutesSwedenMotorPartners.Mg,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: mgTheme,
      logoDesktop: mgLogo,
      homePageIcon: mgHomeIcon,
      icon: mgFavIcon,
      brandName: 'mg',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Mg}`,
      testUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Mg}`,
      contactSupportLinks: {
        default: `https://www.${SwedenMotorPartnerHostNames.Mg}/kundservice`,
      },
      idmProfile: 'mg',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Mg,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: mgTheme,
      logoDesktop: mgLogo,
      homePageIcon: mgHomeIcon,
      icon: mgFavIcon,
      brandName: 'mg',
      showPageTitle: false,
      pageTitleTranslationKey: 'seMotorPageTitleKontakt',
      productionUrl: `https://${REPORT_CLAIM_KONTAKT_PREFIX}.${SwedenMotorPartnerHostNames.Mg}`,
      testUrl: `https://${REPORT_CLAIM_KONTAKT_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Mg}`,
      contactSupportLinks: {
        default: `https://www.${SwedenMotorPartnerHostNames.Mg}/kundservice`,
      },
      idmProfile: 'mg',
      portfolio: 'IMF',
    },
  },

  {
    path: AppRoutesSwedenMotorPartners.Mini,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      homePageIcon: miniHomeIcon,
      brandName: 'mini',
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Mini}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Mini}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Mini}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'mini',
      portfolio: 'IMF',
    },
  },

  {
    path: AppRoutesSwedenMotorPartners.Nissan,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: nissanTheme,
      logoDesktop: nissanLogo,
      homePageIcon: nissanHomeIcon,
      icon: nissanFavIcon,
      brandName: 'nissan',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Nissan}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Nissan}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Nissan}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'nissan',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Nissan,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: nissanTheme,
      logoDesktop: nissanLogo,
      homePageIcon: nissanHomeIcon,
      icon: nissanFavIcon,
      brandName: 'nissan',
      showPageTitle: false,
      pageTitleTranslationKey: 'seMotorPageTitleKontakt',
      productionUrl: `https://${REPORT_CLAIM_KONTAKT_PREFIX}.${SwedenMotorPartnerHostNames.Nissan}`,
      testUrl: `https://${REPORT_CLAIM_KONTAKT_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Nissan}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Nissan}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'nissan',
      portfolio: 'IMF',
    },
  },

  {
    path: AppRoutesSwedenMotorPartners.Opel,
    pageConfig: {
      homePageIcon: opelHomeIcon,
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      brandName: 'opel',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Opel}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Opel}`,
      portfolio: 'IMF',
    },
  },

  {
    path: AppRoutesSwedenMotorPartners.Porsche,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: porscheTheme,
      logoDesktop: porscheLogo,
      homePageIcon: porscheHomeIcon,
      icon: porscheFavIcon,
      brandName: 'porsche',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Porsche}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Porsche}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Porsche}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'porsche',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Porsche,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: porscheTheme,
      logoDesktop: porscheLogo,
      homePageIcon: porscheHomeIcon,
      icon: porscheFavIcon,
      brandName: 'porsche',
      showPageTitle: false,
      pageTitleTranslationKey: 'seMotorPageTitleKontakt',
      productionUrl: `https://${REPORT_CLAIM_KONTAKT_PREFIX}.${SwedenMotorPartnerHostNames.Porsche}`,
      testUrl: `https://${REPORT_CLAIM_KONTAKT_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Porsche}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Porsche}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'porsche',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Renault,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: renaultTheme,
      logoDesktop: renaultLogo,
      homePageIcon: renaultHomeIcon,
      icon: renaultFavIcon,
      brandName: 'renault',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Renault}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Renault}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Renault}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'renault',
      portfolio: 'VRF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Renault,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: renaultTheme,
      logoDesktop: renaultLogo,
      homePageIcon: renaultHomeIcon,
      icon: renaultFavIcon,
      brandName: 'renault',
      showPageTitle: false,
      pageTitleTranslationKey: 'seMotorPageTitleKontakt',
      productionUrl: `https://${REPORT_CLAIM_KONTAKT_PREFIX}.${SwedenMotorPartnerHostNames.Renault}`,
      testUrl: `https://${REPORT_CLAIM_KONTAKT_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Renault}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Renault}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'renault',
      portfolio: 'VRF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Saab,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: saabTheme,
      logoDesktop: saabLogo,
      homePageIcon: saabHomeIcon,
      icon: saabFavIcon,
      brandName: 'saab',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Saab}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Saab}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Saab}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'saab',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Saab,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: saabTheme,
      logoDesktop: saabLogo,
      homePageIcon: saabHomeIcon,
      icon: saabFavIcon,
      brandName: 'saab',
      showPageTitle: false,
      pageTitleTranslationKey: 'seMotorPageTitleKontakt',
      productionUrl: `https://${REPORT_CLAIM_KONTAKT_PREFIX}.${SwedenMotorPartnerHostNames.Saab}`,
      testUrl: `https://${REPORT_CLAIM_KONTAKT_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Saab}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Saab}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'saab',
      portfolio: 'IMF',
    },
  },

  {
    path: AppRoutesSwedenMotorPartners.Seat,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: seatTheme,
      logoDesktop: seatLogo,
      homePageIcon: seatHomeIcon,
      icon: seatFavIcon,
      brandName: 'seat',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Seat}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Seat}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Seat}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'seat',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Seat,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: seatTheme,
      logoDesktop: seatLogo,
      homePageIcon: seatHomeIcon,
      icon: seatFavIcon,
      brandName: 'seat',
      showPageTitle: false,
      pageTitleTranslationKey: 'seMotorPageTitleKontakt',
      productionUrl: `https://${REPORT_CLAIM_KONTAKT_PREFIX}.${SwedenMotorPartnerHostNames.Seat}`,
      testUrl: `https://${REPORT_CLAIM_KONTAKT_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Seat}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Seat}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'seat',
      portfolio: 'IMF',
    },
  },

  {
    path: AppRoutesSwedenMotorPartners.Skoda,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: skodaTheme,
      logoDesktop: skodaLogo,
      homePageIcon: skodaHomeIcon,
      icon: skodaFavIcon,
      brandName: 'skoda',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Skoda}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Skoda}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Skoda}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'skoda',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Skoda,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: skodaTheme,
      logoDesktop: skodaLogo,
      homePageIcon: skodaHomeIcon,
      icon: skodaFavIcon,
      brandName: 'skoda',
      showPageTitle: false,
      pageTitleTranslationKey: 'seMotorPageTitleKontakt',
      productionUrl: `https://${REPORT_CLAIM_KONTAKT_PREFIX}.${SwedenMotorPartnerHostNames.Skoda}`,
      testUrl: `https://${REPORT_CLAIM_KONTAKT_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Skoda}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Skoda}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'skoda',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Subaru,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: subaruTheme,
      logoDesktop: subaruLogo,
      homePageIcon: subaruHomeIcon,
      icon: subaruFavIcon,
      brandName: 'subaru',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Subaru}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Subaru}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Subaru}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'subaru',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Subaru,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: subaruTheme,
      logoDesktop: subaruLogo,
      homePageIcon: subaruHomeIcon,
      icon: subaruFavIcon,
      brandName: 'subaru',
      showPageTitle: false,
      pageTitleTranslationKey: 'seMotorPageTitleKontakt',
      productionUrl: `https://${REPORT_CLAIM_KONTAKT_PREFIX}.${SwedenMotorPartnerHostNames.Subaru}`,
      testUrl: `https://${REPORT_CLAIM_KONTAKT_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Subaru}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Subaru}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'subaru',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Volkswagen,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: volkswagenTheme,
      logoDesktop: volkswagenLogo,
      homePageIcon: volkswagenHomeIcon,
      icon: volkswagenFavIcon,
      brandName: 'volkswagen',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Volkswagen}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Volkswagen}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Volkswagen}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'volkswagen',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Volkswagen,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: volkswagenTheme,
      logoDesktop: volkswagenLogo,
      homePageIcon: volkswagenHomeIcon,
      icon: volkswagenFavIcon,
      brandName: 'volkswagen',
      showPageTitle: false,
      pageTitleTranslationKey: 'seMotorPageTitleKontakt',
      productionUrl: `https://${REPORT_CLAIM_KONTAKT_PREFIX}.${SwedenMotorPartnerHostNames.Volkswagen}`,
      testUrl: `https://${REPORT_CLAIM_KONTAKT_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Volkswagen}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Volkswagen}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'volkswagen',
      portfolio: 'IMF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.VolkswagenTransport,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: volkswagenTheme,
      logoDesktop: volkswagenLogo,
      homePageIcon: volkswagenTransportHomeIcon,
      icon: volkswagenTransportFavIcon,
      brandName: 'volkswagentb',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.VolkswagenTransport}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.VolkswagenTransport}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.VolkswagenTransport}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'volkswagen',
      portfolio: 'IMF',
    },
  },

  {
    path: AppRoutesSwedenMotorPartners.Volvia,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: volviaTheme,
      logoDesktop: volviaLogo,
      homePageIcon: volviaHomeIcon,
      icon: volviaFavIcon,
      brandName: 'volvia',
      showPageTitle: false,
      productionUrl: `https://${REPORT_CLAIM_PREFIX}.${SwedenMotorPartnerHostNames.Volvia}`,
      testUrl: `https://${REPORT_CLAIM_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Volvia}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Volvia}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'volvia',
      portfolio: 'VRF',
    },
  },
  {
    path: AppRoutesSwedenMotorPartners.Volvia,
    pageConfig: {
      tenantId: SWEDEN_MOTOR_TENANT,
      country: Country.SE,
      locale: Locale.SV,
      customTheme: volviaTheme,
      logoDesktop: volviaLogo,
      homePageIcon: volviaHomeIcon,
      icon: volviaFavIcon,
      brandName: 'volvia',
      showPageTitle: false,
      pageTitleTranslationKey: 'seMotorPageTitleKontakt',
      productionUrl: `https://${REPORT_CLAIM_KONTAKT_PREFIX}.${SwedenMotorPartnerHostNames.Volvia}`,
      testUrl: `https://${REPORT_CLAIM_KONTAKT_TEST_PREFIX}.${SwedenMotorPartnerHostNames.Volvia}`,
      contactSupportLinks: {
        default: `https://${SwedenMotorPartnerHostNames.Volvia}/${CONTACT_SUPPORT_SUFFIX}`,
      },
      idmProfile: 'volvia',
      portfolio: 'VRF',
    },
  },
];

export default swedenMotorPartnerMapping;
