import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ClickOutsidePassThroughProps } from '../../../../containers/ClickOutside/ClickOutside';
import { useInitialFocusCallback } from '../../../../utils/customHooks';
import { KeyCodes } from '../../../../constants';
import { translate, Translations } from '../../../../locale';
import { Dimensions } from '../../../../styles/types';
import dimensions from '../../../../styles/dimensions.module.scss';
import classes from './TextInput.module.scss';
import BaseTranscript from '../../../BaseTranscript/BaseTranscript';
import { AriaLive } from '../../../../constants/aria';

export interface TextInputBoxProps {
  inputValue: string;
  setInputValue: (value: string) => void;
  placeholder?: string;
  maxLength: number;
  submitResponse: () => void;
}

function calculateTextAreaHeight(
  shadowElement: HTMLDivElement | null,
  textAreaCurrentHeight: number
): number {
  if (!shadowElement) {
    return 0;
  }
  return shadowElement.clientHeight < textAreaCurrentHeight
    ? textAreaCurrentHeight
    : shadowElement.clientHeight;
}

export default function TextInputBox(
  props: TextInputBoxProps & ClickOutsidePassThroughProps
): JSX.Element {
  const { sendBoxLineHeight } = dimensions as unknown as Dimensions;
  const {
    submitResponse,
    inputValue,
    setInputValue,
    placeholder,
    maxLength,
    clickedOutside,
    setClickedOutside,
  } = props;

  let shadowTextArea: HTMLDivElement | null = null;

  const [textAreaHeight, setTextAreaHeight] =
    useState<number>(sendBoxLineHeight);

  const notifyOfCharacterLimit = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    const textLength = (event.target as HTMLTextAreaElement).value.length;
    const symbolsRemaining = Number(props.maxLength) - Number(textLength);

    if (symbolsRemaining === 0) {
      setTranscriptMessage(
        translate(Translations.textInput.characterLimitReached, null, [
          props.maxLength.toString(),
        ])
      );
    } else {
      setTranscriptMessage('');
    }
  };

  const [transcriptMessage, setTranscriptMessage] = useState<string>('');
  const [textAreaRef, setTextAreaRef] = useInitialFocusCallback();

  const clickInside = useCallback(() => {
    setClickedOutside(false);
  }, [setClickedOutside]);

  const clickOutside = useCallback(() => {
    setClickedOutside(true);
  }, [setClickedOutside]);

  useEffect(() => {
    if (shadowTextArea) {
      setTextAreaHeight(
        calculateTextAreaHeight(shadowTextArea, textAreaHeight)
      );
    }
  }, [shadowTextArea, textAreaHeight, inputValue]);

  useEffect(() => {
    const currentTextAreaRef = textAreaRef.current;
    currentTextAreaRef?.addEventListener('focus', clickInside);
    currentTextAreaRef?.addEventListener('blur', clickOutside);

    return () => {
      currentTextAreaRef?.removeEventListener('focus', clickInside);
      currentTextAreaRef?.removeEventListener('blur', clickOutside);
    };
  }, [textAreaRef, clickOutside, clickInside]);

  const keypressHandler = (event: React.KeyboardEvent) => {
    if (shadowTextArea) {
      setTextAreaHeight(
        calculateTextAreaHeight(shadowTextArea, textAreaHeight)
      );
    }
    if (event.key !== KeyCodes.Enter) {
      return;
    }
    if (event.shiftKey) {
      setInputValue(inputValue);
    } else {
      event.preventDefault();
      event.stopPropagation();
      if (!inputValue) {
        return;
      }
      submitResponse();
    }
  };

  return (
    <div
      data-testid={classes.TextAreaContainer}
      className={classNames(
        classes.TextAreaContainer,
        !clickedOutside ? classes.Focused : null
      )}
    >
      <textarea
        ref={setTextAreaRef}
        className={classes.TextArea}
        autoComplete="off"
        aria-autocomplete="none"
        aria-multiline="true"
        maxLength={maxLength}
        onKeyDown={notifyOfCharacterLimit}
        placeholder={placeholder || translate(Translations.inputBoxPlaceholder)}
        aria-label={translate(
          Translations.textInput.limitedToCharacters,
          null,
          [props.maxLength.toString()]
        )}
        value={inputValue}
        onChange={(event) => setInputValue(event.target.value)}
        onKeyPress={keypressHandler}
        style={{
          height: `${textAreaHeight}px`,
        }}
        aria-required="true"
      />
      <div
        aria-hidden="true"
        className={classes.ShadowTextArea}
        ref={(ref) => {
          if (ref) {
            shadowTextArea = ref;
          }
        }}
      >
        {inputValue}
      </div>
      <BaseTranscript
        politenessSetting={AriaLive.ASSERTIVE}
        transcriptMessage={transcriptMessage}
      />
    </div>
  );
}
